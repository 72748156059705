import './agentStatus.css';

import React, { ReactNode } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { HUNDRENT_PERCENT } from '../../../constants/common.constants';
import { Progress } from 'antd';
import TickSuccessIcon from '../icons/TickSuccessIcon';
import { twMerge } from 'tailwind-merge';

interface AgentStatusProps {
  title: string;
  children: ReactNode;
  className?: string;
  percentage: number;
  subText: string;
  onClick: () => void;
}
const AgentStatus: React.FC<AgentStatusProps> = ({
  title,
  children,
  className,
  percentage,
  subText,
  onClick,
}) => {
  const isCompleted = percentage === HUNDRENT_PERCENT;

  return (
    <div
      className={twMerge(
        'p-[22px] rounded-md font-figtree cursor-pointer flex flex-col gap-2 flex-1 override-ant-progress-line',
        isCompleted
          ? 'bg-[#EDF9FF] text-dark-blue border border-[#B5E6FF]'
          : 'bg-dark-blue text-[#FAFAFA] border border-dark-blue',
        className
      )}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <div className="font-semibold text-xl text-inherit">{title}</div>
        <ArrowRightOutlined />
      </div>
      <div className="text-xs text-inherit min-h-[43px] leading-[14.4px] max-w-[300px]">
        {children}
      </div>
      <div className="flex gap-1 items-center">
        <Progress
          percent={percentage}
          trailColor="#EDF9FF"
          size="small"
          className="flex-1"
          showInfo={false}
          strokeColor={'#00A6FB'}
          success={{
            ...(isCompleted
              ? { strokeColor: '#0F9D58', percent: percentage }
              : {}),
          }}
        />{' '}
        <div className="font-medium text-inherit  flex gap-1 items-center  text-sm ">
          {subText} {isCompleted ? <TickSuccessIcon /> : null}
        </div>
      </div>
    </div>
  );
};

export default AgentStatus;
