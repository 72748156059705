import { Button, Col, Modal, Row, Typography } from 'antd';

import { useState } from 'react';

type ErrorProps = {
  errorMessage: any;
  templateLink: string;
  onClose: () => void;
};

function OnboardError(props: ErrorProps) {
  const [isModalVisible, setModalVisibility] = useState(true);
  return (
    <div>
      <Modal
        closable={false}
        onCancel={props.onClose}
        open={isModalVisible}
        footer={[
          <div
            style={{ textAlign: 'center' }}
            key={'onboard-error-modal-footer'}
          >
            <Button
              href={props.templateLink}
              className="button secondary-button"
              style={{ height: '34px' }}
              key="ok"
            >
              Download Sample
            </Button>
            <Button
              style={{ height: '34px' }}
              key="ok"
              onClick={() => {
                setModalVisibility(false);
                props.onClose();
              }}
            >
              Back to Import Producers
            </Button>
          </div>,
        ]}
      >
        <Row style={{ paddingTop: '20px', paddingLeft: '20px' }}>
          <Col span={3}>
            <Typography.Paragraph
              style={{
                fontSize: '24px',
                fontWeight: 500,
                color: 'var(--secondary-color)',
              }}
            >
              #
            </Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph
              style={{
                fontSize: '24px',
                fontWeight: 500,
                color: 'var(--secondary-color)',
              }}
            >
              Column Header Error
            </Typography.Paragraph>
          </Col>
        </Row>
        <hr
          className="secondary-line"
          style={{ marginTop: '-15px', marginBottom: '20px' }}
        />
        {props.errorMessage.map((error: any, index: number) => {
          return (
            <Row key={index}>
              <Col span={4}>
                <Typography.Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'var(--secondary-color)',
                    marginLeft: '25px',
                  }}
                >
                  {index + 1}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Typography.Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'var(--secondary-color)',
                  }}
                >
                  Column Header -{' '}
                  <span style={{ color: '#F5222D' }}> {error}</span>
                </Typography.Paragraph>
              </Col>
            </Row>
          );
        })}
      </Modal>
    </div>
  );
}

export default OnboardError;
