import {
  ActionRequiredStatus,
  AllLicensesProps,
  NiprActionIcon,
  PendingLicenseType,
  getNiprActionStatus,
  getNiprStatusColor,
} from './pending-producer.utils';
import {
  AutoComplete,
  Button,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  DYNAMIC_DEFAULT_FILTER,
  DYNAMIC_DEFAULT_SELECTED_FILTER,
} from '../actionable';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  UnresolvedTooltip,
  formatDateWithTime,
} from '../../../../utils/date.utils';
import { applySort, isEnumValue } from '../../../../utils/common.utils';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../utils/search.utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AgencyService } from '../../../../services/agency.service';
import { AgentService } from '../../../../services/agent.service';
import { ApplicationStatusEnum } from '../../../../enums/applicationStatus.enum';
import ApplicationTypeCell from '../../../../components/applications/applicationTypeCell';
import { ColumnsType } from 'antd/es/table';
import FeesCell from '../../../../components/applications/feesCell';
import FilterButton from '../../../../components/filterButton';
import { FilterType } from '../../../../enums/filterType.enum';
import { IdConstants } from '../../../../constants/id.constants';
import { LicenseApplicationResponse } from '../../../../types/response/license-application.type';
import { LicensesService } from '../../../../services/licenses.service';
import { LoaDetail } from '../../../../types/data/loaDetail.type';
import MarkAsDoneModal from './markAsDoneModal';
import { PageConstants } from '../../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../../types/response/paginatedSuccessResponse.type';
import PendingApplicationModal from '../../../../utils/modal/application-status/pendingApplication.modal';
import { PlausiblePageConstants } from '../../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../enums/roles.enum';
import { RouteConstants } from '../../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../../constants/regex.constants';
import SimpleFilter from '../../../../components/common/simpleFilter/simpleFilter';
import { StateConstants } from '../../../../constants/state.constants';
import { UserType } from '../../../../enums/userType.enum';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import axios from 'axios';
import { getNameString } from '../../../../utils/name.utils';
import { getTimeDifference } from '../../../../utils/date/date-difference';
import { isEmpty } from 'lodash';
import { setFilterGroups } from '../../../../utils/setSimpleFiltersRequest.utils';
import { signupStore } from '../../../../stores/signupStore';
import { useAuth } from '../../../../auth/authProvider';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../../auth/useRoles';

const { Search } = Input;

const PendingProducerApplications: React.FC<AllLicensesProps> = ({
  isAgentOverview,
  isAgentPortal,
}) => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const { agentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeData, setActiveData] = useState<LicenseApplicationResponse[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPendingApplicationLoading, setIsPendingApplicationLoading] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');

  const navigate = useNavigate();
  const [selectedLicenseDetails, setSelectedLicenseDetails] =
    useState<LicenseApplicationResponse | null>(null);
  const { getAccessTokenSilently } = useAuth();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean | string>(false);
  const [selectedType, setSelectedType] = useState(PendingLicenseType.ALL);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [isUpdating, setIsUpdating] = useState<number[]>([]);
  const [markAsDoneModal, setMarkAsDoneModal] = useState<null | {
    commentCode: number;
    isDone: boolean;
  }>(null);
  const [followupIcon, setFollowUpIcon] = useState<
    null | ActionRequiredStatus | 'unset' // for no icon at the top
  >(null);
  const [isMarkasDoneConfirmationDone, setMarkasDoneConfirmationDone] =
    useState(false); // mark as done modal is needed for first time only

  useEffect(() => {
    const type = searchParams.get('quickFilter') || selectedType;
    fetchLicenses(undefined, undefined, undefined, undefined, type);
  }, [adminStore.account, queryText, searchType, agentId]);

  const updateActionRequiredFlag = async (
    commentCode: number,
    isDone: boolean
  ) => {
    try {
      if (!selectedLicenseDetails?.id) return;

      setMarkasDoneConfirmationDone(true);
      setIsUpdating((prev) => [...prev, commentCode]);
      const token = await getAccessTokenSilently();

      await AgencyService.updateActionRequiredFlagInPendingApplications(
        token,
        selectedLicenseDetails?.id,
        commentCode,
        isDone,
        !!isAgentPortal
      );

      await fetchPendingApplication(selectedLicenseDetails!, true);

      setMarkAsDoneModal(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating((prev) => prev.filter((d) => d !== commentCode));
    }
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        let response: any;
        if (isAgentPortal) {
          response =
            await LicensesService.getFilterInfoForAgentApplication(token);
        } else {
          response = await LicensesService.getFilterInfoForFindApplications(
            token,
            requestBody
          );
        }
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH &&
                ((!isAgentOverview && !isAgentPortal) || data.key !== 'name')
            ),
            PageConstants.PRODUCER_APPLICATIONS
          )
        );
        return isAgentOverview || isAgentPortal
          ? response?.data?.filter((data: any) => data.key !== 'producerIds')
          : response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const updateFollowUpIconStatus = (
    follupRecords: LicenseApplicationResponse[]
  ) => {
    if (follupRecords?.length === 0) {
      setFollowUpIcon('unset');
      return;
    }

    const followUpStatus = follupRecords?.map((d) => getNiprActionStatus(d));

    if (
      followUpStatus?.some((d) => d === ActionRequiredStatus.ACTION_REQUIRED)
    ) {
      setFollowUpIcon(ActionRequiredStatus.ACTION_REQUIRED);
    } else if (
      followUpStatus?.some((d) => d === ActionRequiredStatus.IN_PROGRESS)
    ) {
      setFollowUpIcon(ActionRequiredStatus.IN_PROGRESS);
    } else {
      setFollowUpIcon(ActionRequiredStatus.COMPLETED);
    }
  };

  const fetchFollowUpStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const followupRecords =
        await LicensesService.getApplicationsForCurrentAgency(
          {},
          token,
          1000,
          1,
          '',
          '',
          PendingLicenseType.FOLLOW_UP,
          agentId,
          true,
          isAgentPortal
        );
      updateFollowUpIconStatus(followupRecords?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowUpStatus();
  }, []);

  const fetchLicenses = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object,
    type?: string // selectedType
  ) => {
    if (!adminStore.agency?.id) return;

    try {
      const selectedTypes = type || selectedType;
      setIsLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        // showOnlyAssignedStates: true,
      };
      const token = await getAccessTokenSilently();
      let filter = {};
      if (
        isAgentOverview &&
        searchParams.has('producerId') &&
        !(Object.keys(selectedFilters?.data)?.length > 1)
      ) {
        const producerId = searchParams.get('producerId') || '';
        setSelectedFilters({
          data: DYNAMIC_DEFAULT_SELECTED_FILTER('', {
            value: producerId,
            label: 'producerName', // we don't show in the chip
          }),
        } as unknown as FiltersType);
        filter = {
          filterGroups: DYNAMIC_DEFAULT_FILTER('', producerId || ''),
        };
        if (!isAgentOverview) searchParams.delete('producerId');
        searchParams.delete('producerName');
        if (!isAgentOverview) setRequestBody({ ...requestBody, ...filter });
        setSearchParams(searchParams.toString(), { replace: true });
      }
      const response: PaginatedSuccessResponse<LicenseApplicationResponse> | void =
        await LicensesService.getApplicationsForCurrentAgency(
          {
            ...requestBody,
            ...defaultRequestBody,
            ...filter,
            ...(sort || sortValue || {}),
            ...(isAgentOverview && {
              agentIds: [agentId],
            }),
          },
          token,
          size || pageSize,
          page || pageNumber,
          searchTextProp
            ? searchTextProp === RESET_SEARCH_STRING
              ? ''
              : searchTextProp
            : queryText || '',
          searchType ? searchType : searchType || '',
          selectedTypes,
          agentId,
          undefined,
          isAgentPortal
        );
      if (response) {
        setTotalCount(response.totalCount);
        setActiveData(response.data);
        setIsLoading(false);
      } else {
        setTotalCount(0);
        setActiveData([]);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
      }
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const type = searchParams.get('quickFilter');
    if (type && isEnumValue(PendingLicenseType, type)) {
      setSelectedType(type);
    }
  }, []);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const getAgentDetails = async () => {
    const bearerToken = await getAccessTokenSilently();
    const response: any = await AgentService.getAgentDetails(
      agentId || '',
      bearerToken || ''
    ).catch((error) => {
      console.error('Error:: ', error.message);
    });
    if (response.data) {
      appStateInfoStore.setCurrentAgentId(response.data.id);
      appStateInfoStore.setHeader(getNameString(response.data.name));
    }
  };

  useEffect(() => {
    if (!appStateInfoStore.header && isAgentOverview && agentId) {
      getAgentDetails();
    }
  }, [agentId]);

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenses(queryText, newPageNumber, newPageSize);
  };

  const refreshPoll = async (id?: string) => {
    const token = await getAccessTokenSilently();
    setIsRefreshing(id || true);
    try {
      if (id) await LicensesService.getPollingStatusById(id, token);
      else await LicensesService.getRefreshOpenApplications(token);
      message.success(
        'Polling initiated successfully, please refresh in sometime'
      );
      fetchLicenses();
    } catch (err) {
      message.error('Polling refresh failed. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const redirect = (agent: any) => {
    appStateInfoStore.setHeader(getNameString(agent.name));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', agent._id));
  };

  const Applicationcolumns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'license',
      sorter: true,
      width: 170,
      align: 'left',
      key: 'license.stateCode',
      render: (license: any, record: LicenseApplicationResponse) => {
        const niprAction = getNiprActionStatus(record);
        let style = '';
        if (niprAction) {
          style = 'bg-[#DAF2FF] border border-[#4F9EFF] px-2 py-1 rounded-md';
        }
        return (
          <div key={record.id}>
            <span
              className={'text-base-style ' + style}
              style={{
                lineHeight: '20px',
                letterSpacing: '0.5%',
              }}
            >
              {StateConstants[license?.[0]?.stateCode]}{' '}
              <NiprActionIcon niprAction={niprAction} />
            </span>
          </div>
        );
      },
    },
    ...(isAgentOverview || isAgentPortal
      ? []
      : [
          {
            title: 'Producer Name',
            dataIndex: 'producer',
            sorter: subscriptionStatusInactive ? false : true,
            width: 140,
            key: 'producer.name',
            render: (producer: any) => (
              <div className="agent-link figtree text-[#0074B0] leading-5 text-xs">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    redirect(producer?.[0]);
                  }}
                >
                  {getNameString(producer?.[0]?.name)}
                </span>
              </div>
            ),
          },
        ]),
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      width: '350px',
      sorter: true,
      className: 'cell-with-lines agency-license-state-row center-column',
      render: (licenseConfigurations: any, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center !important',
              alignItems: 'flex-start',
              padding: '8px 0',
            }}
          >
            <Row style={{ width: '40%', alignContent: 'center' }}>
              <div
                style={{
                  gap: '8px',
                  width: '100%',
                  paddingRight: 12,
                  boxSizing: 'border-box',
                  flexGrow: 1,
                }}
              >
                <Tooltip
                  title={`${
                    licenseConfigurations?.[0]?.licenseClass
                  } (${licenseConfigurations?.[0]?.licenseClassCode})`}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#001F45',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      marginBottom: 0,
                    }}
                    className="margin-unset"
                    ellipsis
                  >
                    {`${
                      licenseConfigurations?.[0]?.licenseClass
                    } (${licenseConfigurations?.[0]?.licenseClassCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            </Row>

            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '43%',
                bottom: 0,
                width: '1px',
                backgroundColor: '#d9d9d9',
                height: '100%',
              }}
            />
            <div
              style={{
                width: '55%',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                alignSelf: 'center',
                marginLeft: '3%',
              }}
            >
              {licenseConfigurations.map((loa: LoaDetail, index: number) => {
                return (
                  <Space key={index}>
                    <Typography.Paragraph
                      style={{
                        margin: 0,
                        textAlignLast: 'start',
                        color: '#222222',
                      }}
                      className="margin-unset"
                    >
                      {loa.loa} ({loa.loaCode})
                    </Typography.Paragraph>
                  </Space>
                );
              })}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Fee',
      dataIndex: 'latestApplication',
      key: 'gatewayTransaction.niprGatewaySuccessResponse.stateFee',
      sorter: subscriptionStatusInactive ? false : true,
      width: '100px',
      render: (_: any, record: any) => {
        return <FeesCell fees={record} />;
      },
    },

    ...(selectedType === PendingLicenseType.ALL
      ? [
          {
            title: 'Type',
            dataIndex: 'latestApplication',
            key: 'applicationType',
            width: 150,
            sorter: subscriptionStatusInactive ? false : true,
            align: 'center' as any,
            className: 'center-column',
            render: (_: any, record: LicenseApplicationResponse) => (
              <div className="grid place-content-center">
                <ApplicationTypeCell
                  applicationType={record?.applicationType}
                />
              </div>
            ),
          },
          {
            title: 'Applied on',
            sorter: subscriptionStatusInactive ? false : true,
            dataIndex: 'latestApplication',
            key: 'applyDate',
            width: 120,
            align: 'center' as any,
            className: 'center-column',
            render: (_: any, record: LicenseApplicationResponse) => (
              <Row align="middle" className="grid place-content-center">
                <Typography.Paragraph
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                    marginBottom: 0,
                  }}
                >
                  {record?.applyDate ? (
                    <Tooltip title={formatDateWithTime(record.applyDate)}>
                      {getTimeDifference(record.applyDate.toString())}
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </Typography.Paragraph>
              </Row>
            ),
          },
          {
            // title: 'Polled On',
            title: (
              <p className="flex gap-3 items-center justify-center">
                Polled On{' '}
                {!agentId &&
                !subscriptionStatusInactive &&
                (!isAgentPortal ||
                  signupStore.getUserType() ===
                    UserType.INDIVIDUAL_PRODUCER) ? (
                  <Tooltip
                    title={
                      isRefreshing === true ? 'Refreshing...' : 'Refresh Poll'
                    }
                  >
                    {isRefreshing === true ? (
                      <Spin size="small" />
                    ) : (
                      <ReloadOutlined
                        style={{ fontSize: 14, color: '#0588ca' }}
                        onClick={() => refreshPoll()}
                        className="cursor-pointer primary-color"
                      />
                    )}
                  </Tooltip>
                ) : null}
              </p>
            ),
            width: 180,
            dataIndex: 'latestApplication',
            key: 'latestApplication',
            align: 'center' as any,
            className: 'center-column',
            render: (_: any, record: any) => {
              const lastPolledDate = record.lastPolledDate;
              return (
                <Row align="middle" className="grid place-content-center">
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      whiteSpace: 'nowrap',
                      marginBottom: 0,
                    }}
                  >
                    {record.gatewayTransaction?.submissionSuccess ? (
                      lastPolledDate ? (
                        <Tooltip
                          title={formatDateWithTime(new Date(lastPolledDate))}
                        >
                          {getTimeDifference(lastPolledDate)}
                        </Tooltip>
                      ) : (
                        ' NIPR polling not initiated'
                      )
                    ) : (
                      <>
                        {record?.status === ApplicationStatusEnum.Declined ? (
                          'Polling Complete'
                        ) : (
                          <p
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: 12,
                            }}
                          >
                            <Tooltip title={UnresolvedTooltip()}>
                              NIPR polling not initiated{' '}
                            </Tooltip>
                          </p>
                        )}
                      </>
                    )}
                  </Typography.Paragraph>
                </Row>
              );
            },
          },
        ]
      : [
          {
            title: 'Follow Up',
            sorter: subscriptionStatusInactive ? false : true,
            dataIndex: 'followUp',
            key: 'applyDate',
            width: 120,
            render: (_: any, record: any) => {
              const value = getNiprActionStatus(record);
              return (
                <span
                  className={`${getNiprStatusColor(value!)} border-[1.1px]  font-figtree text-xs leading-[14.4px] py-[2px] px-2 rounded-2xl`}
                >
                  {value}
                </span>
              );
            },
          },
        ]),
    // {
    //   title: 'Application Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: true,
    //   width: '175px',
    //   align: 'center',
    //   fixed: 'right',
    //   render: (_: any, record: any) => {
    //     return (
    //       <Row
    //         id={
    //           IdConstants.LICENSES.APPLICATIONS.TABLE.VIEW_DETAILS +
    //           '-' +
    //           record.license.licenseNumber
    //         }
    //         align="middle"
    //         style={{
    //           textAlign: 'center',
    //         }}
    //         onClick={() => {
    //           if (record?.status !== ApplicationStatusEnum.Not_Applied)
    //             handleRowClick(record);
    //         }}
    //       >
    //         <ApplicationStatusCell
    //           applicationStatus={
    //             record?.status.charAt(0).toUpperCase() + record?.status.slice(1)
    //           }
    //         />
    //       </Row>
    //     );
    //   },
    // },
  ];

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    let data = {};
    if (isAgentOverview || isAgentPortal) {
      data = {
        ...Object.fromEntries(
          Object.entries(selectedFilters?.data)?.filter(
            ([k, v]) => k === 'Producer'
          )
        ),
      };
    }

    setSelectedFilters({ data });
    const filterGroups = setFilterGroups({ data });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchLicenses('', 1);
    setPageNumber(1);
  }, [requestBody]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_PENDING_APPLICATION;

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  const QUICK_FILTERS = [
    {
      type: PendingLicenseType.ALL,
      label: `All`,
    },
    {
      type: PendingLicenseType.FOLLOW_UP,
      label: (
        <div>
          Follow-Ups <NiprActionIcon niprAction={followupIcon} />
        </div>
      ),
    },
  ];

  const fetchPendingApplication = async (
    record: LicenseApplicationResponse,
    updateRecord?: boolean
  ) => {
    try {
      const token = await getAccessTokenSilently();
      if (!updateRecord) setIsPendingApplicationLoading(true);
      const response = await LicensesService.getPendingApplicationById(
        record.id,
        token,
        !!isAgentPortal
      );
      const selectedLicense = {
        ...record,
        gatewayTransaction: response?.data?.gatewayTransaction,
      };
      setSelectedLicenseDetails(selectedLicense);
      if (updateRecord) {
        const updatedActiveData = activeData?.map((d) => {
          if (d.id === selectedLicense?.id) return selectedLicense;
          return d;
        });
        setActiveData(updatedActiveData);
        updateFollowUpIconStatus(updatedActiveData);
      }
      setIsPendingApplicationLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsPendingApplicationLoading(false);
      }
      console.error(error);
    }
  };

  return (
    <div className="relative w-full h-fit">
      <div className="reset-icon-size mb-[10px]">
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          <InfoCircleOutlined />{' '}
          <span>
            Review all submitted license applications awaiting NIPR approval.
            Use the poll option to receive real-time updates from NIPR on these
            applications.
          </span>
        </Typography.Text>
      </div>
      <div className="figtree v2-filter-button v2-search reset-icon-size flex gap-x-[10px] mb-[10px]">
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          disabled={isLoading || subscriptionStatusInactive}
          value={getSearchDisplayText(
            searchType,
            displaySearchType,
            searchText
          )}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
        >
          <Search
            id={IdConstants.SEARCH_INPUT + '-licenses-applications'}
            placeholder={
              isAgentOverview || isAgentPortal
                ? 'Search Applications by State, LOAs or License Class'
                : 'Search Applications by State, Producer, LOAs or License Class'
            }
            style={{
              width: 420,
            }}
            onSearch={handleOnSearch}
          />
        </AutoComplete>

        <FilterButton
          {...{
            filterInfoLoading,
            activeData,
            requestBody,
            isLoading,
            setIsFilterVisible,
            pageName,
          }}
        />
      </div>
      <div
        style={{
          scrollbarWidth: 'none',
        }}
        className="pb-2 gap-2 flex overflow-x-scroll sc"
      >
        <div className="flex gap-2 w-[50%]">
          {QUICK_FILTERS.map((filter) => {
            return (
              <Button
                key={filter.type}
                style={{
                  backgroundColor:
                    selectedType === filter.type ? '#001F45' : '',
                  color: selectedType === filter.type ? '#fff' : '',
                }}
                onClick={() => {
                  const type = filter.type;

                  if (selectedType === type) return;
                  setSelectedType(type);

                  const newSearchParams = new URLSearchParams(searchParams);

                  newSearchParams.set('quickFilter', filter.type);
                  setSearchParams(newSearchParams);

                  if (type === PendingLicenseType.ALL) {
                    setTimeout(() => {
                      fetchLicenses(
                        RESET_SEARCH_STRING,
                        1,
                        100,
                        undefined,
                        'unset'
                      );
                    }, 300);
                  } else {
                    setTimeout(() => {
                      fetchLicenses(
                        RESET_SEARCH_STRING,
                        1,
                        100,
                        undefined,
                        PendingLicenseType.FOLLOW_UP
                      );
                    }, 300);
                  }
                  // resetting the search and filters
                  setRequestBody({});
                  setSearchType('');
                  setSearchText('');
                  setSelectedFilters({ data: {} });
                  setPageNumber(1);
                  setActiveData([]);
                  if (queryText) setQueryText('');
                }}
                className="agency-license-buttons"
                disabled={subscriptionStatusInactive}
              >
                {filter.label}
              </Button>
            );
          })}
        </div>
      </div>
      <RenderFilterChip
        {...{
          selectedFilters:
            !isAgentOverview && !isAgentPortal
              ? selectedFilters
              : {
                  ...selectedFilters,
                  data: {
                    ...Object.fromEntries(
                      Object.entries(selectedFilters?.data)?.filter(
                        ([k, v]) => k !== 'Producer'
                      )
                    ),
                  },
                },
          removeFilter,
          clearFilters,
          pageName,
        }}
      />
      <div
        className="reset-icon-size table-layout-v2"
        style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
      >
        <Table
          columns={Applicationcolumns}
          dataSource={activeData}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                fetchPendingApplication(record);
              },
            };
          }}
          style={{
            cursor: 'pointer',
          }}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenses);
            setSortValue(sort);
          }}
          loading={isLoading}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
        />
        {!isEmpty(activeData) && (
          <Row justify="end">
            <Pagination
              showSizeChanger={false}
              pageSize={pageSize}
              current={pageNumber}
              onChange={onPaginationChange}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
      </div>

      <PendingApplicationModal
        selectedLicenseDetails={selectedLicenseDetails}
        isLoading={isPendingApplicationLoading}
        setSelectedLicenseDetails={setSelectedLicenseDetails}
        isUpdating={isUpdating}
        updateActionRequiredFlag={(commentCode, isDone) => {
          if (!isMarkasDoneConfirmationDone && isDone) {
            setMarkAsDoneModal({ commentCode, isDone });
          } else {
            updateActionRequiredFlag(commentCode, isDone);
          }
        }}
      />
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForFindAll()}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={pageName}
        filterPage={PageConstants.PRODUCER_APPLICATIONS}
      />
      <MarkAsDoneModal
        isUpdating={isUpdating}
        markAsDoneModal={markAsDoneModal}
        setMarkAsDoneModal={setMarkAsDoneModal}
        updateActionRequiredFlag={updateActionRequiredFlag}
        key="pending-producer-application-mark-as-done-modal"
      />
    </div>
  );
};

export const RESET_SEARCH_STRING =
  'unset_search_text-to-stop-useEffect-rerender-on-query-text-null';

export default withRoles(PendingProducerApplications, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
