import { AgencyDetailsOptions, DetailsAgencyKeys } from './details-constants';
import {
  daysToAutoRenewOptions,
  preferenceType,
} from '../../../types/agency/agencyPreference.type';
import { useEffect, useState } from 'react';

import GeneralProfile from './general';
import MyAgencyBackgroundQuestions from '../backgroundQuestions/myAgencyBackgroundQuestions';
import OwnershipDetails from './ownershipDetails/OwnershipDetails';
import Preferences from './Preferences';
import { RoleType } from '../../../enums/roles.enum';
import TabView from '../../../components/common/tabview/tabview';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../auth/useRoles';

function DetailsAgency() {
  const [preferences, setPreferences] = useState<preferenceType>({
    isLoading: false,
    preferences: {
      isProducerAlertEnabled: false,
      daysToAutoRenew: daysToAutoRenewOptions.FIFTEEN_DAYS,
    },
  });
  const [selectedTab, setSelectedTab] = useState(DetailsAgencyKeys.GENERAL);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (selected: string) => {
    setSelectedTab(selected);
    setSearchParams({ tab: selected }, { replace: true });
  };

  useEffect(() => {
    const params = searchParams.get('tab');
    if (params && Object.values(DetailsAgencyKeys).includes(params)) {
      setSelectedTab(params);
    }
  }, []);

  return (
    <div className="onboard-active w-full remove-margin">
      <TabView
        options={AgencyDetailsOptions}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      <div className="mt-3">
        {selectedTab === DetailsAgencyKeys.GENERAL && (
          <GeneralProfile setPreferences={setPreferences} />
        )}
        {selectedTab === DetailsAgencyKeys.OWNERSHIP_DETAILS && (
          <OwnershipDetails />
        )}
        {selectedTab === DetailsAgencyKeys.BACKGROUND_QUESTIONS && (
          <MyAgencyBackgroundQuestions />
        )}
        {selectedTab === DetailsAgencyKeys.PREFERENCES && (
          <Preferences preferences={preferences} />
        )}
      </div>
    </div>
  );
}
export default withRoles(DetailsAgency, [RoleType.SUPER_ADMIN, RoleType.ADMIN]);
