import {
  CsvJson,
  multipleOnboardStore,
} from '../../../stores/multipleOnboardStore';
import { Drawer, Modal, Table, Typography } from 'antd';
import React, { useState } from 'react';

import { BulkOnboardingStage } from '../../../enums/bulkOnboardingStage.enum';
import { CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import PreviewBanner from './preview-banner';

interface csvPreviewModalProps {
  csvJson: CsvJson[];
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleContinueOnboarding: () => void;
  onboardingStage?: string;
}

const PreviewCSVModal: React.FC<csvPreviewModalProps> = (props) => {
  const {
    modalVisibility,
    setModalVisibility,
    csvJson,
    handleContinueOnboarding,
    onboardingStage,
  } = props;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);

  const columnTitles: Record<string, string> = {
    name: 'Producer Name',
    ssn: 'SSN',
    firstName: 'First Name',
    lastName: 'Last Name',
    licenseNumber: 'License Number',
    state: 'State',
    npn: 'NPN',
    prefferredEmail: 'Preferred Email',
    gender: 'Gender',
    phone: 'Phone',
    email: 'Email',
    errorCondition: 'Error Condition',
  };

  const allKeys = new Set<string>();

  csvJson.forEach((item) => {
    Object.keys(item).forEach((key) => allKeys.add(key));
  });

  const columns: ColumnsType<CsvJson> = Array.from(allKeys).map((key) => ({
    title: columnTitles[key] ?? key,
    dataIndex: key,
    key: key,
    render: (text: string) => (text ? text : '-'),
  }));

  return (
    <Drawer
      open={modalVisibility}
      closable={false}
      width={'1037px !important'}
      headerStyle={{ display: 'none' }}
      destroyOnClose={true}
      placement="right"
      bodyStyle={{
        borderRadius: '16px',
        overflow: 'hidden',
      }}
      onClose={() => setModalVisibility(false)}
      footer={null}
      style={{
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        boxShadow: 'none',
        top: '0 !important',
      }}
      className="multiple-onboard-preview"
      rootClassName="multiple-onboard-preview"
    >
      <div className="flex flex-col gap-2 relative">
        <CloseOutlined
          onClick={() => setModalVisibility(false)}
          className="absolute top-4 right-4 cursor-pointer text-lg text-gray-600 hover:text-gray-800"
        />
        <span className="text-sm font-semibold font-figtree">
          CSV/XLSX Data Preview
        </span>

        {onboardingStage !== BulkOnboardingStage.RETRIEVE_SCB && (
          <span className="text-xs font-normal font-figtree text-[#848484]">
            Here is the list of existing data that has been successfully
            imported.
          </span>
        )}
      </div>
      {onboardingStage !== BulkOnboardingStage.RETRIEVE_SCB && (
        <PreviewBanner
          canOnboard={multipleOnboardStore.canBeOnboardCount}
          cannotOnboard={multipleOnboardStore.cannotBeOnboardedCount}
          handleContinueOnboarding={handleContinueOnboarding}
          onBoardErrorCSV={csvJson}
          onboardingStage={onboardingStage}
        />
      )}
      <Table
        columns={columns}
        dataSource={csvJson}
        className="agency-license-table"
        rowClassName={(record) => (record.errorCondition ? 'error-row' : '')}
        style={{ marginTop: '22px' }}
        pagination={{
          pageSize: pageSize,
          current: pageNumber,
          total: csvJson.length || 0,
          onChange: (page, pageSize) => setPageNumber(page),
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: false,
        }}
      />
    </Drawer>
  );
};

export default PreviewCSVModal;
