import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Col,
  Drawer,
  Input,
  Modal,
  Pagination,
  Row,
  Table,
  Tooltip,
  message,
} from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { deleteButtonStyles, duplicateButtonStyles } from './listBuilderUtils';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../utils/search.utils';
import { modalDesc, modalTitle } from '../organizationView/organizationUtils';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import AppliedFilter from './appliedFilter';
import CopyIconCustom from '../../../../components/common/icons/CopyIconCustom';
import DeleteIconCustom from '../../../../components/common/icons/DeleteIconCustom';
import { FilterFieldsMetaData } from '../../../../types/common/filters.type';
import { FilterType } from '../../../../enums/filterType.enum';
import { IdConstants } from '../../../../constants/id.constants';
import NoSavedLists from '../../../../assets/images/SavedListBucket.svg';
import { PageConstants } from '../../../../constants/page.constants';
import { RouteConstants } from '../../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../../constants/regex.constants';
import { applySort } from '../../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../../utils/api.utils';
import { getNameString } from '../../../../utils/name.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';

const { Search } = Input;

export interface SavedListDetailsType {
  listName: string;
  id: string;
  hiearchyListFilters: any;
}

const SavedList: React.FC = () => {
  const navigate = useNavigate();
  const [SavedListDetails, setSavedListDetails] = useState<
    SavedListDetailsType[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [deleteList, setDeleteList] = useState<{
    loading: boolean;
    isOpen: boolean;
  }>({
    loading: false,
    isOpen: false,
  });
  const [cloneList, setCloneList] = useState<{
    loading: boolean;
    isOpen: boolean;
  }>({
    loading: false,
    isOpen: false,
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [previewList, setPreviewList] = useState<SavedListDetailsType | null>(
    null
  );

  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  // search
  const [searchText, setSearchText] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryText, setQueryText] = useQueryState('search');
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  // pagination
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  // sort
  const [sortValue, setSortValue] = useState({});

  const pageNo = searchParams.get('page');

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    getAllSavedLists(queryText || '', newPageNumber, newPageSize);
    searchParams.set('page', `${newPageNumber}`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const page = Number(pageNo);
    if (!isNaN(page) && pageNo) {
      setPageNumber(page);
    } else {
      setPageNumber(1);
    }
  }, [pageNo]);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await AgencyService.getFilterInfoForHiearchySavedList(token);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.ASSIGNMENTS
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    fetchFiltersInfoForFindAll();
  }, []);

  const getAllSavedLists = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      setIsFetching(true);
      const bearerToken = await getAccessTokenSilently();
      if (cancelToken) {
        cancelPreviousRequest(cancelToken);
      }
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const reqBody = {
        ...(sort || sortValue || {}),
      };
      const savedList = await AgencyService.getHiearchyListView(
        bearerToken,
        page || pageNumber,
        100,
        searchTextProp ? searchTextProp : queryText || '',
        searchType || '',
        reqBody
      );
      setSavedListDetails(savedList?.data as SavedListDetailsType[]);
      setTotalCount(savedList?.totalCount);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAllSavedLists(undefined, pageNo ? Number(pageNo) : undefined);
  }, [queryText, searchType]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
        updateSearchParams(text);
      }
    }
  };

  const updateSearchParams = (query: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', `${1}`);
    newSearchParams.set('search', query);
    setSearchParams(newSearchParams);
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
        updateSearchParams(newSearchText);
      } else {
        setQueryText(value);
        updateSearchParams(value);
      }
      setPageNumber(1);
    }
  };

  const onDeleteListById = async () => {
    try {
      setDeleteList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const toBeDeletedRows = previewList ? [previewList?.id] : selectedRows;
      const response = await AgencyService.deleteHiearchyListByIds(
        bearerToken,
        toBeDeletedRows
      );
      setSavedListDetails((prev) =>
        prev.filter((d) => !toBeDeletedRows.includes(d.id))
      );
      setDeleteList((prev) => ({ ...prev, loading: false, isOpen: false }));
      setSelectedRows([]);
      setPreviewList(null);
      message?.success(response?.message);
    } catch (error: any) {
      message?.error(error?.response?.data?.message);
      setDeleteList((prev) => ({ ...prev, loading: false }));
    }
  };

  const getListName = (listName: string, payload: any[]): string => {
    const isListNamePresent = [...SavedListDetails, ...payload]?.find(
      (d) => d.listName === listName
    );

    if (!isListNamePresent) return listName;

    return getListName(`${listName} (1)`, payload);
  };

  const onCloneList = async () => {
    try {
      setCloneList((prev) => ({ ...prev, loading: true }));
      const bearerToken = await getAccessTokenSilently();
      const payloadList = SavedListDetails?.filter((d) =>
        previewList ? d.id === previewList?.id : selectedRows.includes(d.id)
      );
      const payload: any[] = [];

      payloadList.forEach((d) => {
        payload.push({
          ...d,
          listName: getListName(`${d?.listName} (1)`, payload),
        });
      });

      const response = await AgencyService.createHiearchyListView(
        bearerToken,
        payload
      );
      setSavedListDetails((prev) => [...response, ...prev]);
      setCloneList((prev) => ({
        ...prev,
        loading: false,
        isOpen: false,
      }));
      message.success(`The selected lists are duplicated`);
      setSelectedRows([]);
    } catch (error: any) {
      console.error(error);
      message?.error(
        error?.response?.data?.message ||
          'Failed to clone lists.Please Try Again.'
      );
      setCloneList((prev) => ({ ...prev, loading: false }));
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      width: '40%',
      sorter: true,
      render: (text: string, data: any) => {
        return (
          <div
            className="sub-heading-small figtree cursor-pointer"
            onClick={() =>
              navigate(
                RouteConstants.agencyHierarchy.path + `?id=${data?.id}&tab=2`
              )
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">{getNameString(text)}</div>
        );
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      sorter: true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">{getNameString(text)}</div>
        );
      },
    },
    {
      title: 'Last Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">
            {text ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}
          </div>
        );
      },
    },
    {
      dataIndex: 'preview',
      key: 'preview',
      width: 80,
      render: (_: string, record: any) => {
        return (
          <div
            className="figtree"
            style={{
              color: '#2286FF',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '22px',
            }}
            onClick={() => setPreviewList(record)}
          >
            Preview
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        margin: '12px 12px 0 0',
      }}
      className="table-layout-v2"
    >
      <div className="figtree sub-heading">
        <ArrowLeftOutlined
          style={{ marginRight: 12 }}
          className="cursor-pointer"
          onClick={() => navigate(RouteConstants.agencyHierarchy.path)}
        />
        Saved Lists
      </div>
      <div className="flex gap-4 v2-search mt-2">
        <AutoComplete
          options={options}
          style={{ width: '100%' }}
          value={getSearchDisplayText(
            searchType,
            displaySearchType,
            searchText
          )}
          disabled={isFetching || filterInfoLoading}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
        >
          <Search
            id={IdConstants.SEARCH_INPUT + '-hiearchy-saved-list'}
            size="middle"
            placeholder="Search by List Name, Created By, Updated By"
            style={{
              width: '100%',
            }}
            onSearch={handleOnSearch}
          />
        </AutoComplete>
        <Tooltip
          title={
            !selectedRows?.length ? 'Please select a list to duplicate.' : null
          }
        >
          <div className="flex">
            <Button
              className="figtree"
              style={{
                ...duplicateButtonStyles,
              }}
              onClick={() => {
                setCloneList((prev) => ({ ...prev, isOpen: true }));
              }}
              disabled={!selectedRows?.length || isFetching}
            >
              <CopyIconCustom />{' '}
              {`Duplicate List${selectedRows?.length > 1 ? 's' : ''}`}
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          title={
            !selectedRows?.length ? 'Please select a list to delete.' : null
          }
        >
          <div className="flex">
            <Button
              className="figtree"
              style={{
                ...deleteButtonStyles,
                margin: 'unset',
              }}
              onClick={() => {
                setDeleteList((prev) => ({ ...prev, isOpen: true }));
              }}
              disabled={!selectedRows?.length || isFetching}
            >
              <DeleteIconCustom /> Delete
            </Button>
          </div>
        </Tooltip>
      </div>
      {!isFetching && SavedListDetails?.length === 0 ? (
        <div
          className="flex-col-center"
          style={{
            width: '100%',
            height: 'calc(100vh - 240px)',
          }}
        >
          <img src={NoSavedLists} alt="no-saved-listes" />
          <Button
            className="figtree"
            style={{
              background: 'var(--Secondary-Secondary, #001F45)',
              color: '#ffffff',
              fontSize: 14,
              lineHeight: '22px',
              fontWeight: 500,
              margin: '12px 0',
            }}
            onClick={() => navigate(RouteConstants.agencyHierarchy.path)}
          >
            <PlusOutlined /> Create Saved List
          </Button>
          <div className="sub-heading-small figtree">
            You haven’t created any view so far!
          </div>
          <div className="sub-heading-small figtree">
            Create your first view to see the list of producers
          </div>
        </div>
      ) : (
        <>
          {' '}
          <Row className="table w-full my-[10px] mx-0 gap-y-[10px]">
            <Col span={24}>
              <Table
                id="id"
                rowClassName={(record) =>
                  selectedRows.includes(record?.id)
                    ? 'selected-producer-row'
                    : ''
                }
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={SavedListDetails}
                pagination={false}
                loading={isFetching}
                onChange={(pagination, filters, sorter) => {
                  const sort = applySort(sorter, getAllSavedLists);
                  setSortValue(sort);
                }}
                scroll={{
                  y: 'calc(100vh - 240px)',
                }}
              />
              {!isEmpty(SavedListDetails) && (
                <Row
                  className="pagination"
                  style={{ marginTop: '10px' }}
                  justify="end"
                >
                  <Pagination
                    defaultCurrent={1}
                    current={pageNumber}
                    total={totalCount}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    showSizeChanger={false}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    disabled={isFetching}
                  />
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
      <Modal
        width={450}
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            {`Do you want to Delete this saved list${
              selectedRows?.length > 1 ? 's' : ''
            }?`}
          </div>
        }
        open={!!deleteList?.isOpen}
        onOk={onDeleteListById}
        confirmLoading={deleteList?.loading}
        closable={!deleteList?.loading}
        onCancel={() => setDeleteList({ isOpen: false, loading: false })}
        okText={
          <div className="flex items-center gap-3">
            <DeleteIconCustom /> Delete
          </div>
        }
        className="ant-modal-footer-flex-justify-end"
        okButtonProps={{
          style: {
            background: '#EB3131',
          },
        }}
        destroyOnClose
      >
        {' '}
        <div style={{ ...modalDesc }}>
          Lists that have been deleted once cannot be revoked again
        </div>
      </Modal>
      <Modal
        title={
          <div
            style={{
              ...modalTitle,
            }}
          >
            {`Do you want to Duplicate this saved list${
              selectedRows?.length > 1 ? 's' : ''
            }?`}
          </div>
        }
        width={450}
        open={!!cloneList?.isOpen}
        onOk={onCloneList}
        confirmLoading={cloneList?.loading}
        closable={!cloneList?.loading}
        onCancel={() =>
          setCloneList({
            isOpen: false,
            loading: false,
          })
        }
        okText="Duplicate"
        okButtonProps={{
          style: {
            background: '#001F45',
            color: '#ffffff',
          },
        }}
        destroyOnClose
      >
        <div style={{ ...modalDesc }}>
          Create a copy of the saved list that you’ve already created
        </div>
      </Modal>
      <Drawer
        destroyOnClose
        open={!!previewList}
        title={null}
        onClose={() => setPreviewList(null)}
        width={1066}
        headerStyle={{ display: 'none' }}
        placement="right"
        bodyStyle={{
          borderRadius: '16px',
          overflow: 'hidden',
        }}
        style={{
          top: '70px',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
          boxShadow: 'none',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <div>
              <div className="sub-heading">{previewList?.listName}</div>
              <div className="sub-heading-small">
                Preview the saved list criteria that you’ve set
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                className="figtree"
                style={{
                  ...duplicateButtonStyles,
                }}
                onClick={() => {
                  setCloneList((prev) => ({ ...prev, isOpen: true }));
                }}
              >
                <CopyIconCustom /> Duplicate List
              </Button>
              <Button
                className="figtree"
                style={{
                  ...deleteButtonStyles,
                }}
                onClick={() => {
                  setDeleteList((prev) => ({ ...prev, isOpen: true }));
                }}
              >
                <DeleteIconCustom /> Delete
              </Button>
              <div
                className="cursor-pointer w-7 h-7 rounded-md bg-[#F0F0F0] ml-3 grid place-content-center"
                onClick={() => setPreviewList(null)}
              >
                <CloseOutlined />
              </div>
            </div>
          </div>
          <AppliedFilter appliedFilters={previewList?.hiearchyListFilters} />
        </div>
      </Drawer>
    </div>
  );
};

export default SavedList;
