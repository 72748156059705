export const ClickableLinkText = (text: string): (JSX.Element | string)[] => {
  if (!text) return [text];

  const linkRegex = /((?:http?s?:\/\/|www\.)[^\s]+)/g;
  const link = text.match(linkRegex);

  if (link) {
    const parts = text.split(linkRegex);
    return parts.map((data, index) => {
      if (data.match(linkRegex)) {
        const modifiedData = data.endsWith('.')
          ? data.slice(0, data.length - 1)
          : data;

        return (
          <span className="text-[#2286FF]" key={index}>
            <a
              key={`link-${index}`}
              href={
                modifiedData.startsWith('https')
                  ? modifiedData
                  : `//${modifiedData}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {data}
            </a>
          </span>
        );
      } else {
        return <span key={`text-${index}`}>{data}</span>;
      }
    });
  } else {
    return [text];
  }
};

export const filterLinkFromText = (text: string): (JSX.Element | string)[] => {
  if (!text) return [];

  const linkRegex = /((?:http?s?:\/\/|www\.)[^\s]+)/g;
  const link = text.match(linkRegex);

  if (link) {
    const links = text
      .split(linkRegex)
      ?.filter((d) => {
        if (d.match(linkRegex)) {
          const modifiedData = d.endsWith('.') ? d.slice(0, d.length - 1) : d;
          return modifiedData;
        }
        return false;
      })
      ?.map((modifiedData) =>
        modifiedData.startsWith('https') ? modifiedData : `//${modifiedData}`
      );
    return links;
  }
  return [];
};
