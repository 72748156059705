import { StateConstants } from '../../../constants/state.constants';

export enum Tab {
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}

export const DYNAMIC_DEFAULT_SELECTED_FILTER = (
  stateCode: string,
  agent: { value: string; label: string }
) => {
  return {
    ...(stateCode
      ? {
          State: {
            labelValuePair: [
              {
                value: stateCode,
                label: StateConstants[stateCode] || '',
              },
            ],
            key: 'stateCode',
          },
        }
      : {}),
    ...(agent?.value
      ? {
          Producer: {
            labelValuePair: [
              {
                value: agent.value,
                label: agent.label,
              },
            ],
            key: 'producerIds',
          },
        }
      : {}),
  };
};

export const DYNAMIC_DEFAULT_FILTER = (stateCode: string, agent: string) => [
  ...(stateCode
    ? [
        {
          operator: 'or',
          filters: [
            {
              key: 'stateCode',
              condition: 'is',
              value: stateCode,
            },
          ],
        },
      ]
    : []),
  ...(agent
    ? [
        {
          operator: 'or',
          filters: [
            {
              key: 'producerIds',
              condition: 'is',
              value: agent,
            },
          ],
        },
      ]
    : []),
];
