import { Button, Col, Row, Tooltip, notification } from 'antd';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { ActionableLicenseResponseError } from '../../../types/response/actionable-license.type';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../services/licenses.service';
import { UserType } from '../../../enums/userType.enum';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { auth0Store } from '../../../stores/auth0Store';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';

export enum Tab {
  INACTIVE_LICENSES = 'inactive',
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}
interface ButtonCellProps {
  selectedLicensesLength: number;
  record: any;
  currentTab: string;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  deleteAppliedLicense: (id: string) => void;
  disableApplyButton: boolean;
  setDisableApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
  showError: (msg: { message: string; description?: string }) => void;
  setApplyLicenseErrorModalVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setErrors?: React.Dispatch<
    React.SetStateAction<ActionableLicenseResponseError[]>
  >;
  showEngineLights?: boolean;
  applyErrorCallback?: (prop: string) => void;
  payer?: string;
  isRenewalPage?: boolean;
  isV2?: boolean; // to be deleted later
  isNeedsAttentionPage?: boolean;
  onDeclinedClick?: () => void;
  setModalOpen?: (modalOpen: boolean) => void;
}

function IndividualApplyButton({
  record,
  currentTab,
  deleteAppliedLicense,
  disableApplyButton,
  setDisableApplyButton,
  showError,
  setApplyLicenseErrorModalVisibility,
  setErrors,
  payer,
  applyErrorCallback,
  isV2,
  isNeedsAttentionPage,
  onDeclinedClick,
  setModalOpen,
}: ButtonCellProps) {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  const [pending, setPending] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [disableCurrentApplyButton, setDisableCurrentApplyButton] =
    useState(false);

  const onClick = async () => {
    if (
      auth0Store.getCurrentProfile()?.stripeDetails
        ?.defaultStripePaymentMethodAdded === false
    ) {
      if (setModalOpen) {
        setModalOpen(true);
      }
    } else {
      if (!subscriptionStatusInactive) {
        await applyOneLicense(
          !isEmpty(record.latestApplication) ? record.latestApplication : record
        );
      }
    }
  };

  const getToolTip = () => {
    if (subscriptionStatusInactive) {
      return '';
    }
    if (record.status === LicenseStatus.MISSING_LOA) {
      if (['WA'].includes(record?.stateCode)) {
        return "Washington doesn't allow amendments";
      }
      if (record?.withinRenewalPeriod) {
        return "License is within renewal period. Renew the license before applying for new LOA's";
      }
    }
    if (!record.producerDetails?.engineLights?.paymentConfig) {
      return 'Payer not configured';
    }
    if (!record.producerDetails?.engineLights?.employmentHistory) {
      return 'Producer missing 5-year consecutive or latest employment history';
    }
    if (!backgroundQuestionStatus) {
      return 'Producer background questions are not answered';
    }
    if (!record.producerDetails?.engineLights?.isResidentLicenseActive) {
      return 'Resident license is missing';
    }
    return '';
  };

  useEffect(() => {
    if (record.status === LicenseStatus.MISSING_LOA) {
      if (['WA'].includes(record.stateCode) || record.withinRenewalPeriod) {
        setDisableCurrentApplyButton(true);
      }
    }
  }, []);

  const applyOneLicense = async (license: LicenseApplicationResponse) => {
    try {
      setDisableApplyButton(true);
      setPending(true);

      if (
        license?.latestApplication?.status === ApplicationStatusEnum.PENDING
      ) {
        return;
      } else {
        const token = await getAccessTokenSilently();
        const resp: any = await LicensesService.applyLicenses(
          license?.id,
          token,
          license.status === ApplicationStatusEnum.MISSING_LOA,
          signupStore.getUserType() === UserType.AGENT
        );

        if (resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse) {
          const error =
            resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse?.license;
          const lcRespMessages = Array.isArray(error?.response)
            ? error?.response
                ?.filter((itr: any) => !!itr && itr?.approved !== 'Y')
                ?.flatMap((itr: any) => itr?.messages)
                ?.map((d: any) => d?.comments) || []
            : error?.response?.approved !== 'Y'
              ? error?.response?.map((msg: any) => msg?.comments) || []
              : [];

          const loaRespMessages = Array.isArray(error?.loaCodes)
            ? error?.loaCodes
                ?.flatMap((d: any) => d?.response)
                ?.filter((itr: any) => !!itr)
                ?.flatMap((itr: any) =>
                  itr?.approved !== 'Y' ? itr?.messages : []
                )
                ?.map((d: any) => d?.comments) || []
            : error?.loaCodes?.approved !== 'Y'
              ? error?.loaCodes?.response
                  ?.filter((itr: any) => !!itr)
                  ?.flatMap((itr: any) =>
                    itr?.approved !== 'Y' ? itr?.messages : []
                  )
                  ?.map((d: any) => d?.comments) || []
              : [];

          const intermediateMessages = Array.isArray(error?.intResponse)
            ? error?.intResponse
                ?.filter((itr: any) => !!itr)
                ?.flatMap((d: any) => d?.messages)
                ?.map((itr: any) => {
                  return {
                    actionable: itr.actionRequired,
                    message: itr.comments,
                  };
                })
            : error?.intResponse?.messages
              ? error?.intResponse?.messages?.map((itr: any) => {
                  return {
                    actionable: itr.actionRequired,
                    message: itr.comments,
                  };
                })
              : [];

          const lcErrorDetails = { errorMessages: lcRespMessages };
          const loaErrorDetails = { errorMessages: loaRespMessages };
          const mainErrorDetails = { errorMessages: [] };
          const intermediateErrorDetails = {
            errorMessages: intermediateMessages,
          };

          if (setErrors) {
            setErrors([
              {
                LcErrorDetails: lcErrorDetails,
                LoaErrorDetails: loaErrorDetails,
                mainErrorDetails: mainErrorDetails,
                intermediateErrorDetails: intermediateErrorDetails,
              },
            ]);
          }

          if (setApplyLicenseErrorModalVisibility) {
            setApplyLicenseErrorModalVisibility(true);
          }
        } else if (resp?.data?.gatewayTransaction?.niprGatewayErrorResponse) {
          const errors =
            resp?.data?.gatewayTransaction?.niprGatewayErrorResponse || [];
          if (setErrors) {
            setErrors([
              {
                LcErrorDetails: {
                  errorMessages: [],
                },
                LoaErrorDetails: {
                  errorMessages: [],
                },
                mainErrorDetails: {
                  errorMessages: errors?.map((d: any) => d.description) || [],
                },
                intermediateErrorDetails: {
                  errorMessages: [],
                },
              },
            ]);
          }
          if (setApplyLicenseErrorModalVisibility) {
            setApplyLicenseErrorModalVisibility(true);
          }
        }
        setDisableApplyButton(false);
        if (resp?.data?.status === 'pending') deleteAppliedLicense(license?.id); //todo
      }
      setPending(false);
      setDisableApplyButton(false);
      // setRefreshData(true);
    } catch (error: any) {
      console.error(error);
      setPending(false);
      setDisableApplyButton(false);
      showError({
        message: error?.response?.data?.message || 'Failed To Apply License',
      });
      console.error('error message :: ', error);
      if (record?.license?.id && applyErrorCallback) {
        applyErrorCallback(record?.license?.id);
      }
    }
  };

  const backgroundQuestion =
    record?.producerDetails?.engineLights?.backgroundQuestion;

  let backgroundQuestionStatus = true;

  if (isArray(backgroundQuestion)) {
    backgroundQuestion.forEach((question) => {
      if (question.stateCode === 'ALL' && backgroundQuestionStatus) {
        backgroundQuestionStatus = question.isAllAnswered;
      }
      if (question.stateCode === record.stateCode && backgroundQuestionStatus) {
        backgroundQuestionStatus = question.isAllAnswered;
      }
    });
  }

  const isAllConfigured =
    !backgroundQuestionStatus ||
    !record.producerDetails?.engineLights?.employmentHistory ||
    !record.producerDetails?.engineLights?.paymentConfig ||
    !record.producerDetails?.engineLights?.isResidentLicenseActive;

  const isDisabled =
    disableApplyButton || disableCurrentApplyButton || isAllConfigured;

  const autoRenewalPeriod =
    agencyProfileStore?.agencyDetails?.preferences?.daysToAutoRenew || 45;

  if (isAllConfigured && !isV2) return null;

  return (
    <>
      {/* DND */}
      {contextHolder}
      {/* DND End */}

      <Col
        style={{
          alignItems: 'center',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
          margin: '8px 0 0',
        }}
        className="individual-apply-button"
      >
        <Row align="middle" className="grid place-content-center text-center">
          {record.producerDetails?.paymentConfig?.isAutoRenewalActive &&
          !isNeedsAttentionPage ? (
            <div className="flex gap-2 mb-[2px]">
              <Tooltip
                title={`License will be applied automatically ${autoRenewalPeriod}
                days before expiration`}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <div className="no-wrap font-figtree text-xs">Auto Applies</div>
            </div>
          ) : null}
          {record?.latestApplicationStatus?.toLocaleLowerCase() !==
            ApplicationStatusEnum.PENDING && (
            <Tooltip title={getToolTip()}>
              <Button
                id={'apply-individual-button-' + record.licenseNumber}
                disabled={isDisabled || subscriptionStatusInactive}
                style={{
                  background:
                    isDisabled || subscriptionStatusInactive
                      ? '#001836'
                      : '#001F45',
                  opacity: isDisabled || subscriptionStatusInactive ? 0.5 : 1,
                  color: '#ffffff',
                }}
                loading={pending}
                className="font-12 figtree text-white flex justify-center items-center h-[22px] leading-[22px] font-medium w-[100px]"
                onClick={() => {
                  onClick();
                }}
              >
                {record?.latestApplicationStatus ===
                ApplicationStatusEnum.Declined
                  ? 'Retry'
                  : record?.status === ApplicationStatusEnum.MISSING_LOA
                    ? 'Amend'
                    : 'Apply'}
              </Button>
              {record?.latestApplicationStatus ===
                ApplicationStatusEnum.Declined && isV2 ? (
                <div
                  style={{
                    fontWeight: 400,
                    color: '#EB3131',
                  }}
                  className="font-12 figtree cursor-pointer "
                  onClick={onDeclinedClick}
                >
                  <QuestionCircleOutlined />{' '}
                  <span className="mt-[2px] inline-block  underline">
                    Learn More
                  </span>
                </div>
              ) : null}
            </Tooltip>
          )}
        </Row>
      </Col>
    </>
  );
}

export default observer(IndividualApplyButton);
