import {
  APPOINTMENTS_FILTER,
  DEFAULT_FILTER,
  LEVEL_FILTER,
  LICENSE_FILTER,
  ListAppointmentDTO,
  NEW_FILTER,
  conditionOptions,
  matchOptions,
  stateOptions,
  typeOptions,
} from '../organizationView/organizationUtils';
import { Button, Select } from 'antd';

import { filterType } from './appliedFilter';

interface ListFilterProps {
  filterCondition: filterType[];
  setFilterCondition: React.Dispatch<React.SetStateAction<filterType[]>>;
  onApply: (isClear?: boolean) => void;
  appointmentDetails: ListAppointmentDTO[];
  levels: number[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<filterType[]>>;
  setIsPopOverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: filterType[];
  setSearchString: React.Dispatch<
    React.SetStateAction<{ producers: string; list: string }>
  >;
}

const ListFilter: React.FC<ListFilterProps> = ({
  filterCondition,
  setFilterCondition,
  appointmentDetails,
  levels,
  setAppliedFilters,
  setIsPopOverOpen,
  onApply,
  appliedFilters,
  setSearchString,
}) => {
  const getMatchOptions = (filters: filterType[]) => {
    return matchOptions?.filter(
      (d) => !filters?.map((d) => d.match)?.includes(d?.value)
    );
  };

  const getStateOptions = (index: number) => {
    const appliedFilters = filterCondition
      ?.filter(
        (d, i) =>
          index !== i && LICENSE_FILTER.includes(d.match!) && d?.value?.length
      )
      ?.flatMap((d) => d.value);
    return stateOptions?.filter((d) => !appliedFilters?.includes(d.label));
  };

  const getCarrierOptions = (index: number) => {
    const appliedFilters = filterCondition
      ?.filter(
        (d, i) =>
          index !== i &&
          APPOINTMENTS_FILTER.includes(d.match!) &&
          d?.value?.length
      )
      ?.flatMap((d) => d.value);
    return appointmentDetails?.filter(
      (d) => !appliedFilters?.includes(d.companyName)
    );
  };

  const getLevelOptions = (index: number) => {
    const appliedFilters = filterCondition
      ?.filter(
        (d, i) =>
          index !== i && LEVEL_FILTER.includes(d.match!) && d?.value?.length
      )
      ?.flatMap((d) => d.value)
      ?.map((d) => Number(d));

    return levels?.filter((d) => !appliedFilters?.includes(d));
  };

  const onSelectFilter = (
    index: number,
    type: string,
    value: string | string[]
  ) => {
    const filters = filterCondition.map((d, i) => {
      // while updating condition , need to update in all filters
      if (type === 'condition' && i !== 0)
        return {
          ...d,
          [type]: value as string,
          // while selecting "and" condition , update all type to be same as first value
          ...(value === 'and' && filterCondition?.[0]?.type?.length
            ? {
                type: d?.type?.filter((d) =>
                  filterCondition?.[0]?.type?.includes(d)
                ),
              }
            : {}),
        };

      if (i !== index)
        return {
          ...d,
          ...(type === 'type' &&
          Array.isArray(value) &&
          index === 0 &&
          d?.type?.length &&
          filterCondition?.[1]?.condition === 'and'
            ? {
                type: d?.type?.some((data) => !value?.includes(data))
                  ? d?.type?.filter((d) => value.includes(d))
                  : d?.type,
              }
            : []),
        };

      return {
        ...d,
        [type]: value,
        ...(type === 'match' &&
        ((LICENSE_FILTER.includes(d.match!) &&
          !LICENSE_FILTER.includes(value as string)) ||
          (LEVEL_FILTER.includes(d.match!) &&
            !LEVEL_FILTER.includes(value as string)) ||
          (APPOINTMENTS_FILTER.includes(d.match!) &&
            !APPOINTMENTS_FILTER.includes(value as string)))
          ? { value: [] }
          : {}),
      };
    });

    const isAllValuesFilled =
      filters?.every(
        (d, i) => (d?.condition || i === 0) && d?.match && d?.type && d?.value
      ) && getMatchOptions(filters)?.length;

    setFilterCondition([
      ...filters,
      ...(isAllValuesFilled
        ? NEW_FILTER.map((d) => ({
            ...d,
            condition: index > 0 ? filters?.[1]?.condition : '',
          }))
        : []),
    ]);
  };
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
      // onMouseLeave={() => setIsPopOverOpen(false)}
    >
      {filterCondition?.map((data, index) => {
        return (
          <div style={{ display: 'flex', gap: 12 }} key={index}>
            {index === 0 ? (
              <div style={{ width: 120 }}></div>
            ) : (
              <Select
                onChange={(value) => onSelectFilter(index, 'condition', value)}
                value={data.condition || null}
                style={{ width: 120 }}
                placeholder="And / Or"
                disabled={index > 1}
              >
                {conditionOptions.map((option) => (
                  <Select.Option value={option.value} key={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}

            <Select
              value={data.type || []}
              style={{ width: 220 }}
              mode="multiple"
              onChange={(value) =>
                onSelectFilter(index, 'type', value as string[])
              }
              placeholder="Type"
            >
              {typeOptions
                ?.filter(
                  (d) =>
                    index === 0 ||
                    !(
                      filterCondition?.[1]?.condition === 'and' &&
                      filterCondition?.[0]?.type?.length &&
                      !filterCondition?.[0]?.type?.includes(d.value)
                    )
                )
                .map((option) => (
                  <Select.Option value={option.value} key={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>

            <Select
              value={data.match}
              style={{ width: 240 }}
              placeholder="Condition"
              onChange={(value) => onSelectFilter(index, 'match', value)}
            >
              {getMatchOptions(filterCondition).map((option) => (
                <Select.Option value={option.value} key={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={data.value?.length ? data?.value : []}
              style={{ width: 240 }}
              maxTagCount={1}
              maxTagTextLength={15}
              mode="multiple"
              showSearch
              filterOption={(input, option) => {
                return !!(
                  Array.isArray(option?.children)
                    ? option?.children?.join('')
                    : option?.children || ''
                )
                  ?.toLowerCase()
                  ?.includes(input.trim()?.toLowerCase());
              }}
              placeholder="List of"
              onChange={(value) => onSelectFilter(index, 'value', value)}
            >
              {LICENSE_FILTER.includes(data.match!)
                ? getStateOptions(index).map((option) => (
                    <Select.Option value={option.value} key={option.value}>
                      {option.label}
                    </Select.Option>
                  ))
                : APPOINTMENTS_FILTER.includes(data.match!)
                  ? getCarrierOptions(index).map((option) => (
                      <Select.Option
                        value={option.companyName}
                        key={option._id}
                      >
                        {option.companyName}
                      </Select.Option>
                    ))
                  : LEVEL_FILTER.includes(data.match!)
                    ? getLevelOptions(index)?.map((option) => (
                        <Select.Option value={option} key={option}>
                          Level {option}
                        </Select.Option>
                      ))
                    : null}
            </Select>
          </div>
        );
      })}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <div
          style={{
            fontSize: 12,
            lineHeight: '14.4px',
            fontWeight: 500,
          }}
          className="figtree cursor-pointer"
          onClick={(e) => {
            setFilterCondition(
              appliedFilters?.length
                ? [...appliedFilters, ...NEW_FILTER]
                : NEW_FILTER
            );
            setIsPopOverOpen(false);
            e.stopPropagation();
          }}
        >
          Cancel
        </div>
        <Button
          className="figtree"
          style={{
            fontSize: 12,
            lineHeight: '14.4px',
            fontWeight: 500,
            border: '1px solid #001F45',
            color: '#001F45',
            height: 24,
          }}
          onClick={() => {
            setFilterCondition(DEFAULT_FILTER);
            setIsPopOverOpen(false);
            if (appliedFilters?.length) {
              onApply(true); // clear filters
              setAppliedFilters([]);
            }
            setSearchString((prev) => ({ ...prev, list: '' }));
          }}
        >
          Clear
        </Button>
        <Button
          className="figtree"
          style={{
            background: 'var(--Secondary-Secondary, #001F45)',
            color: '#ffffff',
            fontSize: 12,
            lineHeight: '14.4px',
            fontWeight: 500,
            height: 24,
          }}
          onClick={() => {
            onApply();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ListFilter;
