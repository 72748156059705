/* eslint-disable no-async-promise-executor */
import { CancelTokenSource } from 'axios';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';

export class BlackListService {
  static host = UrlConstants.backend;

  /**
   * Creates a new Blacklisted agent
   * @param npn
   * @param name
   * @param reason
   * @param token
   * @param agencyId
   * @param isVisible
   * @param userId
   * @returns
   */
  static async createBlackList(
    npn: string,

    reason: string,
    token: string
  ) {
    const path = `/agency/watchlist`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).post(
          `${this.host}${path}`,
          {
            npn: npn,
            reason: reason,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

        resolve(response);
      } catch (error: any) {
        console.error('createBlackList Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Search in blacklisted agents
   * @param searchString
   * @param size
   * @param page
   * @param token
   * @returns
   */
  static async searchBlackList(
    searchString: string,
    size: number,
    page: number,
    token: string,
    cancelToken: CancelTokenSource
  ) {
    const path = `/agency/watchlist/all`;
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());

    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelToken)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response);
      } catch (error: any) {
        console.error('searchBlackList Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Searches in blacklisted agents for the specified agent NPN
   * @param npn
   * @param token
   * @returns
   */
  static async getBlacklistedAgentsGivenAgency(
    npn: string,
    token: string
  ): Promise<ObjectSuccessResponse<any>> {
    const path = `/agency/watchlist/npn`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/${npn}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error(
          'getBlacklistedAgentsGivenAgency Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for blacklists table
   * @param token
   * @returns
   */

  static async getFilterInfoForBlacklists(token: string): Promise<any> {
    const path = `/blacklist/my-agency/filter-options`;
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'BlacklistService :: getFilterInfoForBlacklists :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
}
