import { Button, Modal, message, notification } from 'antd';
import { ModalContainer, TextDetail } from './review-invite.style';
import React, { useEffect, useState } from 'react';

import { DownlineInviteService } from '../../../../services/downline-invite.service';
import { ErrorResponse } from '../../../../types/response/errorResponse.type';
import { RouteConstants } from '../../../../constants/routes.constants';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  invitationDetails: any;
}

const ReviewInviteModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  invitationDetails,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const showError = (message: React.ReactNode) => {
    api['error']({
      message: 'Stripe Onboarding Incomplete',
      description: message,
    });
  };

  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewAccepted, setIsReviewAccepted] = useState('');
  const navigate = useNavigate();
  const ReviewUplineInvite = async () => {
    let errorShown = false;
    try {
      if (bearerToken && !isEmpty(isReviewAccepted)) {
        setIsLoading(true);
        const response = await DownlineInviteService.updateInviteStatus(
          invitationDetails?.inviteToken,
          bearerToken,
          isReviewAccepted === 'true' ? true : false
        ).catch((e) => {
          showError((e as ErrorResponse)?.error?.message);
          errorShown = true;
          throw e;
        });

        if (response.status === 'SUCCESS') {
          navigate(RouteConstants.uplines.path);
        }
      }
    } catch (error) {
      if (!errorShown) message.error('Something went wrong ,Please try again');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    ReviewUplineInvite();
  }, [isReviewAccepted]);

  const acceptInvite = () => {
    setIsReviewAccepted('true');
  };

  const rejectInvite = () => {
    setIsReviewAccepted('false');
  };
  return (
    <Modal
      width={'500px'}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      okText="Ok"
      footer={null}
    >
      {contextHolder}
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
          Review Invite
        </TextDetail>
        <div style={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
          <TextDetail textColor={'#0588ca'} fontSize={'16px'} fontWeight={500}>
            {invitationDetails?.agencyName &&
              invitationDetails?.agencyName.toUpperCase()}
          </TextDetail>
          <TextDetail fontSize={'14px'} fontWeight={500}>
            {invitationDetails?.agencyName} have invited you to join them as a
            downline agency
          </TextDetail>
        </div>

        <div style={{ width: '100%' }}>
          <Button
            id="downline-invite-agnecy-accept-button"
            onClick={acceptInvite}
            loading={isReviewAccepted === 'true' && isLoading}
            disabled={isReviewAccepted === 'false' && isLoading}
            style={{ background: '#00a6fb', marginBottom: '12px' }}
            type="primary"
            block
          >
            Accept Invite
          </Button>
          <Button
            id="downline-invite-agnecy-reject-button"
            loading={isReviewAccepted === 'false' && isLoading}
            disabled={isReviewAccepted === 'true' && isLoading}
            onClick={rejectInvite}
            block
          >
            Reject Invite
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default ReviewInviteModal;
