import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Spin,
  Switch,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { onboardingStore, signupStore } from '../../../stores/signupStore';
import { useEffect, useState } from 'react';

import { BeError } from '../../../types/response/error.type';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import { ExceptionName } from '../../../enums/exceptionName.enum';
import { InviteResponseEnum } from '../../../enums/invite-response.enum';
import { NIPR_LOOKUP_URL } from '../../../constants/url.constants';
import { NPN_REGEX } from '../../../constants/regex.constants';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { SignupService } from '../../../services/signup.service';
import { isEmpty } from 'lodash';
import { useAuth } from '../../authProvider';

type Props = {
  form: FormInstance;
  isLoading: boolean;
  onSubmit: (values: any) => void;
};
export const SignUpBasicInfoStep = ({ form, isLoading, onSubmit }: Props) => {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const [teamType, setTeamType] = useState(
    onboardingStore.basicInfo.accountType
  );
  const { getAccessTokenSilently } = useAuth();

  const [onboardSelf, setOnboardSelf] = useState(false);
  const [turnonProducerAlerts, setTurnOnProducerAlerts] = useState(true);
  const [adminOnlyMode, setAdminOnlyMode] = useState(false);
  const [formRender, setFormRender] = useState<number>(0);
  const [basicInfoLoading, setBasicInfoLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<any>([]);
  const [selectedInvite, setSelectedInvite] = useState<any>(null);
  const { bearerToken } = useAuth();

  useEffect(() => {
    fetchBasicInfo().finally(() => form.resetFields());
  }, []);

  const fetchBasicInfo = async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      setBasicInfoLoading(true);
      const response = await SignupService.getBasicInfo(token).catch(
        (e: BeError) => {
          if (e.exceptionName !== ExceptionName.FORBIDDEN_EXCEPTION)
            showError(e?.message);
        }
      );
      if (!isEmpty(response.data)) {
        setInvites(response.data);
      }
      setBasicInfoLoading(false);
      setFormRender((prev) => prev + 1);
    }
  };
  const handleAcceptInvite = (invite: any) => {
    if (invite.teamType === 'agency') {
      onboardingStore.setBasicInfo('agency', invite.npn);
      form.setFieldValue('agencyNPN', invite.npn);
    } else {
      onboardingStore.setBasicInfo('producer', invite.npn, invite.npn);
      form.setFieldValue('producerNPN', invite.npn);
    }
    setSelectedInvite(invite);
    setTeamType(invite.teamType);
    form.setFieldValue('teamType', invite.teamType);
    setInvites([]);
  };

  const handleRejectInvite = async (inviteToken: string) => {
    if (bearerToken)
      await DownlineInviteService.updateInviteStatus(
        inviteToken,
        bearerToken,
        false
      );
    setInvites(
      invites.filter((invite: any) => invite.inviteToken !== inviteToken)
    );
  };

  return (
    <div>
      {contextHolder}
      {basicInfoLoading ? (
        <Spin />
      ) : (
        <div className="w-full flex flex-col gap-y-6  justify-start">
          {invites.length > 0 && (
            <div className="mb-5">
              {invites.map((invite: any) => (
                <div
                  key={invite.inviteToken}
                  className="mb-4 text-center flex "
                >
                  <Typography.Text className="content-center mr-[10px]">{`Are you interested in accepting the invite from  ${invite.name}?`}</Typography.Text>
                  <div className="flex justify-center mb-[5px] gap-2">
                    <Button
                      type="primary"
                      onClick={() => handleAcceptInvite(invite)}
                    >
                      Accept
                    </Button>
                    <Button
                      className="button important-action-button"
                      onClick={() => handleRejectInvite(invite.inviteToken)}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Typography.Title level={4} className="mt-0 mb-0">
            Tell us the National Producer Number
          </Typography.Title>

          <Form
            key={formRender}
            autoComplete="off"
            layout="vertical"
            onFinish={(values: any) => {
              const submissionValues = selectedInvite
                ? { ...values, inviteToken: selectedInvite.inviteToken }
                : values;

              onSubmit(submissionValues);
            }}
            form={form}
            className="onboarding-screen"
            disabled={invites.length !== 0}
          >
            <Form.Item
              label="Team Type"
              name="teamType"
              rules={[
                {
                  required: true,
                  message: 'Please select type',
                },
              ]}
            >
              <Radio.Group
                className="onboarding-radio-button"
                onChange={(e) => {
                  form.setFieldValue('teamType', e.target.value);
                  setTeamType(e.target.value);
                }}
                disabled={isLoading || invites.length !== 0 || selectedInvite} // Make Radio.Group non-editable
              >
                <Radio value={'agency'}>Agency</Radio>
                <Radio value={'producer'}>Individual</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="onboarding-radio-button-note">
              <Typography.Paragraph>
                {teamType === 'producer'
                  ? 'I’m a producer, and will manage my producer licenses. I may have other producers who work with me, but I don’t want to manage agency licenses'
                  : 'The agency is its own entity, has its own NPN, and I can manage both agency and producer licenses in the app.'}
              </Typography.Paragraph>
            </div>

            {teamType === 'agency' && (
              <>
                {/* <Form.Item
                  label="Agency Name"
                  name="agencyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter agency name',
                    },
                    {
                      pattern: NAME_REGEX,
                      message: 'Agency name must contain only letters',
                    },
                    {
                      validator: (_, value) =>
                        !value || value?.trim() === ''
                          ? Promise.reject(
                              new Error(
                                'Agency Name cannot contain only spaces'
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    id="agency-name-input"
                    placeholder="Enter Agency Name"
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ agencyName: value });
                    }}
                    disabled={isLoading}
                  />
                </Form.Item> */}
                <Form.Item
                  label={
                    <div>
                      Agency NPN <span className="text-[#e91e1e]">*</span>
                      <Tooltip
                        title={
                          <div>
                            Don’t know your NPN?{' '}
                            <a
                              href={NIPR_LOOKUP_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="cursor-pointer underline underline-offset-2">
                                Click Here
                              </span>
                            </a>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined className="ml-2 cursor-pointer text-[#97AABF]" />
                      </Tooltip>
                    </div>
                  }
                  className="hide-asterisk"
                  name="agencyNPN"
                  initialValue={
                    selectedInvite && onboardingStore.basicInfo.agencyNPN
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please enter agency NPN',
                    },
                    {
                      pattern: NPN_REGEX,
                      message: 'Please enter a valid NPN',
                    },
                    {
                      validator: (_, value) =>
                        !value || value?.trim() === ''
                          ? Promise.reject(
                              new Error('Please enter a valid NPN')
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    id="agency-npn-input"
                    placeholder="Enter NPN"
                    maxLength={10}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ agencyNPN: value });
                    }}
                    disabled={
                      invites.length !== 0 ||
                      selectedInvite ||
                      isLoading ||
                      !!onboardingStore.basicInfo.agencyNPN ||
                      signupStore.downlineInviteResponse ===
                        InviteResponseEnum.ACCEPTED
                    } // Make Input non-editable
                  />
                </Form.Item>
                <Form.Item
                  name="isProducerAlertEnabled"
                  initialValue={turnonProducerAlerts}
                >
                  <div className="flex flex-col gap-3 font-figtree bg-[#F4FBFE] rounded-md p-[10px]">
                    <div className="text-black-blue flex items-center gap-2 leading-[16.8px] text-sm font-semibold">
                      <div>
                        {turnonProducerAlerts
                          ? 'Allow producers to manage their own profiles'
                          : 'Only admins can use Insuretrek'}{' '}
                      </div>
                      <Switch
                        id="turn-on-producer-alerts"
                        onChange={(value) => {
                          if (!value) {
                            setAdminOnlyMode(true);
                          } else {
                            form.setFieldsValue({
                              isProducerAlertEnabled: value,
                            });
                            setTurnOnProducerAlerts(value);
                          }
                        }}
                        size="small"
                        checked={turnonProducerAlerts}
                      />
                    </div>
                    <div className="text-xs leading-14.4 text-dark-blue">
                      {turnonProducerAlerts
                        ? 'When you add a producer to this team, they will get an invite to join. They can update their background questions, employment history, and get notifications about their licenses and applications. You can also set them up to pay for their own applications.'
                        : 'You can invite other admins to manage licenses and add producers, but producers won’t be able to log in to see or edit their profiles. This means admins will need to complete background information and employment history for producers, and the agency will cover all licensing costs.'}
                    </div>
                  </div>
                </Form.Item>
                <Form.Item name="selfOnboard" className="mt-[-12px]">
                  <Checkbox
                    id="onboard-myself"
                    onChange={(e) => {
                      const value = e.target.checked;
                      form.setFieldsValue({ selfOnboard: value });
                      setOnboardSelf(value);
                    }}
                    checked={onboardSelf}
                  >
                    Onboard Myself
                  </Checkbox>
                </Form.Item>
              </>
            )}
            {(teamType === 'producer' || onboardSelf) && (
              <Form.Item
                label="Producer NPN"
                name="producerNPN"
                initialValue={
                  selectedInvite &&
                  onboardingStore.basicInfo.accountType &&
                  onboardingStore.basicInfo.producerNPN
                }
                rules={[
                  {
                    required: true,
                    message: 'Please enter your NPN',
                  },
                  {
                    pattern: NPN_REGEX,
                    message: 'Please enter a valid NPN',
                  },
                  {
                    validator: (_, value) =>
                      !value || value?.trim() === ''
                        ? Promise.reject(new Error('Please enter a valid NPN'))
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  id="producer-npn-input"
                  placeholder="Enter Producer NPN"
                  onChange={(e) => {
                    const value = e.target.value;
                    form.setFieldsValue({ producerNPN: value });
                  }}
                  maxLength={10}
                  disabled={
                    (isLoading || invites.length !== 0 || selectedInvite) &&
                    !onboardSelf
                  } // Make Input non-editable
                />
              </Form.Item>
            )}

            <Button
              type="primary"
              htmlType="submit"
              className="h-[46px] w-1/2"
              loading={isLoading}
            >
              Next
            </Button>
          </Form>
        </div>
      )}
      <Modal
        title={
          <div className="font-figtree font-semibold text-xl text-[#07212D]">
            Are you sure you want admin only mode?
          </div>
        }
        width={580}
        open={adminOnlyMode}
        onOk={() => {
          form.setFieldsValue({
            isProducerAlertEnabled: !turnonProducerAlerts,
          });
          setTurnOnProducerAlerts(!turnonProducerAlerts);
          setAdminOnlyMode(false);
        }}
        onCancel={() => setAdminOnlyMode(false)}
        okText="Confirm"
        cancelText="Go Back"
        okButtonProps={{
          style: {
            background: '#EB3131',
            color: '#ffffff',
          },
        }}
        destroyOnClose
      >
        <div className="font-figtree font-medium text-sm text-[#99ACB5] mb-6">
          This is great if you are going to manage everything for your team, but
          does mean that they can&apos;t edit their own profiles or pay for
          their own licenses. If you change your mind later, you will be able to
          reach out to our team and have it enabled.
        </div>
      </Modal>
    </div>
  );
};
