import {
  AutoComplete,
  Button,
  Card,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AccountWithProducerDocument } from '../../../types/response/data/agents/agentDocument.type';
import { AgentService } from '../../../services/agent.service';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { EyeOutlined } from '@ant-design/icons';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import RtsByProducerTour from '../../../components/common/Tour/rtsByProducerTour';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { TourConstants } from '../../../constants/tour.constant';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { applySort } from '../../../utils/common.utils';
import { getNameString } from '../../../utils/name.utils';
import getProducerDetailsTableColumns from '../../../tables/producerDetails.table';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

function RTS() {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeAgents, setActiveAgents] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [activeAgentsCount, setActiveAgentsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false);
  const [queryText, setQueryText] = useQueryState('search');
  const [agencyId, setAgencyId] = useState(
    hierarchyViewStore.currentSelectedAgencyId
  );
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  useEffect(() => {
    if (adminStore.agency) setAgencyId(adminStore.agency.id);
  }, [adminStore.agency]);

  useEffect(() => {
    loadActiveAgents();
  }, [agencyId, queryText, searchType]);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const loadActiveAgents = async (
    props?: {
      page?: number;
      size?: number;
      paymentConfig?: boolean;
      searchTextProp?: string;
    },
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;
    setIsLoading(true);
    tourStore.setRtsByProducerLoading(true);
    const accessToken = await getAccessTokenSilently();
    const defaultRequestBody = {};
    const reqBody = {
      ...requestBody,
      ...defaultRequestBody,
      ...(sort || sortValue || {}),
    };
    if (sessionStorage.getItem(TourConstants.AGENCY_ONBOARD_TOUR)) {
      tourStore.setRtsAgentsLoading(true);
    }
    const response = await AgentService.getAllProducersForAgency(
      accessToken,
      props?.size || pageSize,
      props?.page || pageNumber,
      props?.searchTextProp ? props.searchTextProp : queryText || '',
      reqBody,
      searchType ? searchType : searchType || ''
    ).catch((error) => {
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }
      setErrorMessage('Error! Unable to fetch agents at the moment');
    });
    if (response?.data) {
      const newAgents: AccountWithProducerDocument[] = response.data;
      setActiveAgents(newAgents);
      setActiveAgentsCount(response?.totalCount);
    }
    if (!errorTypeRequestCancel) {
      tourStore.setRtsByProducerLoading(false);
      setIsLoading(false);
    }
    setSearchLoading(false);
    if (sessionStorage.getItem(TourConstants.AGENCY_ONBOARD_TOUR)) {
      tourStore.setRtsAgentsLoading(false);
    }
    if (tourStore.rtsByProducerTour) {
      setTimeout(() => {
        tourStore.setRtsByProducerVisible(true);
      }, 100);
    }
  };

  const redirect = (record: AccountWithProducerDocument) => {
    appStateInfoStore.setHeader(
      getNameString(record.producer?.name ? record.producer?.name : record.name)
    );
    navigate(
      RouteConstants.agentOverview.path.replace(':agentId', record.producer.id)
    );
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    loadActiveAgents({
      page: newPageNumber,
      size: newPageSize,
      searchTextProp: queryText || '',
    });
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  const RTSTableColumn: ColumnsType<AccountWithProducerDocument> = [
    ...getProducerDetailsTableColumns({
      showContactEmail: false,
      showPaymentConfig: true,
      showResidentState: true,
      onAgentClick: redirect,
    }),
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 100,
      render: (text: string, record: AccountWithProducerDocument) => {
        return (
          <Row
            style={{
              justifyContent: 'center',
            }}
          >
            <Tooltip title="View RTS">
              <Button
                size="middle"
                className={`button-secondary-color-important grid place-content-center ${IdConstants.RTS.BY_PRODUCER.VIEW_BUTTON} disable-rts`}
                onClick={() => {
                  appStateInfoStore.setHeader(getNameString(record.name));
                  navigate({
                    pathname:
                      RouteConstants.rtsViewAgentsFromTable.path.replace(
                        ':agentId',
                        record.producer.id
                      ),
                  });
                }}
                disabled={subscriptionStatusInactive}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      const path = '/account/producer';
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await AgentService.getFilterDetails(
          token,
          pageSize,
          pageNumber,
          path,
          searchText
        );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.HOME
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) loadActiveAgents();
    setPageNumber(1);
  }, [requestBody]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_RTS_AGENT;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);
  return (
    <div className="width-100">
      {/* <Card
        style={{
          padding: '0',
          borderRadius: '6px',
        }}
      > */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
        }}
      >
        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          fetchFilters={() => fetchFiltersInfoForFindAll()}
          clearFilters={() => {
            clearFilters();
          }}
          pageName={pageName}
          filterPage={PageConstants.APPOINTMENT}
        />
        <div
          style={{
            display: 'flex',
            columnGap: 10,
            justifyContent: 'space-between',
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            disabled={isSearchLoading || isLoading}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-all-agents-rts'}
              placeholder="Search by Producers or NPN"
              size="middle"
              style={{
                width: '100%',
              }}
              onSearch={handleOnSearch}
              disabled={subscriptionStatusInactive}
            />
          </AutoComplete>
          <FilterButton
            {...{
              filterInfoLoading,
              activeData: activeAgents,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>

        {!isEmpty(selectedFilters?.data) && (
          <RenderFilterChip
            {...{ selectedFilters, removeFilter, clearFilters, pageName }}
          />
        )}

        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
          }}
          className="table-layout-v2"
        >
          <Table
            style={{ width: '100%' }}
            columns={RTSTableColumn}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, (_, __, ___, sort) => {
                loadActiveAgents(undefined, sort);
              });
              setSortValue(sort);
            }}
            dataSource={activeAgents}
            loading={isLoading}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{
              y: 'calc(100vh - 230px)',
            }}
            locale={{
              emptyText() {
                return isLoading ? (
                  <div style={{ minHeight: 200 }}></div>
                ) : activeAgents &&
                  adminStore.account &&
                  adminStore.agency?.id &&
                  activeAgents[0]?.agencyId &&
                  activeAgents[0].agencyId === adminStore.agency?.id ? (
                  <div
                    style={{
                      textAlign: 'center',
                      marginLeft: '20%',
                      marginTop: '10px',
                    }}
                  >
                    <EmptyState
                      heading="No active producers to show yet"
                      route={RouteConstants.onboardProducer.path}
                      button
                      buttonText="Producer Onboarding"
                      content={
                        errorMessage
                          ? errorMessage
                          : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                      }
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: 'center',
                      marginLeft: '20%',
                      marginTop: '10px',
                    }}
                  >
                    <EmptyState
                      image="empty data"
                      heading="No active producers to show yet"
                      content={
                        errorMessage
                          ? errorMessage
                          : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                      }
                    />
                  </div>
                );
              },
            }}
          />
          {!isEmpty(activeAgents) && (
            <Row justify="end" style={{ position: 'relative' }}>
              <Pagination
                total={activeAgentsCount}
                pageSize={pageSize}
                current={pageNumber}
                showSizeChanger={false}
                onChange={onChange}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                disabled={isLoading}
              />
            </Row>
          )}
        </Row>
      </div>
      {/* </Card> */}
      {tourStore.rtsByProducerTour && tourStore.rtsByProducerVisible && (
        <RtsByProducerTour />
      )}
    </div>
  );
}
export default withRoles(observer(RTS), [RoleType.ADMIN, RoleType.SUPER_ADMIN]);
