import { Form, Steps, notification } from 'antd';
import {
  MagicLinkVerificationStage,
  SignUpEmailVerification,
} from './signUpEmailVerificationStep';
import {
  isAboveDesktopWidth,
  isAboveTabletWidth,
} from '../../../utils/screen.utils';
import { isEmpty, isNull } from 'lodash';
import { useEffect, useState } from 'react';

import { RouteConstants } from '../../../constants/routes.constants';
import { SignUpBasicInfoStep } from './signUpBasicInfoStep';
import { SignupService } from '../../../services/signup.service';
import { VerifyDetails } from './verifyDetails';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../authProvider';
import { useNavigate } from 'react-router';
import useWidth from '../../../hooks/useWidth';

const steps = [
  {
    title: 'Basic Info',
  },
  {
    title: 'Email Verification',
  },
  {
    title: 'Details',
  },
];

const onboardingStageMapping = {
  'basic-info': 0,
  'magic-link': 1,
  'no-magic-link': 2,
  'verify-details': 3,
  'stripe-customer': 4,
};

export const SignUpOnboarding = () => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [isLoading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const {
    isAuthenticated,
    onboardingStage,
    refreshToken,
    isOnboarded,
    getAccessTokenSilently,
  } = useAuth();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNull(onboardingStage) && !isNull(isOnboarded))
      navigateUserToOnboarding();
  }, [onboardingStage, isOnboarded]);

  function navigateUserToOnboarding() {
    if (isAuthenticated()) {
      if (isOnboarded) {
        if (isAboveTabletWidth()) navigate('/redirect');
        else navigate(RouteConstants.onboardedMobile.path);
      } else if (!isEmpty(onboardingStage)) {
        if (onboardingStage === 'stripe-customer') {
          navigate(RouteConstants.stripeRedirection.path);
        }
        setCurrentStep(
          onboardingStageMapping[
            onboardingStage as keyof typeof onboardingStageMapping
          ]
        );
      }
    } else {
      navigate(RouteConstants.rootRedirect.path);
    }
  }

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const handleSignUpStep1 = async ({
    agencyNPN,
    agencyName,
    teamType,
    producerNPN,
    inviteToken,
    isProducerAlertEnabled,
  }: {
    agencyNPN: string;
    agencyName?: string;
    teamType: any;
    producerNPN?: string;
    inviteToken?: string;
    isProducerAlertEnabled?: boolean;
  }) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    if (token) {
      const response = await SignupService.updateBasicInfo(token, {
        type: teamType === 'individual' ? 'producer' : teamType,
        agencyName,
        agencyNPN: teamType === 'individual' ? '' : agencyNPN,
        producerNPN: producerNPN,
        inviteToken,
        isProducerAlertEnabled,
      }).catch((err) => {
        showError(err);
      });
      if (response) {
        await refreshToken(true);
      }
    }
    setLoading(false);
  };

  return (
    <div className="responsive-container">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}
      <div className="flex flex-col gap-y-6 w-full px-[10px]">
        <div
          className={twMerge(
            'w-full rounded-md border border-[#E6E6E6]',
            isAboveDesktopWidth() ? 'p-5' : 'py-2 px-4'
          )}
        >
          <Steps
            className="onboarding-steps"
            current={currentStep === 2 ? 1 : currentStep}
            items={steps}
          />
          {/* currentstep 1 = magic-link & currentstep 2 = no-magic-link both corresponds to step 1 = email verification */}
        </div>
        {currentStep === 0 && (
          <SignUpBasicInfoStep
            isLoading={isLoading}
            form={form}
            onSubmit={handleSignUpStep1}
          />
        )}
        {(currentStep === 1 || currentStep === 2) && (
          <SignUpEmailVerification
            variant={onboardingStage as MagicLinkVerificationStage}
          />
        )}
        {currentStep === 3 && <VerifyDetails />}
      </div>
    </div>
  );
};
