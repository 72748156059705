import {
  modalDesc,
  modalTitle,
} from '../../../agents/agentsOverview/organizationView/organizationUtils';

import { Modal } from 'antd';

export type MarkAsDone = {
  commentCode: number;
  isDone: boolean;
};

interface MarkasDoneModalProps {
  markAsDoneModal: MarkAsDone | null;
  setMarkAsDoneModal: React.Dispatch<React.SetStateAction<MarkAsDone | null>>;
  updateActionRequiredFlag: (commentCode: number, isDone: boolean) => void;
  isUpdating: number[];
}

const MarkAsDoneModal: React.FC<MarkasDoneModalProps> = ({
  markAsDoneModal,
  setMarkAsDoneModal,
  updateActionRequiredFlag,
  isUpdating,
}) => {
  return (
    <Modal
      title={
        <div
          style={{
            ...modalTitle,
          }}
        >
          Are you sure you want to Mark as done?
        </div>
      }
      width={539}
      open={!!markAsDoneModal}
      onOk={() =>
        updateActionRequiredFlag(
          markAsDoneModal?.commentCode as number,
          markAsDoneModal?.isDone as boolean
        )
      }
      confirmLoading={!!isUpdating?.length}
      closable={!isUpdating?.length}
      onCancel={() => setMarkAsDoneModal(null)}
      okText="Mark as done"
      okButtonProps={{
        style: {
          background: '#001F45',
          color: '#ffffff',
        },
      }}
      destroyOnClose
    >
      <div style={{ ...modalDesc }}>
        Please note that clicking &quot;Mark as done&quot; will not complete the
        license application warnings. You still need to manually follow the
        instructions below and mark it as done for your own tracking purposes.
      </div>
    </Modal>
  );
};

export default MarkAsDoneModal;
