/* eslint-disable no-async-promise-executor */
import {
  AdvancedFiltersDtoType,
  HiearchyListFilterType,
} from '../pages/agents/agentsOverview/organizationView/organizationUtils';
import axios, { CancelTokenSource } from 'axios';

import { AgencyAgentDownline } from '../types/data/agencyDownline';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { cancelPreviousRequest } from '../utils/api.utils';
import { filterRequestBody } from '../utils/common.utils';
import moment from 'moment';

export class DownlineService {
  static host = UrlConstants.backend;
  static modulePath = '/upline-downline';

  /**
   * Service Function to get Downline Producers of my agency
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @param cancelTokenSource
   * @returns
   */
  static async getAgentDownline(
    size: number,
    page: number,
    token: string,
    searchString?: string,
    cancelTokenSource?: CancelTokenSource,
    reqBody?: object
  ): Promise<AgencyAgentDownline> {
    const path = `/upline-downline`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .post(
            `${
              this.host
            }${path}/my-agency/downline-agents?size=${size}&page=${page}${
              searchString
                ? `&searchString=${encodeURIComponent(searchString)}`
                : ''
            }`,
            reqBody
          );
        resolve(response.data);
      } catch (error: any) {
        console.error('getAgentDownline Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Producers of my agency
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @param cancelTokenSource
   * @returns
   */
  static async getDownline(
    requestBody: object,
    size: number,
    page: number,
    token: string,
    searchString?: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<AgencyAgentDownline> {
    const path = `/upline-downline`;
    // const path = "upline-downline/my-agency/downlines"
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .post(
            `${this.host}${path}/my-agency/downlines?size=${size}&page=${page}${
              searchString
                ? `&searchString=${encodeURIComponent(searchString)}`
                : ''
            }`,
            requestBody
          );
        resolve(response.data);
      } catch (error: any) {
        console.error('getAgentDownline Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Agencies of my agency
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @param cancelTokenSource
   * @returns
   */

  static async getAgenciesDownline(
    size: number,
    page: number,
    token: string,
    searchString?: string,
    cancelTokenSource?: CancelTokenSource,
    reqBody?: object
  ): Promise<AgencyAgentDownline> {
    const path = `/upline-downline`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .post(
            `${
              this.host
            }${path}/my-agency/downline-agencies?size=${size}&page=${page}&searchString=${
              searchString ? encodeURIComponent(searchString) : ''
            }`,
            reqBody,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        resolve(response.data);
      } catch (error: any) {
        console.error('getAgenciesDownline Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Agencies of my agency
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @param cancelTokenSource
   * @returns
   */

  static async getDownlines(
    size: number,
    page: number,
    token: string,
    searchString?: string,
    searchType?: string,
    cancelTokenSource?: CancelTokenSource,
    reqBody?: object
  ): Promise<AgencyAgentDownline> {
    const currentAgencyId = localStorage.getItem('x-agency-id');
    const path = `/upline-downline/${currentAgencyId}/upline-downline-agencies`;
    const url_ = new URL(`${this.host}${path}`);
    url_.searchParams.append('type', 'downline');
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(reqBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response.data);
      } catch (error: any) {
        console.error('getAgenciesDownline Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Invitation
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */

  static async getDownlineInvitation(
    size: number,
    page: number,
    token: string,
    searchString: string
  ) {
    const path = `/downline-invite/my-agency/downline-invites`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(
            `${
              this.host
            }${path}?size=${size}&page=${page}&searchString=${encodeURIComponent(
              searchString
            )}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const downlineData =
          response.data.data &&
          response.data.data.map((downline: any) => {
            return {
              id: downline.id,
              email: downline.email,
              invitedUsing: downline.invitedUsing,
              invitedby: downline.invitedBy,
              total_amount_allocated: downline.amountAllocated,
              invitedOn: moment(downline.invitedOn).format('MMMM DD, YYYY'),
            };
          });
        resolve({
          count: response.data.totalCount,
          downlines: downlineData,
        });
      } catch (error: any) {
        console.error('getDownlineInvitation Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Agent in Distribution Channel View
   * @param agencyId
   * @param token
   * @returns
   */

  static async getAgentsDownlineHierarchy(
    agencyId: string,
    token: string
  ): Promise<AgencyAgentDownline> {
    const path = `/upline-downline`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/${agencyId}/downline-agents`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error('getAgentsDownlineHierarchy Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Agencies in Distribution Channel View
   * @param agencyId
   * @param token
   * @returns
   */

  static async getAgenciesDownlineHierarchy(
    agencyId: string,
    token: string
  ): Promise<AgencyAgentDownline> {
    const path = `/upline-downline`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/${agencyId}/downline-agencies`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response.data);
      } catch (error: any) {
        console.error('getAgenciesDownlineHierarchy Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Agencies in Organization List View
   * @param agencyId
   * @param token
   * @returns
   */

  static async getAgenciesDownlineHierarchyList(
    agencyId: string,
    token: string,
    page: number | null,
    size: number | null,
    searchString: string,
    searchType?: string,
    reqBody?: object,
    filterGroups?: AdvancedFiltersDtoType,
    filterType?: HiearchyListFilterType,
    skipPagination?: boolean,
    isExport?: boolean
  ): Promise<any> {
    cancelPreviousRequest(apiStore.getTokenForOrganizationList());
    const cancelTokenSource = axios.CancelToken.source();
    apiStore.setTokenForOrganizationList(cancelTokenSource);

    const path =
      `/upline-downline/${agencyId}/downline-lists` +
      (filterType ? `?filterType=${filterType}` : '');
    const url_ = new URL(`${this.host}${path}`);

    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    if (skipPagination) url_.searchParams.append('skip-pagination', 'true');
    if (isExport) url_.searchParams.append('is-export', 'true');
    // Process requestBody using filterRequestBody function
    if (reqBody) filterRequestBody(reqBody, url_);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .post(`${url_}`, filterGroups, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response.data);
      } catch (error: any) {
        console.error(
          'getAgenciesDownlineHierarchyList Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Service Function to verify Downline
   * @param npn
   * @param token
   * @param email
   * @returns
   */

  static async verifyDownline(npn: string, email: string, token: string) {
    const path = `/invite`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/${npn}?email=${encodeURIComponent(email)}`);

        resolve(response.data);
      } catch (error: any) {
        console.error('verify downlines Error :: ', error?.message);
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Filter For Download Service
   * @param npn
   * @param token
   * @param email
   * @returns
   */

  static async getDownlineFilters(token: string) {
    const path = `/upline-downline/downline/filters`;
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'AgentService :: getDownlineFilters :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Remove Downline
   * @param token
   * @body otp
   * @returns
   */

  // static async removeDownlines(token: string, otpValue: string) {
  //   const path = `/upline-downline/my-agency/remove`;
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const config = {
  //         otpValue: otpValue,
  //       };
  //       const response = await apiStore
  //         .getApiClient(token)
  //         .post(`${this.host}${path}`, config);

  //       resolve(response.data);
  //     } catch (error: any) {
  //       reject(error.response.data as ErrorResponse);
  //     }
  //   });
  // }

  static async removeDownlinesById(
    token: string,
    downlineIdsToBeRemoved: string[],
    otp: string
  ): Promise<any> {
    const path = `/upline-downline/downline/remove`;
    try {
      const response = await apiStore
        .getApiClient(token)
        .delete(`${this.host}${path}`, {
          data: { downlineIds: downlineIdsToBeRemoved, otp: otp },
        });
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  static async sendOTP(
    token: string,
    downlineIdsToBeRemoved: string[]
  ): Promise<any> {
    const path = `/upline-downline/downline/otp`;
    try {
      const response = await apiStore
        .getApiClient(token)
        .post(`${this.host}${path}`, { downlineIds: downlineIdsToBeRemoved });
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}
