import { daysToAutoRenewOptions } from '../../../types/agency/agencyPreference.type';

const DetailsAgencyKeys = {
  GENERAL: 'general',
  OWNERSHIP_DETAILS: 'ownership-details',
  BACKGROUND_QUESTIONS: 'background-questions',
  PREFERENCES: 'Preferences',
};

const AgencyDetailsOptions = [
  {
    label: 'General',
    key: DetailsAgencyKeys.GENERAL,
  },
  {
    label: 'Ownership Details',
    key: DetailsAgencyKeys.OWNERSHIP_DETAILS,
  },
  {
    label: 'Background Questions',
    key: DetailsAgencyKeys.BACKGROUND_QUESTIONS,
  },
  {
    label: 'Preferences',
    key: DetailsAgencyKeys.PREFERENCES,
  },
];

const DaysToAutoRenewOptions = [
  { label: '15 days', value: daysToAutoRenewOptions.FIFTEEN_DAYS },
  { label: '30 days', value: daysToAutoRenewOptions.THIRTY_DAYS },
  { label: '45 days', value: daysToAutoRenewOptions.FOURTY_FIVE_DAYS },
];

export { DetailsAgencyKeys, AgencyDetailsOptions, DaysToAutoRenewOptions };
