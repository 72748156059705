import React from 'react';

const LinkOutline = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66667 1.99805V3.33138H1.33333V10.6647H8.66667V7.33138H10V11.3314C10 11.6996 9.70153 11.998 9.33333 11.998H0.666667C0.29848 11.998 0 11.6996 0 11.3314V2.66471C0 2.29653 0.29848 1.99805 0.666667 1.99805H4.66667ZM12 -0.00195312V5.33138H10.6667L10.6666 2.27338L5.4714 7.46945L4.52859 6.52665L9.72327 1.33138H6.66667V-0.00195312H12Z"
        fill={props?.fill || '#006ef5'}
      />
    </svg>
  );
};

export default LinkOutline;
