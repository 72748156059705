import { calculateNiprFee } from '../../../../utils/nipr/calculate-nipr-fee.utils';
import isArray from 'lodash/isArray';

export enum PendingLicenseType {
  ALL = 'All',
  FOLLOW_UP = 'Follow-Ups',
}

export const ACTION_REQUIRED = 'Y';

export enum ActionRequiredStatus {
  ACTION_REQUIRED = 'Action Required',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

export const getNiprActionStatus = (record: any) => {
  const allMessages = [
    ...(isArray(
      record?.gatewayTransaction?.niprGatewaySuccessResponse?.license?.response
    )
      ? record?.gatewayTransaction?.niprGatewaySuccessResponse?.license?.response?.flatMap(
          (d: any) => d?.messages || []
        ) || []
      : record?.gatewayTransaction?.niprGatewaySuccessResponse?.license
          ?.response?.messages || []),
    ...(record?.gatewayTransaction?.niprGatewaySuccessResponse?.license
      ?.intResponse?.messages || []),
  ]?.filter((d) => d.actionRequired === ACTION_REQUIRED);

  if (allMessages?.length === 0) return null;

  const hasUnread = allMessages.some((d) => !d.markedAsDone);
  const hasRead = allMessages.some((d) => d.markedAsDone);

  if (!hasUnread) return ActionRequiredStatus.COMPLETED;
  if (hasRead) return ActionRequiredStatus.IN_PROGRESS;
  return ActionRequiredStatus.ACTION_REQUIRED;
};

export const getNiprStatusColor = (value: ActionRequiredStatus) => {
  if (!value) return '';
  if (value === ActionRequiredStatus.ACTION_REQUIRED)
    return ' border-[#EB3131] bg-[#FACBCB] border-[1.1px]';
  if (value === ActionRequiredStatus.IN_PROGRESS)
    return ' border-[#F8D79A] bg-[#FCEFCF] border';
  return ' border-[#0F9D58] bg-[#CBFAE3] border-[1.1px]';
};

export const getFees = (record: any) => {
  const stateFee =
    record?.gatewayTransaction?.niprGatewaySuccessResponse?.stateFee || 0;
  const transFee =
    record?.gatewayTransaction?.niprGatewaySuccessResponse?.transFee || 0;
  return calculateNiprFee(stateFee, transFee);
};

// Pending Producer Applications
export interface AllLicensesProps {
  isAgentOverview?: boolean;
  isAgentPortal?: boolean;
}

export const NiprActionIcon: React.FC<{
  niprAction: ActionRequiredStatus | null | 'unset';
}> = ({ niprAction }) => {
  if (!niprAction || niprAction === 'unset') return null;

  let bg = 'bg-[#EB3131]';
  if (niprAction === ActionRequiredStatus.COMPLETED) {
    bg = 'bg-[#0F9D58]';
  } else if (niprAction === ActionRequiredStatus.IN_PROGRESS) {
    bg = 'bg-[#F8D79A]';
  }
  return (
    <span
      className={`inline-block w-[7.25px] h-[7.25px] ml-1 rounded-full ${bg}`}
    ></span>
  );
};
