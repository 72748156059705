import { costFormatter } from '../input.utils';

export const PROCESSING_FEE_PERCENTAGE = 0.03;

// if any changes needed also update fees calculation in the feesCell.tsx
export const calculateNiprFee = (stateFee: number, transFee: number) => {
  const totalFees = stateFee + transFee;

  return (
    totalFees + Number(costFormatter(totalFees * PROCESSING_FEE_PERCENTAGE))
  );
};
