export class PageConstants {
  public static readonly NEEDS_ATTENTION = 'NEEDS_ATTENTION';
  public static readonly ALL_PRODUCER_LICENSES = 'ALL_PRODUCER_LICENSES';
  public static readonly RENEWALS = 'RENEWALS';
  public static readonly PRODUCER_APPLICATIONS = 'PRODUCER_APPLICATIONS';
  public static readonly ALL_AGENCY_LICENSES = 'ALL_AGENCY_LICENSES';
  public static readonly AGENCY_APPLICATIONS = 'AGENCY_APPLICATIONS';
  public static readonly USERS = 'USERS';
  public static readonly USER_INVITATIONS = 'USER_INVITATIONS';
  public static readonly DOWNLINES = 'DOWNLINES';
  public static readonly DOWNLINE_INVITATIONS = 'DOWNLINE_INVITATIONS';
  public static readonly UPLINES = 'UPLINES';
  public static readonly UPLINE_INVITATIONS = 'UPLINE_INVITATIONS';
  public static readonly WATCHLIST = 'WATCHLIST';
  public static readonly ASSIGNMENTS = 'ASSIGNMENTS';
  public static readonly APPOINTMENT = 'APPOINTMENT';
  public static readonly HOME = 'HOME';
  public static readonly ORGANIZATION = 'ORGANIZATION';
  public static readonly UNASSIGNED = 'UNASSIGNED';
  public static readonly AGENCY_HIEARCHY_LIST = 'AGENCY_HIEARCHY_LIST';
}
