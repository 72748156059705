import { Collapse, CollapseProps } from 'antd';
import {
  ListBuilderLevel,
  ListBuilderType,
} from '../listView/listBuilderUtils';
import React, { ReactNode } from 'react';

import Highlighter from '../../../../components/common/highlighter/highlighter';
import accountBoxLine from '../../../../assets/icons/account-box-line.svg';
import arrowUpSLine from '../../../../assets/icons/arrow-up-s-line.svg';

export const AgencyCard: React.FC<{
  agencyName: string;
  totalDownlines: number;
  totalProducers: number;
  directProducers: number;
  level: number;
  searchString: string;
  hideDownline?: boolean;
  isDownlineProducer?: boolean;
}> = ({
  agencyName,
  totalDownlines,
  totalProducers,
  directProducers,
  level,
  searchString,
  hideDownline,
  isDownlineProducer,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        ...(isDownlineProducer
          ? {
              padding: '12px 16px',
              background: '#ffffff',
              borderRadius: '0 0 8px 8px',
              marginTop: level - 2 ? 12 : 0,
              marginLeft: (level - 2) * 50,
            }
          : {}),
      }}
      className="figtree var(--color-primary) font-14 line-height-20 relative"
    >
      {' '}
      {isDownlineProducer ? (
        <img src={arrowUpSLine} alt="arrow-left-double-line" />
      ) : null}
      <ListBuilderLevel>Level {level}</ListBuilderLevel>{' '}
      <Highlighter search={searchString?.trim()}>{`${agencyName}`}</Highlighter>
      <div className="flex" style={{ marginLeft: 'auto', gap: 12 }}>
        {!hideDownline ? (
          <ListBuilderType>
            {totalDownlines} Downline
            {totalDownlines > 1 ? 's' : ''}
          </ListBuilderType>
        ) : null}
        <ListBuilderType>
          {totalProducers} Producer
          {!hideDownline ? (
            <>
              {totalProducers > 1 ? 's' : ''} ({directProducers || 0} Direct){' '}
            </>
          ) : null}
        </ListBuilderType>
      </div>
    </div>
  );
};

export const OrganizationTab: React.FC<{
  onClick: () => void;
  producerCount?: number;
  children: () => ReactNode | null;
  agencyName?: string;
  isLast?: boolean;
  isProducerSelected: boolean;
  downlineProducers?: { name: string; npn: string; level: number }[];
}> = ({
  onClick,
  producerCount,
  children,
  agencyName,
  isProducerSelected,
  isLast,
  downlineProducers,
}) => {
  if (!agencyName && !producerCount && !downlineProducers?.length) return null;

  return (
    <div className="relative">
      {agencyName && !isLast ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 28,
            bottom: 0,
            width: 1,
            background: '#D3E7FF',
          }}
        ></div>
      ) : null}
      {producerCount ? (
        <div style={{ marginLeft: agencyName ? 50 : 0, padding: '16px 0 0' }}>
          <div
            className="cursor-pointer"
            style={{
              display: 'flex',
              gap: 8,
              border: '1px solid #D3E7FF',
              padding: 8,
              borderRadius: 8,
              background: isProducerSelected ? '#B5E6FF' : '#ffffff',
            }}
            onClick={() => {
              onClick();
            }}
          >
            <img src={accountBoxLine} alt="arrow-up-s-line" />
            Producers
            <img
              src={arrowUpSLine}
              alt="arrow-up-s-line"
              style={{ rotate: '90deg', marginLeft: 8 }}
            />
            <ListBuilderType
              styles={{
                marginLeft: 'auto',
                ...(isProducerSelected
                  ? {
                      border: '1px solid #00A6FB',
                      background: '#00A6FB',
                      color: '#ffffff',
                    }
                  : {}),
              }}
            >
              {producerCount} Producer
              {producerCount > 1 ? 's' : ''}
            </ListBuilderType>
          </div>
        </div>
      ) : null}
      {downlineProducers?.map((d, index) => {
        return (
          <AgencyCard
            key={d?.name || index}
            agencyName={d?.name}
            totalDownlines={0}
            totalProducers={1}
            directProducers={0}
            isDownlineProducer={true}
            searchString=""
            level={d.level}
            hideDownline
          />
        );
      })}
      <div
        style={{
          marginLeft: 50,
          padding: '12px 0 0',
        }}
      >
        {children()}
      </div>
    </div>
  );
};

const DownlineHierarchy: React.FC<{
  agencyName?: string;
  totalDownlines: number;
  totalProducers: number;
  directProducers: number;
  downlineProducers: { name: string; npn: string; level: number }[];
  onClick: () => void;
  children: () => ReactNode | null;
  level: number;
  isLast?: boolean;
  isProducerSelected: boolean;
  searchString: string;
  hideDownline?: boolean;
}> = ({
  agencyName,
  totalProducers,
  totalDownlines,
  directProducers,
  onClick,
  children,
  level,
  isLast,
  isProducerSelected,
  searchString,
  hideDownline,
  downlineProducers,
}) => {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: agencyName ? (
        <AgencyCard
          level={level}
          agencyName={agencyName}
          totalDownlines={totalDownlines}
          totalProducers={totalProducers}
          directProducers={directProducers}
          searchString={searchString}
          hideDownline={hideDownline}
        />
      ) : null,
      children: (
        <OrganizationTab
          onClick={() => {
            onClick();
          }}
          producerCount={directProducers}
          children={children}
          agencyName={agencyName}
          isLast={isLast}
          downlineProducers={downlineProducers}
          isProducerSelected={isProducerSelected}
        />
      ),
    },
  ];
  return (
    <Collapse
      className={!agencyName ? 'hide-header' : ''}
      defaultActiveKey={['1']}
      onChange={() => {}}
      expandIcon={(isOpen) =>
        agencyName ? (
          <img
            src={arrowUpSLine}
            alt="arrow-left-double-line"
            style={{ rotate: !isOpen?.isActive ? '90deg' : '' }}
          />
        ) : null
      }
      items={items}
      ghost={true}
    />
  );
};

export default DownlineHierarchy;
