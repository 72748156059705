import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Tour } from 'antd';
import React, { useEffect, useState } from 'react';

import { AdminService } from '../../../services/admin.service';
import CustomIcon from '../customiseIcon/customIcon';
import { IdConstants } from '../../../constants/id.constants';
import { TourConstants } from '../../../constants/tour.constant';
import type { TourProps } from 'antd';
import assignmentsIcon from '../../../assets/icons/assignments.svg';
import insuretrek from '../../../assets/icons/insuretrek-logo.svg';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';

const tourTitleStyle = {
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
};

const OnboardNewUserTour: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState(0);
  const { getAccessTokenSilently } = useAuth();
  const isWalletStepVisible = tourStore.showWalletStep;

  const home =
    document.getElementById(TourConstants.SIDER_MENU.OVERVIEW) ||
    document.getElementById(TourConstants.SIDER_MENU.HOME);
  const onboard = document.getElementById(
    TourConstants.SIDER_MENU.PRODUCER_ONBOARDING
  );
  const assignments = document.getElementById(
    TourConstants.SIDER_MENU.TERRITORIES
  );
  const stripeOnboardPending = document.getElementById(
    TourConstants.STRIPE_ONBOARD
  );

  const updateCurrentStep = (isNext: boolean) => {
    if (isNext) {
      tourStore.setSignUpDemoStep(true);
      setCurrentStep((step) => step + 1);
    } else {
      setCurrentStep((step) => step - 1);
      tourStore.setSignUpDemoStep(false);
    }
  };

  useEffect(() => {
    setCurrentStep(tourStore.signUpDemoStep);
  }, [tourStore.signUpDemoStep]);

  const updateStepDetails = async (payload: any) => {
    const bearerToken = await getAccessTokenSilently();
    AdminService.updateTourCompleted(bearerToken, payload).catch((err) =>
      console.error(err)
    );
  };

  const resetStore = () => {
    updateStepDetails({
      producerOnboardingTour: true,
      // assignmentTour: true,
      // isStripeOnboardingTourCompleted: true,
    });
    tourStore.setSignUpDemoTour(false);
    tourStore.setSignUpDemoStep(0);
    setOpen(false);
    sessionStorage.removeItem(TourConstants.AGENCY_ONBOARD_TOUR);
  };

  const updateStep = (element: HTMLElement, isNext: boolean) => {
    const sider = document.getElementById(IdConstants.SIDER_MENU_CONTAINER);
    if (!sider) return;

    let scrollTimer: boolean | number = false;

    const handleScrollEnd = () => {
      if (scrollTimer) return;
      updateCurrentStep(isNext);
      scrollTimer = 1;
      sider.removeEventListener('scrollend', handleScrollEnd);
    };

    const handleScroll = () => {
      if (scrollTimer === false) scrollTimer = 0;
      sider.addEventListener('scrollend', handleScrollEnd);
      sider.removeEventListener('scroll', handleScroll);
    };

    sider.addEventListener('scroll', handleScroll);

    element.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setTimeout(() => {
      if (scrollTimer === false) {
        scrollTimer = true;
        updateCurrentStep(isNext);
        sider.removeEventListener('scroll', handleScroll);
        sider.removeEventListener('scrollend', handleScrollEnd);
      }
    }, 100);
  };

  const handlePrevClick = (
    prev: HTMLElement | [HTMLElement, string, number] | null
  ) => {
    const prevElement = Array.isArray(prev) ? prev?.[0] : prev;
    if (!prevElement) return;

    if (Array.isArray(prev)) {
      prevElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(prev?.[1]);
        elem?.click();
      }, prev?.[2]);
    } else {
      prevElement?.click();
    }
    updateStep(prevElement, false);
  };

  const handleNextClick = (
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void
  ) => {
    const nextElement = Array.isArray(next) ? next?.[0] : next;
    if (!nextElement) return;

    sessionStorage.removeItem(TourConstants.PRODUCER_ONBOARDING_STEP);

    if (Array.isArray(next)) {
      nextElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(next?.[1]);
        elem?.click();
      }, next?.[2]);
    } else {
      nextElement?.click();
    }
    if (cb) cb(true);
    updateStep(nextElement, true);
  };

  const navigateTour = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void
  ) => {
    return {
      cover: <img style={{ height: 72 }} src={insuretrek} alt="insuretrek" />, // need to change by section
      prevButtonProps: {
        children: <ArrowLeftOutlined />,
        onClick: () => handlePrevClick(prev),
      },
      nextButtonProps: {
        // 7 -> final step
        ...(currentStep !== (isWalletStepVisible ? 1 : 0) // todo --> producer onboarding hide
          ? { children: <ArrowRightOutlined /> }
          : []),
        onClick: () => handleNextClick(next, cb),
      },
    };
  };

  const steps: TourProps['steps'] = [
    {
      title: (
        <div
          className="flex-center"
          style={{ flexDirection: 'column', marginTop: 12 }}
        >
          <h2 style={tourTitleStyle}>Welcome to InsureTrek!</h2>
          <p style={{ fontWeight: 'normal' }}>
            One-stop place to manage your producers and their licenses
            lifecycle!
          </p>
        </div>
      ),
      description: (
        <div
          className="flex-center"
          style={{
            paddingBottom: 24,
            gap: 12,
          }}
        >
          <Button
            onClick={() => {
              resetStore();
            }}
          >
            {' '}
            Skip Onboarding{' '}
          </Button>
          <Button
            className="button"
            onClick={() => {
              if (isWalletStepVisible) {
                stripeOnboardPending?.click();
                setCurrentStep(1);
                tourStore.setSignUpDemoStep(true);
              } else {
                resetStore();
              }
            }}
          >
            {' '}
            {isWalletStepVisible ? 'Learn More' : 'Finish'}
          </Button>
        </div>
      ),
      cover: <img style={{ height: 72 }} src={insuretrek} alt="insuretrek" />,
      target: null,
      placement: 'center',
      nextButtonProps: {
        children: null,
      },
    },
    {
      title: (
        <div className="flex-center" style={{ margin: 0, paddingTop: 6 }}>
          <h2 style={tourTitleStyle}>
            <DollarCircleOutlined />
            Setup your wallet
          </h2>
        </div>
      ),
      description: (
        <div style={{ paddingBottom: 12 }}>
          Configure your wallet to handle all transactions within the
          application, including payments for producer licenses, allocating
          funds to your downlines, and covering platform subscription fees.
          Streamline all your financial needs!
          {/* <div className="flex-center mt-24">
            <Button
              disabled
              onClick={() => {
                sessionStorage.setItem(
                  TourConstants.SIGNUP_DEMO_BY_SECTION,
                  'true'
                );
                tourStore.setManageTerritoryTour(true);
                tourStore.setManageTerritoryCurrentStep(0);
                tourStore.setSignUpDemoTour(false);
              }}
              className="button"
            >
              Go to Section
            </Button>
          </div> */}
        </div>
      ),
      target: stripeOnboardPending,
      placement: 'bottomLeft',
      // ...navigateTour(home, assignments
      ...navigateTour(home, null, (isNext) => {
        if (!isNext) return;
        updateStepDetails({
          isStripeOnboardingTourCompleted: true,
        });
      }),
    },
    // {
    //   title: (
    //     <div className="flex-center" style={{ margin: 0, paddingTop: 6 }}>
    //       <h2 style={tourTitleStyle}>
    //         <CustomIcon icon={assignmentsIcon} altText="assignments" />
    //         Manage territory assignments
    //       </h2>
    //     </div>
    //   ),
    //   description: (
    //     <div style={{ paddingBottom: 12 }}>
    //       Tired of applying for individual states? Use the territories feature
    //       to combine states into one group and assign them to your producers in
    //       three simple steps!
    //       <div className="flex-center mt-24">
    //         <Button
    //           onClick={() => {
    //             sessionStorage.setItem(
    //               TourConstants.SIGNUP_DEMO_BY_SECTION,
    //               'true'
    //             );
    //             tourStore.setManageTerritoryTour(true);
    //             tourStore.setManageTerritoryCurrentStep(0);
    //             tourStore.setSignUpDemoTour(false);
    //           }}
    //           className="button"
    //         >
    //           Go to Section
    //         </Button>
    //       </div>
    //     </div>
    //   ),
    //   target: assignments,
    //   placement: 'bottomLeft',
    //   // navigateTour(home, onboard, //todo
    //   ...navigateTour(home, null, (isNext) => {
    //     if (!isNext) return;
    //     updateStepDetails({
    //       assignmentTourCompleted: true,
    //     });
    //   }),
    // },
    // {
    //   title: (
    //     <div className="flex-center" style={{ margin: 0, paddingTop: 6 }}>
    //       <h2 style={tourTitleStyle}>
    //         <UsergroupAddOutlined />
    //         Producer Onboarding!
    //       </h2>
    //     </div>
    //   ),
    //   description: (
    //     <div style={{ paddingBottom: 12 }}>
    //       InsureTrek introduces a seamless Producer Onboarding feature,
    //       simplifying producer management for your agency's success. Easily
    //       onboard individual producers or multiple producers simultaneously
    //       <div className="flex-center mt-24">
    //         <Button
    //           onClick={() => {
    //             sessionStorage.setItem(
    //               TourConstants.PRODUCER_ONBOARDING_STEP,
    //               'true'
    //             );
    //             tourStore.setProducerOnboardingTour(true);
    //             tourStore.setShowProducerOnboardTour(true);
    //             tourStore.setProducerOnboardingCurrentStep(0);
    //             tourStore.setProducerCompletedStep(0);
    //             tourStore.setSignUpDemoTour(false);
    //           }}
    //           className="button"
    //         >
    //           Go to Section
    //         </Button>
    //       </div>
    //     </div>
    //   ),
    //   target: onboard,
    //   placement: 'bottomLeft',
    //   ...navigateTour(assignments, home, (isNext) => {
    //     if (!isNext) return;
    //     updateStepDetails({
    //       indOnboardingTourCompleted: true,
    //     });
    //   }),
    // },
  ];

  const filteredSteps = isWalletStepVisible
    ? steps
    : steps.filter((step, index) => index !== 1);

  return (
    <>
      {open && <div className="overlay" />}
      <Tour
        rootClassName={
          currentStep === 0
            ? 'hide-antd-tour-footer demo-tour'
            : 'hide-antd-prev demo-tour'
        }
        open={open}
        onClose={() => {
          resetStore();
        }}
        steps={filteredSteps}
        current={currentStep}
      />
    </>
  );
};

export default observer(OnboardNewUserTour);
