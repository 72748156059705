import AppliedFilter, { filterType } from './listView/appliedFilter';
import {
  AutoComplete,
  Button,
  Input,
  Popover,
  Skeleton,
  Tabs,
  message,
} from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import {
  DEFAULT_FILTER,
  ListAppointmentDTO,
  NEW_FILTER,
  appliedFilterType,
  buttonStyle,
  convertFiltersToIFilterGroups,
  getFilterType,
  organizationOptions,
} from './organizationView/organizationUtils';
import ListBuilder, { ListType } from './listView/listBuilder';
import SaveConfirmationModal, {
  savedNameType,
} from './listView/saveConfirmationModal';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import { DownlineService } from '../../../services/downlines.service';
import EmptyState from '../../../components/common/emptyState/emptyState';
import FileParser from '../../../utils/file-parser/fileParser';
import { FilterFieldsMetaData } from '../../../types/common/filters.type';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import ListFilter from './listView/listFilter';
import { Name } from '../../../types/data/name.type';
import OrganizationView from './organizationView/organizationView';
import { PageConstants } from '../../../constants/page.constants';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { getAddressLine } from '../onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { getNameString } from '../../../utils/name.utils';
import moment from 'moment';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';

const fileParser = new FileParser();

const { Search } = Input;

const Organization = () => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth();
  const [selectedTab, setSelectedTab] = useState('1');
  const [producers, setProducers] = useState<
    { producerName: Name; id?: string; npn: string }[]
  >([]);
  const [filterCondition, setFilterCondition] =
    useState<filterType[]>(DEFAULT_FILTER);
  const [appliedFilters, setAppliedFilters] = useState<filterType[]>([]);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  // organization View
  const [isFetching, setIsFetching] = useState({
    orgView: false,
    listView: false,
    isSaving: false,
    tableLoader: false,
  });
  const [isExporting, setIsExporting] = useState(false);
  const [downlineAgency, setDownlineAgency] = useState<any>([]);
  const [searchString, setSearchString] = useState({
    producers: '',
    list: '',
  });
  const [savedName, setSavedName] = useState<savedNameType>(null);
  const [directProducers, setDirectProducers] = useState<
    { producerName: Name; id?: string; npn: string }[]
  >([]);
  const [selectedProducerLevel, setSelectedProducerLevel] = useState('');
  const [producerDetailsFetching, setProducerDetailsFetching] = useState(false);
  // list view
  const [listDetails, setListDetails] = useState<ListType[]>([]);
  const [levels, setLevels] = useState<number[]>([]);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [isUpdate, setIsUpdateId] = useState('');
  const listId = searchParams.get('id');
  const [saveListModal, setSaveListModal] = useState(false);

  const [appointmentDetails, setAppointmentDetails] = useState<
    ListAppointmentDTO[]
  >([]);

  // search
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  // pagination
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  // sort
  const [sortValue, setSortValue] = useState({});
  const pageNo = searchParams.get('page');

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    if (!adminStore?.agency?.id) return;
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    getAgenciesDownlineHierarchyList(
      adminStore?.agency?.id,
      undefined,
      queryText || '',
      newPageNumber,
      newPageSize
    );
    searchParams.set('page', `${newPageNumber}`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const page = Number(pageNo);
    if (!isNaN(page) && pageNo) {
      setPageNumber(page);
    } else {
      setPageNumber(1);
    }
  }, [pageNo]);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
        searchParams.set('page', '1');
        searchParams.set('search', text);
        setSearchParams(searchParams);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      let newSearchText = value;
      if (match) {
        newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      searchParams.set('page', '1');
      searchParams.set('search', newSearchText);
      setSearchParams(searchParams);

      setPageNumber(1);
    }
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token && adminStore?.agency?.id) {
        const response: any =
          await AgencyService.getFilterInfoForOrganizationHiearchyList(
            token,
            adminStore?.agency?.id
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.AGENCY_HIEARCHY_LIST
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    fetchFiltersInfoForFindAll();
  }, [adminStore?.agency?.id]);

  useEffect(() => {
    if (!adminStore?.agency?.id) return;

    getAgenciesDownlineHierarchyList(
      adminStore?.agency?.id,
      undefined,
      undefined,
      pageNo ? Number(pageNo) : undefined
    );
  }, [queryText, searchType]);

  const getOrganizationDetails = async (agencyId: string) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        setIsFetching((prev) => ({ ...prev, orgView: true }));
        const response: any =
          await DownlineService.getAgenciesDownlineHierarchy(
            agencyId,
            bearerToken
          );
        setDownlineAgency(response?.data);

        const directProducers = await AgentService.getAllProducersForAgency(
          bearerToken,
          50000,
          1
        );
        setDirectProducers(
          directProducers.data
            ?.map((d) => ({
              producerName: d?.producer?.name,
              id: d.producer?.id,
              npn: d?.producer?.npn,
            }))
            ?.sort((a, b) =>
              getNameString(a.producerName).localeCompare(
                getNameString(b.producerName)
              )
            ) || []
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching((prev) => ({ ...prev, orgView: false }));
      }
    }
  };

  const getAppointmentDetails = async (agencyId: string) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        const response: any =
          await AgencyService.getAppointmentDetails(bearerToken);
        const appointmentsSet = new Set();
        const agencyAppointments = response?.agencyAppointments?.filter(
          (d: ListAppointmentDTO) => {
            if (appointmentsSet.has(d.companyName)) return false;
            appointmentsSet.add(d.companyName);
            return true;
          }
        );
        const producerAppointments = response?.producerAppointments?.filter(
          (d: ListAppointmentDTO) => {
            if (appointmentsSet.has(d.companyName)) return false;
            appointmentsSet.add(d.companyName);
            return true;
          }
        );

        setAppointmentDetails([
          ...(producerAppointments || []),
          ...(agencyAppointments || []),
        ]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateLevel = (listDetails: any) => {
    const levels = [
      ...new Set(
        (listDetails?.map(
          (item: { level: number }) => item?.level
        ) as number[]) || []
      ),
    ].sort((a, b) => a - b);

    setLevels(levels);
  };

  const getAgenciesDownlineHierarchyList = async (
    agencyId: string,
    filters?: filterType[],
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      setIsFetching((prev) => ({ ...prev, tableLoader: true }));
      const bearerToken = await getAccessTokenSilently();
      const hiearchyListFilters = filters || appliedFilters;
      const reqBody = {
        ...(sort || sortValue || {}),
      };
      const response: any =
        await DownlineService.getAgenciesDownlineHierarchyList(
          agencyId,
          bearerToken,
          page || pageNumber,
          100,
          searchTextProp ? searchTextProp : queryText || '',
          searchType || '',
          reqBody,
          hiearchyListFilters?.length
            ? convertFiltersToIFilterGroups(
                hiearchyListFilters as appliedFilterType[]
              )
            : undefined,
          getFilterType(hiearchyListFilters || [])
        );
      setListDetails(response?.data);
      setTotalCount(response?.totalCount || 100);

      if (!hiearchyListFilters?.length) {
        updateLevel(response?.data);
      } else {
        setLevels([1, 2, 3]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching((prev) => ({ ...prev, tableLoader: false }));
    }
  };

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const getOrganizationList = async (
    agencyId: string,
    listId: string | null
  ) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        setIsFetching((prev) => ({ ...prev, listView: true }));
        let savedList: any;
        if (listId) {
          savedList = await AgencyService.getHiearchyListViewById(
            bearerToken,
            listId
          );

          setAppliedFilters(savedList?.hiearchyListFilters);
          const isCondition = savedList?.hiearchyListFilters?.[1]?.condition;
          setFilterCondition([
            ...(savedList?.hiearchyListFilters || []),
            { ...NEW_FILTER?.[0], condition: isCondition || '' },
          ]);
          setSearchString((prev) => ({ ...prev, list: savedList?.listName }));
          setIsUpdateId(savedList?.id);
          setSavedName({
            agencyName: savedList?.agencyName,
            updatedAt: savedList?.updatedAt,
          });
        }
        await getAgenciesDownlineHierarchyList(
          agencyId,
          savedList?.hiearchyListFilters?.length
            ? (savedList?.hiearchyListFilters as appliedFilterType[])
            : undefined,
          undefined,
          pageNo ? Number(pageNo) : undefined
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching((prev) => ({ ...prev, listView: false }));
      }
    }
  };

  useEffect(() => {
    if (!adminStore?.agency?.id) return;
    setSavedName(null);
    getOrganizationDetails(adminStore.agency?.id);
    getOrganizationList(adminStore.agency?.id, listId);
    getAppointmentDetails(adminStore?.agency?.id);
  }, [adminStore.agency?.id, listId]);

  const showProducers = async () => {
    if (!selectedProducerLevel) return;

    setSearchString((prev) => ({ ...prev, producers: '' }));
    if (selectedProducerLevel === adminStore?.agency?.id) {
      setProducers(directProducers);
      if (cancelToken) cancelPreviousRequest(cancelToken);
      setProducerDetailsFetching(false);
    } else {
      try {
        setProducerDetailsFetching(true);
        const bearerToken = await getAccessTokenSilently();
        if (cancelToken) cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const downlineProducers = await AgentService.getAllAgents(
          bearerToken,
          null,
          5000,
          1,
          cancelTokenSource,
          selectedProducerLevel
        );
        if (selectedProducerLevel === adminStore?.agency?.id) return;
        setProducers(
          downlineProducers?.data
            ?.map((d) => ({
              producerName: d?.producer?.name,
              npn: d?.producer?.npn,
            }))
            ?.sort((a, b) =>
              getNameString(a.producerName).localeCompare(
                getNameString(b.producerName)
              )
            ) || []
        );
        setProducerDetailsFetching(false);
      } catch (error) {
        console.error(error);
        if (!axios.isCancel(error)) {
          setProducerDetailsFetching(false);
        }
      }
    }
  };

  useEffect(() => {
    showProducers();
  }, [selectedProducerLevel]);

  const handleTabChange = (selected: string) => {
    if (selectedTab === selected) return;
    setSelectedTab(selected);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tab', selected);
    setSearchParams(newParams);
  };

  useEffect(() => {
    const selectedTab = searchParams.get('tab');
    if (selectedTab) {
      setSelectedTab(selectedTab);
    }
  }, []);

  const getProducers = () => {
    return producers.filter((d) =>
      getNameString(d.producerName)
        ?.toLowerCase()
        ?.trim()
        ?.includes(searchString?.producers?.trim()?.toLowerCase())
    );
  };

  const onSaveList = async () => {
    try {
      const isNotValidFilter = appliedFilters?.some(
        (d) => !d.match?.length || !d.type?.length || !d?.value?.length
      );
      if (isNotValidFilter) {
        message.error(
          'Please select all the mandatory fields before saving the list'
        );
        return;
      }
      setIsFetching((prev) => ({ ...prev, isSaving: true }));
      const bearerToken = await getAccessTokenSilently();
      const hiearchyLists = await AgencyService.createHiearchyListView(
        bearerToken,
        [
          {
            listName: searchString?.list,
            hiearchyListFilters: appliedFilters?.filter(
              (d) => d.match && d.type && d.value
            ),
          },
        ],
        isUpdate
      );
      message.success(
        `${searchString?.list} is ${
          !isUpdate ? 'created' : 'updated'
        } successfully`
      );
      setSaveListModal(false);
      setSearchString((prev) => ({ ...prev, list: '' }));
      setIsUpdateId(hiearchyLists?.[0]?.id);
    } catch (error: any) {
      console.error(error);
      message?.error(
        error?.response?.data?.message ||
          `Failed to ${!isUpdate ? 'create' : 'update'} list.Please Try Again.`
      );
    } finally {
      setIsFetching((prev) => ({ ...prev, isSaving: false }));
    }
  };

  const onApply = async (isClear?: boolean) => {
    if (!adminStore?.agency?.id) return;
    try {
      const isNotFilled = filterCondition?.some(
        (d, i) =>
          (d.type?.length && (!d.match || !d.value?.length)) ||
          (d.match && (!d.type?.length || !d.value?.length)) ||
          (d.value?.length && (!d.type?.length || !d.match))
      );

      if (isNotFilled && !isClear) {
        message.error('Please select all the filter options');
        return;
      }

      setIsPopOverOpen(false);

      if (
        isEqual(
          filterCondition.slice(0, filterCondition?.length - 1),
          appliedFilters
        ) &&
        !isClear
      ) {
        return;
      }

      setIsFetching((prev) => ({ ...prev, tableLoader: true }));

      const filters = filterCondition.filter(
        (d, i) =>
          (i === 0 &&
            Object.values(d)?.filter((d) =>
              Array.isArray(d) ? d?.length : Boolean(d)
            )?.length === 3) ||
          Object.values(d)?.filter((d) =>
            Array.isArray(d) ? d?.length : Boolean(d)
          )?.length === 4
      );

      await getAgenciesDownlineHierarchyList(
        adminStore?.agency?.id,
        isClear
          ? []
          : filters?.length
            ? (filters as appliedFilterType[])
            : undefined
      );
      setAppliedFilters(filters);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching((prev) => ({ ...prev, tableLoader: false }));
    }
  };

  const exportList = async () => {
    if (!adminStore?.agency?.id) return;
    try {
      const bearerToken = await getAccessTokenSilently();
      const reqBody = {
        ...(sortValue || {}),
      };
      const SKIP_PAGINATION = true;
      const IS_EXPORT = true;
      setIsExporting(true);
      const response: any =
        await DownlineService.getAgenciesDownlineHierarchyList(
          adminStore?.agency?.id,
          bearerToken,
          null,
          null,
          queryText || '',
          searchType || '',
          reqBody,
          appliedFilters?.length
            ? convertFiltersToIFilterGroups(
                appliedFilters as appliedFilterType[]
              )
            : undefined,
          getFilterType(appliedFilters || []),
          SKIP_PAGINATION, // skip-pagination
          IS_EXPORT // is-export
        );

      const listDetails = response?.data?.data || [];

      const exportList = [...listDetails]?.map((d) => {
        const businessAddress = getAddressLine(d?.businessAddress);
        return {
          Type: d?.type,
          Name: getNameString(d?.name),
          NPN: d?.npn,
          'Business Email': d?.businessEmail,
          'Business Phone': d?.businessPhone,
          Level: d?.level,
          'Resident License Number': d?.residentLicenseNumber,
          'Resident State Code': d?.residentStateCode,
          Upline: d?.upline || '-',
          'Business Address': `${businessAddress.addressLine1} ${businessAddress.addressLine2}`,
        };
      });

      const formattedDate = moment().format('MM_DD_YYYY');
      const formattedTime = new Date()
        .toTimeString()
        .slice(0, 8)
        .replace(/:/g, '_');

      const fileName = `${
        searchString.list || 'list'
      }_${formattedDate}_${formattedTime}`;

      fileParser.exportJsonToExcel(exportList, fileName);
    } catch (error) {
      console.error(error);
      message.error('Failed to export list.please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const onFilterClear = (type: string, value: string, index: number) => {
    setAppliedFilters((prev) => {
      return prev.map((d, i) => {
        if (i !== index) return d;
        const oldValues = d?.[type as keyof filterType];
        return {
          ...d,
          [type]: Array.isArray(oldValues)
            ? oldValues?.filter((d) => d !== value)
            : null,
        };
      });
    });
    setFilterCondition((prev) => {
      return prev.map((d, i) => {
        if (i !== index) return d;
        const oldValues = d?.[type as keyof filterType];
        return {
          ...d,
          [type]: Array.isArray(oldValues)
            ? oldValues?.filter((d) => d !== value)
            : null,
        };
      });
    });
  };

  const onSaveListCancel = () => {
    if (isFetching?.isSaving) return;
    setSaveListModal(false);
  };

  return (
    <div className="width-100 hide-bottom-tab-border flex relative">
      <div style={{ flex: 1, marginRight: 15 }}>
        <Tabs
          items={organizationOptions}
          activeKey={selectedTab}
          type="line"
          style={{
            width: '100%',
          }}
          onChange={handleTabChange}
          tabBarExtraContent={
            <div className="flex justify-end w-full gap-3 h-[46px]">
              {selectedTab === '1' ? (
                <>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => navigate(RouteConstants.uplines.path)}
                    disabled={subscriptionStatusInactive}
                  >
                    Uplines
                  </Button>{' '}
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => navigate(RouteConstants.downlines.path)}
                    disabled={subscriptionStatusInactive}
                  >
                    Downlines
                  </Button>
                </>
              ) : null}
              {selectedTab === '2' ? (
                <>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                      const newParams = new URLSearchParams(searchParams);
                      newParams.delete('id');
                      setSearchParams(newParams);
                      setIsPopOverOpen(false);
                      navigate(RouteConstants.agencyHierarchySavedLists.path);
                    }}
                    disabled={
                      isFetching?.listView || subscriptionStatusInactive
                    }
                  >
                    View Saved Lists
                  </Button>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                      exportList();
                    }}
                    loading={isExporting}
                    disabled={
                      isFetching?.listView ||
                      !listDetails?.length ||
                      subscriptionStatusInactive
                    }
                  >
                    Export List
                  </Button>
                  <Popover
                    content={
                      <ListFilter
                        setAppliedFilters={setAppliedFilters}
                        appliedFilters={appliedFilters}
                        levels={levels}
                        appointmentDetails={appointmentDetails}
                        filterCondition={filterCondition}
                        setFilterCondition={setFilterCondition}
                        setIsPopOverOpen={setIsPopOverOpen}
                        onApply={onApply}
                        setSearchString={setSearchString}
                      />
                    }
                    placement="bottomRight"
                    open={isPopOverOpen}
                  >
                    <Button
                      className="figtree"
                      style={{
                        background: '#E8EBEE',
                        color: '#001F45',
                        fontSize: 14,
                        lineHeight: '20px',
                        fontWeight: 400,
                        margin: '12px 0',
                      }}
                      disabled={
                        isFetching?.listView || subscriptionStatusInactive
                      }
                      onMouseEnter={() =>
                        isFetching?.listView ? null : setIsPopOverOpen(true)
                      }
                      onClick={() =>
                        isFetching?.listView
                          ? null
                          : setIsPopOverOpen((prev) => !prev)
                      }
                    >
                      <FilterOutlined /> Filters
                    </Button>
                  </Popover>
                </>
              ) : null}
            </div>
          }
        />

        <div>
          {selectedTab === '1' && (
            <OrganizationView
              {...{
                setProducers,
                downlineAgency,
                isFetching: isFetching.orgView,
                directProducers,
                selectedProducerLevel,
                setSelectedProducerLevel,
              }}
            />
          )}
          {selectedTab === '2' ? (
            isFetching.listView ? (
              <Skeleton active />
            ) : (
              <div>
                {' '}
                <div className="flex gap-4 v2-search mb-2">
                  <AutoComplete
                    className="cursor-pointer"
                    options={options}
                    style={{ width: '100%' }}
                    value={getSearchDisplayText(
                      searchType,
                      displaySearchType,
                      searchText
                    )}
                    disabled={
                      isFetching?.tableLoader ||
                      filterInfoLoading ||
                      subscriptionStatusInactive
                    }
                    onSelect={handleOnSelect}
                    onChange={handleOnChange}
                  >
                    <Search
                      id={IdConstants.SEARCH_INPUT + '-organization-list-view'}
                      size="middle"
                      placeholder="Search by Type, Name, NPN, Email, Phone Number "
                      style={{
                        width: '100%',
                      }}
                      onSearch={handleOnSearch}
                    />
                  </AutoComplete>
                </div>
                {!appliedFilters?.length ? null : (
                  <div className="my-4 rounded-md bg-white border border-[#e6e6e6] p-4">
                    <AppliedFilter
                      appliedFilters={appliedFilters}
                      onClick={(type, value, index) =>
                        onFilterClear(type, value, index)
                      }
                    />
                    <div
                      className="flex mt-3"
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 12,
                      }}
                    >
                      <div
                        className="flex"
                        style={{ gap: 16, alignItems: 'center' }}
                      >
                        {' '}
                        <div
                          style={{
                            fontSize: 12,
                            lineHeight: '14.4px',
                            fontWeight: 500,
                            cursor: isFetching?.isSaving
                              ? 'default'
                              : 'pointer',
                          }}
                          className={'figtree'}
                          onClick={() => {
                            if (isFetching?.isSaving) return;
                            setAppliedFilters([]);
                            setFilterCondition(NEW_FILTER);
                            setIsUpdateId('');
                            onApply(true); // clear filters
                            setSearchString((prev) => ({ ...prev, list: '' }));
                          }}
                        >
                          Cancel
                        </div>
                        <Button
                          className="figtree save-list-button"
                          style={{
                            background: 'var(--Secondary-Secondary, #001F45)',
                            color: '#ffffff',
                            fontSize: 12,
                            lineHeight: '14.4px',
                            fontWeight: 500,
                            height: 24,
                          }}
                          disabled={
                            isFetching?.isSaving || subscriptionStatusInactive
                          }
                          onClick={() => {
                            setSaveListModal(true);
                          }}
                          size="small"
                        >
                          Save {isUpdate ? 'Changes' : 'List'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <ListBuilder
                  isLoading={isFetching?.tableLoader}
                  listDetails={listDetails}
                  pageNumber={pageNumber}
                  isDisabled={
                    isFetching?.tableLoader || subscriptionStatusInactive
                  }
                  pageSize={pageSize}
                  totalCount={totalCount}
                  key={'list-builder'}
                  setSortValue={setSortValue}
                  onPageChange={onPageChange}
                  cb={(search, page, size, sort) => {
                    if (!adminStore?.agency?.id) return;
                    getAgenciesDownlineHierarchyList(
                      adminStore?.agency?.id,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      sort
                    );
                  }}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
      {(producers?.length || producerDetailsFetching) && selectedTab === '1' ? (
        <>
          <div
            style={{
              minWidth: 300,
              padding: '12px',
              background: '#ffffff',
              height: 'calc(100vh - 72px)',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 0 12px',
                cursor: 'pointer',
              }}
              className="ant-modal-close-selected-producer"
            >
              <CloseOutlined
                onClick={() => {
                  setProducers([]);
                  setSelectedProducerLevel('');
                  setSearchString((prev) => ({ ...prev, producers: '' }));
                }}
                style={{ padding: 5, borderRadius: 6 }}
              />
            </div>
            {producerDetailsFetching ? (
              <Skeleton active />
            ) : (
              <>
                <Input
                  placeholder="Search"
                  value={searchString.producers}
                  onChange={(e) =>
                    setSearchString((prev) => ({
                      ...prev,
                      producers: e.target.value,
                    }))
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                    padding: '16px 0',
                    fontWeight: 400,
                  }}
                  className="figtree"
                >
                  {getProducers()?.map((d) => (
                    <div key={d?.npn}>
                      <span
                        className={'producer-list-hover'}
                        style={{
                          cursor: d?.id ? 'pointer' : 'not-allowed',
                          width: 'fit-content',
                          fontSize: 14,
                          color: '#001F45',
                          lineHeight: '20px',
                        }}
                        onClick={() => {
                          if (d?.id) {
                            navigate(
                              RouteConstants.agentOverview.path.replace(
                                ':agentId',
                                d.id
                              )
                            );
                          }
                        }}
                      >
                        {' '}
                        {getNameString(d.producerName)}
                      </span>
                      <span
                        style={{
                          display: 'block',
                          fontSize: 10,
                          color: '#848484',
                          lineHeight: '12px',
                        }}
                      >
                        {d.npn}
                      </span>
                    </div>
                  ))}{' '}
                  {getProducers().length === 0 ? (
                    <div
                      style={{
                        display: 'grid',
                        placeContent: 'center',
                        height: '475px',
                      }}
                    >
                      <EmptyState
                        image="empty data"
                        heading=""
                        width="100%"
                        content="No Producers Found"
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
      <SaveConfirmationModal
        isFetching={isFetching}
        isUpdate={!!isUpdate}
        onSaveList={onSaveList}
        onSaveListCancel={onSaveListCancel}
        saveListModal={saveListModal}
        savedName={savedName} // bug
        searchString={searchString}
        setSearchString={setSearchString}
        key={'save-confirmation-modal-org-list-view'}
      />
    </div>
  );
};

export default Organization;
