/* eslint-disable no-async-promise-executor */
import {
  Auth0UserData,
  InsureTrekUser,
  auth0Store,
} from '../stores/auth0Store';
import {
  InternalSubscriptionStatus,
  appStateInfoStore,
} from '../stores/appStateInfo.store';

import { ArraySuccessResponse } from '../types/response/arraySuccessResponse.type';
import { MessageSuccessResponse } from '../types/response/messageSuccessResponse.type';
import { Profile } from '../types/common/profile.type';
import { TourConstants } from '../constants/tour.constant';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import auth0 from 'auth0-js';
import axios from 'axios';
import { isNull } from 'lodash';
import { toJS } from 'mobx';
import { tourStore } from '../stores/tour.store';

export class Auth0Service {
  static host = UrlConstants.backend;
  static modulePath = '/Auth0User';

  static auth0_: any = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    scope: 'openid email profile offline_access',
  });

  static async performLogin(
    email: string,
    password: string,
    recaptchaToken: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      apiStore
        .getApiClientWithoutAuthentication()
        .post(`${this.host}/auth/login`, {
          email,
          password,
          recaptchaToken,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          const error = err?.response?.data?.data;
          if (error?.exceptionName === 'GoogleRecaptchaException') {
            reject('Captcha Validation Failed. Please try again');
          } else reject(error?.message);
        });
    });
  }

  static async forgotPassword(
    email: string,
    recaptchaToken: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      apiStore
        .getApiClientWithoutAuthentication()
        .post(`${this.host}/auth/forgot-password`, {
          email,
          recaptchaToken,
        })
        .then((response) => {
          resolve(response.data?.message);
        })
        .catch((err) => {
          const error = err?.response?.data?.error;
          if (error.exceptionName === 'UnauthorizedException') {
            reject(error.message);
          } else if (error.exceptionName === 'GoogleRecaptchaException') {
            reject('Captcha Validation Failed. Please try again');
          } else reject(err.description);
        });
    });
  }

  static async performLogout(logoutUrl: string): Promise<void> {
    return Auth0Service.auth0_.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
        ? process.env.REACT_APP_AUTH0_CLIENT_ID
        : '',
      redirectTo: logoutUrl ? logoutUrl : window.location.origin,
    });
  }

  static async performTokenRefresh(refreshToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth0Service.auth0_.client.oauthToken(
        {
          refresh_token: refreshToken,
          grantType: 'refresh_token',
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
            ? process.env.REACT_APP_AUTH0_CLIENT_ID
            : '',
        },
        (err: any, authResult: any) => {
          if (!isNull(err)) {
            reject(err.description);
          } else {
            resolve(authResult);
          }
        }
      );
    });
  }

  /**
   * Fetches the auth0 data of the JWT user
   * @param token auth0 JWT
   * @returns
   */
  static async getCurrentUser(
    token: string,
    inUserInvite: boolean,
    isLogin?: boolean
  ): Promise<InsureTrekUser> {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      apiStore.setTokenForGetUser(cancelTokenSource);
      const response = await apiStore
        .getApiClient(token)
        .get(`${this.host}/account/profile/me?fromUserInvite=${inUserInvite}`, {
          ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        });
      const result = response.data.data;
      if (isLogin) {
        const openTour = () => {
          tourStore.setSignUpDemoTour(true);
          tourStore.setSideBarOpen(true);
          sessionStorage.setItem(TourConstants.AGENCY_ONBOARD_TOUR, 'true');
        };
        const currentProfile = auth0Store.getCurrentProfile();
        if (currentProfile?.tourFlags) {
          // Stripe tour not required
          // if (!result?.account?.isStripeOnboardingTourCompleted) {
          //   openTour();
          // } else
          // if (!currentProfile?.tourFlags?.assignmentTour) {
          //   openTour();
          //   // tourStore.setSignUpDemoStep(1);
          // } else
          if (!currentProfile?.tourFlags?.producerOnboardingTour) {
            openTour();
            // tourStore.setSignUpDemoStep(2);
          }
        }
        if (result?.agency) {
          if (
            result?.agency?.stripeDetails?.internalSubscriptionStatus ===
            InternalSubscriptionStatus.Inactive
          ) {
            appStateInfoStore.setInternalSubscriptionStatus(
              InternalSubscriptionStatus.Inactive
            );
            appStateInfoStore.setShowPendingStripeInvoiceModal(true);
          }
        }
      }
      apiStore.setTokenForGetUser(undefined);
      return result;
    } catch (error: any) {
      apiStore.setTokenForGetUser(undefined);
      console.error('Auth0Service :: getUser Error :: ', error?.message);
      throw error;
    }
  }

  static async getCurrentUserProfiles(
    token: string,
    inUserInvite: boolean
  ): Promise<ArraySuccessResponse<Profile>> {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      apiStore.setTokenForGetUser(cancelTokenSource);
      const response = await apiStore
        .getApiClient(token)
        .get(`${this.host}/account/me/roles?fromUserInvite=${inUserInvite}`, {
          ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        });
      const result = response.data;
      apiStore.setTokenForGetUser(undefined);
      return result as ArraySuccessResponse<any>;
    } catch (error: any) {
      apiStore.setTokenForGetUser(undefined);
      console.error(
        'Auth0Service :: getCurrentUserProfiles Error :: ',
        error?.message
      );
      throw error;
    }
  }

  /**
   * Onboards an agent (with or without agency) at the end of sign up steps
   * @param token auth0 jwt
   * @returns
   */
  static async onboardAgentOnAuth0(token: string): Promise<Auth0UserData> {
    const path = `${this.modulePath}/onboard-current-agent`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}`, {});

        resolve(response.data.data);
      } catch (error: any) {
        console.error(
          'Auth0Service :: onboardAgentOnAuth0 Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Sends Reset Password Mail to the user
   * @param token auth0 jwt
   * @returns
   */
  static async sendResetPasswordMail(token: string) {
    return new Promise(async (resolve, reject) => {
      const path = '/auth/me/reset-password';
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`);
        resolve(response.data as unknown as MessageSuccessResponse);
      } catch (error: any) {
        reject(error);
      }
    });
  }
}
