import './generalPageStyle.css';

import {
  Button,
  Card,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { InsureTrekAgency, auth0Store } from '../../../stores/auth0Store';
import {
  formatPhoneAndFax,
  isPhoneNumberValid,
} from '../../../utils/form-utils/format-phone-fax.utils';
import { useEffect, useState } from 'react';

import AddressInput from '../../../components/common/addressInput';
import { AddressType } from '../../../enums/addressType.enum';
import AgencyLogo from '../../../components/common/agencyLogo/agencyLogo';
import { AgencyService } from '../../../services/agency.service';
import DeleteAgencyModal from '../../../utils/modal/admins/delete-agency/delete-agency.modal';
import { EditOutlined } from '@ant-design/icons';
import { GeneralPropsType } from '../../../types/agency/agencyPreference.type';
import { IdConstants } from '../../../constants/id.constants';
import { Profile } from '../../../types/common/profile.type';
import { RoleType } from '../../../enums/roles.enum';
import { StateConstants } from '../../../constants/state.constants';
import UpdateEmailModal from '../../../utils/modal/admins/update-email/update-email';
import UploadProfileModal from '../licenses/allLicenses/agency/uploadProfileModal/uploadProfileModal';
import { adminStore } from '../../../stores/admin.store';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import editLogo from '../../../assets/icons/editLogo.svg';
import { getInitials } from '../../../utils/name.utils';
import { isEmpty } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

const GeneralProfile: React.FC<GeneralPropsType> = ({ setPreferences }) => {
  const { roles } = useAuth();
  const { getAccessTokenSilently } = useAuth();
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  const user = auth0Store.getUser();
  const [agencyId, setAgenctId] = useState(adminStore.agency?.id);
  const { bearerToken } = useAuth();

  useEffect(() => {
    if (adminStore.agency) {
      setAgenctId(adminStore.agency.id);
    }
  }, [adminStore.account]);

  useEffect(() => {
    agencyProfileStore.setProfileRole(roles.join(', '));
    agencyProfileStore.setProfileNpn(agencyProfileStore.fetchNpn());
  }, [roles]);

  const [loading, setLoading] = useState(false);
  const [addressInfoEdit, setAddressInfoEdit] = useState(false);
  const [updateEmailAddressModal, setUpdateEmailAddressModal] = useState(false);
  const [deleteAgencyModal, setDeleteAgencyModal] = useState(false);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData | null>(null);

  const [generalDetails, setGeneralDetails] = useState<any>(' ');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData(); // Call the async function
  }, [agencyId, bearerToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setPreferences((prev) => ({ ...prev, isLoading: true }));
      if (bearerToken && agencyId) {
        const resp = await AgencyService.getMyAgencyInfo(bearerToken);

        const addressDict: any = {};
        resp.data?.address?.forEach((stateAddress: any) => {
          addressDict[stateAddress.addressTypeCode] = stateAddress;
        });

        const agencyInformation: any = {
          name: resp?.data?.name,
          npn: resp?.data?.npn,
          fein: resp?.data?.fein,
          address: addressDict,
          email: resp?.data?.businessEmail,
          phone: resp?.data?.phone,
          businessPhone: resp?.data?.businessPhone,
          fax: resp?.data?.fax,
          registeredState: resp?.data?.residentStateCode,
          logo: resp?.data.logo,
        };
        setGeneralDetails(agencyInformation);
        setPreferences({
          isLoading: false,
          preferences: resp?.data?.preferences,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setPreferences((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleImageUpload = async (previewImage: string) => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      if (agencyId) {
        await AgencyService.uploadAgencyImage(token, formData);
        setGeneralDetails({ ...generalDetails, logo: previewImage });
        const currentProfile = auth0Store.getCurrentProfile();
        const currentAgency = adminStore.getAgency();
        if (currentProfile) {
          const currentProfiles = auth0Store.getProfiles();
          const updatedProfiles: Profile[] = currentProfiles.map((profile) =>
            profile.id === currentProfile.id
              ? {
                  ...profile,
                  agency: {
                    ...profile.agency,
                    logo: previewImage,
                  },
                }
              : profile
          );
          auth0Store.setProfiles(updatedProfiles);
        }
        if (currentAgency) {
          const updatedAgency: InsureTrekAgency = {
            ...currentAgency,
            logo: previewImage,
          };
          adminStore.setAgency(updatedAgency);
        }
      }
    } catch (err) {
      message.error('An error occurred while saving agency image');
    } finally {
      setLoading(false);
    }
  };

  const updateGeneralInformation = async (values: any) => {
    try {
      setLoading(true);
      const updateObject: any = {
        email:
          generalDetails?.email !== values.email ? values.email : undefined,
        phone:
          generalDetails?.businessPhone !== values.phone
            ? values.phone
            : undefined,
        fax: generalDetails?.fax !== values.fax ? values.fax : undefined,
        stateCode: generalDetails?.registeredState,
        address: [
          {
            dateUpdated: new Date().toISOString(),
            addressTypeCode: '1',
            addressType: AddressType.RESIDENCE,
            addressLine1: values.raddressLine1
              ? values.raddressLine1.trim()
              : values.raddressLine1,
            addressLine2: values.raddressLine2
              ? values.raddressLine2.trim()
              : values.raddressLine2,
            addressLine3: values.raddressLine3
              ? values.raddressLine3.trim()
              : values.raddressLine3,
            city: values.rcity,
            stateCode: values.rstate,
            zip: values.rzipcode,
            country: values.rcountry,
          },
          {
            dateUpdated: new Date().toISOString(),
            addressTypeCode: '2',
            addressType: AddressType.BUSINESS,
            addressLine1: values.baddressLine1
              ? values.baddressLine1.trim()
              : values.baddressLine1,
            addressLine2: values.baddressLine2
              ? values.baddressLine2.trim()
              : values.baddressLine2,
            addressLine3: values.baddressLine3
              ? values.baddressLine3.trim()
              : values.baddressLine3,
            city: values.bcity,
            stateCode: values.bstate,
            zip: values.bzipcode,
            country: values.bcountry,
          },
          {
            dateUpdated: new Date().toISOString(),
            addressTypeCode: '3',
            addressType: AddressType.MAILING,
            addressLine1: values.maddressLine1
              ? values.maddressLine1.trim()
              : values.maddressLine1,
            addressLine2: values.maddressLine2
              ? values.maddressLine2.trim()
              : values.maddressLine2,
            addressLine3: values.maddressLine3
              ? values.maddressLine3.trim()
              : values.maddressLine3,
            city: values.mcity,
            stateCode: values.mstate,
            zip: values.mzipcode,
            country: values.mcountry,
          },
        ],
      };
      if (bearerToken && agencyId) {
        await AgencyService.updateAgencydetails(updateObject, bearerToken);
        await fetchData();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setAddressInfoEdit(!addressInfoEdit);
      setLoading(false);
    }
  };

  const [checkAppliedMailing, setCheckAppliedMailing] = useState(false);
  const [checkAppliedResidence, setCheckAppliedResidence] = useState(false);

  const handleMailingCheckboxChange = () => {
    setCheckAppliedMailing((prev) => !prev);

    if (!checkAppliedMailing) {
      const businessAddress = form.getFieldsValue([
        'baddressLine1',
        'baddressLine2',
        'baddressLine3',
        'bcity',
        'bstate',
        'bzipcode',
        'bcountry',
      ]);
      form.setFieldsValue({
        maddressLine1: businessAddress.baddressLine1,
        maddressLine2: businessAddress.baddressLine2,
        maddressLine3: businessAddress.baddressLine3,
        mcity: businessAddress.bcity,
        mstate: businessAddress.bstate,
        mzipcode: businessAddress.bzipcode,
        mcountry: businessAddress.bcountry,
      });
    }
  };

  const handleResidenceCheckboxChange = () => {
    setCheckAppliedResidence((prev) => !prev);

    if (!checkAppliedResidence) {
      const businessAddress = form.getFieldsValue([
        'baddressLine1',
        'baddressLine2',
        'baddressLine3',
        'bcity',
        'bstate',
        'bzipcode',
        'bcountry',
      ]);
      form.setFieldsValue({
        raddressLine1: businessAddress.baddressLine1,
        raddressLine2: businessAddress.baddressLine2,
        raddressLine3: businessAddress.baddressLine3,
        rcity: businessAddress.bcity,
        rstate: businessAddress.bstate,
        rzipcode: businessAddress.bzipcode,
        rcountry: businessAddress.bcountry,
      });
    }
  };

  return (
    <div className="w-full">
      {loading ? (
        <div className="spin-style flex items-center justify-center">
          <Skeleton active />
        </div>
      ) : (
        !isEmpty(generalDetails) && (
          <div>
            {/* <Card
              style={{
                display: 'grid',
                paddingBottom: '0px',
              }}
            >
              <Typography.Paragraph className="heading">
                Basic Details
              </Typography.Paragraph>
              <div className="mt-5">
                <div className="flex justify-start">
                  <div className="basis-[45%]">
                    <Typography.Paragraph className="text-[#858585]">
                      Name of the agency
                    </Typography.Paragraph>
                  </div>
                  <div className="basis-1/2 pb-0 ">
                    <Typography.Paragraph className="content">
                      {generalDetails.name}
                    </Typography.Paragraph>
                  </div>
                </div>
                <div className="flex justify-start">
                  <div className="basis-[45%]">
                    <Typography.Paragraph className="text-[#858585]">
                      NPN No.
                    </Typography.Paragraph>
                  </div>
                  <div className="basis-1/2 pb-0 ">
                    <Typography.Paragraph className="content">
                      {generalDetails.npn}
                    </Typography.Paragraph>
                  </div>
                </div>
                <div className="flex justify-start">
                  <div className="basis-[45%]">
                    <Typography.Paragraph className="text-[#858585]">
                      FEIN No.
                    </Typography.Paragraph>
                  </div>
                  <div className="basis-1/2 pb-0 ">
                    <Typography.Paragraph className="content">
                      {generalDetails.fein}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            </Card> */}

            <Card
              style={{
                marginTop: '10px',
                display: 'grid',
                rowGap: '240x',
                padding: '0px',
              }}
            >
              <div
                style={{
                  height: '110px',
                  backgroundColor: '#EDF9FF',
                  marginBottom: '14px',
                  borderRadius: '6px',
                  padding: '16px 20px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <AgencyLogo
                  agencyNameInitial={getInitials(generalDetails.name)}
                  imageUrl={generalDetails.logo}
                />

                <img
                  src={editLogo}
                  alt="editLogo"
                  style={{
                    position: 'absolute',
                    top: 82,
                    left: 80,
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!subscriptionStatusInactive) setModalVisibility(true);
                  }}
                />
                <div className="flex flex-col gap-1">
                  <span className="text-[black] font-medium text-lg">
                    {generalDetails.name}
                  </span>
                  <span className="text-[#1D2C50] font-normal text-xs">
                    NPN: {generalDetails.npn}
                  </span>
                  <span className="text-[#1D2C50] font-normal text-xs">
                    FEIN: {generalDetails.fein}
                  </span>
                </div>
              </div>
              <Row justify={'space-between'} style={{ alignItems: 'center' }}>
                <Typography.Paragraph
                  className="heading"
                  style={{ marginBottom: 0 }}
                >
                  Other Agency Information
                </Typography.Paragraph>
                <div>
                  {!addressInfoEdit && (
                    <Space>
                      {/* {false && (
                        <Button
                          id={
                            IdConstants.AGENCY.GENERAL.MANAGE_AGENT_ADDRESS +
                            '-agency-general'
                          }
                          size="middle"
                          onClick={() => {
                            navigate('/agents/general/update-address');
                          }}
                        >
                          Manage Producer Addresses
                        </Button>
                      )} */}

                      <Tooltip title="Edit Information">
                        <Button
                          id={
                            IdConstants.AGENCY.GENERAL.EDIT_INFO_BUTTON +
                            '-agency-general'
                          }
                          className="button-secondary-color-important grid place-content-center disable-rts"
                          size="middle"
                          onClick={() => {
                            form.resetFields();
                            setAddressInfoEdit(!addressInfoEdit);
                          }}
                          disabled={subscriptionStatusInactive}
                        >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                    </Space>
                  )}
                  {
                    <UploadProfileModal
                      setModalVisibility={setModalVisibility}
                      modalVisibility={modalVisibility}
                      agencyName={generalDetails.name}
                      agencyLogo={generalDetails.logo}
                      setFormData={setFormData}
                      handleSave={handleImageUpload}
                    />
                  }
                  {addressInfoEdit && (
                    <Space>
                      <Button
                        id={
                          IdConstants.AGENCY.GENERAL.AGENCY_INFORMATION
                            .SAVE_BUTTON + '-agency-general'
                        }
                        size="middle"
                        className="button"
                        onClick={() => {
                          setCheckAppliedMailing(false);
                          setCheckAppliedResidence(false);
                          form.submit();
                        }}
                        style={{
                          borderColor: 'var(--border-color)',
                          color: 'var(--secondary-color)',
                          width: '100px',
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        id={
                          IdConstants.AGENCY.GENERAL.AGENCY_INFORMATION
                            .DISCARD_BUTTON + '-agency-general'
                        }
                        size="middle"
                        style={{
                          width: '100px',
                        }}
                        onClick={() => {
                          setCheckAppliedMailing(false);
                          setCheckAppliedResidence(false);
                          setAddressInfoEdit(!addressInfoEdit);
                        }}
                      >
                        Discard
                      </Button>
                    </Space>
                  )}
                </div>
              </Row>
              <Form
                layout="vertical"
                form={form}
                initialValues={generalDetails}
                onFieldsChange={(changedValues, allValues) => {
                  // if the checkbox is checked for mailing perform this
                  if (checkAppliedMailing) {
                    const updatedMailingFields: { [key: string]: any } = {};
                    allValues.forEach((field) => {
                      const fieldName = field.name as string; // Type assertion
                      // if bstate is for business then mstate is for mailing
                      if (fieldName[0].startsWith('b')) {
                        const mailingKey = fieldName[0].replace(
                          'b',
                          'm'
                        ) as keyof typeof updatedMailingFields; // Type assertion
                        const mailingField = allValues.find(
                          (v) => v.name === mailingKey
                        );
                        //if its values are not matching then replace business values to mail
                        if (
                          field.value !== mailingField?.value ||
                          mailingField?.value === undefined
                        ) {
                          updatedMailingFields[mailingKey] = field.value;
                        }
                      }
                    });
                    form.setFieldsValue(updatedMailingFields);
                  }

                  if (checkAppliedResidence) {
                    const updatedResidenceFields: { [key: string]: any } = {};
                    allValues.forEach((field) => {
                      const fieldName = field.name as string;
                      if (fieldName[0].startsWith('b')) {
                        // if bstate is for business then rstate is for residence
                        const residenceKey = fieldName[0].replace(
                          'b',
                          'r'
                        ) as keyof typeof updatedResidenceFields;
                        const residenceField = allValues.find(
                          (v) => v.name === residenceKey
                        );
                        if (
                          field.value !== residenceField?.value ||
                          residenceField?.value === undefined
                        ) {
                          updatedResidenceFields[residenceKey] = field.value;
                        }
                      }
                    });
                    form.setFieldsValue(updatedResidenceFields);
                  }
                }}
                onFinish={updateGeneralInformation}
                style={{ marginTop: '20px' }}
              >
                <div>
                  {addressInfoEdit && (
                    <AddressInput
                      addressDetails={{
                        addressTypeCode: '2',
                        addressType: 'Business',
                        addressLine1: generalDetails?.address[2]?.addressLine1,
                        addressLine2: generalDetails?.address[2]?.addressLine2,
                        addressLine3: generalDetails?.address[2]?.addressLine3,
                        city: generalDetails?.address[2]?.city,
                        stateCode: generalDetails?.address[2]?.stateCode,
                        zip: generalDetails?.address[2]?.zip,
                        country: 'U.S.A',
                      }}
                      addressType="business"
                      style={{ width: '100%', display: 'block' }}
                      form={form}
                    />
                  )}
                </div>
                <div>
                  {addressInfoEdit && (
                    <AddressInput
                      viewOnly={checkAppliedMailing}
                      addressDetails={{
                        addressTypeCode: '3',
                        addressType: 'Mailing',
                        addressLine1: generalDetails?.address[3]?.addressLine1,
                        addressLine2: generalDetails?.address[3]?.addressLine2,
                        addressLine3: generalDetails?.address[3]?.addressLine3,
                        city: generalDetails?.address[3]?.city,
                        stateCode: generalDetails?.address[3]?.stateCode,
                        zip: generalDetails?.address[3]?.zip,
                        country: 'U.S.A',
                      }}
                      addressType="mailing"
                      style={{ width: '100%', display: 'block' }}
                      form={form}
                      checkboxState={checkAppliedMailing}
                      handleCheckBoxChange={handleMailingCheckboxChange}
                    />
                  )}
                </div>
                <div>
                  {addressInfoEdit && (
                    <AddressInput
                      viewOnly={checkAppliedResidence}
                      addressDetails={{
                        addressTypeCode: '1',
                        addressType: 'Residence',
                        addressLine1: generalDetails?.address[1]?.addressLine1,
                        addressLine2: generalDetails?.address[1]?.addressLine2,
                        addressLine3: generalDetails?.address[1]?.addressLine3,
                        city: generalDetails?.address[1]?.city,
                        stateCode: generalDetails?.address[1]?.stateCode,
                        zip: generalDetails?.address[1]?.zip,
                        country: 'U.S.A',
                      }}
                      addressType="residence"
                      style={{ width: '100%', display: 'block' }}
                      form={form}
                      checkboxState={checkAppliedResidence}
                      handleCheckBoxChange={handleResidenceCheckboxChange}
                    />
                  )}
                </div>
                <div className="mt-5 w-full">
                  {!addressInfoEdit && (
                    <div>
                      <div className="flex justify-start w-full pb-5">
                        {!addressInfoEdit && (
                          <div className="basis-[45%]">
                            <Typography.Paragraph className="text-[#858585]">
                              Business Address
                            </Typography.Paragraph>
                          </div>
                        )}
                        <div className="basis-1/2 pb-0 w-full">
                          {!addressInfoEdit &&
                          generalDetails.address &&
                          generalDetails.address[2] ? (
                            <Typography.Paragraph className="content-two">
                              {generalDetails?.address[2]?.addressLine1
                                ?.length > 0 && (
                                <div>
                                  {generalDetails?.address[2]?.addressLine1}
                                </div>
                              )}
                              {generalDetails?.address[2]?.addressLine2
                                ?.length > 0 && (
                                <div>
                                  {generalDetails?.address[2]?.addressLine2}
                                </div>
                              )}
                              {generalDetails?.address[2]?.addressLine3
                                ?.length > 1 && (
                                <div>
                                  {generalDetails?.address[2]?.addressLine3}
                                </div>
                              )}
                              <div className="flex">
                                {generalDetails?.address[2]?.city?.length >
                                  1 && (
                                  <div>{generalDetails?.address[2]?.city}</div>
                                )}
                                {generalDetails?.address[2]?.stateCode?.length >
                                  1 && (
                                  <div>
                                    &nbsp;
                                    {
                                      StateConstants[
                                        generalDetails?.address[2]?.stateCode
                                      ]
                                    }
                                  </div>
                                )}
                              </div>
                              {generalDetails?.address[2]?.country?.length >
                                1 && (
                                <div>{generalDetails?.address[2]?.country}</div>
                              )}
                              {generalDetails?.address[2]?.zip?.length > 1 && (
                                <div>{generalDetails?.address[2]?.zip}</div>
                              )}
                            </Typography.Paragraph>
                          ) : addressInfoEdit ? (
                            ''
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div
                        className={twMerge(
                          'flex justify-start',
                          addressInfoEdit ? 'pb-5' : ''
                        )}
                      >
                        {!addressInfoEdit && (
                          <div className="basis-[45%]">
                            <Typography.Paragraph className="text-[#858585]">
                              Mailing Address
                            </Typography.Paragraph>
                          </div>
                        )}
                        <div className="basis-1/2 pb-0 ">
                          {!addressInfoEdit &&
                          generalDetails.address &&
                          generalDetails.address[3] ? (
                            <Typography.Paragraph className="content-two">
                              {generalDetails.address[3]?.addressLine1?.length >
                                0 && (
                                <div>
                                  {generalDetails?.address[3]?.addressLine1}
                                </div>
                              )}
                              {generalDetails?.address[3]?.addressLine2
                                ?.length > 0 && (
                                <div>
                                  {generalDetails?.address[3]?.addressLine2}
                                </div>
                              )}
                              {generalDetails?.address[3]?.addressLine3
                                ?.length > 1 && (
                                <div>
                                  {generalDetails?.address[3]?.addressLine3}
                                </div>
                              )}
                              <div className="flex">
                                {generalDetails?.address[3]?.city?.length >
                                  1 && (
                                  <div>{generalDetails?.address[3]?.city}</div>
                                )}
                                {generalDetails?.address[3]?.stateCode?.length >
                                  1 && (
                                  <div>
                                    &nbsp;
                                    {
                                      StateConstants[
                                        generalDetails?.address[3]?.stateCode
                                      ]
                                    }
                                  </div>
                                )}
                              </div>
                              {generalDetails?.address[3]?.country?.length >
                                1 && (
                                <div>{generalDetails?.address[3]?.country}</div>
                              )}
                              {generalDetails?.address[3]?.zip?.length > 1 && (
                                <div>{generalDetails?.address[3]?.zip}</div>
                              )}
                            </Typography.Paragraph>
                          ) : addressInfoEdit ? (
                            ''
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div
                        className="flex justify-start"
                        style={{
                          paddingBottom: addressInfoEdit ? '20px' : '',
                        }}
                      >
                        {!addressInfoEdit && (
                          <div className="basis-[45%]">
                            <Typography.Paragraph className="text-[#858585]">
                              Resident Address
                            </Typography.Paragraph>
                          </div>
                        )}
                        <div className="basis-1/2 pb-0 ">
                          {!addressInfoEdit &&
                          generalDetails.address &&
                          generalDetails.address[1] ? (
                            <Typography.Paragraph className="content-two">
                              {generalDetails?.address[1]?.addressLine1
                                ?.length > 0 && (
                                <div>
                                  {generalDetails?.address[1]?.addressLine1}
                                </div>
                              )}
                              {generalDetails?.address[1]?.addressLine2
                                ?.length > 0 && (
                                <div>
                                  {generalDetails?.address[1]?.addressLine2}
                                </div>
                              )}
                              {generalDetails?.address[1]?.addressLine3
                                ?.length > 1 && (
                                <div>
                                  {generalDetails?.address[1]?.addressLine3}
                                </div>
                              )}
                              <div className="flex">
                                {generalDetails?.address[1]?.city?.length >
                                  1 && (
                                  <div>{generalDetails?.address[1]?.city}</div>
                                )}
                                {generalDetails?.address[1]?.stateCode?.length >
                                  1 && (
                                  <div>
                                    &nbsp;
                                    {
                                      StateConstants[
                                        generalDetails?.address[1]?.stateCode
                                      ]
                                    }
                                  </div>
                                )}
                              </div>
                              {generalDetails?.address[1]?.country?.length >
                                1 && (
                                <div>{generalDetails?.address[1]?.country}</div>
                              )}
                              {generalDetails?.address[1]?.zip?.length > 1 && (
                                <div>{generalDetails?.address[1]?.zip}</div>
                              )}
                            </Typography.Paragraph>
                          ) : addressInfoEdit ? (
                            ''
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-start">
                    <div className="basis-[45%]">
                      <Typography.Paragraph className="text-[#858585]">
                        Email Address
                      </Typography.Paragraph>
                    </div>
                    <div className="basis-1/2 pb-0 ">
                      {!addressInfoEdit ? (
                        <Typography.Paragraph className="content-two">
                          {generalDetails.email ? generalDetails.email : '-'}
                        </Typography.Paragraph>
                      ) : (
                        <Form.Item
                          name="email"
                          rules={
                            generalDetails.email === '' ||
                            generalDetails.email === undefined ||
                            generalDetails.email === null
                              ? [
                                  { required: true },
                                  {
                                    type: 'email',
                                    message:
                                      'Please enter a valid email address',
                                  },
                                ]
                              : []
                          }
                          initialValue={generalDetails.email}
                        >
                          <Input
                            id={
                              IdConstants.AGENCY.GENERAL.EMAIL_INPUT +
                              '-agency-General'
                            }
                            onInput={(e: any) =>
                              (e.target.value = e.target.value.toLowerCase())
                            }
                            disabled={
                              generalDetails.email === '' ||
                              generalDetails.email === undefined ||
                              generalDetails.email === null
                                ? false
                                : true
                            }
                            style={{ width: '680px' }}
                            placeholder="Enter your email address"
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-start">
                    <div className="basis-[45%]">
                      <Typography.Paragraph className="text-[#858585]">
                        Phone No.
                      </Typography.Paragraph>
                    </div>
                    <div className="basis-1/2 pb-0 ">
                      {!addressInfoEdit ? (
                        <Typography.Paragraph className="content-two">
                          {generalDetails.businessPhone
                            ? generalDetails.businessPhone
                            : '-'}
                        </Typography.Paragraph>
                      ) : (
                        <Form.Item
                          name="phone"
                          rules={
                            generalDetails.businessPhone === '' ||
                            generalDetails.businessPhone === undefined ||
                            generalDetails.businessPhone === null
                              ? [
                                  () => ({
                                    validator(_, value) {
                                      const numericValue = value?.replace(
                                        /\D/g,
                                        ''
                                      );
                                      const formattedValue =
                                        formatPhoneAndFax(numericValue);
                                      if (
                                        !value ||
                                        isPhoneNumberValid(formattedValue)
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        'Please enter a valid phone number'
                                      );
                                    },
                                  }),
                                ]
                              : []
                          }
                          initialValue={generalDetails.businessPhone}
                        >
                          <Input
                            id={
                              IdConstants.AGENCY.GENERAL.PHONE_NUMBER +
                              '-agency-General'
                            }
                            disabled
                            style={{ width: '680px' }}
                            placeholder="Enter your phone number"
                            onChange={(e) => {
                              const formattedValue = formatPhoneAndFax(
                                e.target.value
                              );
                              if (
                                !e.target.value ||
                                isPhoneNumberValid(formattedValue)
                              ) {
                                form.setFieldsValue({ phone: formattedValue });
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData('text/plain');
                              if (!/^[0-9]+$/.test(pastedText)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-start w-full">
                    <div className="basis-[45%]">
                      <Typography.Paragraph className="text-[#858585]">
                        FAX
                      </Typography.Paragraph>
                    </div>
                    <div className="basis-1/2 pb-0  w-[800px]">
                      {!addressInfoEdit ? (
                        <Typography.Paragraph className="content-two">
                          {generalDetails.fax ? generalDetails.fax : '-'}
                        </Typography.Paragraph>
                      ) : (
                        <Form.Item
                          name="fax"
                          rules={
                            generalDetails.fax === '' ||
                            generalDetails.fax === undefined ||
                            generalDetails.fax === null
                              ? [
                                  () => ({
                                    validator(_, value) {
                                      if (!value) return Promise.resolve();

                                      const numericValue = value.replace(
                                        /\D/g,
                                        ''
                                      );
                                      const formattedValue =
                                        formatPhoneAndFax(numericValue);
                                      if (isPhoneNumberValid(formattedValue)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        'Please enter a valid fax'
                                      );
                                    },
                                  }),
                                ]
                              : []
                          }
                          initialValue={generalDetails.fax}
                        >
                          <Input
                            id={
                              IdConstants.AGENCY.GENERAL.FAX + '-agency-General'
                            }
                            disabled={
                              !(
                                generalDetails.fax === '' ||
                                generalDetails.fax === undefined ||
                                generalDetails.fax === null
                              )
                                ? true
                                : false
                            }
                            style={{ width: '680px' }}
                            placeholder="Enter your FAX number"
                            onChange={(e) => {
                              const formattedValue = formatPhoneAndFax(
                                e.target.value
                              );
                              if (
                                !e.target.value ||
                                isPhoneNumberValid(formattedValue)
                              ) {
                                form.setFieldsValue({ fax: formattedValue });
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData('text/plain');
                              if (!/[0-9]/.test(pastedText)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
            <UpdateEmailModal
              visible={updateEmailAddressModal}
              setVisible={setUpdateEmailAddressModal}
              currentEmail={user?.email ? user?.email : ''}
            />
            <DeleteAgencyModal
              visible={deleteAgencyModal}
              setVisible={setDeleteAgencyModal}
              agencyName={generalDetails.name}
            />
          </div>
        )
      )}
    </div>
  );
};

export default withRoles(GeneralProfile, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
