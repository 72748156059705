import { ListBuilderLevel, ListBuilderType } from './listBuilderUtils';
import { Pagination, Row, Table } from 'antd';

import { Address } from '../../../../types/data/address.type';
import { Name } from '../../../../types/data/name.type';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { applySort } from '../../../../utils/common.utils';
import { getNameString } from '../../../../utils/name.utils';
import { isEmpty } from 'lodash';

export interface ListType {
  type: 'agency' | 'producer';
  _id: string;
  level: number;
  name: string | Name;
  npn: number;
  businessEmail: string;
  businessPhone: string;
  residentStateCode: string;
  residentLicenseNumber: string;
  upline: string;
  residentAddress: Address;
}

export interface SortType {
  field: keyof ListType;
  order: string;
}

interface ListBuilderProps {
  listDetails: ListType[];
  isLoading: boolean;
  setSortValue: React.Dispatch<React.SetStateAction<object>>;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  isDisabled: boolean;
  onPageChange: (pageNo: number, pageSize: number) => void;
  cb: (
    search?: undefined,
    page?: undefined,
    size?: undefined,
    sort?: object
  ) => void;
}

const ListBuilder: React.FC<ListBuilderProps> = ({
  listDetails,
  isLoading,
  setSortValue,
  onPageChange,
  pageNumber,
  pageSize,
  totalCount,
  isDisabled,
  cb,
}) => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const Columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => {
        return (
          <div>
            <ListBuilderType
              styles={{ textTransform: 'capitalize' }}
              isProducer={text === 'producer'}
            >
              {text}
            </ListBuilderType>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string | Name) => {
        return (
          <div>{typeof text === 'string' ? text : getNameString(text)}</div>
        );
      },
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      sorter: subscriptionStatusInactive ? false : true,
    },
    {
      title: 'Email',
      dataIndex: 'businessEmail',
      key: 'businessEmail',
      width: 280,
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => text || '-',
    },
    {
      title: 'Phone Number',
      dataIndex: 'businessPhone',
      key: 'businessPhone',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => text || '-',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => {
        return (
          <div>
            <ListBuilderLevel>{`Level ${text}`}</ListBuilderLevel>
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-layout-v2 relative width-100">
      <Table
        columns={Columns}
        dataSource={listDetails}
        scroll={{
          y: 'calc(100vh - 270px)',
        }}
        loading={isLoading}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, cb);
          setSortValue(sort);
        }}
      />
      {!isEmpty(listDetails) && (
        <Row className="pagination" style={{ marginTop: '10px' }} justify="end">
          <Pagination
            defaultCurrent={1}
            current={pageNumber}
            total={totalCount}
            pageSize={pageSize}
            onChange={onPageChange}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            disabled={isDisabled}
          />
        </Row>
      )}
    </div>
  );
};

export default ListBuilder;
