import './customTab.css';

import React from 'react';

interface Tab {
  onSelect: (value: string) => void;
  tabList: string[];
  selectedTab: string;
  styles?: object;
  className?: string;
}

const CustomTab: React.FC<Tab> = ({
  onSelect,
  tabList,
  selectedTab,
  styles = {},
  className,
}) => {
  return (
    <div className={`custom-tabs ${className ? className : ''}`}>
      {Object.values(tabList).map((data, index) => {
        return (
          <div
            key={`${data}-${index}`}
            onClick={() => onSelect(data)}
            id={`agency-all-licenses-tab-${data}`}
            className={`tab ${
              index === 0 ? 'border-l-radius' : 'border-r-radius'
            } ${data === selectedTab ? 'selected-tab-active' : ''}`}
            style={styles}
          >
            {data}
          </div>
        );
      })}
    </div>
  );
};

export default CustomTab;
