export class IdConstants {
  public static readonly LOGOUT = {
    AGENCY: 'agency-portal-logout-button',
    AGENT: 'agent-portal-logout-button',
  };

  public static readonly SIGNUPFLOW = {
    LOGOUT_BUTTON: 'signup_flow-logout-button',
  };

  public static readonly SEARCH_INPUT = 'search-input';

  public static readonly SEARCH_BUTTON = 'search-button';

  public static readonly SWITCH_BUTTON = 'switch-button';

  public static readonly RADIO_BUTTON = 'radio-button';

  public static readonly DOWNLOAD_INVOICE = 'download-invoice-button';

  public static readonly FILTERS_BUTTON = 'filters-button';

  public static readonly AMOUNT_FIELD = 'amount-input-field-wrapper';
  public static readonly ANT_CARD_BODY = 'ant-card-body';
  public static readonly ANT_TABLE_TBODY = 'ant-table-tbody';
  public static readonly ANT_TABLE_ROW = 'ant-table-row';
  public static readonly ANT_TABLE_PLACEHOLDER = 'ant-table-placeholder';
  public static readonly SIDER_MENU_CONTAINER = 'sider-menu-container';

  public static readonly INVITE_ADMIN = {
    CANCEL_BUTTON: 'admin-modal-cancel-button',
    SEND_INVITE: 'admin-modal-send-invite',
  };

  public static readonly RTS = {
    BY_AGENCY: {
      CSV_DOWNLOAD: 'agency-rts-csv-download',
      STATE_RADIO: 'rts-agency-state',
      CARRIER_RADIO: 'rts-agency-carrier',
    },
    BY_PRODUCER: {
      VIEW_BUTTON: 'view-rts-button',
      ASSIGNMENTS_CARD: 'assignments-card-body',
      APPOINTMENTS_CARD: 'appointments-card-body',
    },
  };

  public static readonly INDIVIDUAL_ONBOARDING = {
    HEADING: 'individual-onboard-heading',
    PRODUCER_DETAILS: {
      ENTER_DETAIL: 'individual-onboarding-enter-detail-dropdown',
      ACCEPT_BUTTON: 'onboarding-agent-accept-button',
      CANCEL_BUTTON: 'onboarding-agent-cancel-button',
      FETCH_BUTTON: 'get-agent-details',
    },
    ASSIGNMENTS: {
      RADIO_GROUP: 'assignments-radio-group-individual-onboard',
    },
    PAYMENTS: {
      INITIAL_PAYMENT_CONTAINER: 'initial-payment-account-container',
      RENEWAL_PAYMENT_CONTAINER: 'renewal-payment-account-container',
    },
  };

  public static AGENT_INFO = {
    EDIT_BIO_INFO: 'agent-overview-edit-biographic-info-button',
    MANAGE_ASSIGNMENT: 'agent-overview-manage-assignments-button',
    SAVE_BUTTON: 'agent-overview-save-button',
    EDIT_INFO: 'agent-overview-edit-info',
    REFRESH_NIPR_DETAILS: 'agent-overview-refresh-nipr-details',
    CANCEL_BUTTON: 'agent-overview-cancel-button',
    EDIT_INFO_APPLY: 'agent-overview-edit-info-apply',
    EDIT_INFO_CANCEL: 'agent-overview-edit-info-cancel',
  };

  public static readonly HIERARCHY_VIEW = {
    TOTAL_AGENTS: 'hierarchy-view-total-agents-button',
    TOTAL_DOWNLINES: 'hierarchy-view-total-downlines-button',
  };

  public static readonly AUTO_RENEWAL_MODAL = {
    SAVE_BUTTON: 'save-button-auto-renewal-modal',
    CANCEL_BUTTON: 'cancel-button-auto-renewal-modal',
    SELECT_DROPDOWN: 'select-dropdown-auto-renewal-modal',
  };

  public static readonly INITIAL_PAYMENT_MODAL = {
    SAVE_BUTTON: 'save-button-initial-payment-modal',
    CANCEL_BUTTON: 'cancel-button-initial-payment-modal',
  };

  public static readonly RENEWAL_PAYMENT_MODAL = {
    SAVE_BUTTON: 'save-button-renewal-payment-modal',
    CANCEL_BUTTON: 'cancel-button-renewal-payment-modal',
  };

  public static readonly PAYMENT_TYPE_MODAL = {
    SAVE_BUTTON: 'save-button-payment-type-modal',
    CANCEL_BUTTON: 'cancel-button-payment-type-modal',
    FULL_PAYMENT: 'full-payment-checkbox-payment-type-modal',
    CAPPED_PAYMENT: 'capped-payment-checkbox-payment-type-modal',
  };

  public static readonly AGENTS = {
    AGENT_OVERVIEW: {
      VIEW_AGENTS: 'agent-overview-view-agents-button',
      TABLE: {
        VIEW_LICENSES: 'agent-overview-table-view-licenses-button',
        LOA: 'line-only-agents',
        GA: 'general-agents',
        OLAO: 'other-line-only-agents',
        INITIAL_PAYMENT: 'initial-payment-',
        RENEWAL_PAYMENT: 'renewal-payament-',
        PAYMENT_TYPE: 'payment-type-',
        AUTO_RENEWAL: 'auto-renewal-',
      },
    },
    ASSIGNMENTS: {
      TABLE: {
        EDIT_ASSIGNMENT_BUTTON: 'assignments-table-edit-assignment-button',
        MANAGE_ASSIGNMENTS_BUTTON: 'assignments-table-assign-button',
      },
      ASSIGN_STATE_GROUP_MODAL: {
        CANCEL_BUTTON: 'assign-state-modal-cancel-button',
        ASSIGN_BUTTON: 'assign-state-modal-assign-button',
      },
    },
    OFFBOARD: {
      INDIVIDUAL_OFFBOARDING: {
        BUTTON: 'offboard-agents-individual-offboarding-button',
      },
      OFFBOARD_CONFIRMATION_MODAL: {
        CONFIRM_BUTTON: 'offboard-confirmation-modal-confirm-button',
      },
      CSV_OFFBOARDING: {
        SSN: 'csv-offboarding-ssn',
        NPN: 'csv-offboarding-npn',
        LICENSE: 'csv-offboarding-licenses',
        OPEN_LINK: 'csv-offboarding-open-link-button',
        DOWNLOAD: 'csv-offboarding-download-button',
        START_OFFBOARDING: 'csv-offboarding-start-offboarding-button',
        PREVIOUS: 'csv-offboarding-previous-button',
        NEXT: 'csv-offboarding-next-button',
      },
    },
    INDIVIDUAL_ONBOARDING: {
      ASSIGN: 'individual-onboarding-assign-button',
      ASSIGN_NOW: 'individual-onboarding-assign-now-button',
      SKIP_BUTTON: 'individual-onboarding-skip-button',
      NEXT_BUTTON: 'individual-onboarding-next-button',
      FINISH_BUTTON: 'individual-onboarding-finish-button',
      PREVIOUS_BUTTON: 'individual-onboarding-previous-button',
      SOCIAL_SECURITY_INPUT:
        'individual-onboarding-social-security-number-input',
      LAST_NAME_INPUT: 'individual-onboarding-last-name-input',
      LICENSES_INPUT: 'individual-onboarding-licenses-number-input',
      NPN_INPUT: 'individual-onboarding-npn-number-input',
      INIT_PAYMENT: 'individual-onboarding-select-initial-payment-button',
      RENEW_PAYMENT: 'individual-onboarding-select-renew-payment-button',
      PAYMENT_TYPE: 'individual-onboarding-select-payment-type-button',
    },
    INVITE_DOWNLINE: {
      DROP_DOWN: 'invite-downline-drop-down',
      NAME_INPUT: 'invite-downline-name-input',
      NPN_NUMBER: 'invite-downline-npn-number-input',
      EMAIL_ADDRESS: 'invite-downline-email-address-input',
      SOCIAL_SECURITY_NUMBER: 'invite-downline-social-security-number-input',
      LAST_NAME_INPUT: 'invite-downline-last-name-input',
      LICENSES_NUMBER_INPUT: 'invite-downline-licenses-number-input',
      STATE_SELECT_DROP_DOWN: 'invite-downline-state-select-drop-down',
      VERIFY_DOWNLINE_BUTTON: 'invite-downline-verify-downline-button',
      CREDIT_AMOUNT: 'invite-downline-credit-amount-input',
      CANCEL_INVITE: 'invite-downline-cancel-invite-button',
      SEND_INVITE: 'invite-downline-send-invite-button',
      CHECK_BOX: 'invite-downline-check-box',
      VIEW_INVITATIONS: 'view-invitations-table',
    },
    INVITE_DOWNLINE_MODAL: {
      CONFIRM_ALLOCATION: 'invite-downline-modal-confirm-allocation-button',
      GO_BACK: 'invite-downline-go-back-button',
    },
  };

  public static readonly WATCH_LIST_MODAL = {
    NPN_INPUT: 'input-npn-watchlistmodel',
    REASON_INPUT: 'input-reason-watchlistmodal',
    CHECK_BOX: 'check-box-watchlistmodal',
    CANCEL_BUTTON: 'cancel-button-watchlistmodal',
    ADD_BUTTON: 'add-to-watchlist-button-watchlistmodal',
  };

  public static readonly LICENSES = {
    TABLE: {
      ASSOCIATED_AGENT: 'all-licenses-table-associated-agent-button',
      RENEWAL_PAYMENT: 'all-lincenses-table-renewal-payment-button',
    },
    ACTIONABLE_LICENSES: {
      APPLY_ALL_LICENSES: 'actionable-licenses-apply-all-licenses',
      TABLE: {
        ASSOCIATED_AGENT: 'actionable-licenses-table-associated-agent-button',
        RENEWAL_PAYMENT: 'actionable-lincenses-table-renewal-payment-button',
        APPLY: 'actionable-lincenses-table-apply-button',
        INITIAL_PAYMENT: 'actionable-lincenses-table-initial-payment-button',
      },
    },
    APPLICATIONS: {
      TABLE: {
        VIEW_DETAILS: 'licenses-applications-table-view-details-button',
      },
    },
  };

  public static readonly APPLY_LICENSES_MODAL = {
    CANCEL: 'apply-licenses-modal-cancel-button',
    APPLY: 'apply-licenses-modal-apply-button',
  };

  public static readonly STATE_GROUPS = {
    CREATE_STATE_GROUPS_BUTTON: 'create-state-groups-button',
    EDIT_STATE_GROUPS_CLASS_NAME: 'edit-state-group-territory-name',
    STATE_GROUPS_TABLE_CLASS_NAME: 'state-groups-table',
    SELECTED_STATES_CARD: 'selected-states-card-body',
    DRAGGABLE_STATE_LABEL: 'draggable-state-card',
    DRAGGABLE_LC_CARD: 'draggable-lc-card',
    DRAGGABLE_LOA_CARD: 'draggable-loa-card',
    STATE_GROUPS_INPUT_NAME: 'state-group-input-name',
    SAVE_STATE_GROUP: 'save-state-group-button',
    SAVE_NEW_STATE_GROUP: 'save-as-new-state-group-button',
    RESET_BUTTON: 'reset-button',
    DELETE_STATE_GROUP: 'delete-state-group-button',
    STATE_GROUP_BACK: 'back-button',
    DROPPABLE_STATE_LABEL: 'droppable-state-card',
    DROPPABLE_LC_CARD: 'droppable-lc-card',
    DROPPABLE_LOA_CARD: 'droppable-loa-card',
    VIEW_STATE_GROUP: 'view-state-group-button',
    DISCARD_MODAL: {
      CANCEL: 'discard-modal-cancel-button',
      DISCARD_CHANGES: 'discard-modal-discard-changes-button',
    },
    DELETE_CONFIRMATION_MODAL: {
      DELETE_STATE_GROUP: 'delete-confirmation-modal-confirm-button',
      CANCEL: 'delete-confirmation-modal-cancel-button',
      DELETE_ASSIGNMENT:
        'delete-confirmation-modal-delete-assignment-radio-button',
      CONVERT_ASSIGNMENT:
        'delete-confirmation-modal-convert-assignment-radio-button',
    },
    SAVE_STATE_GROUP_MODAL: {
      CANCEL: 'save-state-group-modal-cancel-button',
      SAVE: 'save-state-group-modal-save-button',
    },
    SAVE_NEW_STATE_GROUP_MODAL: {
      NEW_NAME: 'save-new-state-group-modal-input',
      CANCEL: 'save-new-state-group-modal-cancel-button',
      SAVE: 'save-new-state-group-modal-save-button',
    },
  };

  public static readonly AGENCY = {
    SIGNUP: {
      FEIN_INPUT: 'fein-input',
      STATE_SELECT: 'resident-state-select',
      AGENCY_DETAILS: 'verify-agency-details',
      CREATE_AGENCY: 'create-agency-button',
    },
    GENERAL: {
      MANAGE_AGENT_ADDRESS: 'manage-agent-addresses',
      EDIT_INFO_BUTTON: 'edit-info-button',
      EDIT_INFORMATION: {
        BUTTON: 'edit-information-button',
        DISCARD: 'edit-information-discard-button',
        SAVE: 'edit-information-save-button',
        FIRST_NAME_INPUT: 'edit-information-first-name-input',
        LAST_NAME_INPUT: 'edit-information-last-name-input',
      },
      CHANGE_PASSWORD: 'change-password-button',
      AGENCY_INFORMATION: {
        SAVE_BUTTON: 'save-button-agency-information',
        DISCARD_BUTTON: 'discard-button-agency-information',
      },
      SAVE_BUTTON: 'save-button',
      EMAIL_INPUT: 'email-input-edit-information',
      PHONE_NUMBER: 'phone-no-input-edit-information',
      FAX: 'fax-input-edit-information',
    },
    DOWNLINES: {
      INVITE_DOWNLINE_BUTTON: 'invite-downline-button',
      VIEW_INVITATION_LINK: 'view-invitation-link',
      REMOVE_DOWNLINE_BUTTON: 'remove-downline-button',
      REMOVE_DOWNLINE_CONFIRMATION_MODAL: {
        CONFIRM_BUTTON: 'remove-downline-confirmation-modal-confirm-button',
        CANCEL_BUTTON: 'remove-downline-confirmation-modal-cancel-button',
      },
      TABLE: {
        ALLOCATION_HISTORY: 'downlines-table-allocation-history-button',
        ALLOCATE_AMOUNT: 'downlines-allocate-amount-button',
      },
    },
    ALL_LICENSES: {
      FILL_OWNERSHIP_DETAILS: 'fill-ownership-details',
      EXPAND_ALL: 'agency-all-licenses-expand-all',
    },
    DOWNLINES_ALLOCATION_MODAL: {
      CONFIRM_ALLOCATION: 'downline-allocation-modal-confirm-allocation-button',
      GO_BACK: 'downline-allocation-modal-go_back_button',
      ALLOCATE_AMOUNT_BUTTON: 'downline-allocation-modal-allocate-amount',
      CANCEL: 'downline-allocation-modal-cancel-button',
      AMOUNT_INPUT: 'downline-allocation-modal-input',
    },
    UPLINES: {
      MY_INVITES_BUTTON: 'my-invites-button',
      TABLE: {
        ALLOCATION_HISTORY: 'uplines-table-allocation-history-button',
        VIEW_UPLINES: 'uplines-table-view-uplines',
      },
    },
    WATCHLIST: {
      ADD_TO_WATCHLIST: 'add-to-watchlist-button',
    },
    USERS: {
      INVITE_USERS: 'invite-users-button',
      VIEW_INVITATION_LINK: 'invited-user-button',
      INVITE_USERS_MODAL: 'invite-users-modal-controls',
    },
  };

  public static readonly BILLING = {
    RECHARGE_BUTTON: 'recharge-wallet-button',
    CURRENT_BALANCE: 'current-balance-wallet',
    REFRESH_BALANCE: 'fetch-stripe-details-wallet',
    NEXT_BUTTON: 'wallet-amount-added-next-button',
    CANCEL_BUTTON: 'wallet-amount-added-cancel-button',
  };

  public static readonly EMPLOYMENT_HISTORY = {
    JOB_TITLE: 'employee-history-job-title-input',
    COMPANY_NAME: 'employee-history-company-name-input',
    EMPLOYMENT_TYPE: 'employee-history-type-drop-box',
    CITY: 'employee-history-city-input',
    STATE_CODE: 'employee-history-state-code-input',
    START_DATE: 'employee-history-start-date',
    END_DATE: 'employee-history-end-date',
    DESCRIPTION: 'employee-history-description-input',
    SAVE_BUTTON: 'employee-history-save-button',
    CHECK_BUTTON: 'employee-history-check-box',
    DELETE_BUTTON: 'employee-history-delete-button',
    CANCEL_BUTTON: 'employee-history-cancel-button',
    SAVE_EMPLOYMENT: 'employee-history-save-employment-button',
    ADD_ANOTHER: 'employee-history-add-another-button',
  };

  public static readonly BACKGROUND_QUESTIONS = {
    SAVE_BUTTON: 'background-questions-save-button',
    CANCEL_BUTTON: 'background-questions-cancel-button',
    UPLOAD_BUTTON: 'background-questions-upload-button',
    EDIT_RESPONSE: 'background-questions-edit-response-button',
    INPUT_FIELD: 'background-questions-input-field',
    DROP_DOWN: 'background-questions-drop-down',
    DRAGGER: 'background-questions-dragger',
  };
}
