import { Card, Col, Progress, Row, Typography } from 'antd';

import DraggerComponent from '../../../../components/common/dragger/draggerComponent';
import FileParser from '../../../../utils/file-parser/fileParser';
import OnboardError from './importAgents/error';
import googleSheetsLogo from '../../../../assets/icons/googleSheetsLogo.svg';
import { multipleOnboardStore } from '../../../../stores/multipleOnboardStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { validateMultipleOnboardLookup } from '../../../../utils/onboard-agents/multipleOnboard/validate-look-up-csv';

interface ImportAgentsProps {
  fileLink: string;
  onComplete: () => void;
  onUploadFailure: () => void;
  setUploadedFile: React.Dispatch<React.SetStateAction<any>>;
}
function ImportAgents({
  fileLink,
  onComplete,
  onUploadFailure,
  setUploadedFile,
}: ImportAgentsProps) {
  const loadedData = multipleOnboardStore.loadUploadedContent();

  const handleFileChange = (uploadedFile: any, uploadedFileContent: any) => {
    const { status, name, percent } = uploadedFile.file;
    if (status === 'uploading') {
      multipleOnboardStore.setUploadProgress(percent);
      multipleOnboardStore.setUploadStatus(status);
    } else if (status === 'done') {
      multipleOnboardStore.setIsFileUploaded(true);
      multipleOnboardStore.setUploadFileName(name);
      multipleOnboardStore.setUploadedFile(uploadedFile.file);
      multipleOnboardStore.setFileContent(uploadedFileContent);
      multipleOnboardStore.setUploadProgress(100);
      const fileParser = new FileParser();
      const multipleOnboardJsonData =
        fileParser.parseCsvToJson(uploadedFileContent);

      const updatedUploadedContent = multipleOnboardStore.loadUploadedContent();
      if (updatedUploadedContent && updatedUploadedContent.length > 0) {
        try {
          validateMultipleOnboardLookup(
            multipleOnboardStore.lookupMethod,
            updatedUploadedContent[0]
          );
          multipleOnboardStore.setUploadStatus('done');
          onComplete();
        } catch (error: any) {
          multipleOnboardStore.setUploadStatus('done');
          multipleOnboardStore.setIsFileUploaded(false);
          multipleOnboardStore.setUploadProgress(40);
          multipleOnboardStore.setErrorMessage(
            error.message.split(',').map((error: any) => error.trim())
          );
          onUploadFailure();
        }
      }
    }
  };

  useEffect(() => {
    multipleOnboardStore.loadUploadedFile();
  }, [onComplete, loadedData]);

  return (
    <div style={{ width: '90%' }}>
      <DraggerComponent
        handleFileChange={handleFileChange}
        setIsUploadComplete={multipleOnboardStore.setIsFileUploaded}
        isFileUploaded={multipleOnboardStore.isFileUploaded}
        acceptedFileSize={35 * 1024 * 1024}
        acceptedFileTypes={[
          'text/csv',
          '.csv',
          '.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        uploadedFileName={multipleOnboardStore.uploadedFileName}
        setUploadedFile={setUploadedFile}
      />

      <Row style={{ justifyContent: 'space-between' }}>
        <Typography.Paragraph style={{ fontSize: '12px', color: '#ABABAB' }}>
          Supported Format: CSV
        </Typography.Paragraph>

        <Typography.Paragraph style={{ fontSize: '12px', color: '#ABABAB' }}>
          Maximum Size: 35MB
        </Typography.Paragraph>
      </Row>
      {multipleOnboardStore.uploadStatus === 'exception' && (
        <Card className="import-agents-inner-card">
          <Row justify="space-between" align="middle">
            <Col>
              <Row align="middle">
                <img src={googleSheetsLogo} alt="logo" />
                <Typography.Paragraph className="download-sublabel-bold">
                  {multipleOnboardStore.uploadedFileName || 'Table Example.csv'}
                </Typography.Paragraph>
              </Row>
            </Col>
            <Col>
              <Progress
                type="circle"
                percent={multipleOnboardStore.uploadProgress}
                size={75}
                status={'exception'}
              />
            </Col>
          </Row>
        </Card>
      )}
      {multipleOnboardStore.uploadErrorMessage && (
        <OnboardError
          templateLink={fileLink}
          errorMessage={multipleOnboardStore.uploadErrorMessage}
          onClose={() => multipleOnboardStore.setErrorMessage(null)}
        />
      )}
    </div>
  );
}

export default observer(ImportAgents);
