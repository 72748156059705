import {
  AutoComplete,
  Button,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import IndividualApplyButton, {
  Tab,
} from '../../agency/licenses/individualApplyButton';
import axios, { CancelTokenSource } from 'axios';
import {
  getApplicationStatus,
  updateLicenseAndApplicationStatus,
} from '../../../utils/application-status.utils';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { ActionableLicenseResponseError } from '../../../types/response/actionable-license.type';
import AgentAssignmentStatus from '../../../components/licenses/agentAssignmentStatus';
import { AgentService } from '../../../services/agent.service';
import { AllLicenseResponse } from '../../../types/response/all-license.type';
import { ApplicationTypeEnumInBackend } from '../../../enums/applicationType.enum';
import ApplyLicenseErrorModal from '../../../components/actionableLicenseModal/applyLicenseErrorModal';
import CardDetailsModal from '../../../components/cardDetailsModal';
import { ColumnsType } from 'antd/es/table';
import CopyTextAnimation from '../../../components/common/copyTextAnimation/CopyTextAnimation';
import DaysToExpireCell from '../../../components/licenses/daysToExpireCell';
import EngineLights from '../../../components/licenses/engineLights';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import LicenseModal from '../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../services/licenses.service';
import { LoaDetail } from '../../../types/data/loaDetail.type';
import { LoaStatus } from '../../../enums/loaStatus.enum';
import { ObjectSuccessResponse } from '../../../types/response/objectSuccessResponse.type';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { PortalType } from '../../../enums/portalType.enum';
import { ProducerLicenseType } from '../../../enums/agencyLicenseType.enum';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { StateConstants } from '../../../constants/state.constants';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { UserType } from '../../../enums/userType.enum';
import { activeLicenseFilter } from '../../../constants/active-license.constants';
import activeLoaIcon from '../../../assets/icons/success rectangle.svg';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { applySort } from '../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import error from '../../../assets/icons/error.svg';
import { getAssignmentStatusTooltip } from '../../../utils/licenses/getAssignmentStatusTooltip.utils';
import { getBackgroundColor } from '../../agents/manageAssignments/manageAssignment';
import { getProducerName } from '../../agents/agentsOverview/agencyAgentOverview';
import { isEmpty } from 'lodash';
import missingLoaIcon from '../../../assets/icons/reject rectangle.svg';
import { produce } from 'immer';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { signupStore } from '../../../stores/signupStore';
import success from '../../../assets/icons/success.svg';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

function AllLicenses() {
  const isIndividualProducer =
    signupStore.getUserType() === UserType.INDIVIDUAL_PRODUCER;
  const isProducer = signupStore.getUserType() === UserType.AGENT;
  const [loading, setIsLoading] = useState({
    all: false,
    needsAttention: false,
    renewal: false,
  });
  const [activeData, setActiveData] = useState<any>('');
  const { bearerToken, getAccessTokenSilently } = useAuth();
  const [count, setCount] = useState(10);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryText, setQueryText] = useQueryState('search');
  const [searchText, setSearchText] = useState('');
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchingStripeURL, setFetchingStripeURL] = useState(false);
  const [licenseCount, setLicenseCount] = useState({
    activeCount: 0,
    needsAttentionCount: 0,
    renewalsCount: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  // renewal
  const [renewalLicenses, setRenewalLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  //

  const [controlledFilters, setControlledFilters] = useState([]);
  // needs attention
  const [needsAttentionLicenses, setNeedsAttentionLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  const [selectedLicenseType, setSelectedLicenseType] =
    useState<ProducerLicenseType>(
      (searchParams.get('quickFilter') as ProducerLicenseType) ||
        ProducerLicenseType.ALL
    );
  // individual apply button
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>('');
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const [disableAssign, setDisableAssign] = useState(false);
  const [currentAssignLoadingId, setCurrentAssignLoadingId] = useState('');

  const showCustomError = (msg: { message: string; description?: string }) => {
    api['error']({
      message: msg.message,
      description: msg.description,
    });
  };

  const handleRowClick = (record: any) => {
    setSelectedLicenseDetails(record);
    setApplicationModalVisible(true);
  };

  const QUICK_FILTERS = [
    {
      type: ProducerLicenseType.ALL,
      label: `All`,
    },
    {
      type: ProducerLicenseType.ACTIVE,
      label: `Active (${licenseCount.activeCount})`,
    },
    {
      type: ProducerLicenseType.NEEDS_ATTENTION,
      label: `Needs Attention (${licenseCount.needsAttentionCount})`,
    },
    {
      type: ProducerLicenseType.RENEWALS,
      label: `Upcoming Renewals (${licenseCount.renewalsCount})`,
    },
  ];

  const fetchNeedsAttentionLicenses = async (
    search?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;
    try {
      setIsLoading((prev) => ({ ...prev, needsAttention: true }));
      if (bearerToken) {
        const type = ApplicationTypeEnumInBackend.missing;
        const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
          await LicensesService.getActionableLicensesForCurrentAgent(
            type,
            '',
            search || queryText || '',
            searchType ? searchType : '',
            bearerToken,
            size || pageSize,
            page || pageNumber,
            {
              ...requestBody,
              ...(sort || sortValue || {}),
            }
          );
        if (response) {
          setCount(response.totalCount);
          setNeedsAttentionLicenses(response.data);
        } else {
          setCount(0);
        }
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }
    } finally {
      if (!errorTypeRequestCancel)
        setIsLoading((prev) => ({ ...prev, needsAttention: false }));
    }
  };

  const createAssignmentForLicense = async (
    producerId: string,
    requestBody: any,
    id: string
  ) => {
    try {
      setDisableAssign(true);
      setCurrentAssignLoadingId(id);
      const token = await getAccessTokenSilently();
      const resp = await AgentService.createAssignmentForLicense(
        token,
        producerId,
        requestBody
      );
      if (resp) fetchLicenseDetails();
    } catch (e: any) {
      console.error('createAssignmentForLicense error :: ', e);
      message.error(e.error.message || 'Assignment failed for given license');
    } finally {
      setDisableAssign(false);
      setCurrentAssignLoadingId('');
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      width: 170,
      render: (text: string, record: AllLicenseResponse) => {
        const status =
          selectedLicenseType === ProducerLicenseType.RENEWALS
            ? record?.license?.status
            : record?.status;
        const applicationStatus = getApplicationStatus(
          record,
          status,
          selectedLicenseType === ProducerLicenseType.RENEWALS
        );
        const { updatedStatus, updatedApplicationStatus } =
          updateLicenseAndApplicationStatus(status, applicationStatus, record);
        return (
          <div style={{ marginLeft: 12 }}>
            <div
              className="text-base-style"
              style={{
                lineHeight: '20px',
                letterSpacing: '0.5%',
              }}
            >
              {StateConstants[text]}
            </div>
            <div
              style={{
                color: '#222222',
                borderRadius: 16,
                padding: '2px 8px',
                textTransform: 'capitalize',
                width: 'fit-content',
                fontSize: '7.32px',
                lineHeight: '8.78px',
                marginTop: 4,
                ...getBackgroundColor(updatedStatus!),
              }}
            >
              {updatedStatus}{' '}
              {updatedApplicationStatus && (
                <div>({updatedApplicationStatus})</div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: <div>License No</div>,
      dataIndex: 'licenseNumber',
      sorter: true,
      key: 'licenseNumber',
      width: 130,
      className: 'agency-license-state-row custom-header-cell',
      render: (text: string, record: AllLicenseResponse) => {
        return (
          <div>
            {text && !record?.isDuplicateLicenseNumber ? (
              <span className="flex gap-1 items-center">
                {text} <CopyTextAnimation text={text} />
              </span>
            ) : (
              <span className="ml-2">-</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      width: '330px',
      sorter: true,

      className: 'cell-with-lines agency-license-state-row center-column',
      render: (loas: any, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center !important',
              alignItems: 'flex-start',
              padding: '8px 0',
            }}
          >
            <Row style={{ width: '40%', alignContent: 'center' }}>
              <div
                style={{
                  gap: '8px',
                  width: '100%',
                  paddingRight: 12,
                  boxSizing: 'border-box',
                  flexGrow: 1,
                }}
              >
                <Tooltip
                  title={`${
                    record.licenseConfigurations.licenseClass
                  } (${record.licenseConfigurations.licenseClassCode})`}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#001F45',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      marginBottom: 0,
                    }}
                    className="margin-unset"
                    ellipsis
                  >
                    {`${
                      record.licenseConfigurations.licenseClass
                    } (${record.licenseConfigurations.licenseClassCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            </Row>

            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '43%',
                bottom: 0,
                width: '1px',
                backgroundColor: '#d9d9d9',
                height: '100%',
              }}
            />

            <div
              style={{
                width: '55%',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                alignSelf: 'center',
                marginLeft: '3%',
              }}
            >
              {record.licenseConfigurations.loaDetails.map(
                (loa: LoaDetail, index: number) => {
                  return (
                    <Space key={index}>
                      {loa.status?.toLocaleLowerCase() ===
                      LoaStatus.ACTIVE.toLocaleLowerCase() ? (
                        <img src={activeLoaIcon} alt="activeLoa" />
                      ) : (
                        <img src={missingLoaIcon} alt="nonActiveLoa" />
                      )}
                      <Typography.Paragraph
                        style={{
                          margin: 0,
                          textAlignLast: 'start',
                          color: '#222222',
                        }}
                        className="margin-unset"
                      >
                        {loa.loa} ({loa.loaCode})
                      </Typography.Paragraph>
                    </Space>
                  );
                }
              )}
            </div>
          </div>
        );
      },
    },
    ...([
      ProducerLicenseType.ALL,
      ProducerLicenseType.ACTIVE,
      ProducerLicenseType.RENEWALS,
    ].includes(selectedLicenseType as ProducerLicenseType)
      ? [
          {
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            sorter: true,
            width: 150,
            render: (text: string, record: any) => {
              return (
                <DaysToExpireCell
                  expiryDate={text}
                  licenseStatus={
                    [
                      ProducerLicenseType.ALL,
                      ProducerLicenseType.ACTIVE,
                    ].includes(selectedLicenseType)
                      ? record?.status
                      : (record?.license?.status as LicenseStatus)
                  }
                  licenseState={record.stateCode}
                />
              );
            },
          },
        ]
      : []),
    ...([
      ProducerLicenseType.NEEDS_ATTENTION,
      ProducerLicenseType.RENEWALS,
    ].includes(selectedLicenseType as ProducerLicenseType)
      ? [
          ...(selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION
            ? [
                {
                  title: 'Initial Payment',
                  dataIndex: 'paymentConfig',
                  key: 'producerDetails.paymentConfig.licenseInitialPayee',
                  sorter: false,
                  width: 90,
                  align: 'center' as any,
                  render: (_: any, record: any, index: number) => {
                    return (
                      <Row
                        id={
                          IdConstants.AGENTS.AGENT_OVERVIEW.TABLE
                            .INITIAL_PAYMENT +
                          record.id +
                          '-other-line-only-agents'
                        }
                        className="text-base-style"
                      >
                        {record.producerDetails.paymentConfig
                          ?.licenseInitialPayee ? (
                          getProducerName(
                            record.producerDetails.paymentConfig
                              ?.licenseInitialPayee
                          )
                        ) : (
                          <span style={{ color: '#92A69E', fontSize: '12px' }}>
                            Payee not selected
                          </span>
                        )}
                      </Row>
                    );
                  },
                },
              ]
            : []),
          {
            title: 'Renewal Payment',
            dataIndex: 'producerDetails',
            sorter: false,
            key: 'producerDetails.paymentConfig.licenseRenewalPayee',
            width: 90,
            align: 'center' as any,
            render: (producerDetails: any, record: any, index: number) => {
              return (
                <Row
                  id={
                    IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
                    record.id +
                    '-other-line-only-agents'
                  }
                  className="text-base-style"
                >
                  {record.producerDetails.paymentConfig?.licenseRenewalPayee ? (
                    getProducerName(
                      record.producerDetails.paymentConfig?.licenseRenewalPayee
                    )
                  ) : (
                    <span style={{ color: '#92A69E', fontSize: '12px' }}>
                      Payee not selected
                    </span>
                  )}
                </Row>
              );
            },
          },
          {
            title: 'Status Graph',
            width: 110,
            render: (record: any) => {
              const isPaymentConfiguredAsAgency =
                record?.producerDetails?.paymentConfig?.licenseInitialPayee ===
                  'agency' &&
                record?.producerDetails?.paymentConfig?.licenseRenewalPayee ===
                  'agency';
              return (
                <Col
                  style={{
                    rowGap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 12,
                  }}
                >
                  <EngineLights
                    isAgentPortal={true}
                    disable={isPaymentConfiguredAsAgency}
                    payer={
                      record.applicationType ===
                      ApplicationTypeEnumInBackend.creation
                        ? record?.producerDetails?.paymentConfig
                            ?.licenseInitialPayee
                        : record?.producerDetails?.paymentConfig
                            ?.licenseRenewalPayee
                    }
                    setPayerCallBack={() => {}}
                    record={record}
                  />
                </Col>
              );
            },
          },
          ...(isIndividualProducer || isProducer
            ? [
                {
                  width: 110,
                  render: (record: any) => {
                    return (
                      <IndividualApplyButton
                        key={record.id}
                        payer={
                          record.applicationType ===
                          ApplicationTypeEnumInBackend.creation
                            ? record.producerDetails?.paymentConfig
                                ?.licenseInitialPayee
                            : record.producerDetails?.paymentConfig
                                ?.licenseRenewalPayee
                        }
                        selectedLicensesLength={0}
                        record={record}
                        currentTab={Tab.MISSING_LICENSES}
                        setRefreshData={() => {}}
                        deleteAppliedLicense={deleteAppliedLicense}
                        setDisableApplyButton={setDisableApplyButton}
                        disableApplyButton={
                          disableApplyButton || !!record.isLoading
                        }
                        showError={showCustomError}
                        setApplyLicenseErrorModalVisibility={
                          setApplyLicenseErrorModalVisibility
                        }
                        setErrors={setErrors}
                        applyErrorCallback={refreshLicenseById}
                        isV2={true}
                        onDeclinedClick={() => {
                          handleRowClick(record);
                        }}
                        isNeedsAttentionPage={
                          selectedLicenseType ===
                          ProducerLicenseType.NEEDS_ATTENTION
                        }
                        setModalOpen={setModalOpen}
                      />
                    );
                  },
                },
              ]
            : []),
        ]
      : [
          {
            title: 'Auto Renew',
            dataIndex: 'autoRenews',
            key: 'licenseProducer.producerDetails.paymentConfig.isAutoRenewalActive',
            width: 70,
            align: 'center' as any,
            className: 'center-column',
            sorter: true,
            render: (text: boolean, record: any) => {
              return (
                <div className="flex justify-center w-full">
                  {record?.producerDetails?.paymentConfig
                    ?.isAutoRenewalActive === true ? (
                    <img alt="logo" src={success} />
                  ) : (
                    <img alt="logo" src={error} />
                  )}
                </div>
              );
            },
          },

          {
            title: 'Assignment Status',
            sorter: true,
            dataIndex: 'licenseConfigurations.agencyAssignment.isAssigned',
            key: 'licenseConfigurations.agencyAssignment.isAssigned',
            width: 140,
            onCell: (record: any) => ({
              style: {
                color: record?.licenseConfigurations?.agencyAssignment[0]
                  ?.isAssigned
                  ? 'green'
                  : 'red',
              },
            }),
            render: (licenseConfiguration: any, record: any) => {
              const isLoading = currentAssignLoadingId === record.id;

              const tooltipTitle = getAssignmentStatusTooltip(
                record?.producerDetails
              );

              return (
                <AgentAssignmentStatus
                  key={record.id}
                  isAssigned={
                    record?.licenseConfigurations?.agencyAssignment[0]
                      ?.isAssigned
                  }
                  showAssignButton={
                    !record?.licenseConfigurations?.agencyAssignment[0]
                      ?.isAssigned &&
                    (record.status === LicenseStatus.Active ||
                      (record.status === LicenseStatus.Inactive &&
                        !record?.isDuplicateLicenseNumber)) &&
                    isIndividualProducer
                  }
                  handleClick={() => {
                    const licenseConfigurationIds =
                      record.licenseConfigurations.loaDetails.map(
                        (loa: any) => {
                          return loa.licenseConfigurationId;
                        }
                      );
                    const stateCode = record.stateCode;
                    const requestBody = { licenseConfigurationIds, stateCode };
                    createAssignmentForLicense(
                      record.producerDetails.producerId,
                      requestBody,
                      record.id
                    );
                  }}
                  isLoading={isLoading}
                  tooltipTitle={tooltipTitle}
                  isDisabled={(!isLoading && disableAssign) || !!tooltipTitle}
                />
              );
            },
          },
        ]),
  ];
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<object>({});

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const deleteAppliedLicense = async (licenseId: string) => {
    api['success']({
      message: 'License application submitted',
      description: SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS,
    });
    fetchNeedsAttentionLicenses();
  };

  const updateLicenseById = (
    licenseId: string,
    record: LicenseApplicationResponse
  ) => {
    const newLicenses = produce(needsAttentionLicenses, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].license.id === licenseId) {
          console.debug('found license by id :: updating');
          draft[i] = { ...record };
          break;
        }
      }
    });
    setNeedsAttentionLicenses(newLicenses);
  };

  const setIsLoadingForGivenLicense = (
    licenseId: string,
    isLoading: boolean
  ) => {
    const newLicenses = produce(needsAttentionLicenses, (draft) => {
      draft.forEach((itr) => {
        if (itr.id === licenseId) itr.isLoading = isLoading;
      });
    });
    setNeedsAttentionLicenses(newLicenses);
  };

  const refreshLicenseById = async (licenseId: string) => {
    try {
      setIsLoadingForGivenLicense(licenseId, true);
      const token = await getAccessTokenSilently();

      const response: ObjectSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.findLatestApplicationForGivenLicense(
          token,
          licenseId
        );
      if (response?.data) {
        updateLicenseById(licenseId, response.data);
      } else setIsLoadingForGivenLicense(licenseId, false);
    } catch (e) {
      setIsLoadingForGivenLicense(licenseId, false);
    }
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const fetchLicenseStatusCount = async (isNotReset?: boolean) => {
    try {
      if (!isNotReset) {
        setLicenseCount({
          activeCount: 0,
          needsAttentionCount: 0,
          renewalsCount: 0,
        });
      }
      const token = await getAccessTokenSilently();
      const licenseStatusCount = await LicensesService.getLicenseStatusCount(
        token,
        undefined,
        true
      ); // producer portal
      setLicenseCount({
        activeCount: licenseStatusCount?.activeCount || 0,
        needsAttentionCount: licenseStatusCount?.needsAttentionCount || 0,
        renewalsCount: licenseStatusCount?.renewalLicensesCount || 0,
      });
    } catch (e: any) {
      console.error('fetchLicenseStatusCount error :: ', e);
    }
  };

  useEffect(() => {
    fetchLicenseStatusCount();
  }, []);

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      if (bearerToken) {
        let response: any;

        if (selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION) {
          response =
            await LicensesService.getFilterInfoForFindAgentActionable(
              bearerToken
            );
        } else if (selectedLicenseType === ProducerLicenseType.RENEWALS) {
          response =
            await LicensesService.getFilterInfoForRenewals(bearerToken);
        } else {
          response = await LicensesService.getFilterInfoForFindAgentAllLicenses(
            bearerToken,
            {}
          );
        }
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH && data.key !== 'name'
            ),
            PageConstants.ALL_PRODUCER_LICENSES
          )
        );
        const result = response.data.filter(
          (d: any) => d.key !== 'producerIds'
        );
        setControlledFilters(result || []);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    fetchFiltersInfoForFindAll();
  }, [selectedLicenseType]);

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenseDetails(queryText || '', newPageNumber, newPageSize);
  };

  useEffect(() => {
    fetchLicenseDetails();
  }, [bearerToken, queryText, searchType]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchLicenseDetails('', 1);
    }
  }, [requestBody]);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  // all and active licenses
  const fetchLicenseDetails = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object,
    lcType?: string,
    reqBody?: object // to reset the request body while switching different license sections
  ) => {
    const licenseType = lcType || selectedLicenseType;
    const updatedRequestBody = reqBody || requestBody;
    if (licenseType === ProducerLicenseType.NEEDS_ATTENTION) {
      fetchNeedsAttentionLicenses(
        searchTextProp,
        pageNumberProp,
        pageSizeProp,
        sort
      );
      return;
    } else if (licenseType === ProducerLicenseType.RENEWALS) {
      fetchRenewalLicenses(searchTextProp, pageNumberProp, pageSizeProp, sort);
      return;
    }
    try {
      setIsLoading((prev) => ({ ...prev, all: true }));
      if (bearerToken) {
        if (cancelToken) {
          cancelPreviousRequest(cancelToken);
        }
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: PaginatedSuccessResponse<AllLicenseResponse> =
          await LicensesService.findAllForAgent(
            {
              ...updatedRequestBody,
              ...updatedRequestBody,
              ...(sort || sortValue || {}),
            },
            bearerToken,
            pageSizeProp || pageSize,
            pageNumberProp || pageNumber,
            reqBody ? '' : searchTextProp ? searchTextProp : queryText || '',
            reqBody ? '' : searchType ? searchType : searchType || '',
            licenseType === ProducerLicenseType.ACTIVE,
            cancelTokenSource
          );
        if (response) {
          setCancelToken(undefined);
          setCount(response.totalCount);
          setActiveData(response.data);
          setIsLoading((prev) => ({ ...prev, all: false }));
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading((prev) => ({ ...prev, all: false }));
      }
      console.error('Error:', error);
    }
  };

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fetchRenewalLicenses = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object,
    reqBody?: object
  ) => {
    try {
      const token = await getAccessTokenSilently();
      setIsLoading((prev) => ({ ...prev, renewal: true }));
      const payload = reqBody || requestBody;
      const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.getRenewalLicensesForAgency(
          token,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : searchType || '',
          { ...(payload || {}), ...(sort || sortValue || {}) },
          undefined,
          true
        );
      if (response.data) {
        setCount(response.totalCount);
        setRenewalLicenses(response.data);
        setIsLoading((prev) => ({ ...prev, renewal: false }));
      } else {
        setCount(0);
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading((prev) => ({ ...prev, renewal: false }));
      }
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  const getTableData = () => {
    if (selectedLicenseType === ProducerLicenseType.NEEDS_ATTENTION)
      return needsAttentionLicenses;
    if (selectedLicenseType === ProducerLicenseType.RENEWALS)
      return renewalLicenses;
    return activeData;
  };

  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const handleBilling = async () => {
    try {
      if (bearerToken) {
        setFetchingStripeURL(true);
        const response: any =
          await AgentService.getProducerStripeValidURL(bearerToken);
        const stripeURL = response.data.checkoutUrl;
        if (stripeURL) {
          setFetchingStripeURL(false);
          window.location.href = stripeURL;
        }
      }
    } catch (error) {
      setFetchingStripeURL(false);
      console.error('Error :: getProducerStripeURL ::', error);
      api['error']({
        message: 'Unable to proceed',
        description: 'Please contact support',
      });
    }
  };

  return (
    <div
      className="reset-icon-size table-layout-v2 relative"
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <div
          style={{ display: 'flex', columnGap: '10px', margin: '0px 0px 10px' }}
          className="figtree v2-filter-button v2-search"
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            disabled={
              Object.values(loading)?.some((d) => d) ||
              subscriptionStatusInactive
            }
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-all-licenses'}
              size="middle"
              placeholder="Search Licenses by State, LOAs or License Class"
              style={{
                width: 372,
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>

          <FilterButton
            {...{
              filterInfoLoading,
              activeData: getTableData(),
              requestBody,
              isLoading: Object.values(loading)?.some((d) => d),
              setIsFilterVisible,
              pageName: 'Manage Licenses - Producer Portal',
            }}
          />
        </div>
        {/* quick filters */}
        <div
          style={{
            padding: '8px 0',
            gap: '8px',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            scrollbarWidth: 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '8px',
              width: '50%',
            }}
          >
            {QUICK_FILTERS.map((filter) => {
              return (
                <Button
                  key={filter.type}
                  style={{
                    backgroundColor:
                      selectedLicenseType === filter.type ? '#001F45' : '',
                    color: selectedLicenseType === filter.type ? '#fff' : '',
                  }}
                  onClick={() => {
                    const licenseType = filter.type;

                    if (selectedLicenseType === licenseType) return;

                    setSelectedLicenseType(licenseType);

                    if (
                      licenseType === ProducerLicenseType.ACTIVE ||
                      licenseType === ProducerLicenseType.ALL
                    ) {
                      fetchLicenseDetails(
                        undefined,
                        1,
                        100,
                        undefined,
                        licenseType === ProducerLicenseType.ACTIVE
                          ? ProducerLicenseType.ACTIVE
                          : 'unset',
                        {}
                      );
                    } else if (
                      licenseType === ProducerLicenseType.NEEDS_ATTENTION
                    ) {
                      fetchNeedsAttentionLicenses(
                        undefined,
                        1,
                        100,
                        undefined
                        // {}
                      );
                    } else if (licenseType === ProducerLicenseType.RENEWALS) {
                      fetchRenewalLicenses(undefined, 1, 100, undefined, {});
                    }
                    // resetting the search and filters
                    setRequestBody({});
                    setSearchType('');
                    setSearchText('');
                    setSelectedFilters({ data: {} });
                    setPageNumber(1);
                    setActiveData([]);
                    setNeedsAttentionLicenses([]);
                    setRenewalLicenses([]);
                    const newSearchParams = new URLSearchParams(searchParams);

                    if (queryText) {
                      newSearchParams.delete('search');
                      setQueryText('');
                    }

                    newSearchParams.set('quickFilter', filter.type);
                    setSearchParams(newSearchParams);
                  }}
                  className="agency-license-buttons"
                  disabled={subscriptionStatusInactive}
                >
                  {filter.label}
                </Button>
              );
            })}
          </div>
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters }}
        />

        <Table
          columns={columns}
          dataSource={getTableData()}
          loading={Object.values(loading)?.some((d) => d)}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenseDetails);
            setSortValue(sort);
          }}
        />
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={pageNumber}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={Object.values(loading)?.some((d) => d)}
            />
          </Row>
        )}
      </ConfigProvider>

      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        controlledFilters={controlledFilters.map((filter: any) => {
          if (filter.key === 'status') {
            return {
              ...filter,
              filterOptions: filter?.filterOptions?.filter(
                (d: any) =>
                  activeLicenseFilter?.includes(d?.value) ||
                  selectedLicenseType !== ProducerLicenseType.ACTIVE
              ),
            };
          }
          return filter;
        })}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        filterPage={PageConstants.ALL_PRODUCER_LICENSES}
      />
      <ApplyLicenseErrorModal
        applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
        setApplyLicenseErrorModalVisibility={
          setApplyLicenseErrorModalVisibility
        }
        errors={errors}
        fetchLicense={() => {
          fetchLicenseStatusCount(true);
          if (selectedLicenseType === ProducerLicenseType.RENEWALS) {
            fetchRenewalLicenses();
          } else {
            fetchNeedsAttentionLicenses();
          }
        }}
      />
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENT}
        showTransactionDetails={false}
        isProducerAllLicenses={true}
        autoRenewEnabled={selectedLicenseDetails?.agent?.autoRenewEnabled}
      />
      {modalOpen && (
        <CardDetailsModal
          modalVisibility={modalOpen}
          setModalVisibility={setModalOpen}
          handleBilling={handleBilling}
          loading={fetchingStripeURL}
        />
      )}
    </div>
  );
}

export default withRoles(AllLicenses, [
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
