import { Button, Modal, Skeleton, Table, Typography } from 'antd';
import {
  CsvJson,
  OnboardErrorDto,
  OnboardResultDto,
  multipleOnboardStore,
} from '../../../../stores/multipleOnboardStore';
import React, { useEffect, useState } from 'react';

import { AgentService } from '../../../../services/agent.service';
import { BulkOnboardingStage } from '../../../../enums/bulkOnboardingStage.enum';
import { ScbReportService } from '../../../../services/scb-report.service';
import { getNameString } from '../../../../utils/name.utils';
import { useAuth } from '../../../../auth/authProvider';

interface ContinueOnboardModalProps {
  CsvJson: CsvJson[];
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  reportId: string;
  handleNext: () => void;
  onboardingStage?: string;
}

const ContinueOnboardModal = (props: ContinueOnboardModalProps) => {
  // const { getAccessTokenSilently } = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  // const [onboardError, setOnboardError] = useState<OnboardErrorDto[]>([]);
  const [error, setError] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();

  // useEffect(() => {
  //   fetchConfirmationModalDetails();
  // }, []);

  const fetchConfirmationModalDetails = async () => {
    setLoader(true);
    // try {
    //   const token = await getAccessTokenSilently();
    //   const reportResponse: any = await AgentService.multipleOnboardLookup(
    //     token,
    //     props.reportId
    //   );
    //   setOnboardError(reportResponse.onboardError);
    //   multipleOnboardStore.setOnboardResult(reportResponse as OnboardResultDto);
    //   multipleOnboardStore.setReportId(props.reportId);
    //   multipleOnboardStore.setProducerViewTableContent();
    //   setLoader(false);
    // } catch (error) {
    //   console.error('Error ::', error);
    //   setError(true);
    //   setLoader(false);
    //   throw error;
    // }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (data: any) => {
        return getNameString(data);
      },
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
    },
    {
      title: 'SSN',
      dataIndex: 'ssn',
      key: 'ssn',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Error Condition',
      dataIndex: 'errorCondition',
      key: 'errorCondition',
    },
  ];

  interface ReplacementMap {
    [key: string]: string;
  }

  const getCSV = (): void => {
    const onboardError = multipleOnboardStore.onBoardErrorCSV?.[0];
    const header = Object.keys(onboardError || {});
    let csvHeader = header.join(',') + '\n';

    const replacements: ReplacementMap = {
      name: 'lastName',
      npn: 'NPN',
      ssn: 'SSN',
      email: 'Email',
      errorCondition: 'Error Condition',
    };

    const csvRows = multipleOnboardStore.onBoardErrorCSV.map((obj) => {
      const formattedName = obj?.name || obj?.lastName;

      const rowData = { ...obj, name: formattedName };
      return header
        .map((key) => (key === 'name' ? rowData.name : (obj as any)[key]))
        .join(',');
    });

    const pattern = new RegExp(Object.keys(replacements).join('|'), 'g');
    csvHeader = csvHeader.replace(pattern, (match) => replacements[match]);
    const csv = csvHeader + csvRows.join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'producer-error.csv';

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const fetchProducerIdsForBulkOnboard = async () => {
    try {
      setLoader(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getProducerIdsForBulkOnboard(
        token,
        multipleOnboardStore.bulkOnboardId
      );
      multipleOnboardStore.setBulkAgentsIds(response?.data as string[]);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const handleOnboardingStep = () => {
    if (
      props.onboardingStage === BulkOnboardingStage.RETRIEVE_SCB ||
      props.onboardingStage === BulkOnboardingStage.CONTINUE_ONBOARDING
    ) {
      multipleOnboardStore.setCurrentStep(1);

      if (multipleOnboardStore.maxStep < 1) multipleOnboardStore.setMaxStep(1);
    } else if (props.onboardingStage === BulkOnboardingStage.BULK_ASSIGNMENT) {
      fetchProducerIdsForBulkOnboard();
      multipleOnboardStore.setCurrentStep(2);
      if (multipleOnboardStore.maxStep < 2) multipleOnboardStore.setMaxStep(2);
    } else if (
      props.onboardingStage === BulkOnboardingStage.PAYMENT_CONFIGURATION
    ) {
      fetchProducerIdsForBulkOnboard();
      multipleOnboardStore.setCurrentStep(3);
      if (multipleOnboardStore.maxStep < 3) multipleOnboardStore.setMaxStep(3);
    }
  };

  return (
    <Modal
      open={props.modalVisibility}
      width={'850px'}
      bodyStyle={{ minHeight: '100px' }}
      className={
        multipleOnboardStore.cannotBeOnboardedCount ? 'errorModal' : ''
      }
      onCancel={() => props.setModalVisibility(false)}
      footer={[
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          key={'continue-onboard-modal-footer'}
        >
          <Button onClick={() => props.setModalVisibility(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleOnboardingStep();
              props.setModalVisibility(false);
            }}
            disabled={
              loader ||
              multipleOnboardStore.getCanBeOnboardCount() === 0 ||
              error
            }
          >
            Continue
          </Button>
        </div>,
      ]}
    >
      {loader ? (
        <Skeleton />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            margin: '12px 0',
          }}
        >
          {multipleOnboardStore.cannotBeOnboardedCount &&
          multipleOnboardStore.cannotBeOnboardedCount > 0 ? (
            <>
              <Typography.Paragraph
                style={{ fontSize: '16px', marginBottom: '12px' }}
              >
                Cannot onboard {multipleOnboardStore.cannotBeOnboardedCount}{' '}
                {multipleOnboardStore.cannotBeOnboardedCount === 1
                  ? 'producer'
                  : 'producers'}
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  color: 'var(--light-grey2)',
                  fontSize: '14px',
                  margin: '0',
                }}
              >
                Download the{' '}
                <span
                  style={{
                    color: 'var(--hint-primary-color)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    getCSV();
                  }}
                >
                  CSV
                </span>{' '}
                to view producer errors.
              </Typography.Paragraph>

              <Table
                columns={columns}
                dataSource={multipleOnboardStore.onBoardErrorCSV}
                scroll={{ y: 400 }}
                pagination={{
                  defaultPageSize: 100,
                  showSizeChanger: false,
                }}
              />
            </>
          ) : error ? (
            <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
              Something Went Wrong
            </Typography.Paragraph>
          ) : props.onboardingStage === BulkOnboardingStage.BULK_ASSIGNMENT ||
            props.onboardingStage ===
              BulkOnboardingStage.PAYMENT_CONFIGURATION ? (
            <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
              Onboarded all producers.
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
              NPN found for all producers.
            </Typography.Paragraph>
          )}

          {multipleOnboardStore.canBeOnboardCount &&
          multipleOnboardStore.canBeOnboardCount > 0 ? (
            props.onboardingStage === BulkOnboardingStage.BULK_ASSIGNMENT ? (
              <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
                Continue with the assignment of states or territories for
                producers who have been onboarded?
              </Typography.Paragraph>
            ) : props.onboardingStage ===
              BulkOnboardingStage.PAYMENT_CONFIGURATION ? (
              <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
                Continue with the payment configuration for producers who have
                been onboarded?
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph style={{ fontSize: '16px', margin: '0' }}>
                Continue with the onboarding of producers whose details have
                been successfully retrieved?
              </Typography.Paragraph>
            )
          ) : (
            <></>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ContinueOnboardModal;
