export enum RoleType {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  AGENT = 'producer',
  'LICENSE_MANAGER' = 'license-manager',
  'BILLING' = 'billing',
  INDIVIDUAL_PRODUCER = 'individual-producer',
}

export const RoleTypeLabels: { [key in RoleType]: string } = {
  [RoleType.SUPER_ADMIN]: 'Super Admin',
  [RoleType.ADMIN]: 'Admin',
  [RoleType.AGENT]: 'Producer',
  [RoleType.LICENSE_MANAGER]: 'License Manager',
  [RoleType.BILLING]: 'Billing Manager',
  [RoleType.INDIVIDUAL_PRODUCER]: 'Individual Producer',
};
