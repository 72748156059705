import { autorun, makeAutoObservable } from 'mobx';

import { DownlineService } from '../services/downlines.service';
import { Name } from '../types/data/name.type';
import { adminStore } from './admin.store';

export interface DownlineData {
  id: string;
  name: Name;
  npn: string;
  type: 'agent' | 'agency';
}

export interface AgencyLoadedData {
  fetchedAt: string;
  id: string;
  name: string;
  npn?: string;
  depth?: number;
  type: 'agent' | 'agency';
  downlineAgencyCount?: number;
  downlineAgentCount?: number;
  downlineData: AgencyLoadedData[];
}

export interface DownlineAgencyHierarchyDetails {
  _id: string;
  name: string;
  npn: string;
  type?: string;
}
export interface DownlineAgentHierarchyDetails {
  _id: string;
  name: Name;
  npn: string;
}
interface DownlineHierarchy {
  uplineAgencyId: string;
  level: number;
  downlineAgencyHierarchyDetails?: DownlineAgencyHierarchyDetails;
  downlineAgentHierarchyDetails?: DownlineAgentHierarchyDetails;
}
interface AgencyDetails {
  _id: string;
  name: string;
  npn: string;
}
interface distributionChannelDetailsType {
  downlineHierarchy: DownlineHierarchy[];
  uplineAgencyDetails: AgencyDetails;
  downlineAgencyDetails?: AgencyDetails;
  downlineAgentDetails?: DownlineAgentHierarchyDetails;
}

class HierarchyViewDataStore {
  downlineAgencyCount: number = 0;
  downlineAgentCount: number = 0;
  lineOnlyAgentsCount: number = 0;
  currentSelectedAgencyName: string = '';
  currentSelectedAgencyId: string = '';
  agencyData: AgencyLoadedData[] = [];
  agentData: AgencyLoadedData[] = [];
  distributionChannelDetails: distributionChannelDetailsType[] = [];

  agencyName: string = '';

  constructor() {
    makeAutoObservable(this);
    this.updateAgencyName();

    autorun(() => {
      this.updateAgencyName();
    });
  }

  updateAgencyName() {
    if (adminStore.agency) {
      this.currentSelectedAgencyId = adminStore?.agency.id;
      this.agencyName = adminStore.agency?.name;
    }
  }

  setCurrentAgencyId(agencyId: string) {
    if (agencyId) this.currentSelectedAgencyId = agencyId;
  }

  getDistributionChannelDetails = async (
    agencyId?: string,
    bearerToken?: string | null,
    setCount?: boolean
  ) => {
    if (agencyId && bearerToken) {
      const response: any = await DownlineService.getAgenciesDownlineHierarchy(
        agencyId,
        bearerToken
      ).catch((error) => {
        console.error(error);
      });
      if (response?.data) {
        this.distributionChannelDetails = response?.data;
      }
    } else {
      console.error('Error loading');
    }
  };
}

export const hierarchyViewStore = new HierarchyViewDataStore();
