import { Button, Modal, Row, Typography } from 'antd';

import { useState } from 'react';

type ErrorModalProps = {
  errorMessage?: string | null;
  title?: string;
  onClose: () => void;
};

export default function ErrorModal({
  errorMessage,
  title = 'Error',
  onClose,
}: ErrorModalProps) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    onClose();
  };

  return (
    <Modal
      open={isModalVisible}
      centered
      closable={false}
      onCancel={handleCloseModal}
      footer={[
        <div style={{ textAlign: 'center' }} key={'error-modal-footer'}>
          <Button
            id="error-modal-close-button"
            className="button primary-button"
            style={{ height: '45px' }}
            key="ok"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </div>,
      ]}
    >
      {title && (
        <div className="modal-content">
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography.Paragraph
              className="error-heading"
              style={{ fontWeight: 600 }}
            >
              {title}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              {errorMessage}
            </Typography.Paragraph>
          </Row>
        </div>
      )}
    </Modal>
  );
}
