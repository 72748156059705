import axios, { AxiosError, AxiosInstance, CancelTokenSource } from 'axios';

import { Auth0Service } from '../services/auth0.service';
import { auth0Store } from './auth0Store';
import { generateRandom10DigitAlphanumericString } from '../utils/hash.utils';
import { makeAutoObservable } from 'mobx';

export interface ApiTokens {
  agent: {
    getFirstLevelDownlineAgents?: CancelTokenSource;
    getUnconfiguredPaymentAgents?: CancelTokenSource;
    getAllAgents?: CancelTokenSource;
    getAllAgents2?: CancelTokenSource;
    getAgentByEmail?: CancelTokenSource;
    updateInitialPayee?: CancelTokenSource;
    getActionableLicensesForAgent?: CancelTokenSource;
    renewals?: CancelTokenSource;
  };
  auth0: {
    getUser?: CancelTokenSource;
  };
  agency: {
    getStateGroupsForAgency?: CancelTokenSource;
    organizationList?: CancelTokenSource;
  };
  license: {
    getActionableLicensesForAgency?: CancelTokenSource;
    getLicenses?: CancelTokenSource;
    getallLicenses?: CancelTokenSource;
    getApplications?: CancelTokenSource;
  };
}

class ApiStore {
  apiTokens: ApiTokens = { agent: {}, auth0: {}, agency: {}, license: {} };
  sessionId: string | undefined;

  init = () => {
    this.refreshSessionId();
  };

  refreshSessionId = () => {
    this.sessionId = generateRandom10DigitAlphanumericString();
  };

  // Middleware function to handle 403 responses
  async handleForbiddenResponse(error: AxiosError) {
    if (
      error.response?.headers['x-role'] !==
        auth0Store.getCurrentProfile()?.role ||
      error.response?.headers['x-agency-id'] !==
        auth0Store.getCurrentProfile()?.agencyId
    ) {
      // Not logging out because profile changed
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 403) {
      // logging user out
      localStorage.removeItem('isLoggedIn');
      const currentAgencyId = localStorage.getItem('x-agency-id');
      const currentProfileName = localStorage.getItem('x-role');
      localStorage.clear();
      if (currentAgencyId && currentProfileName) {
        localStorage.setItem('x-agency-id', currentAgencyId);
        localStorage.setItem('x-role', currentProfileName);
      }
      // await Auth0Service.performLogout('').finally(() => {});
    }
    return Promise.reject(error);
  }

  getApiClientForSmartyAddress = (
    cancelTokenSource?: CancelTokenSource
  ): AxiosInstance => {
    if (!this.sessionId) this.refreshSessionId();
    const client = axios.create();
    if (cancelTokenSource) {
      client.defaults.cancelToken = cancelTokenSource.token;
    }

    return client;
  };

  getApiClient = (
    token: string,
    cancelTokenSource?: CancelTokenSource
  ): AxiosInstance => {
    if (!this.sessionId) this.refreshSessionId();
    const client = axios.create();
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    client.defaults.headers.common['Accept'] = 'application/json';
    client.defaults.headers.common['Content-type'] = 'application/json';
    client.defaults.headers.common['Session-id'] = this.sessionId;
    client.defaults.headers.common['Request-id'] =
      generateRandom10DigitAlphanumericString();
    client.defaults.headers.common['X-role'] =
      auth0Store.getCurrentProfile()?.role;
    client.defaults.headers.common['X-agency-id'] =
      auth0Store.getCurrentProfile()?.agencyId;
    if (cancelTokenSource)
      client.defaults.cancelToken = cancelTokenSource.token;

    // Add a response interceptor
    client.interceptors.response.use(
      (response) => response, // Simply return the response for success cases
      this.handleForbiddenResponse // Use the middleware function for error cases
    );
    return client;
  };

  getApiClientWithoutAuthentication = (
    cancelTokenSource?: CancelTokenSource
  ): AxiosInstance => {
    if (!this.sessionId) this.refreshSessionId();
    const client = axios.create();
    client.defaults.headers.common['Accept'] = 'application/json';
    client.defaults.headers.common['Content-type'] = 'application/json';
    client.defaults.headers.common['Session-id'] = this.sessionId;
    client.defaults.headers.common['Request-id'] =
      generateRandom10DigitAlphanumericString();
    if (cancelTokenSource)
      client.defaults.cancelToken = cancelTokenSource.token;
    return client;
  };

  getTokenForGetFirstLevelDownlineAgents = ():
    | CancelTokenSource
    | undefined => {
    return this.apiTokens.agent.getFirstLevelDownlineAgents;
  };
  setTokenForGetFirstLevelDownlineAgents = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getFirstLevelDownlineAgents = cancelTokenSource;
  };

  getTokenForGetUnconfiguredPaymentAgents = ():
    | CancelTokenSource
    | undefined => {
    return this.apiTokens.agent.getUnconfiguredPaymentAgents;
  };
  setTokenForGetUnconfiguredPaymentAgents = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getUnconfiguredPaymentAgents = cancelTokenSource;
  };

  getTokenForGetAllAgents = (): CancelTokenSource | undefined => {
    return this.apiTokens.agent.getAllAgents;
  };
  setTokenForGetAllAgents = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getAllAgents = cancelTokenSource;
  };

  getTokenForRenewals = (): CancelTokenSource | undefined => {
    return this.apiTokens.agent.renewals;
  };
  setTokenForRenewals = (cancelTokenSource: CancelTokenSource | undefined) => {
    this.apiTokens.agent.renewals = cancelTokenSource;
  };

  getTokenForGetAllAgents2 = (): CancelTokenSource | undefined => {
    return this.apiTokens.agent.getAllAgents2;
  };
  setTokenForGetAllAgents2 = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getAllAgents2 = cancelTokenSource;
  };

  getTokenForGetAgentByEmail = (): CancelTokenSource | undefined => {
    return this.apiTokens.agent.getAgentByEmail;
  };
  setTokenForGetAgentByEmail = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getAgentByEmail = cancelTokenSource;
  };

  getTokenForGetUser = (): CancelTokenSource | undefined => {
    return this.apiTokens.auth0.getUser;
  };

  setTokenForGetUser = (cancelTokenSource: CancelTokenSource | undefined) => {
    this.apiTokens.auth0.getUser = cancelTokenSource;
  };

  getTokenForUpdateInitialPayee = (): CancelTokenSource | undefined => {
    return this.apiTokens.agent.updateInitialPayee;
  };
  setTokenForUpdateInitialPayee = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.updateInitialPayee = cancelTokenSource;
  };

  getTokenForGetStateGroupsForAgency = (): CancelTokenSource | undefined => {
    return this.apiTokens.agency.getStateGroupsForAgency;
  };
  setTokenForGetStateGroupsForAgency = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agency.getStateGroupsForAgency = cancelTokenSource;
  };

  getTokenForOrganizationList = (): CancelTokenSource | undefined => {
    return this.apiTokens.agency.organizationList;
  };
  setTokenForOrganizationList = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agency.organizationList = cancelTokenSource;
  };

  getTokenForGetActionableLicensesForAgency = ():
    | CancelTokenSource
    | undefined => {
    return this.apiTokens.license.getActionableLicensesForAgency;
  };
  setTokenForGetActionableLicensesForAgency = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.license.getActionableLicensesForAgency = cancelTokenSource;
  };

  getTokenForGetActionableLicensesForAgent = ():
    | CancelTokenSource
    | undefined => {
    return this.apiTokens.agent.getActionableLicensesForAgent;
  };
  setTokenForGetActionableLicensesForAgent = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.agent.getActionableLicensesForAgent = cancelTokenSource;
  };

  getTokenForGetLicenses = (): CancelTokenSource | undefined => {
    return this.apiTokens.license.getLicenses;
  };
  setTokenForGetLicenses = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.license.getLicenses = cancelTokenSource;
  };

  getTokenForGetAllLicenses = (): CancelTokenSource | undefined => {
    return this.apiTokens.license.getallLicenses;
  };
  setTokenForGetAllLicenses = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.license.getallLicenses = cancelTokenSource;
  };

  getTokenForGetApplications = (): CancelTokenSource | undefined => {
    return this.apiTokens.license.getApplications;
  };
  setTokenForGetApplications = (
    cancelTokenSource: CancelTokenSource | undefined
  ) => {
    this.apiTokens.license.getApplications = cancelTokenSource;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const apiStore = new ApiStore();
