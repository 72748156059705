import {
  AssignmentOptions,
  INDIVIDUAL_ONBOARD_STEPS,
  IndividualOnboardAgentProps,
  IndividualOnboardStateGroupProps,
  ProducerDetails,
  StateLicenses,
  paymentOptionsType,
  updateProducerAssignmetPayload,
} from './individualOnboardV2Type.type';
import {
  Button,
  Card,
  Layout,
  Modal,
  Skeleton,
  Typography,
  message,
  notification,
} from 'antd';
import {
  CsvJson,
  multipleOnboardStore,
} from '../../../../stores/multipleOnboardStore';
import {
  EMAIL_REGEX,
  LAST_NAME_REGEX,
  NPN_REGEX,
  SSN_REGEX,
} from '../../../../constants/regex.constants';
import {
  MultipleOnboardLookup,
  MultipleOnboardSteps,
} from '../../../../enums/onboardSteps.enum';
import { NiprLookUpData, Report } from '../draft/draft';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../../utils/common.utils';
import {
  isErrorNoPaymentMethodsFound,
  isErrorSsnIsInvalid,
  isErrorStripeCustomerMissing,
  isErrorStripeOnboardingRequired,
  isErrorUnprocessedScbReport,
} from '../../../../utils/errorUtils';
import {
  steps,
  updateAgentBioInfo,
  validateAssignmentStep,
  validateOnboardingStep,
} from './IndividualOnboardHelpers';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import { AgentService } from '../../../../services/agent.service';
import { BulkOnboardingStage } from '../../../../enums/bulkOnboardingStage.enum';
import CustomSteps from '../../../../components/common/steps/customStepper';
import DraggerComponent from '../../../../components/common/dragger/draggerComponent';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import FileParser from '../../../../utils/file-parser/fileParser';
import IndividualOnboardAssignments from './individualOnboardAssignments';
import IndividualOnboardDetails from './individualOnboardDetails';
import IndividualOnboardOptions from './individualOnboardOptions';
import IndividualOnboardingIcon from '../../../../assets/icons/id-card-line.svg';
import { LeftOutlined } from '@ant-design/icons';
import MultipleOnboard from '../multipleOnboard';
import { NotificationType } from '../../../../types/common/notification.type';
import OnboardedWelcomeScreen from './onboardedWelcomeScreen';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import { RouteConstants } from '../../../../constants/routes.constants';
import { ScbReportService } from '../../../../services/scb-report.service';
import StripeOnboardingRequiredToast from '../../../../components/common/stripeOnboardingRequiredToast';
import { currentStateFee } from '../../../../types/data/feeCalculation.type';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import insureTrekLogo from '../../../../assets/images/insureTrekLogo.png';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import refreshReport from '../../../../assets/icons/refresh-report.svg';
import siderImage from '../../../../assets/images/agencyLicensingSidebar.png';
import { ssnColumns } from '../../../../utils/table-manager/lookupTemplate.table-manager';
import { useAuth } from '../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { verifyCSV } from '../../../../enums/verifyCSV.enum';

const { Content, Sider, Footer } = Layout;

const IndividualOnboardV2 = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || 'individual';
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<NiprLookUpData[]>();
  const [modalLoading, setModalLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [sortValue, setSortValue] = useState({});
  const [data, setData] = useState<Report[]>([]);
  const [columnData, setColumnData] = useState<any>(ssnColumns);
  const [isRetrievingPreviewData, setIsRetrievingPreviewData] =
    useState<boolean>(false);

  const [currentOnboardStep, setCurrentOnboardStep] = useState(
    INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD
  );
  const [warningModal, setWarningModal] = useState(false);
  const [multipleOnboardBlocker, setMultipleOnboardBlocker] = useState(false);
  const [multipleOnboardWarningModal, setMultipleOnboardWarningModal] =
    useState(false);
  const [agentBioInfo, setAgentBioInfo] = useState<IndividualOnboardAgentProps>(
    {
      isExistingAgent: false,
      agentEmail: '',
      agentNPN: '',
      agentSsn: '',
      agentFax: '',
      agentGender: '',
      agentDateOfBirth: '',
      agentHomeStateAddresses: [],
      addressLastUpdatedAt: new Date(),
      agentPhone: '',
      firstName: '',
      lastName: '',
      producerId: '',
    }
  );
  const [producerDetails, setProducerDetails] = useState<ProducerDetails>();
  const [disableSave, setDisableSave] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<paymentOptionsType>({
    newLicenses: '',
    renewalLicenses: '',
    autoApply: false,
  });
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [stateGroups, setStateGroups] = useState<
    IndividualOnboardStateGroupProps[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<StateLicenses[]>([]); // selected states in assignments
  const [selectedTerritory, setSelectedTerritory] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedTab, setSelectedTab] = useState(AssignmentOptions.TERRITORIES);
  const TerritoryCount = stateGroups?.filter((data) => data.isSelected)?.length;
  const StateCount = selectedItems?.length;
  const isDataFetching = individualOnboardStore.isProducerDataFetching;
  const [producerEstimatedFee, setProducerEstimatedFee] = useState<number>(0);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [offboardJsonData, setOffboardJsonData] = useState<any>();
  const [requestBody, setRequestBody] = useState<CsvJson[]>([]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>('');
  const [onboardingStage, setOnboardingStage] = useState<string>('');

  const fileParser = new FileParser();

  const setFileContentHandler = (fileContent: any) => {
    try {
      const offboardJsonData = fileParser.parseCsvToJson(fileContent);
      const offboardJsonDatawithIds = offboardJsonData.map(
        (row: any, index: number) => {
          return {
            id: index + 1,
            ...row,
          };
        }
      );
      setOffboardJsonData(offboardJsonDatawithIds);
    } catch (error) {
      console.error(error);
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const showAlert = (message: string, type: NotificationType) => {
    api[type]({
      message: 'Notification',
      description: message,
    });
  };
  const showError = (message: React.ReactNode) => {
    api['error']({
      message: 'Stripe Onboarding Incomplete',
      description: message,
    });
  };

  const fetchScbReports = async (
    pageNumberprops?: number,
    pageSizeprops?: number,
    type?: any,
    sort?: object
  ) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getAllScbReport(
        pageNumberprops || pageNumber,
        pageSizeprops || pageSize,
        token,
        { ...(sort || sortValue || {}) }
      );
      setData(response.data);
      setTotalCount(response.totalCount);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const onUpload = async (values: any) => {
    try {
      const token = await getAccessTokenSilently();
      if (uploadedFile) {
        let bulkOnboardId = '';
        setModalLoading(true);
        const filteredFileContent = uploadedFile.filter(
          (obj) => Object.keys(obj).length > 1
        );
        if (filteredFileContent) {
          const duplicatedValue = hasDuplicates(filteredFileContent);
          if (duplicatedValue !== verifyCSV.NO_DUPLICATE) {
            showAlert(`${duplicatedValue}`, 'error');
            setModalLoading(false);
            return;
          }
          const response: any = await ScbReportService.bulkNpnLookUp(
            filteredFileContent,
            token
          );
          if (response.status === 'SUCCESS') {
            showAlert('CSV/XLSX Uploaded SuccessFully', 'success');
            bulkOnboardId = response.data.bulkOnboardId;
            retrieveScbReport();
          } else {
            showAlert('CSV/XLSX Upload Failed , Try Again', 'error');
          }
          setModalLoading(false);
          fetchScbReports();
        }
        multipleOnboardStore.setIsFileUploaded(false);
        multipleOnboardStore.setLookupMethod(MultipleOnboardLookup.SSN);
        multipleOnboardStore.setUploadFileName('');
        multipleOnboardStore.resetMultipleOnboard();
        if (!isEmpty(bulkOnboardId)) {
          multipleOnboardStore.setCurrentStep(
            MultipleOnboardSteps.RETRIEVE_REPORT
          );
          multipleOnboardStore.setBulkOnboardId(bulkOnboardId);
        }
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (isErrorStripeOnboardingRequired(error.response.data))
          showError(<StripeOnboardingRequiredToast />);
        else if (isErrorStripeCustomerMissing(error.response.data))
          showError(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING);
        else if (isErrorNoPaymentMethodsFound(error.response.data))
          showError(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND);
        else if (isErrorSsnIsInvalid(error.response.data))
          showError(ErrorMessageConstants.INVALID_SSNS);
        else
          showAlert(
            'Some fields in the uploaded CSV are missing. Please review and update the values accordingly before uploading again. You can refer to the sample CSV for the correct format if needed',
            'error'
          );
      } else {
        showAlert(
          'Something went wrong, please try again after some time.',
          'error'
        );
      }
      setModalLoading(false);
      console.error('Error:', error?.response?.data?.error?.message);
    } finally {
      setIsFileUploaded(false);
    }
  };

  const hasDuplicates = (array: any[]) => {
    const npnSet = new Set();
    const ssnSet = new Set();
    const emailSet = new Set();

    const generateDuplicateErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields have duplicate in this batch. Ensure there are no duplicates.`;
    const generateInvalidErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields are not valid in this batch. Please enter a valid ${field}`;

    for (const obj of array) {
      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.NPN) {
        if (obj?.npn) {
          const npn =
            typeof obj.npn === 'string' ? obj.npn.trim() : obj.npn.toString();
          if (npnSet.has(npn)) {
            return generateDuplicateErrorMessage('NPN', npn);
          }
          if (typeof npn === 'string' && NPN_REGEX.test(npn)) {
            npnSet.add(npn);
          } else {
            return generateInvalidErrorMessage('NPN', npn);
          }
        }
      }

      if (obj?.ssn) {
        const ssn =
          typeof obj.ssn === 'string' ? obj.ssn.trim() : obj.ssn.toString();
        if (ssnSet.has(ssn)) {
          return generateDuplicateErrorMessage('SSN', ssn);
        }
        if (typeof ssn === 'string' && SSN_REGEX.test(ssn)) {
          ssnSet.add(ssn);
        } else {
          return generateInvalidErrorMessage('SSN', ssn);
        }
      }

      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.SSN) {
        if (obj.lastName) {
          const lastName =
            typeof obj.lastName === 'string'
              ? obj.lastName.trim()
              : obj.lastName.toString();
          if (typeof lastName === 'string' && !LAST_NAME_REGEX.test(lastName)) {
            return generateInvalidErrorMessage('last name', lastName);
          }
        }
      }

      if (obj?.email) {
        const email =
          typeof obj.email === 'string' ? obj.email.trim() : obj.email;
        if (emailSet.has(email)) {
          return generateDuplicateErrorMessage('Email', email);
        }
        if (typeof email === 'string' && EMAIL_REGEX.test(email)) {
          emailSet.add(email);
        } else {
          return generateInvalidErrorMessage('Email', email);
        }
      }
    }

    return verifyCSV.NO_DUPLICATE;
  };

  const handleFileChange = (uploadedFile: any, uploadedFileContent: any) => {
    const { status, name, percent } = uploadedFile.file;
    if (status === 'uploading') {
      setUploadStatus(status);
      setUploadProgress(percent);
    } else if (status === 'done') {
      setUploadedFileName(name);
      setUploadProgress(percent);
      setFileContentHandler(uploadedFileContent);
      setIsFileUploaded(true);
    }
  };

  const updateProducerDetails = async () => {
    let isError = false;
    try {
      setLoading(true);
      const ifNotUpdated =
        !agentBioInfo.agentUpdatedDOB &&
        !agentBioInfo.agentUpdatedGender &&
        !agentBioInfo.agentUpdatedSSN &&
        !paymentOptions.newLicenses;
      if (!ifNotUpdated) {
        const bearerToken = await getAccessTokenSilently();
        const payload = {
          ...(agentBioInfo.agentUpdatedDOB
            ? { dateOfBirth: agentBioInfo.agentUpdatedDOB }
            : {}),
          ...(agentBioInfo.agentUpdatedGender
            ? { gender: agentBioInfo.agentUpdatedGender }
            : {}),
          ...(agentBioInfo.agentUpdatedSSN
            ? { ssn: agentBioInfo.agentUpdatedSSN }
            : {}),
          ...(paymentOptions.newLicenses
            ? {
                paymentConfig: {
                  licenseInitialPayee: paymentOptions.newLicenses,
                  licenseRenewalPayee: paymentOptions.renewalLicenses,
                  paymentType: 'full',
                  cappedAmount: 0,
                  isAutoRenewalActive: paymentOptions.autoApply,
                },
              }
            : {}),
        };
        const response = await AgencyService.updateProducerDetails(
          agentBioInfo.producerId,
          payload,
          bearerToken
        );
        message.success(response?.message);
        setAgentBioInfo(updateAgentBioInfo);
      }
      setCurrentOnboardStep((prev) => prev + 1);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
      isError = true;
    } finally {
      setLoading(false);
    }
    return isError;
  };

  const updateAssignmentDetails = async () => {
    let isError = false;
    try {
      setLoading(true);
      const bearerToken = await getAccessTokenSilently();
      const payload: updateProducerAssignmetPayload[] = [];
      const selectedStateGroup = stateGroups?.filter((d) => d.isSelected) || [];
      const homeState = agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;
      selectedStateGroup.forEach((d) => {
        d.stateLicenseConfigs.forEach((data) => {
          payload.push({
            territoryId: d.id,
            stateCode: data?.stateCode,
            licenseConfigurations: data?.licenseConfigs
              ?.flatMap((d) => d.loaConfigs)
              ?.filter(
                (d) =>
                  !(
                    (!homeState?.includes(data.stateCode) &&
                      d?.residencyType === ResidencyType.Resident) ||
                    (homeState?.includes(data.stateCode) &&
                      d?.residencyType === ResidencyType.NonResident)
                  )
              )
              .map((d) => d.id || '')
              .filter((d) => d),
          });
        });
      });
      selectedItems.forEach((data) => {
        const licenseConfigurations: string[] = [];
        Object.entries(data).forEach(([k, v]) => {
          if (typeof v === 'string') return;
          v?.loas?.forEach((element) => {
            licenseConfigurations.push(element?.id || '');
          });
        });
        payload.push({
          stateCode: getStateCodeFromName(data.name) || '',
          licenseConfigurations,
        });
      });
      const response = await AgencyService.updateBulkAssignmentsForProducer(
        [agentBioInfo.producerId],
        payload,
        bearerToken
      );
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
      await AgentOnboardFees();
      setIsOnboarded(true);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
      isError = true;
    } finally {
      setLoading(false);
    }
    return isError;
  };

  const handleNext = async () => {
    let isError = false;

    // validation for each step
    if (currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS) {
      isError = validateOnboardingStep(agentBioInfo, paymentOptions);
    } else if (
      currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS
    ) {
      isError = validateAssignmentStep(selectedItems, stateGroups);
      if (!isError) {
        const selectedState = selectedItems?.[0]?.name;
        const selectedTerritory = stateGroups?.filter((d) => d.isSelected);
        if (selectedTerritory?.length && !selectedState) {
          // if territory is selected , switch to first terriroty in review
          setSelectedTab(AssignmentOptions.TERRITORIES);
          setSelectedTerritory(selectedTerritory?.[0]?.name);
          setSelectedState(
            getStateNameFromCode(
              selectedTerritory?.[0]?.stateLicenseConfigs?.[0]?.stateCode
            )
          );
        } else if (selectedState) {
          setSelectedTab(AssignmentOptions.STATE);
          setSelectedState(selectedState);
        }
      }
    }

    if (isError) return;

    if (currentStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS) {
      isError = await updateProducerDetails();
    } else if (
      currentStep ===
      INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
    ) {
      isError = await updateAssignmentDetails();
    }

    if (isError) return;

    const nextStep = currentStep + 1;
    if (nextStep < steps.length + 1) {
      setCurrentStep(nextStep);
    }
  };

  const handlePrevious = () => {
    const prevStep = currentStep - 1;
    if (prevStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS) {
      setCurrentOnboardStep(
        INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS
      );
    }
    setCurrentStep(prevStep);

    if (prevStep === INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS) {
      if (selectedTab === AssignmentOptions.TERRITORIES) {
        const firstTerritory = stateGroups?.[0]?.name;
        const firstTerritoryState =
          stateGroups?.[0]?.stateLicenseConfigs?.[0]?.stateCode;

        if (firstTerritory) setSelectedTerritory(firstTerritory);
        if (firstTerritoryState)
          setSelectedState(getStateNameFromCode(firstTerritoryState));
      }
    }
  };

  const handleStepClick = (step: number) => {
    if (
      currentStep ===
        INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW &&
      step === 1
    )
      setCurrentStep(step + 1);
  };

  const isAssignmentAssigned = () => {
    const selectedStateGroup = stateGroups.filter((data) => data.isSelected);
    if (selectedStateGroup?.length) return true;

    return selectedItems?.some((data) => data.name);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
      sessionStorage.removeItem('isOnboarding');
    };

    // on refresh, show warning modal
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const producerId = individualOnboardStore.agentBioInfo.producerId;

  const getProducerDetails = async () => {
    try {
      const bearerToken = await getAccessTokenSilently();
      const producerDetails = await AgentService.getProducerDetailsById(
        producerId,
        bearerToken
      );
      const producer = producerDetails?.data;
      setProducerDetails(producer);
      setAgentBioInfo({
        isExistingAgent: false,
        agentEmail:
          producer?.contactEmail ||
          agentBioInfo?.agentEmail ||
          producer?.businessEmail ||
          '',
        agentNPN: producer?.npn,
        agentFax: producer?.businessPhone,
        agentSsn: producer?.ssn,
        agentDateOfBirth: producer?.dateOfBirth,
        agentGender: producer?.gender,
        agentHomeStateAddresses: producer?.address,
        addressLastUpdatedAt: producer?.addressLastUpdatedAt || new Date(),
        agentPhone: producer?.businessPhone,
        firstName: producer?.name?.firstName || '',
        lastName: producer?.name?.lastName || '',
        producerId,
      });
      setPaymentOptions((prev) => ({
        ...prev,
        autoApply: producer?.paymentConfig?.isAutoRenewalActive,
        newLicenses: producer?.paymentConfig?.licenseInitialPayee,
        renewalLicenses: producer?.paymentConfig?.licenseRenewalPayee,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      individualOnboardStore.setIsProducerDataFetching(false);
    }
  };

  useEffect(() => {
    if (producerId) {
      getProducerDetails();
    }
    return () => {
      if (!producerId) return;
      individualOnboardStore.resetStore();
      sessionStorage.removeItem('isOnboarding');
    };
  }, [producerId]);

  useEffect(() => {
    setCurrentOnboardStep(individualOnboardStore.currentOnboardStep);
  }, [individualOnboardStore.currentOnboardStep]);

  const updateReport = (reportId: string, response: any) => {
    const reportToUpdate: any = columnData.findIndex(
      (report: Report) => report.id === reportId
    );
    if (reportToUpdate !== -1) {
      const newData: any = columnData.map((columnData: any, index: any) => {
        if (index === reportToUpdate) {
          return {
            ...columnData,
            isBeingProcessed: response?.isBeingProcessed,
            requestBody: response?.requestBody,
            canBeOnboardedCount: response?.canBeOnboardedCount,
            cannotBeOnboardedCount: response?.cannotBeOnboardedCount,
            onboardingStage: response?.onboardingStage,
          };
        }
        return columnData;
      });
      setColumnData(newData);
    }
  };

  const AgentOnboardFees = async () => {
    const selectedStates: currentStateFee[] = [];
    const producerActiveLicenses =
      individualOnboardStore.getProducerActiveLicenses();
    const homeState = agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;
    stateGroups
      .filter((data) => data.isSelected)
      ?.forEach((d) => {
        d.stateLicenseConfigs.forEach((data) => {
          const isActiveLicensePresentForState = producerActiveLicenses.find(
            (license) => license.stateCode === data.stateCode
          );
          data.licenseConfigs.forEach((licenseConfig) => {
            const residencyTypes =
              homeState === data.stateCode
                ? [ResidencyType.Resident, ResidencyType.NotApplicable]
                : [ResidencyType.NonResident, ResidencyType.NotApplicable];
            if (
              residencyTypes.includes(
                licenseConfig.loaConfigs[0]?.residencyType as ResidencyType
              )
            ) {
              const isActive =
                isActiveLicensePresentForState &&
                licenseConfig.lcCode ===
                  isActiveLicensePresentForState.licenseClassCode;

              selectedStates.push({
                stateCode: data.stateCode,
                active: !!isActive,
              });
            }
          });
        });
      });

    // selected state fees
    selectedItems?.forEach((d) => {
      const stateCode = getStateCodeFromName(d.name) || '';

      const isActiveLicensePresentForState = producerActiveLicenses.find(
        (license) => license.stateCode === getStateCodeFromName(d.name)
      );
      if (isActiveLicensePresentForState) {
        const lcCodes = Object.keys(d)?.filter((d) => d !== 'name');
        lcCodes?.forEach((licenseConfig) => {
          if (
            licenseConfig === isActiveLicensePresentForState.licenseClassCode
          ) {
            selectedStates.push({ stateCode: stateCode, active: true });
          } else {
            selectedStates.push({ stateCode: stateCode, active: false });
          }
        });
      } else {
        selectedStates.push({ stateCode: stateCode, active: false });
      }
    });
    try {
      const token = await getAccessTokenSilently();
      const response = await AgencyService.calculateFee(token, {
        currentState: selectedStates,
      });
      if (response) {
        const applicationFee = response?.applicationFee || 0;
        setProducerEstimatedFee(applicationFee);
      }
    } catch (error) {
      console.error(`Error while fetching fee | ${error}`);
    }
  };

  const onOnboardAnotherProducer = () => {
    setIsOnboarded(false);
    setCurrentStep(1);
    setProducerEstimatedFee(0);
    setSelectedTab(AssignmentOptions.TERRITORIES);
    setSelectedState('');
    setSelectedTerritory('');
    setSelectedItems([]);
    setStateGroups([]);
    setDisableSave(false);
    setCurrentOnboardStep(
      INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD
    );
    setMultipleOnboardBlocker(false);
    setMultipleOnboardWarningModal(false);
    individualOnboardStore.resetStore();
  };

  const retrieveScbReport = async () => {
    try {
      const token = await getAccessTokenSilently();
      setIsRetrievingPreviewData(true);
      const response: any = await ScbReportService.retrieveScbReport(
        multipleOnboardStore.bulkOnboardId,
        token
      );

      if (response.status === 'SUCCESS' && response.message) {
        showAlert(response.message, 'warning');
      }

      if (response.status === 'SUCCESS' && response.data) {
        setRequestBody(response?.data?.requestBody);
        updateReport(selectedRecordId, response?.data);
        if (
          response?.data?.onboardingStage ===
          BulkOnboardingStage.CONTINUE_ONBOARDING
        ) {
          if (response?.data?.canBeOnboardedCount === 0)
            multipleOnboardStore.setCurrentStep(
              MultipleOnboardSteps.IMPORT_AGENTS
            );
          else
            multipleOnboardStore.setCurrentStep(
              MultipleOnboardSteps.RETRIEVED_REPORT
            );
          multipleOnboardStore.setCanBeOnboardCount(
            response?.data?.canBeOnboardedCount
          );
          multipleOnboardStore.setCannotBeOnboardedCount(
            response?.data?.cannotBeOnboardedCount
          );
        }
        setOnboardingStage(response?.data?.onboardingStage);
        if (response?.data?.onboardingStage === BulkOnboardingStage.COMPLETED)
          multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.ONBOARDED);
      }
      setIsRetrievingPreviewData(false);
      if (response.status !== 'SUCCESS') {
        showAlert('SomeThing went wrong please try again later', 'error');
      }
    } catch (err: any) {
      if (isErrorStripeCustomerMissing(err.response.data))
        showAlert(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING, 'error');
      else if (isErrorNoPaymentMethodsFound(err.response.data)) {
        showAlert(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND, 'error');
      } else if (isErrorUnprocessedScbReport(err.response.data))
        showAlert(err?.response.data.error?.message, 'error');
      else
        showAlert(
          'Please review the CSV file for any incorrect data and try uploading again.',
          'error'
        );
    }
  };

  if (isOnboarded)
    return (
      <OnboardedWelcomeScreen
        firstName={agentBioInfo.firstName}
        lastName={agentBioInfo.lastName}
        AgentAmount={producerEstimatedFee}
        TerritoryCount={TerritoryCount}
        StateCount={StateCount}
        producerId={producerId}
        onOnboardAnotherProducer={onOnboardAnotherProducer}
      />
    );

  return (
    <>
      {contextHolder}
      <Layout
        style={{ minHeight: '100vh' }}
        className="individual-onboarding-v2-layout"
      >
        <Sider
          style={{
            height: '100vh',
            position: 'sticky',
            top: '0',
            backgroundImage: `url(${siderImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          width={258}
          theme="light"
        >
          {/* Content inside Sider */}
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: '30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '40px',
              }}
            >
              <img
                alt="logo"
                style={{
                  width: '164px',
                  height: '42.8px',
                }}
                src={insureTrekLogo}
              />
            </div>
            <div
              style={{
                width: '198px',
                height: '27px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '27px',
                color: '#FFFFFF',
                marginBottom: '20px',
              }}
            >
              {type === 'bulk' ? 'Bulk' : 'Producer'} Onboarding
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                style={{
                  width: '201px',
                  height: '36px',
                  borderRadius: '6px',
                  padding: '12px 24px',
                  backgroundColor: '#E8EBEE',
                  border: 'none',
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  if (type === 'bulk') {
                    if (
                      multipleOnboardStore.currentStep ===
                      MultipleOnboardSteps.IMPORT_AGENTS
                    )
                      navigate(RouteConstants.manageAssignments.path);
                    else
                      multipleOnboardStore.setCurrentStep(
                        MultipleOnboardSteps.IMPORT_AGENTS
                      );
                  } else if (
                    currentOnboardStep ===
                    INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD
                  ) {
                    navigate(RouteConstants.manageAssignments.path);
                  } else {
                    setWarningModal(true);
                  }
                }}
              >
                <LeftOutlined style={{ fontSize: '4px' }} />
                Go Back
              </Button>
            </div>
            <div
              style={{
                width: '201px',
                height: '1px',
                border: '0.5px solid #374F6D',
                marginTop: '30px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: '198px',
                  height: '54px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  textAlign: 'left',
                  marginTop: '27px',
                  marginBottom: '17px',
                }}
              >
                {type === 'bulk'
                  ? `Add New Producer’s to your agency`
                  : 'Add a producer to your team'}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: '198px',
                  height: '54px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#00A6FB',
                  textAlign: 'left',
                  margin: 0,
                }}
              >
                {type == 'bulk'
                  ? ' Lets verify the details and add new producers to the agency!'
                  : 'Get their current information and licenses from NIPR, and start tracking their information in InsureTrek.'}{' '}
              </p>
            </div>
          </div>
        </Sider>
        {/* Main Content Layout */}
        <Layout>
          {isDataFetching ? (
            <Card
              style={{
                margin: '24px',
              }}
            >
              {' '}
              <Skeleton avatar paragraph={{ rows: 2 }} active />
            </Card>
          ) : (
            <Content
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            >
              {type === 'individual' ? (
                currentOnboardStep ===
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD ? (
                  <div
                    style={{
                      background: '#ffffff',
                      padding: 20,
                      display: 'flex',
                      gap: 12,
                      alignItems: 'center',
                    }}
                  >
                    <img
                      alt="individual-onboarding"
                      src={IndividualOnboardingIcon}
                    />
                    <div className="header-title">
                      {type === 'individual' ? 'Individual' : 'Bulk'} Onboarding
                    </div>
                  </div>
                ) : null
              ) : (
                multipleOnboardStore.currentStep ===
                  MultipleOnboardSteps.IMPORT_AGENTS && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      background: '#f5f5f5',
                      padding: '16px 20px 16px 20px',
                      placeContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography.Paragraph
                        style={{
                          fontSize: '18px',
                          fontFamily: 'Figtree',
                          fontWeight: 600,
                          marginBottom: 0,
                        }}
                      >
                        Continue Onboarding or Upload CSV/XLSX
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        style={{
                          color: '#848484',
                          fontSize: '12px',
                          marginBottom: 0,
                        }}
                      >
                        Continue Onboarding or add the information of your
                        producers in a CSV/XLSX File format and upload it in
                        here.
                      </Typography.Paragraph>
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                      <Button
                        style={{
                          height: '22px',
                          width: '120px',
                          backgroundColor: '#001F45',
                          color: '#FCFAFF',
                          padding: '0 12px',
                          fontFamily: 'Figtree !important',
                          borderRadius: '6px',
                          border: 'none',
                        }}
                        onClick={() => setRefreshKey((prev) => prev + 1)}
                      >
                        <div className="flex flex-row gap-2 justify-center font-figtree !important">
                          <img src={refreshReport} alt="refresh" />
                          <span className="font-medium text-sm text-center">
                            Refresh
                          </span>
                        </div>
                      </Button>
                    </div>
                  </div>
                )
              )}
              {type === 'bulk' &&
                multipleOnboardStore.currentStep ===
                  MultipleOnboardSteps.IMPORT_AGENTS && (
                  <div style={{ padding: '0 20px 16px 20px' }}>
                    <DraggerComponent
                      handleFileChange={handleFileChange}
                      setIsUploadComplete={setIsFileUploaded}
                      isFileUploaded={isFileUploaded}
                      acceptedFileSize={35 * 1024 * 1024}
                      acceptedFileTypes={[
                        'text/csv',
                        '.csv',
                        '.xlsx',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      ]}
                      uploadedFileName={uploadedFileName}
                      onUpload={onUpload}
                      setUploadedFile={setUploadedFile}
                    />
                  </div>
                )}
              <div
                style={{
                  padding:
                    multipleOnboardStore.currentStep ===
                    MultipleOnboardSteps.AGENT_VIEW
                      ? '0'
                      : type === 'bulk'
                        ? '0 20px 16px 20px'
                        : '20px',
                }}
              >
                {currentOnboardStep ===
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD ? (
                  type === 'individual' ? (
                    <IndividualOnboardOptions />
                  ) : (
                    <MultipleOnboard
                      isBlocking={multipleOnboardBlocker}
                      refreshKey={refreshKey}
                      setIsBlocking={setMultipleOnboardBlocker}
                      openWarningModal={multipleOnboardWarningModal}
                      changeTab={(isLeaving) => {
                        if (isLeaving) {
                          setMultipleOnboardWarningModal(false);
                          setMultipleOnboardBlocker(false);
                        } else {
                          setMultipleOnboardWarningModal(false);
                        }
                      }}
                      requestBody={requestBody}
                      selectedRecordId={selectedRecordId}
                      setSelectedRecordId={setSelectedRecordId}
                      onboardingStage={onboardingStage}
                      setOnboardingStage={setOnboardingStage}
                      data={columnData}
                      setData={setColumnData}
                      isRetrievingPreviewData={isRetrievingPreviewData}
                    />
                  )
                ) : null}
                {currentOnboardStep ===
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS ? (
                  <IndividualOnboardDetails
                    {...{
                      agentBioInfo,
                      setAgentBioInfo,
                      paymentOptions,
                      setPaymentOptions,
                      loading,
                    }}
                  />
                ) : null}
                {currentOnboardStep ===
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS ? (
                  <IndividualOnboardAssignments
                    {...{
                      selectedItems,
                      setSelectedItems,
                      setStateGroups,
                      stateGroups,
                      selectedTerritory,
                      setSelectedTerritory,
                      selectedState,
                      setSelectedState,
                      selectedTab,
                      setSelectedTab,
                      setDisableSave,
                    }}
                    producerDetails={producerDetails!}
                    isReview={
                      currentStep ===
                      INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
                    }
                  />
                ) : null}
              </div>
            </Content>
          )}
          {!isDataFetching &&
          currentOnboardStep !==
            INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD ? (
            <Footer
              style={{
                height: '64px',
                border: '1px solid #E6E6E6',
                backgroundColor: '#FFFFFF',
                padding: '20px',
                gap: '4px',
                position: 'sticky',
                bottom: '0px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <CustomSteps
                steps={steps}
                disabled={loading}
                current={currentStep - 1}
                onClickStep={handleStepClick}
                maxStep={steps.length}
              />
              {![
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARDING_ONBOARD,
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS,
              ].includes(currentStep) ? (
                <Button
                  style={{
                    height: '35px',
                    borderRadius: '6px',
                    padding: '12px 24px 12px 16px',
                    backgroundColor: '#E8EBEE',
                    color: '#000000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                    marginLeft: '40px',
                  }}
                  onClick={handlePrevious}
                  disabled={loading} // disable previous on onboard and assign step
                >
                  <span
                    style={{
                      fontSize: '22px',
                      lineHeight: '1em',
                      marginRight: '5px', // Optional: add some space between text and symbol
                    }}
                  >
                    &lt;
                  </span>
                  <span
                    className="font-poppins"
                    style={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#222222',
                    }}
                  >
                    Previous
                  </span>
                </Button>
              ) : null}
              <Button
                loading={loading}
                disabled={
                  loading ||
                  (currentStep ===
                    INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS &&
                    (!isAssignmentAssigned() || disableSave))
                }
                style={{
                  marginLeft: '10px',
                  height: '36px',
                  backgroundColor: '#00A6FB',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleNext}
              >
                {currentStep ===
                INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
                  ? 'Finish'
                  : 'Next'}
                {!(
                  currentStep ===
                  INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW
                ) && (
                  <span
                    style={{
                      fontSize: '22px',
                      lineHeight: '1em',
                      marginLeft: '5px', // add some space between text and symbol
                    }}
                  >
                    &gt;
                  </span>
                )}
              </Button>
            </Footer>
          ) : null}
        </Layout>
        <Modal
          title={
            // currentOnboardStep === 0
            //   ? 'Are you sure you want to leave the onboarding process?'
            //   :
            'Are you sure you want to exit the onboarding process?'
          }
          open={warningModal}
          maskClosable={false}
          closable={false}
          width={600}
          onOk={() => {
            setWarningModal(false);
          }}
          onCancel={() => {
            navigate(RouteConstants.manageAssignments.path);
            setWarningModal(false);
          }}
          okText={'Complete onboarding'}
          cancelButtonProps={{
            className: 'leave-onboard important-action-button',
          }}
          cancelText={
            // currentOnboardStep === 0 ? 'Leave' :
            'Exit and Complete Later'
          }
        >
          {/* {currentOnboardStep === 0 ? (
          <p>
            Leaving the onboarding process at this stage will discard all the
            information and your onboarding progress will not be saved. Are you
            sure you want to exit?
          </p>
        ) : ( */}
          <p>
            The producer has been onboarded. However, the onboarding process
            involves additional steps that should be completed in their
            respective sections on the platform if you choose to exit. Are you
            sure?
          </p>
          {/* )} */}
        </Modal>
      </Layout>
    </>
  );
});

export default IndividualOnboardV2;
