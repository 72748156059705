import {
  AllLicenseResponse,
  LatestApplication,
  LicenseApplication,
  LicenseApplicationType,
} from '../types/response/all-license.type';
import {
  ApplicationStatusDisplayEnum,
  ApplicationStatusEnum,
} from '../enums/applicationStatus.enum';

import { LicenseStatus } from '../enums/licenseStatus.enum';

export const getApplicationStatus = (
  record: AllLicenseResponse,
  status: string,
  renewal?: boolean
) => {
  if (
    record?.latestApplication?.length !== 0 ||
    record?.latestApplicationStatus
  ) {
    let latestApplicationStatus;
    if (record?.latestApplication?.length !== 0) {
      latestApplicationStatus =
        (record?.latestApplication?.[0] as LatestApplication)
          ?.applicationStatus ||
        (record?.latestApplication?.[0] as LicenseApplication)?.status;
    }
    if (record?.latestApplicationStatus) {
      latestApplicationStatus = record.latestApplicationStatus;
    }
    let isRenewalLicense = false;
    if (record?.latestApplication?.length !== 0 || record?.applicationType) {
      isRenewalLicense = [
        LicenseApplicationType.RENEWAL,
        LicenseApplicationType.REINSTATEMENT,
        LicenseApplicationType.LATE_RENEWAL,
      ].includes(
        (record?.latestApplication?.[0] as LatestApplication)
          ?.licenseApplicationType ||
          (record?.latestApplication?.[0] as LicenseApplication)
            ?.applicationType ||
          record?.applicationType
      );
    }

    if (latestApplicationStatus === ApplicationStatusEnum.PENDING) {
      if (status === LicenseStatus.Active) {
        if (isRenewalLicense)
          return ApplicationStatusDisplayEnum.RENEWAL_PENDING;
        else return ApplicationStatusDisplayEnum.MISSING_LOA;
      }
      if (
        [
          LicenseStatus.Inactive,
          LicenseStatus.MISSING_LOA,
          LicenseStatus.Missing,
        ].includes(status as LicenseStatus)
      ) {
        return ApplicationStatusDisplayEnum.APPLIED;
      }
    }
    if (latestApplicationStatus === ApplicationStatusEnum.Declined) {
      if (status === LicenseStatus.Active || isRenewalLicense || renewal) {
        return ApplicationStatusDisplayEnum.RENEWAL_DECLINED;
      }
      if (
        [
          LicenseStatus.Inactive,
          LicenseStatus.MISSING_LOA,
          LicenseStatus.Missing,
        ].includes(status as LicenseStatus)
      ) {
        return ApplicationStatusDisplayEnum.DECLINED;
      }
    }
  }
};

export const updateLicenseAndApplicationStatus = (
  status: LicenseStatus,
  applicationStatus: ApplicationStatusDisplayEnum | undefined,
  record: {
    latestApplication?: (LatestApplication | LicenseApplication)[];
    isDuplicateLicenseNumber?: boolean;
    applicationType?: LicenseApplicationType;
  }
): {
  updatedStatus: LicenseStatus;
  updatedApplicationStatus?: ApplicationStatusDisplayEnum;
} => {
  let updatedStatus = status;
  let updatedApplicationStatus: ApplicationStatusDisplayEnum | undefined =
    applicationStatus;
  let isRenewalLicense = false;

  if (record?.latestApplication?.length !== 0 || record?.applicationType) {
    isRenewalLicense = [
      LicenseApplicationType.RENEWAL,
      LicenseApplicationType.REINSTATEMENT,
      LicenseApplicationType.LATE_RENEWAL,
    ].includes(
      (record?.latestApplication?.[0] as LatestApplication)
        ?.licenseApplicationType ||
        (record?.latestApplication?.[0] as LicenseApplication)
          ?.applicationType ||
        record?.applicationType
    );
  }

  if (status === LicenseStatus.Inactive) {
    if (!record?.isDuplicateLicenseNumber) {
      updatedStatus = LicenseStatus.Expired;
    }
    if (applicationStatus === ApplicationStatusDisplayEnum.APPLIED) {
      if (isRenewalLicense) {
        updatedApplicationStatus = ApplicationStatusDisplayEnum.RENEWAL_PENDING;
      } else {
        updatedStatus = LicenseStatus.Pending;
        updatedApplicationStatus = undefined; // if license status is pending don't display anything beside
      }
    }
  }

  if (status === LicenseStatus.MISSING_LOA) {
    updatedStatus = LicenseStatus.Active;
    if (
      isRenewalLicense &&
      applicationStatus === ApplicationStatusDisplayEnum.RENEWAL_DECLINED
    ) {
      updatedApplicationStatus = ApplicationStatusDisplayEnum.RENEWAL_DECLINED;
    } else if (
      applicationStatus === ApplicationStatusDisplayEnum.APPLIED &&
      isRenewalLicense
    ) {
      updatedApplicationStatus = ApplicationStatusDisplayEnum.RENEWAL_PENDING;
    } else if (applicationStatus === ApplicationStatusDisplayEnum.APPLIED) {
      updatedApplicationStatus = ApplicationStatusDisplayEnum.MISSING_LOA;
    } else if (applicationStatus === ApplicationStatusDisplayEnum.DECLINED) {
      updatedApplicationStatus = ApplicationStatusDisplayEnum.DECLINED_LOA;
    } else {
      updatedApplicationStatus = ApplicationStatusDisplayEnum.MISSING_LOA;
    }
  }

  return { updatedStatus, updatedApplicationStatus };
};
