import { CSSProperties } from 'react';

const SIDER_WIDTH_MANAGE_ASSIGNMENTS = 258;

export { SIDER_WIDTH_MANAGE_ASSIGNMENTS };

export const STATUS_CHIP: CSSProperties = {
  color: '#222222',
  borderRadius: 16,
  padding: '2px 8px',
  textTransform: 'capitalize',
  width: 'fit-content',
  fontSize: '7.32px',
  lineHeight: '8.78px',
};
