import { Modal, Select, Skeleton, message } from 'antd';
import {
  daysToAutoRenewOptions,
  daysToAutoRenewType,
  preferenceIsUpdatingType,
  preferenceType,
} from '../../../types/agency/agencyPreference.type';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import BottomToast from '../../../components/common/bottomToast/BottomToast';
import CustomButton from '../../../components/common/button/CustomButton';
import { DaysToAutoRenewOptions } from './details-constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import LinkOutline from '../../../components/common/icons/LinkOutline';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../auth/authProvider';

const Preferences: React.FC<{ preferences?: preferenceType }> = ({
  preferences,
}) => {
  const { getAccessTokenSilently } = useAuth();
  const [daysToAutoRenew, setDaysToAutoRenew] = useState<daysToAutoRenewType>(
    daysToAutoRenewOptions.FOURTY_FIVE_DAYS
  );
  const [isUpdating, setIsUpdating] = useState<preferenceIsUpdatingType>({
    isOpen: false,
    loading: false,
    daysToAutoRenew: daysToAutoRenewOptions.FIFTEEN_DAYS,
  });
  const [isContactModal, setIsContactModal] = useState({
    isOpen: false,
    loading: false,
  });
  const [successResp, setSuccessResp] = useState({
    isOpen: false,
    message: '',
  });

  const isProducerAlertEnabled =
    !!preferences?.preferences?.isProducerAlertEnabled;

  const onUpdateList = async () => {
    try {
      setIsUpdating((prev) => ({ ...prev, loading: true }));
      const token = await getAccessTokenSilently();
      const response = await AgencyService.updateDaysToAutoRenew(
        token,
        isUpdating?.daysToAutoRenew
      );
      setSuccessResp((prev) => ({ ...prev, isOpen: true, message: response }));
      setIsUpdating((prev) => ({ ...prev, isOpen: false, loading: false }));
      // updating preference in the store
      agencyProfileStore.setPreferences({
        daysToAutoRenew: isUpdating?.daysToAutoRenew,
        isProducerAlertEnabled:
          !!agencyProfileStore?.agencyDetails?.preferences
            ?.isProducerAlertEnabled,
      });
      setDaysToAutoRenew(isUpdating?.daysToAutoRenew);
    } catch (error) {
      console.error(error);
      message.error(
        'Error while updating the auto renewal period.please try again'
      );
      setIsUpdating((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    setDaysToAutoRenew(preferences?.preferences?.daysToAutoRenew || 15);
  }, [preferences]);

  const onSendContactMail = async () => {
    try {
      setIsContactModal((prev) => ({ ...prev, loading: true }));
      const token = await getAccessTokenSilently();
      const response = await AgencyService.sendConfirmationMailToSupportTeam(
        token,
        !isProducerAlertEnabled
      );
      message.success(response || 'Email sent successfully');
      setIsContactModal((prev) => ({ ...prev, isOpen: false, loading: false }));
    } catch (error) {
      console.error(error);
      message.error(
        'Error while sending the email to support team. please try again'
      );
      setIsContactModal((prev) => ({ ...prev, loading: false }));
    }
  };

  const AlertsInfo = () => {
    return (
      <div
        className={twMerge(
          'py-1 px-2 rounded-md text-xs reset-icon-size reset-color leading-14.4 w-fit bg-[#DAF2FF]'
        )}
      >
        <InfoCircleOutlined color={'#00A6FB'} />{' '}
        {isProducerAlertEnabled
          ? 'Producers can also handle and manage their own profiles.'
          : 'Only admins can use Insuretrek.'}
      </div>
    );
  };

  const getModalSubText = () => {
    if (isProducerAlertEnabled) {
      return [
        'Invite other admins to manage licenses and add producers',
        'Prevent producers from logging in to view or edit their profiles',
        'Require admins to complete background information and employment history for producers',
        'Have the agency cover all licensing costs for producers',
      ];
    }
    return [
      'Allow Producers to update their background questions and employment history',
      'Enable Producers to receive notifications about their licenses and applications',
      'Set up Producers to pay for their own applications',
    ];
  };

  if (preferences?.isLoading)
    return (
      <div className="mt-3">
        <Skeleton active />
      </div>
    );

  return (
    <div className="py-1 pl-3 font-figtree">
      <div className="font-semibold text-base leading-7 text-dark-blue">
        Configure your preferences
      </div>
      <div className="bg-white rounded-md my-3 r">
        <div className="border-b px-5 py-4 border-[#E6E6E6] flex justify-between items-center gap-3">
          <div className="flex flex-col gap-2 leading-5 text-sm">
            <div className="text-dark-blue">Auto Renew Period</div>
            <div className="text-[#5D5D5D]">
              The auto renew of applications is happening {daysToAutoRenew} days
              before the end of renewal period.
            </div>
          </div>
          <div className="min-w-[180px]">
            <Select
              id="preferences-days-to-auto-renew-options"
              className="w-[160px]"
              placeholder="Select Days to  Auto Renew"
              value={daysToAutoRenew}
              disabled={isUpdating?.isOpen || isUpdating?.loading}
              onChange={(value) => {
                setIsUpdating((prev) => ({
                  ...prev,
                  isOpen: true,
                  daysToAutoRenew: value,
                }));
              }}
            >
              {DaysToAutoRenewOptions.map((options) => (
                <Select.Option key={options.value} value={options.value}>
                  {options.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="border-b px-5 py-4 border-[#E6E6E6] flex justify-between items-center gap-3">
          <div className="flex pr-6 flex-col gap-3 leading-5 text-sm">
            <div>
              <div className="text-dark-blue">
                {isProducerAlertEnabled
                  ? 'Admin with Producers access'
                  : 'Admin Only Mode'}
              </div>
              <div className="font-figtree text-xs leading-5 text-[#5D5D5D]">
                {isProducerAlertEnabled
                  ? 'When you add a producer to this team, they will get an invite to join. They can update their background questions, employment history, and get notifications about their licenses and applications. You can also set them up to pay for their own applications. Request the team to enable “Admin Only Mode”.'
                  : 'You can invite other admins to manage licenses and add producers, but producers won’t be able to log in to see or edit their profiles. This means admins will need to complete background information and employment history for producers, and the agency will cover all licensing costs. Request team to enable “Admin with Producers access”.'}
              </div>
            </div>
            <AlertsInfo />
          </div>
          <div className="min-w-[180px]">
            <CustomButton
              type="internalNavigation"
              buttonName={
                <div className="flex items-center gap-2">
                  <LinkOutline fill="inherit" className="button-icon" /> Request
                  Team
                </div>
              }
              classNames="w-[160px] grid place-content-center"
              id="preferences-contact-team-button"
              buttonStyle="ternary"
              onClick={() => {
                setIsContactModal((prev) => ({ ...prev, isOpen: true }));
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title={
          // modal Title
          <div className="font-figtree font-semibold text-xl text-[#07212D]">
            Do you want to save the changes that you’ve made?
          </div>
        }
        width={580}
        open={isUpdating?.isOpen}
        onOk={onUpdateList}
        confirmLoading={isUpdating?.loading}
        closable={!isUpdating?.loading}
        onCancel={() =>
          setIsUpdating({
            isOpen: false,
            loading: false,
            daysToAutoRenew: daysToAutoRenew,
          })
        }
        okText="Save Changes"
        okButtonProps={{
          style: {
            background: '#001F45',
            color: '#ffffff',
          },
          className: 'secondary-button',
        }}
        destroyOnClose
      >
        <div className="font-figtree font-medium text-sm text-[#99ACB5] mb-6">
          Your changes will not be saved if you leave without saving them
        </div>
      </Modal>
      <Modal
        className="custom-footer-modal ul-override-style"
        title={
          <div>
            <div className="font-figtree font-semibold text-xl text-[#07212D]">
              {isProducerAlertEnabled
                ? 'Are you sure you want Admin Only Mode?'
                : 'Are you sure you want Admins with Producer access?'}
            </div>
          </div>
        }
        width={580}
        open={isContactModal?.isOpen}
        onOk={onSendContactMail}
        confirmLoading={isContactModal?.loading}
        onCancel={() => {
          setIsContactModal((prev) => ({ ...prev, isOpen: false }));
        }}
        okText={
          <div className="flex items-center gap-2">
            <LinkOutline fill="white" className="button-icon" /> Request Team
          </div>
        }
        cancelText="Go Back"
        okButtonProps={{
          style: {
            background: '#001F45',
            color: '#ffffff',
          },
          className: 'secondary-button',
        }}
        destroyOnClose
      >
        <div className="font-figtree text-xs font-medium mt-4 mb-2">
          <span className="font-semibold">
            {isProducerAlertEnabled
              ? '“Admin Only Mode”'
              : '“Admin with Producer access”'}
          </span>{' '}
          is ideal if you want to:
        </div>

        <ul className="text-[#494949] font-figtree text-xs flex flex-col gap-1 mb-4 font-medium">
          {getModalSubText()?.map((text) => <li key={text}>{text}</li>)}
        </ul>
      </Modal>
      <BottomToast
        isOpen={successResp?.isOpen}
        onClose={() => {
          setSuccessResp((prev) => ({ ...prev, isOpen: false }));
        }}
        timeOut={2000}
        type="success"
      >
        <span className="font-figtree text-sm">{successResp?.message}</span>
      </BottomToast>
    </div>
  );
};

export default Preferences;
