import {
  ApplicationDetails,
  DetailsCard,
  DetailsContainer,
  DetailsHeading,
  ErrorList,
  ErrorListItem,
  InsuranceLayout,
  Insurancetypes,
  LicenseDetails,
  ModalContainer,
  TextDetail,
  TransactionDetails,
} from './applicationstatus.style';
import React, { useEffect, useState } from 'react';
import {
  TransStatus,
  TransStatusLabels,
} from '../../../enums/nipr/transactionStatus.enum';

import AgentAssignmentStatus from '../../../components/licenses/agentAssignmentStatus';
import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ClickableLinkText } from '../../../components/clickableLink';
import { CloseOutlined } from '@ant-design/icons';
import CopyTextAnimation from '../../../components/common/copyTextAnimation/CopyTextAnimation';
import { Drawer } from 'antd';
import { PortalType } from '../../../enums/portalType.enum';
import { StateConstants } from '../../../constants/state.constants';
import { calculateNiprFee } from '../../nipr/calculate-nipr-fee.utils';
import { capitalizeFirstLetter } from '../../common.utils';
import { costFormatter } from '../../input.utils';
import { getBackgroundColor } from '../../../pages/agents/manageAssignments/manageAssignment';
import { getNameString } from '../../name.utils';
import { getNiprResponseFromLicenseForModal } from '../../nipr/license.utils';
import { isArray } from 'lodash';
import moment from 'moment';

interface LicenseModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  agentLicenseDetails: any | undefined;
  portal: PortalType;
  showTransactionDetails: boolean;
  autoRenewEnabled: boolean;
  hideFees?: boolean;
  hideProducerName?: boolean;
  isProducerAllLicenses?: boolean;
}

const LicenseModal: React.FC<LicenseModalProps> = ({
  visible,
  setVisible,
  agentLicenseDetails,
  portal,
  showTransactionDetails,
  autoRenewEnabled,
  hideFees,
  hideProducerName,
  isProducerAllLicenses,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [niprResponses, setNiprResponses] = useState<string[]>([]);

  useEffect(() => {
    if (agentLicenseDetails) {
      const niprResponse =
        getNiprResponseFromLicenseForModal(agentLicenseDetails);
      if (
        niprResponse.errors.length === 0 ||
        agentLicenseDetails.gatewayTransaction.submissionSuccess
      ) {
        setNiprResponses(niprResponse.messages);
        if (niprResponse.errors && niprResponse.errors.length)
          setWarnings(niprResponse.errors);
        setErrors([]);
      } else {
        setErrors(niprResponse.errors);
        setNiprResponses([]);
      }
    }
  }, [agentLicenseDetails]);

  const stateFee =
    agentLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
      ?.stateFee || 0;
  const transFee =
    agentLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
      ?.transFee || 0;
  const fees = calculateNiprFee(stateFee, transFee);
  const licenseStatus =
    agentLicenseDetails?.license?.[0]?.status ??
    agentLicenseDetails?.license?.status ??
    agentLicenseDetails?.status ??
    '';

  return agentLicenseDetails && Object.keys(agentLicenseDetails).length ? (
    <Drawer
      width={'850px'}
      open={visible}
      onClose={() => {
        setVisible(false);
      }}
      title={null}
      closable={false}
      headerStyle={{ display: 'none' }}
      destroyOnClose={true}
      placement="right"
      bodyStyle={{
        borderRadius: '16px',
        overflow: 'hidden',
      }}
      style={{
        top: '70px',
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        boxShadow: 'none',
      }}
    >
      <ModalContainer>
        <ApplicationDetails>
          <DetailsContainer>
            <div className="flex w-full items-start justify-between">
              <div>
                <DetailsHeading>License Details</DetailsHeading>
                <div
                  className="font-12 figtree"
                  style={{
                    color: '#848484',
                    lineHeight: '20px',
                    fontWeight: 400,
                  }}
                >
                  Additional information about your license will be provided
                  here.
                </div>
              </div>
              <div
                className="cursor-pointer w-7 h-7 rounded-md bg-[#F0F0F0] ml-3 grid place-content-center"
                onClick={() => setVisible(false)}
              >
                <CloseOutlined />
              </div>
            </div>
            <DetailsCard>
              <LicenseDetails width={'20%'}>
                <TextDetail lineHeight="24px">State Name</TextDetail>
                <TextDetail
                  lineHeight="24px"
                  textColor={'#222222'}
                  fontSize={'16px'}
                  fontWeight={500}
                >
                  {isArray(agentLicenseDetails?.license) &&
                  agentLicenseDetails?.license.length
                    ? StateConstants[agentLicenseDetails?.license[0].stateCode]
                    : StateConstants[agentLicenseDetails?.stateCode]
                      ? StateConstants[agentLicenseDetails?.stateCode]
                      : StateConstants[agentLicenseDetails?.license.stateCode]
                        ? StateConstants[agentLicenseDetails?.license.stateCode]
                        : agentLicenseDetails?.license?.stateName
                          ? agentLicenseDetails?.license?.stateName
                          : ''}
                </TextDetail>
                <TextDetail></TextDetail>
              </LicenseDetails>
              {PortalType.AGENCY === portal && !hideProducerName && (
                <LicenseDetails width={'25%'}>
                  <TextDetail lineHeight="24px">Producer Name</TextDetail>
                  <TextDetail
                    lineHeight="24px"
                    textColor={'#0074B0'}
                    fontSize={'16px'}
                    fontWeight={500}
                  >
                    {agentLicenseDetails.producerDetails?.name
                      ? getNameString(agentLicenseDetails.producerDetails?.name)
                      : agentLicenseDetails.producer
                        ? getNameString(agentLicenseDetails.producer[0]?.name)
                        : ''}
                  </TextDetail>
                  <TextDetail></TextDetail>
                </LicenseDetails>
              )}
              <LicenseDetails width={'25%'}>
                <TextDetail lineHeight="24px">License Status</TextDetail>
                <TextDetail fontSize={'14px'}>
                  <div
                    style={{
                      color: '#222222',
                      borderRadius: 16,
                      padding: '2px 8px',
                      textTransform: 'capitalize',
                      width: 'fit-content',
                      fontSize: '12px',
                      lineHeight: '14.4px',
                      marginTop: 4,
                      ...getBackgroundColor(licenseStatus!),
                      borderWidth: '1.1px',
                    }}
                  >
                    {licenseStatus}
                  </div>
                </TextDetail>
              </LicenseDetails>
              {!isProducerAllLicenses ? (
                <LicenseDetails width={'25%'}>
                  <TextDetail lineHeight="24px">Application Status</TextDetail>
                  <ApplicationStatusCell
                    applicationStatus={
                      agentLicenseDetails?.latestApplicationStatus
                        ? agentLicenseDetails?.latestApplicationStatus
                        : agentLicenseDetails?.status
                          ? agentLicenseDetails.status.charAt(0).toUpperCase() +
                            agentLicenseDetails.status.slice(1)
                          : ''
                    }
                    styles={{
                      justifyContent: 'flex-start',
                    }}
                    showViewDetails={false}
                  />
                </LicenseDetails>
              ) : (
                <LicenseDetails width={'25%'}>
                  <TextDetail lineHeight="24px">Assignment Status</TextDetail>
                  <AgentAssignmentStatus
                    isAssigned={
                      agentLicenseDetails?.licenseConfigurations
                        ?.agencyAssignment[0]?.isAssigned
                    }
                    showAssignButton={false}
                    styles={{
                      marginLeft: 0,
                      height: '24.4px',
                    }}
                  />
                </LicenseDetails>
              )}
              {isProducerAllLicenses ? (
                <>
                  <LicenseDetails width={'20%'}>
                    <TextDetail lineHeight="24px">License Number</TextDetail>
                    <TextDetail
                      fontSize={'16px'}
                      lineHeight="24px"
                      fontWeight={500}
                    >
                      {agentLicenseDetails?.isDuplicateLicenseNumber ? (
                        <div className="ml-2">-</div>
                      ) : (
                        <div className="flex gap-1 items-center">
                          {agentLicenseDetails?.licenseNumber}{' '}
                          <CopyTextAnimation
                            text={agentLicenseDetails?.licenseNumber}
                            toastMessage="License Number copied to clipboard"
                          />
                        </div>
                      )}
                    </TextDetail>
                  </LicenseDetails>
                  <LicenseDetails width={'25%'}>
                    <TextDetail lineHeight="24px">Renewal Type</TextDetail>
                    <TextDetail
                      fontSize={'16px'}
                      lineHeight="24px"
                      fontWeight={500}
                    >
                      {autoRenewEnabled ? 'Auto Apply' : 'Manual Apply'}
                    </TextDetail>
                  </LicenseDetails>
                  <LicenseDetails width={'25%'}></LicenseDetails>
                  <LicenseDetails width={'25%'}></LicenseDetails>
                </>
              ) : null}
            </DetailsCard>
            <LicenseDetails style={{ width: '100%' }}>
              <TextDetail lineHeight="24px">License Class & LOAs</TextDetail>
              <TextDetail fontSize={'14px'} fontWeight={500}>
                {isArray(agentLicenseDetails?.licenseConfigurations) &&
                agentLicenseDetails?.licenseConfigurations.length
                  ? `${agentLicenseDetails?.licenseConfigurations?.[0]?.licenseClass} (${agentLicenseDetails?.licenseConfigurations?.[0]?.licenseClassCode})`
                  : `${agentLicenseDetails?.licenseConfigurations?.licenseClass} (${agentLicenseDetails?.licenseConfigurations?.licenseClassCode})`}
              </TextDetail>
              <Insurancetypes style={{ marginTop: 6 }}>
                {isArray(agentLicenseDetails?.licenseConfigurations)
                  ? agentLicenseDetails?.licenseConfigurations.map(
                      (loaDetails: any) => (
                        <InsuranceLayout
                          key={loaDetails.loaCode}
                          border={`1px solid ${
                            loaDetails.status === 'Active'
                              ? '#0F9D58'
                              : '#EB3131'
                          }`}
                          background={`${
                            loaDetails.status === 'Active'
                              ? '#CBFAE3'
                              : '#FACBCB'
                          }`}
                        >
                          <TextDetail
                            style={{ textAlign: 'center' }}
                            fontSize={'12px'}
                            lineHeight="14.4px"
                            padding="0 8px"
                          >
                            {loaDetails.loa} ({loaDetails.loaCode})
                          </TextDetail>
                        </InsuranceLayout>
                      )
                    )
                  : agentLicenseDetails?.licenseConfigurations?.loaDetails.map(
                      (loaDetails: any) => (
                        <InsuranceLayout
                          key={loaDetails.loaCode}
                          border={`1px solid ${
                            loaDetails.status === 'Active'
                              ? '#0F9D58'
                              : '#EB3131'
                          }`}
                          background={`${
                            loaDetails.status === 'Active'
                              ? '#CBFAE3'
                              : '#FACBCB'
                          }`}
                        >
                          <TextDetail
                            style={{ textAlign: 'center' }}
                            fontSize={'12px'}
                            lineHeight="14.4px"
                            padding="0 8px"
                          >
                            {loaDetails?.loa} ({loaDetails.loaCode})
                          </TextDetail>
                        </InsuranceLayout>
                      )
                    )}
              </Insurancetypes>
            </LicenseDetails>
            {!isProducerAllLicenses ? (
              <DetailsCard>
                <TransactionDetails>
                  <TextDetail>Type</TextDetail>
                  <TextDetail fontSize={'12px'}>
                    {capitalizeFirstLetter(
                      agentLicenseDetails?.applicationType?.toString()
                    )}
                  </TextDetail>
                </TransactionDetails>
                <TransactionDetails>
                  <TextDetail>Renewal Type</TextDetail>
                  <TextDetail fontSize={'12px'}>
                    {autoRenewEnabled ? 'Auto Apply' : 'Manual Apply'}
                  </TextDetail>
                </TransactionDetails>
                <TransactionDetails width={'160px'}>
                  <TextDetail>NIPR Status</TextDetail>
                  <TextDetail fontSize={'12px'}>
                    {agentLicenseDetails?.gatewayTransaction
                      ?.niprGatewayTransactionResponseDto?.transaction
                      ?.transStatus
                      ? capitalizeFirstLetter(
                          TransStatusLabels[
                            agentLicenseDetails?.gatewayTransaction
                              ?.niprGatewayTransactionResponseDto?.transaction
                              ?.transStatus as TransStatus
                          ]
                        )
                      : agentLicenseDetails?.gatewayTransaction
                            ?.niprGatewayTransactionResponseDto?.errors
                        ? 'Application failed'
                        : 'No response found'}
                  </TextDetail>
                </TransactionDetails>
              </DetailsCard>
            ) : null}
          </DetailsContainer>

          {showTransactionDetails && (
            <DetailsContainer>
              <DetailsHeading>Transaction Details</DetailsHeading>
              <DetailsContainer>
                <DetailsCard>
                  <TransactionDetails width="248px">
                    <TextDetail>Transaction ID</TextDetail>
                    <TextDetail fontSize={'14px'}>
                      {agentLicenseDetails?.id}
                    </TextDetail>
                  </TransactionDetails>
                  <TransactionDetails>
                    <TextDetail>Fee</TextDetail>
                    {!hideFees && fees ? (
                      <TextDetail fontSize={'14px'}>
                        $ {costFormatter(fees)}{' '}
                      </TextDetail>
                    ) : (
                      <div style={{ marginLeft: 6 }}> - </div>
                    )}
                  </TransactionDetails>
                  {agentLicenseDetails?.applyDate && (
                    <TransactionDetails width={'140px'}>
                      <TextDetail>Applied at</TextDetail>
                      <TextDetail fontSize={'12px'}>
                        {agentLicenseDetails?.applyDate
                          ? moment(
                              new Date(agentLicenseDetails?.applyDate)
                            ).format('lll')
                          : '-'}
                      </TextDetail>
                    </TransactionDetails>
                  )}
                </DetailsCard>
              </DetailsContainer>
            </DetailsContainer>
          )}

          {errors.length > 0 && (
            <DetailsContainer gap={'8px'}>
              <DetailsHeading>
                Application {errors.length === 1 ? 'Error' : 'Errors'}
              </DetailsHeading>
              <ErrorList>
                {errors.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
          {niprResponses.length > 0 && (
            <DetailsContainer>
              <DetailsHeading>
                {niprResponses.length === 1 ? 'NIPR Message' : 'NIPR Messages'}
              </DetailsHeading>
              <ErrorList>
                {niprResponses.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
          {warnings.length > 0 && (
            <DetailsContainer>
              <DetailsHeading>
                Application {errors.length === 1 ? 'Warning' : 'Warnings'}
              </DetailsHeading>
              <ErrorList>
                {warnings.map((data, index) => (
                  <ErrorListItem key={index}>
                    {ClickableLinkText(data)}
                  </ErrorListItem>
                ))}
              </ErrorList>
            </DetailsContainer>
          )}
        </ApplicationDetails>
      </ModalContainer>
    </Drawer>
  ) : (
    <></>
  );
};

export default LicenseModal;
