import './modalStyle.css';

import { Button, ConfigProvider, Modal, Radio, Space, message } from 'antd';

import { AgencyService } from '../../../../services/agency.service';
import { DeleteStateGroup } from '../../../../enums/stateGroups.enum';
import { IdConstants } from '../../../../constants/id.constants';
import { adminStore } from '../../../../stores/admin.store';
import { buttonStyle } from '../../../agents/agentsOverview/organizationView/organizationUtils';
import { useAuth } from '../../../../auth/authProvider';
import { useState } from 'react';

type DeleteModalProps = {
  stateGroupId: string[] | undefined;
  visible: boolean;
  handleVisibility: (confirm?: boolean) => void;
};

function DeleteConfirmationModal(props: DeleteModalProps) {
  const { getAccessTokenSilently } = useAuth();

  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState<
    DeleteStateGroup | undefined
  >();

  const deleteAssignments = async () => {
    setLoading(true);
    if (adminStore.account && props.stateGroupId) {
      const token = await getAccessTokenSilently();
      await AgencyService.deleteStateGroupById(props.stateGroupId, true, token)
        .then(() => {
          props.handleVisibility(true);
        })
        .catch((error) => {
          console.error('error :: ', error);
          message.error('Failed to delete territories.please try again.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const convertToStatesAssignments = async () => {
    setLoading(true);
    if (adminStore.account && props.stateGroupId) {
      const token = await getAccessTokenSilently();
      await AgencyService.deleteStateGroupById(props.stateGroupId, false, token)
        .then(() => {
          props.handleVisibility(true);
        })
        .catch((error) => {
          console.error('error :: ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDelete = async () => {
    if (selectedRadio === DeleteStateGroup.Delete) {
      await deleteAssignments();
    } else {
      await convertToStatesAssignments();
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => !loading && props.handleVisibility()}
      closable={!loading}
      centered
      destroyOnClose
      width="600px"
      className="figtree"
      style={{ boxShadow: 'none' }}
      footer={
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 36 }}
        >
          <Button
            id={
              IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL.CANCEL +
              '-state-groups'
            }
            size="large"
            onClick={() => {
              props.handleVisibility();
              setSelectedRadio(undefined);
            }}
            style={{
              ...buttonStyle,
              margin: 0,
              height: 28,
              padding: '2px 24px',
              background: '#E8EBEE',
              color: '#001F45',
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            id={
              IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                .DELETE_STATE_GROUP + '-state-groups'
            }
            htmlType="submit"
            size="large"
            className={`button important-action-button`}
            style={{
              ...buttonStyle,
              margin: 0,
              height: 28,
              padding: '2px 24px',
              // width: '100%',
              marginLeft: 12,
              marginBottom: '10px',
              opacity: !selectedRadio ? '50%' : '100%',
            }}
            onClick={handleDelete}
            disabled={!selectedRadio}
            loading={loading}
          >
            Remove
          </Button>
        </div>
      }
    >
      <div
        style={{
          paddingRight: 24,
        }}
      >
        <div
          className="figtree"
          style={{
            color: '#07212D',
            fontSize: '20px',
            lineHeight: '28px',
            marginBottom: 0,
            fontWeight: 600,
          }}
        >
          Are you sure you want to remove this territory?
        </div>
      </div>
      <div
        style={{
          color: '#ABABAB',
          fontSize: '12px',
          lineHeight: '24px',
          fontWeight: 500,
        }}
        className="figtree"
      >
        This territory will no longer exist. This action cannot be undone.
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#f7fcfc',
          },
        }}
      >
        <Radio.Group
          onChange={(event) => {
            setSelectedRadio(event.target.value);
          }}
          style={{ marginTop: '2%' }}
          value={selectedRadio}
        >
          <Space direction="vertical">
            <Radio
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                  .DELETE_ASSIGNMENT + '-state-groups'
              }
              value={DeleteStateGroup.Delete}
              style={{
                fontSize: 14,
                lineHeight: '16.8px',
                color: '#222222',
                marginTop: 6,
              }}
            >
              Delete Assignments
            </Radio>
            <div
              style={{
                color: '#ABABAB',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14.4px',
              }}
              className="figtree"
            >
              All the assignments will be deleted for all producers
            </div>
            <Radio
              id={
                IdConstants.STATE_GROUPS.DELETE_CONFIRMATION_MODAL
                  .CONVERT_ASSIGNMENT + '-state-groups'
              }
              style={{
                fontSize: 14,
                lineHeight: '16.8px',
                color: '#222222',
                marginTop: 6,
              }}
              value={DeleteStateGroup.Convert}
            >
              Convert Assignments to State Assignments
            </Radio>
            <div
              style={{
                color: '#ABABAB',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14.4px',
              }}
              className="figtree"
            >
              All the assignments will be converted to State Assignments for all
              producers
            </div>
          </Space>
        </Radio.Group>
      </ConfigProvider>
    </Modal>
  );
}

export default DeleteConfirmationModal;
