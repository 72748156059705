import { isEqual, omit } from 'lodash';

import { Address } from '../types/data/address.type';

export const getDefaultAddress = (): Address => ({
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: '',
  zip: '',
  country: '',
});

export const compareAddresses = (
  updatedAddress: Record<string, any>,
  agentPrevAddress: Record<string, any>
) => {
  const updatedAddressAfterOmit: Record<string, any> = {};
  for (const key in updatedAddress) {
    if (Object.prototype.hasOwnProperty.call(updatedAddress, key)) {
      updatedAddressAfterOmit[key] = omit(updatedAddress[key], 'dateUpdated');
    }
  }
  const agentPrevAddressAfterOmit: Record<string, any> = {};
  for (const key in agentPrevAddress) {
    if (Object.prototype.hasOwnProperty.call(agentPrevAddress, key)) {
      agentPrevAddressAfterOmit[key] = omit(agentPrevAddress[key], [
        'dateUpdated',
        '_id',
      ]);
    }
  }
  return isEqual(updatedAddressAfterOmit, agentPrevAddressAfterOmit);
};

export const convertMomentToString = (momentObj: moment.Moment): string => {
  return momentObj.format('YYYY-MM-DD');
};
