import { Checkbox, Tooltip } from 'antd';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LoaProps } from './individualOnboardV2Type.type';
import React from 'react';

const AssignmentLoa: React.FC<LoaProps> = ({
  text,
  loaId,
  licenseClassCode,
  isCheckboxNeeded,
  selectedIds,
  handleCheckboxChange,
  disableCheckBox = false,
  loaDbId,
  activeLoa,
  tooltipText,
}) => {
  const handleLoaCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(
      licenseClassCode,
      text,
      { loaId, id: loaDbId },
      e.target.checked
    );
    e.stopPropagation();
  };
  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          height: 'fit-content',
          backgroundColor: activeLoa ? '#CBFAE3' : '#FFFFFF',
          borderColor: activeLoa ? '#0F9D58' : '#E6E6E6',
        }}
        className={`flex justify-center items-start box-border gap-1 ${isCheckboxNeeded ? 'py-2' : 'py-1'} px-3 border override-checkbox-bg rounded-[4px]`}
      >
        {isCheckboxNeeded && (
          <Checkbox
            style={{ width: '24px', height: '24px' }}
            checked={selectedIds.includes(loaId)}
            onChange={handleLoaCheckboxChange}
            disabled={disableCheckBox}
          />
        )}
        <p
          style={{
            fontSize: isCheckboxNeeded ? '14px' : '12px',
          }}
          className="capitalize m-0 leading-5 font-poppins font-medium text-[#222222] mt-[2px]"
        >
          {text}
        </p>
      </div>
    </Tooltip>
  );
};

export default AssignmentLoa;
