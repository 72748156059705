import React, { useState } from 'react';

import BottomToast from '../bottomToast/BottomToast';
import CopyIconCustom from '../icons/CopyIconCustom';
import Lottie from 'lottie-react';
import copyAnimation from '../../../assets/lottie/Tick.json';
import { copyTextAnimationProps } from '../../../types/common/icons.type';
import { twMerge } from 'tailwind-merge';

const CopyTextAnimation: React.FC<copyTextAnimationProps> = ({
  text,
  className = '',
  styles,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <div>
      {showAnimation ? (
        <Lottie
          animationData={copyAnimation}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          className="ml-[-18px] my-[-18px] h-[54px]"
        />
      ) : (
        <CopyIconCustom
          onClick={() => {
            navigator.clipboard.writeText(text);
            setShowAnimation(true);
          }}
          className={twMerge('cursor-pointer ', className)}
          fill={styles?.fill || '#ABABAB'}
        />
      )}
      <BottomToast
        isOpen={showAnimation}
        onClose={() => {
          setShowAnimation(false);
        }}
        type="success"
      >
        <span className="font-figtree text-sm">
          License Number copied to clipboard
        </span>
      </BottomToast>
    </div>
  );
};

export default CopyTextAnimation;
