import React, { ReactNode } from 'react';

import { Typography } from 'antd';

const { Text } = Typography;

type TextWithIconProps = {
  text: string | ReactNode;
  icon?: ReactNode;
  iconColor?: string;
  textType?: 'warning' | 'success' | 'danger';
  iconAlignment?: 'left' | 'right';
  textStyle?: React.CSSProperties;
  iconSize?: string;
};

function TextWithIcon({
  text,
  icon,
  iconColor,
  textType,
  iconAlignment,
  textStyle,
  iconSize,
}: TextWithIconProps) {
  return (
    <div style={{ columnGap: '6px', alignItems: 'center', display: 'flex' }}>
      <div>
        {icon && (iconAlignment === 'left' || !iconAlignment) && (
          <span style={{ color: iconColor }}>{icon}</span>
        )}{' '}
      </div>
      <div>
        <Text {...(textType ? { type: textType } : {})} style={textStyle}>
          <span dangerouslySetInnerHTML={{ __html: text as string }} />
        </Text>
      </div>
      <div>
        {icon && iconAlignment === 'right' && (
          <span style={{ color: iconColor, fontSize: iconSize }}>{icon}</span>
        )}{' '}
      </div>
    </div>
  );
}

export default TextWithIcon;
