import { Button, Tour } from 'antd';
import { useEffect, useState } from 'react';

import { IdConstants } from '../../../constants/id.constants';
import type { TourProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../../stores/tour.store';

const tourTitleStyle = {
  margin: 0,
  gap: 8,
};

const TourTitle = () => {
  return (
    <div style={{ margin: 0, paddingTop: 6 }}>
      <h2 style={tourTitleStyle}>Invite Downlines</h2>
    </div>
  );
};

const InviteDownlineTour = () => {
  const inviteDownlineButton = document.getElementById(
    IdConstants.AGENCY.DOWNLINES.INVITE_DOWNLINE_BUTTON + '-agency-downlines'
  );
  const selectionWrapper = document.getElementById(
    IdConstants.AGENTS.INVITE_DOWNLINE.DROP_DOWN + 'Wrapper'
  );
  const emailInput = document.getElementById(
    IdConstants.AGENTS.INVITE_DOWNLINE.EMAIL_ADDRESS
  );
  const amountInputFieldWrapper = document.getElementsByClassName(
    IdConstants.AMOUNT_FIELD
  )?.[0];
  const sendInvite = document.getElementById(
    IdConstants.AGENTS.INVITE_DOWNLINE.SEND_INVITE
  );
  const viewInvitations = document.getElementById(
    IdConstants.AGENCY.DOWNLINES.VIEW_INVITATION_LINK + '-agency-downlines'
  );
  const viewInvitationsTable = document.getElementsByClassName(
    IdConstants.AGENTS.INVITE_DOWNLINE.VIEW_INVITATIONS
  )?.[0];
  const antTableTbody = viewInvitationsTable?.getElementsByClassName(
    IdConstants.ANT_TABLE_TBODY
  )?.[0];
  const firstRow = antTableTbody?.querySelector(
    `.${IdConstants.ANT_TABLE_ROW}:first-child`
  );

  const [currentStep, setCurrentStep] = useState(0);
  const stepsCount = tourStore.inviteDownlineDemoTourPhase === 1 ? 2 : 1;

  useEffect(() => {
    setCurrentStep(tourStore.inviteDownlineDemoTourCurrentStep);
  }, [tourStore.inviteDownlineDemoTourCurrentStep]);

  const resetStore = () => {
    tourStore.setInviteDownlineDemoTourCurrentStep(0);
    tourStore.setInviteDownlineDemoTourVisible(false);
    tourStore.setInviteDownlineDemoTour(false);
    tourStore.setInviteDownlineDemoTourPhase(1);
  };

  const updateCurrentStep = (isNext: boolean) => {
    if (isNext) {
      if (
        currentStep + 1 > stepsCount &&
        tourStore.inviteDownlineDemoTourPhase === 3
      ) {
        resetStore();
      } else if (currentStep + 1 > stepsCount) {
        tourStore.setInviteDownlineDemoTourCurrentStep(0);
        tourStore.setInviteDownlineDemoTourVisible(false);
        tourStore.setInviteDownlineDemoTourPhase(1);
      } else {
        tourStore.setInviteDownlineDemoTourCurrentStep(true);
      }
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      tourStore.setInviteDownlineDemoTourCurrentStep(false);
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  const handlePrevClick = (
    prev: HTMLElement | [HTMLElement, string, number] | null
  ) => {
    const prevElement = Array.isArray(prev) ? prev?.[0] : prev;
    if (!prevElement) return;

    if (Array.isArray(prev)) {
      prevElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(prev?.[1]);
        elem?.click();
      }, prev?.[2]);
    } else {
      prevElement?.click();
    }
    updateCurrentStep(false);
  };

  const handleNextClick = (
    next: HTMLElement | [HTMLElement, string, number] | null,
    selectChild?: boolean
  ) => {
    const nextElement = Array.isArray(next) ? next?.[0] : next;
    if (!nextElement) {
      updateCurrentStep(true);
      return;
    }

    if (Array.isArray(next)) {
      nextElement?.click();
      setTimeout(() => {
        const elem = document.getElementById(next?.[1]);
        elem?.click();
        updateCurrentStep(true);
      }, next?.[2]);
    } else {
      nextElement?.click();
      if (selectChild) {
        const elem = nextElement.querySelector('*') as HTMLElement;
        elem?.click();
      }
      updateCurrentStep(true);
    }
  };

  const navigateTour = (
    prev: HTMLElement | [HTMLElement, string, number] | null,
    next: HTMLElement | [HTMLElement, string, number] | null,
    cb?: (isNext: boolean) => void,
    selectChild?: boolean
  ) => {
    return (
      <div className="antd-navigate-footer">
        <div>
          {currentStep + 1} of {stepsCount + 1}
        </div>
        <div className="flex" style={{ gap: 12 }}>
          <Button
            size="small"
            style={{
              width: 90,
            }}
            onClick={() => {
              resetStore();
            }}
          >
            Skip
          </Button>
          {![0].includes(currentStep) && (
            <Button
              size="small"
              style={{
                width: 90,
              }}
              onClick={() => {
                if (cb) cb(false);
                handlePrevClick(prev);
              }}
            >
              Previous
            </Button>
          )}
          <Button
            size="small"
            style={{
              width: 90,
              paddingLeft: 0,
            }}
            onClick={() => {
              if (cb) cb(true);
              handleNextClick(next, selectChild);
            }}
            type="primary"
          >
            {currentStep === stepsCount ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    );
  };

  const steps: TourProps['steps'] =
    tourStore.inviteDownlineDemoTourPhase === 1
      ? [
          {
            title: <TourTitle />,
            description: (
              <div style={{ paddingBottom: 12 }}>
                Start by clicking on &quot;Invite Downline.&quot;
                {navigateTour(null, inviteDownlineButton)}
              </div>
            ),
            target: inviteDownlineButton,
            placement: 'bottom',
          },
          {
            title: <TourTitle />,
            description: (
              <div style={{ paddingBottom: 12 }}>
                Before sending the invite, ensure downline verification using
                NPN, SSN, or license number.
                {navigateTour(null, emailInput, (isNext: boolean) => {
                  if (isNext) return;
                  window.history.back();
                  setTimeout(() => {
                    tourStore.setInviteDownlineDemoTourCurrentStep(0);
                  }, 10);
                })}
              </div>
            ),
            target: selectionWrapper,
            placement: 'bottom',
          },
          {
            title: <TourTitle />,
            description: (
              <div style={{ paddingBottom: 12 }}>
                Enter the recipient&apos;s email address for the invite.
                {navigateTour(
                  selectionWrapper,
                  emailInput,
                  (isNext: boolean) => {
                    if (isNext) {
                      tourStore.setInviteDownlineDemoTourVisible(false);
                    }
                  }
                )}
              </div>
            ),
            target: emailInput,
            placement: 'bottom',
          },
        ]
      : tourStore.inviteDownlineDemoTourPhase === 2
        ? [
            {
              title: <TourTitle />,
              description: (
                <div style={{ paddingBottom: 12 }}>
                  If desired, allocate a credit amount and confirm by checking
                  the box.
                  {navigateTour(emailInput, null)}
                </div>
              ),
              target: amountInputFieldWrapper as HTMLElement,
              placement: 'bottom',
            },
            {
              title: <TourTitle />,
              description: (
                <div style={{ paddingBottom: 12 }}>
                  Finally, click &quot;Send Invite&quot; to dispatch the
                  invitation to the downline.
                  {navigateTour(amountInputFieldWrapper as HTMLElement, null)}
                </div>
              ),
              target: sendInvite,
              placement: 'bottom',
            },
          ]
        : [
            {
              title: <TourTitle />,
              description: (
                <div style={{ paddingBottom: 12 }}>
                  Access sent invitations by clicking on the designated button.
                  {navigateTour(null, viewInvitations, (isNext: boolean) => {
                    if (!isNext) return;
                    tourStore.setInviteDownlineDemoTourVisible(false);
                  })}
                </div>
              ),
              target: viewInvitations,
              placement: 'bottom',
            },
            {
              title: <TourTitle />,
              description: (
                <div style={{ paddingBottom: 12 }}>
                  Invitations expire after one month. If necessary, revoke an
                  invitation within this timeframe.
                  {navigateTour(null, null, (isNext: boolean) => {
                    if (isNext) return;
                    window.history.back();
                    setTimeout(() => {
                      tourStore.setInviteDownlineDemoTourCurrentStep(0);
                    }, 100);
                  })}
                </div>
              ),
              target: firstRow as HTMLElement,
              placement: 'bottom',
            },
          ];

  return (
    <>
      <Tour
        rootClassName="onboard-antd"
        open={tourStore.inviteDownlineDemoTourVisible}
        onClose={() => {
          resetStore();
        }}
        steps={steps}
        current={currentStep}
      />
      {tourStore.inviteDownlineDemoTourVisible && <div className="overlay" />}
    </>
  );
};

export default observer(InviteDownlineTour);
