/* eslint-disable no-async-promise-executor */
import { AgentsDownlineUpline } from '../types/data/agencyDownline';
import { CancelTokenSource } from 'axios';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { filterRequestBody } from '../utils/common.utils';
import moment from 'moment';

export class UpLineService {
  static host = UrlConstants.backend;

  /**
   * Retrieves agent's upline
   * /upline-downline
   * @param agentId
   * @param token
   * @returns
   */
  static async getUplineAgencyForCurrentAgent(token: string) {
    return new Promise(async (resolve, reject) => {
      const path = '/upline-downline';

      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}/me/upline-agency`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data.data);
      } catch (error: any) {
        console.error(
          'UpLinesService :: getUplineAgencyForCurrentAgent Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Service to get Upline Producers
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getAgentUpline(
    agencyId: string,
    size: number,
    page: number,
    token: string,
    searchString: string
  ) {
    const path = `/upline-downline`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(
            `${
              this.host
            }${path}/${agencyId}/upline-agents?size=${size}&page=${page}&searchString=${encodeURIComponent(
              searchString
            )}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const uplineData =
          response.data.data &&
          response.data.data.map((upline: any) => {
            return {
              id: upline.id,
              name: upline.name,
              npn: upline.npn,
              type: upline.type,
              date_added: moment(upline.dateAdded).format('MMMM DD, YYYY'),
              amount_left: upline.amountLeft,
              total_amount_allocated: upline.totalAmountAllocated,
              account_id: upline.accountId,
            };
          });
        resolve({
          count: response.data.totalCount,
          uplineData: uplineData,
        });
      } catch (error: any) {
        console.error(
          'UpLinesService :: getAllAdmins Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
  /**
   * Service to get Upline Agencies
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getAgenciesUpline(
    reqBody: object,
    size: number,
    page: number,
    token: string,
    searchString: string,
    searchType: string,
    cancelTokenSource?: CancelTokenSource
  ): Promise<AgentsDownlineUpline> {
    const currentAgencyId = localStorage.getItem('x-agency-id');
    const path = `/upline-downline/${currentAgencyId}/upline-downline-agencies`;
    const url_ = new URL(`${this.host}${path}`);
    url_.searchParams.append('type', 'upline');
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (searchType)
      url_.searchParams.append('searchType', encodeURIComponent(searchType));
    filterRequestBody(reqBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'UpLinesService :: getAllAdmins Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Filter For Upline Service
   * @param npn
   * @param token
   * @param email
   * @returns
   */

  static async getFilterDetails(
    bearerToken: string,
    size: number,
    page: number,
    path: string,
    searchString?: string,
    requestBody?: object
    // advancedFilters?: AdvancedFilters
  ): Promise<any> {
    // const path = '/license/my-agency/preflight';
    return new Promise(async (resolve, reject) => {
      try {
        const path = 'upline-downline/upline/filters';
        const AllLicenses = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}/${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'UpLinesService :: getFilterDetails :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
}
