import {
  ActiveLicense,
  AssignmentOptions,
  AssignmentProps,
  ProducerDetails,
  StateLicenses,
} from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { Button, Card, Spin, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LoaDetails, StateDetails } from '../../../types/data/masterData';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../utils/common.utils';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import AssignmentLicenseClass from '../onboardAgents/individualOnboardV2/assignmentLicenseClass';
import AssignmentStateTerritories from '../onboardAgents/individualOnboardV2/assignmentStateTerritories';
import { LicensesService } from '../../../services/licenses.service';
import { ResidencyType } from '../../../enums/residencyType.enum';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import assignmentsImg from '../../../assets/icons/producerAssignment.svg';
import { individualOnboardStore } from '../../../stores/individualOnboardStore';
import { observer } from 'mobx-react-lite';
import { statDetails } from './ProducerStatDetails';
import { useAuth } from '../../../auth/authProvider';

interface ProducerAssignmentsProps extends AssignmentProps {
  producerId: string;
  setStatDetails: React.Dispatch<React.SetStateAction<statDetails>>;
  handleIndividualAssignStates: (
    activeLicenses: ActiveLicense[],
    isBulk?: boolean
  ) => void;
  isLoading: boolean;
  producerDetails?: ProducerDetails;
  masterData: StateDetails[];
  statDetails: statDetails;
  setDisableSave: React.Dispatch<React.SetStateAction<boolean>>;
  isBulk?: boolean;
  isIndividualProducer?: boolean;
}

const ProducerAssignments: React.FC<ProducerAssignmentsProps> = observer(
  ({
    producerId,
    selectedItems,
    stateGroups,
    setSelectedItems,
    setStateGroups,
    isReview = false,
    selectedTerritory,
    setSelectedTerritory,
    selectedState,
    setSelectedState,
    selectedTab,
    setSelectedTab,
    statDetails,
    setStatDetails,
    handleIndividualAssignStates,
    isLoading,
    producerDetails,
    masterData,
    setDisableSave,
    isBulk = false,
    isIndividualProducer = false,
  }) => {
    const { getAccessTokenSilently } = useAuth();
    const [loading, setLoading] = useState(false);
    const [producerLicenseLoader, setProducerLicenseLoader] = useState(false);

    const [searchAssignmentQuery, setSearchAssignmentQuery] = useState('');
    const [searchStateQuery, setSearchStateQuery] = useState('');

    const [producerActiveLicenses, setProducerActiveLicenses] = useState<
      ActiveLicense[]
    >([]);
    const [toggleActiveState, setToggleActiveState] = useState(false);
    const isAssignmentsLoading = loading || producerLicenseLoader || isLoading;

    const fetchStateGroups = async (activeLicenses: ActiveLicense[]) => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await AgencyService.getTerritories(token);
        if (response && response.data) {
          const data = response.data;
          data.sort((a, b) => a.name.localeCompare(b.name));
          data.forEach((item) => {
            item.stateLicenseConfigs.sort((a, b) => {
              const stateNameA = getStateNameFromCode(a.stateCode);
              const stateNameB = getStateNameFromCode(b.stateCode);
              return stateNameA.localeCompare(stateNameB);
            });
          });
          // also update it in the individual onboarding assignments -> IndividualOnboardAssignments.tsx
          const masterData = [
            ...appStateInfoStore.getLicenseConfigsMasterData(),
          ];
          const updatedActiveLicenseInTerritories = data.map((d) => {
            const stateLicenseConfigs = d.stateLicenseConfigs.map((data) => {
              const isActiveState = activeLicenses.find(
                (d) => d.stateCode === data.stateCode
              );

              if (!isActiveState) return data;
              const masterDataState = masterData.find(
                (d) => d.stateCode === data.stateCode
              );
              const updatedLicenseConfigs = data.licenseConfigs?.map((d) => {
                if (d.lcCode !== isActiveState.licenseClassCode) return d;

                const masterDataLcs = masterDataState?.licenseClasses?.find(
                  (data) => data.lcCode === isActiveState.licenseClassCode
                );

                const updatedConfigs: string[] = [];
                const activeLoas: string[] = [];

                isActiveState?.loaDetails?.forEach((data) => {
                  const activeLoa = masterDataLcs?.loaData?.find(
                    (d) => d.loaCode === data.loaCode
                  );
                  if (activeLoa) {
                    activeLoas.push(activeLoa.loaCode);
                  }
                  if (activeLoa && activeLoa?.referenceLicenseConfig) {
                    updatedConfigs.push(activeLoa?.referenceLicenseConfig);
                  }
                });

                const loas = masterDataLcs?.loaData
                  ?.filter((d) => {
                    if (
                      d?.referenceLicenseConfig &&
                      updatedConfigs.includes(d?.id || '')
                    )
                      return false;
                    return activeLoas.includes(d.loaCode) || !d.isRetired;
                  })
                  ?.map((d) => ({
                    id: d.id,
                    isRetired: d.isRetired,
                    loaCode: d.loaCode,
                    loaName: d.loa,
                    referenceLicenseConfig: d.referenceLicenseConfig,
                    residencyType: d.residencyType,
                  }));

                return { ...d, loaConfigs: loas! };
              });
              return {
                ...data,
                licenseConfigs: updatedLicenseConfigs,
              };
            });
            return { ...d, stateLicenseConfigs };
          });

          setStateGroups(
            updatedActiveLicenseInTerritories.map((d) => ({
              ...d,
              isSelected: false,
            }))
          );
          handleIndividualAssignStates(activeLicenses, isBulk);
          setStatDetails((prev) => ({
            ...prev,
            totalTerriotories: updatedActiveLicenseInTerritories?.length || 0,
          }));
          setSelectedTerritory(
            updatedActiveLicenseInTerritories?.[0]?.name || ''
          );
          setSelectedState(
            getStateNameFromCode(
              updatedActiveLicenseInTerritories?.[0]?.stateLicenseConfigs?.[0]
                ?.stateCode
            )
          );
        } else {
          setStateGroups([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchProducerActiveLicense = async () => {
      if (!producerId) return;
      setProducerLicenseLoader(true);
      try {
        const token = await getAccessTokenSilently();
        let response: ActiveLicense[] = [];
        if (isIndividualProducer) {
          response = await LicensesService.getMyActiveLicense(token);
          handleIndividualAssignStates(response, false);
        } else {
          response = await LicensesService.getActiveLicenseForProducers(
            token,
            producerId
          );
        }
        if (response) {
          setProducerActiveLicenses(response);
          individualOnboardStore.setProducerActiveLicenses(response);
        }
        setStatDetails((prev) => ({
          ...prev,
          activeStates: response?.length || 0,
        }));
        if (!isIndividualProducer) fetchStateGroups(response);
      } catch (error) {
        individualOnboardStore.setProducerActiveLicenses([]);
        console.error(error);
      } finally {
        setProducerLicenseLoader(false);
      }
    };

    useEffect(() => {
      if (isBulk) fetchStateGroups([]);
      else {
        if (!producerId) return;
        fetchProducerActiveLicense();
      }
    }, [producerId]);

    useEffect(() => {
      setDisableSave(isAssignmentsLoading);
    }, [isAssignmentsLoading]);

    const getActiveLicenseStates = () => {
      return producerActiveLicenses?.map((al) => al.stateCode);
    };

    const getFilteredStateGroupItems = () => {
      return stateGroups
        .filter(
          (d) =>
            d.name
              ?.toLowerCase()
              ?.trim()
              ?.includes(searchAssignmentQuery?.trim()?.toLowerCase()) &&
            // in review mode, need to show only selected territories
            (isReview ? d.isSelected : true)
        )
        .map((d) => d.name);
    };

    const areAllLicenseClassesResidentTypeRForhomeState = (
      currentStateCode: string
    ): boolean => {
      const homeState = producerDetails?.residentState;
      const licenses = masterData
        ?.filter(
          (data) => getStateNameFromCode(data.stateCode) === currentStateCode
        )
        ?.flatMap((d) => d.licenseClasses);
      return (
        licenses.length > 0 &&
        ![homeState].includes(currentStateCode) &&
        licenses.every((licenseClass) => licenseClass.residencyType === 'R')
      );
    };

    const getFilteredStateItems = () => {
      if (selectedTab === AssignmentOptions.TERRITORIES) {
        return stateGroups
          ?.filter((d) => d.name === selectedTerritory)?.[0]
          ?.stateLicenseConfigs?.filter((d) =>
            getStateNameFromCode(d.stateCode)
              ?.toLowerCase()
              ?.trim()
              ?.includes(searchStateQuery?.trim()?.toLowerCase())
          )
          ?.map((d) => ({
            code: d.stateCode,
            name: getStateNameFromCode(d.stateCode),
          }));
      }

      return masterData
        ?.map((data) => {
          const isDisabled = areAllLicenseClassesResidentTypeRForhomeState(
            data.stateCode
          );
          return {
            code: data.stateCode,
            name: getStateNameFromCode(data.stateCode),
            isDisabled,
            tooltipText: isDisabled
              ? 'This state cannot be selected as there are no matching license classes available.'
              : null,
          };
        })
        .filter(
          (data) =>
            data.name
              ?.toLowerCase()
              ?.trim()
              ?.includes(searchStateQuery?.trim()?.toLowerCase()) &&
            // in review mode, need to show only selected states
            (isReview
              ? selectedItems.map((data) => data.name).includes(data.name)
              : true)
        );
    };

    const getLicensedData = () => {
      // for territories
      if (selectedTab === AssignmentOptions.TERRITORIES) {
        const selectedStateLicenseDetails =
          stateGroups
            ?.filter((d) => d.name === selectedTerritory)?.[0]
            // state has to be filter based on the input value in the search
            ?.stateLicenseConfigs?.filter(
              (d) =>
                getStateNameFromCode(d.stateCode)
                  ?.toLowerCase()
                  ?.trim()
                  ?.includes(searchStateQuery?.trim()?.toLowerCase()) &&
                selectedState === getStateNameFromCode(d.stateCode)
            )
            ?.map((d) => {
              return {
                ...d,
                licenseConfigs: d.licenseConfigs.map((d) => ({
                  ...d,
                  licenseClass: d.lcName,
                  loaData: d.loaConfigs,
                })),
              };
            }) || [];

        return selectedStateLicenseDetails?.[0] || [];
      }

      // if its not territory show all the states from master data
      const filteredLicenseConfgs = masterData
        .filter(
          (data) => getStateNameFromCode(data.stateCode) === selectedState
        )
        ?.map((d) => ({
          licenseConfigs: d.licenseClasses,
          stateCode: d.stateCode,
        }));

      const stateCode = filteredLicenseConfgs?.[0]?.stateCode;

      return {
        licenseConfigs: filteredLicenseConfgs?.[0]?.licenseConfigs,
        stateCode,
      };
    };

    //  it takes true for right click and false for left click
    const handleClick = (type: boolean) => {
      const isRight = type ? 1 : -1;
      const states = getFilteredStateItems();
      const currentIndex = states.findIndex(
        (state) => state?.name === selectedState
      );
      const currentStateCode = states?.filter(
        (state) => state.name === states[currentIndex + isRight]?.name
      )?.[0]?.code;
      if (currentStateCode) {
        setSelectedState(getStateNameFromCode(currentStateCode));
      }
    };

    const selectCoupledConfigs = (
      id: string,
      allLoaDetails: LoaDetails[],
      selected?: {
        name: string;
        loaCode: string | undefined;
        id?: string | undefined;
      }[]
    ): any => {
      const licenseConfigs = appStateInfoStore
        ?.getLicenseConfigs()
        ?.find((d) => d.id === id);

      if (!licenseConfigs || !licenseConfigs.isCoupled) return [];

      if (selected) {
        const isSuperSet =
          licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
        if (isSuperSet) {
          const subsets = licenseConfigs?.referenceCoupledConfiguration?.subSet;
          return allLoaDetails
            ?.filter((d) => subsets?.includes(d.id!))
            ?.map((d) => {
              return {
                name: d?.loaName || '',
                loaCode: d?.loaCode,
                id: d.id,
              };
            });
        } else {
          const superSet =
            licenseConfigs?.referenceCoupledConfiguration?.superSet;

          // supset of the superset
          const subsets =
            appStateInfoStore
              ?.getLicenseConfigs()
              ?.find((d) => superSet?.includes(d.id!))
              ?.referenceCoupledConfiguration?.subSet || [];

          const isAllSubsetSelected = subsets?.every((d) =>
            selected?.map((d) => d.id)?.includes(d)
          );

          if (!isAllSubsetSelected) return [];

          return allLoaDetails
            ?.filter((d) => superSet?.includes(d.id!))
            ?.map((d) => {
              return {
                name: d?.loaName || '',
                loaCode: d?.loaCode,
                id: d.id,
              };
            });
        }
      } else {
        const isSuperSet =
          licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
        if (isSuperSet) {
          return [];
        } else {
          return licenseConfigs?.referenceCoupledConfiguration?.superSet;
        }
      }
    };

    const handleCheckboxChange = (
      lcCode: string,
      loaName: string,
      loaDetails: { loaId: string; id: string } | null,
      checked: boolean,
      stateName: string,
      lcName: string,
      allLoaDetails: LoaDetails[]
    ) => {
      // while checked
      if (checked) {
        const isStateChecked = selectedItems
          .map((data) => data.name)
          ?.includes(stateName);
        // state is already selected
        if (isStateChecked) {
          setSelectedItems((prev) => {
            return prev.map((data) => {
              if (data.name === stateName) {
                // add loas only if the handle change is triggered by loa
                const selectedLoas = [
                  ...(data?.[lcCode]?.loas || []),
                  {
                    name: loaName,
                    loaCode: loaDetails?.loaId,
                    id: loaDetails?.id,
                  },
                ];
                const loas = loaDetails?.loaId
                  ? [
                      ...selectedLoas,
                      ...selectCoupledConfigs(
                        loaDetails?.id,
                        allLoaDetails!,
                        selectedLoas
                      ),
                    ]
                  : allLoaDetails?.map((d) => ({
                      // if lc selected
                      name: d?.loaName || d?.loa || '',
                      loaCode: d?.loaCode,
                      id: d.id,
                    })) || [];
                return {
                  ...data,
                  [lcCode]: {
                    name: lcName,
                    loas,
                  },
                };
              }
              return data;
            });
          });
        } else {
          // if state is not selected,
          // 1. add state and lc and loa if loa is selected
          // 2. add state and lc if lc is selected
          const selectedLoas = [
            {
              name: loaName,
              loaCode: loaDetails?.loaId,
              id: loaDetails?.id,
            },
          ];
          const loas = loaDetails?.loaId
            ? [
                ...selectedLoas,
                ...selectCoupledConfigs(
                  loaDetails?.id,
                  allLoaDetails!,
                  selectedLoas
                ),
              ]
            : allLoaDetails?.map((d) => ({
                // if lc selected
                name: d?.loaName || d?.loa || '',
                loaCode: d?.loaCode,
                id: d.id,
              })) || [];
          setSelectedItems((prev) => [
            ...prev,
            {
              name: stateName,
              [lcCode]: {
                name: lcName,
                loas,
              },
            } as StateLicenses,
          ]);
        }
      } else {
        // while uncheck
        setSelectedItems((prev) => {
          return prev.map((data) => {
            if (data.name === stateName) {
              // if loa uncheck
              if (loaDetails?.loaId) {
                data = {
                  ...data,
                  [lcCode]: {
                    ...(data?.[lcCode] || []),
                    //  then filter the loa alone
                    loas: data?.[lcCode]?.loas?.filter(
                      (d) =>
                        d.loaCode !== loaDetails.loaId &&
                        !selectCoupledConfigs(
                          loaDetails?.id,
                          allLoaDetails!
                        )?.includes(d.id as string)
                    ),
                  },
                };
                return { ...data };
              }
              //  if lc uncheck , delete the lc with loa details
              delete data?.[lcCode];
              return {
                ...data,
              };
            }
            return data;
          });
        });
      }
    };

    useEffect(() => {
      setStatDetails((prev) => ({
        ...prev,
        statesSelected: selectedItems?.length || 0,
        territoriesSelected:
          stateGroups?.filter((data) => data.isSelected)?.length || 0,
      }));
    }, [selectedItems, JSON.stringify(stateGroups)]);

    const onSelectActiveLicenses = () => {
      if (!toggleActiveState) {
        const alreadySelectedStates: string[] = [];
        stateGroups
          .filter((d) => d.isSelected)
          ?.forEach((d) => {
            d.stateLicenseConfigs?.forEach((data) => {
              alreadySelectedStates.push(data.stateCode);
            });
          });
        selectedItems?.forEach((d) => {
          alreadySelectedStates.push(getStateCodeFromName(d.name) || '');
        });
        const toBeSelectedStates = producerActiveLicenses?.filter(
          (d) => !alreadySelectedStates.includes(d.stateCode)
        );
        const activeStateSelected: StateLicenses[] = [];
        toBeSelectedStates.forEach((d) => {
          const state: any = {};
          state['name'] = getStateNameFromCode(d.stateCode);
          state[d.licenseClassCode] = {
            name: d.licenseClass,
            loas: d.loaDetails.map((d) => ({
              name: d.loa,
              loaCode: d.loaCode,
              id: d.id,
            })),
          };
          activeStateSelected.push(state);
        });
        const updatedSelectedStateItems = selectedItems.map((data) => {
          const isActive = producerActiveLicenses?.find(
            (d) => d.stateCode === getStateCodeFromName(data.name)
          );
          if (!isActive) return data;
          const state: any = data;
          if (Object.keys(data)?.includes(isActive?.licenseClassCode)) {
            state[isActive.licenseClassCode] = {
              ...state,
              loas: [
                ...(state?.[isActive.licenseClassCode]?.loas || {}),
                ...(isActive?.loaDetails?.map((d) => ({
                  name: d.loa,
                  loaCode: d.loaCode,
                  id: d.id,
                })) || {}),
              ],
            };
            return state;
          } else {
            state[isActive.licenseClassCode] = {
              name: isActive.licenseClass,
              loas: isActive.loaDetails.map((d) => ({
                name: d.loa,
                loaCode: d.loaCode,
                id: d.id,
              })),
            };
            return state;
          }
        });
        setSelectedItems((prev) => [
          ...updatedSelectedStateItems,
          ...activeStateSelected,
        ]);
      } else {
        const activeStateName = producerActiveLicenses?.map((d) =>
          getStateNameFromCode(d.stateCode)?.toLowerCase()
        );
        const items: any = [];
        selectedItems.forEach((d) => {
          const state: any = d;
          if (!activeStateName.includes(d.name?.toLowerCase())) {
            items.push(d);
          } else {
            const activeSelected = producerActiveLicenses.filter(
              (data) =>
                getStateNameFromCode(data.stateCode)?.toLowerCase() ===
                d?.name?.toLowerCase()
            )?.[0];
            state[activeSelected?.licenseClassCode] = {
              ...(state[activeSelected?.licenseClassCode] || {}),
              loas: state[activeSelected?.licenseClassCode]?.loas?.filter(
                (d: any) =>
                  !activeSelected?.loaDetails
                    ?.map((d) => d.loaCode)
                    ?.includes(d.loaCode)
              ),
            };
            if (!state[activeSelected?.licenseClassCode]?.loas?.length) {
              delete state[activeSelected?.licenseClassCode];
            }
            if (Object.keys(state)?.length > 1) {
              items.push(state);
            }
          }
        });
        setSelectedItems(items);
      }
      setToggleActiveState((prev) => !prev);
    };

    return (
      <>
        <Card className="individual-onboard-assignment-card figtree mt-3 h-[calc(100vh-230px)]">
          <div className="figtree flex justify-between items-center mb-[18px]">
            <div className="flex items-center gap-x-3 leading-7 font-semibold text-base">
              <img src={assignmentsImg} alt="producer assignments" />
              Assign States {isIndividualProducer ? null : '& Territories'}
            </div>
            {isReview || isBulk ? null : (
              <Tooltip
                title={
                  isAssignmentsLoading || statDetails?.activeStates
                    ? null
                    : 'No Active states present for this producer'
                }
              >
                <Button
                  className="figtree"
                  style={{
                    background: 'var(--Secondary-Secondary, #001F45)',
                    color: '#ffffff',
                    fontSize: 14,
                    lineHeight: '22px',
                    fontWeight: 500,
                    opacity:
                      isAssignmentsLoading || !statDetails?.activeStates
                        ? 0.8
                        : 1,
                  }}
                  disabled={isAssignmentsLoading || !statDetails?.activeStates}
                  onClick={() => onSelectActiveLicenses()}
                >
                  {toggleActiveState ? 'Unselect' : 'Select'} Active States
                </Button>
              </Tooltip>
            )}
          </div>
          {isAssignmentsLoading ? (
            <div className="grid place-content-center h-full">
              <Spin />
            </div>
          ) : (
            <div className="flex gap-[18px]">
              {/* territories */}
              {isIndividualProducer ? null : (
                <AssignmentStateTerritories
                  isCheckboxNeeded={!isReview}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  isAssignmentPage={true}
                  searchQuery={searchAssignmentQuery}
                  setSearchQuery={setSearchAssignmentQuery}
                  selectedCount={stateGroups.filter((d) => d.isSelected)}
                  placeHolder="Territories" // DND
                  selectedItems={selectedItems}
                  currentSelected={selectedTerritory}
                  filteredItems={getFilteredStateGroupItems() || []}
                  isIndividualProducer={isIndividualProducer}
                  onCheck={(e, t) => {
                    let selectedItem: StateLicenses[] = selectedItems;
                    const updatedStateGroups = stateGroups.map((d) => {
                      if (d.name === t) {
                        // while selecting the state group, remove the states from state selection if its already selected
                        if (e) {
                          const states: string[] = [];
                          d.stateLicenseConfigs.forEach((d) => {
                            const stateName = getStateNameFromCode(d.stateCode);
                            states.push(stateName);
                          });
                          selectedItem = selectedItems.filter(
                            (d) => !states.includes(d.name)
                          );
                        }
                        return { ...d, isSelected: e };
                      }
                      return d;
                    });
                    setStateGroups(() => updatedStateGroups);
                    setSelectedItems(() => selectedItem);
                  }}
                  onSelect={(e, type) => {
                    if (type === 'State') {
                      setSelectedState(e);
                    } else {
                      setSelectedTerritory(e as string);
                      setSelectedState(
                        getStateNameFromCode(
                          stateGroups.filter((d) => d.name === e)?.[0]
                            ?.stateLicenseConfigs?.[0]?.stateCode
                        )
                      );
                    }
                  }}
                  type="Territories"
                  activeState={getActiveLicenseStates()}
                />
              )}
              {/* states */}
              <AssignmentStateTerritories
                searchQuery={searchStateQuery}
                setSearchQuery={setSearchStateQuery}
                isCheckboxNeeded={!isReview}
                selectedCount={
                  selectedTab === AssignmentOptions.TERRITORIES
                    ? []
                    : selectedItems
                }
                isAssignmentPage={true}
                disabledItems={
                  // for disabling the states if any territory selected which contains the current state
                  selectedTab === AssignmentOptions.TERRITORIES
                    ? []
                    : stateGroups
                        .filter((d) => d.isSelected)
                        ?.flatMap((d) => d.stateLicenseConfigs)
                        ?.map((d) => getStateNameFromCode(d.stateCode))
                }
                placeHolder="States"
                currentSelected={selectedState}
                filteredItems={getFilteredStateItems() || []}
                onCheck={(e, selectedState) => {
                  // selection of states not applicable to territories
                  if (selectedTab === AssignmentOptions.TERRITORIES) return;

                  if (e) {
                    setSelectedItems((prev) => [
                      ...prev,
                      { name: selectedState } as StateLicenses,
                    ]);
                  } else {
                    setSelectedItems((prev) =>
                      prev.filter((d) => d.name !== selectedState)
                    );
                  }
                }}
                onSelect={(e) => {
                  setSelectedState(e);
                }}
                type="State"
                {...(selectedTab === AssignmentOptions.TERRITORIES
                  ? {
                      checkBoxProps: {
                        disabled: true,
                        checked: true,
                      },
                    }
                  : {})}
                activeState={getActiveLicenseStates()}
              />
              <div
                className="w-full rounded-lg border border-[#D6E4EB] p-8 gap-8 bg-white box-border overflow-y-auto flex-1"
                style={{ height: 'calc(100vh - 316px)' }}
              >
                <div className="w-full h-14 flex justify-between items-center gap-5">
                  <div className="flex flex-col w-full h-full mb-5">
                    <p className="font-poppins font-medium text-xs text-[#07212D] leading-5 m-0">
                      Current State:
                    </p>
                    <p className="font-poppins font-medium text-[26px] text-[#001F45] mt-1.5 leading-[28px]">
                      {selectedState}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 mb-5">
                    <Button
                      icon={<LeftOutlined />}
                      onClick={() => handleClick(false)}
                      disabled={
                        selectedState === getFilteredStateItems()?.[0]?.name
                      }
                      className="w-9 h-9 rounded-md bg-[#E8EBEE] px-[1px] flex items-center justify-center ml-5"
                    />
                    <Button
                      icon={<RightOutlined style={{ color: 'white' }} />}
                      onClick={() => handleClick(true)}
                      disabled={
                        selectedState ===
                        getFilteredStateItems()?.[
                          getFilteredStateItems()?.length - 1
                        ]?.name
                      }
                      className="w-9 h-9 rounded-md bg-[#00A6FB] px-[1px] flex items-center justify-center"
                    />
                  </div>
                </div>
                <div
                  style={{
                    overflowY: 'auto',
                  }}
                >
                  <Spin spinning={loading || producerLicenseLoader}>
                    {getLicensedData()?.licenseConfigs?.map((d) => {
                      const stateCode = getLicensedData()?.stateCode;
                      const stateName = getStateNameFromCode(stateCode);
                      const isSelectedInTerritory = stateGroups
                        .filter((d) => d.isSelected)
                        ?.flatMap((d) => d.stateLicenseConfigs)
                        ?.find((d) => d.stateCode === stateCode);

                      let selectedLoaCodes: string[] = [];
                      // if its selected in territory, select only the territory loas
                      if (isSelectedInTerritory) {
                        selectedLoaCodes =
                          isSelectedInTerritory?.licenseConfigs
                            ?.find((lc) => lc?.lcCode === d?.lcCode)
                            ?.loaConfigs?.map((d) => d?.loaCode) || [];
                      } else {
                        // all the selected state loas
                        selectedLoaCodes =
                          selectedItems
                            ?.filter((d) => d.name === stateName)?.[0]
                            ?.[d.lcCode]?.loas?.map((d) => d.loaCode) || [];
                      }
                      const activeLicensepresent = producerActiveLicenses?.find(
                        (activeLicense) =>
                          activeLicense?.stateCode === stateCode &&
                          activeLicense.licenseClassCode === d.lcCode
                      );
                      const disableTheStateCardForRLicenses =
                        areAllLicenseClassesResidentTypeRForhomeState(
                          stateCode
                        );
                      const homeState = producerDetails?.residentState;
                      const residencyType =
                        d?.residencyType || d?.loaData?.[0]?.residencyType;
                      const isDisabled = disableTheStateCardForRLicenses
                        ? 'This state cannot be selected as there are no matching license classes available.'
                        : !homeState?.includes(stateCode) &&
                            residencyType === ResidencyType.Resident
                          ? 'This configuration cannot be applied to the producer as it is designated for residents'
                          : homeState?.includes(stateCode) &&
                              residencyType === ResidencyType.NonResident
                            ? 'This configuration cannot be applied to the producer as it is designated for non-residents'
                            : null;

                      const isTerritoriesTab =
                        selectedTab === AssignmentOptions.TERRITORIES;

                      return (
                        <AssignmentLicenseClass
                          key={d.lcCode}
                          residencyType={d.residencyType}
                          licenseClassCode={d.lcCode}
                          isLicenseChecked={
                            isTerritoriesTab
                              ? !isDisabled
                              : !!selectedItems?.filter(
                                  (d) => d.name === stateName
                                )?.[0]?.[d.lcCode] ||
                                (isSelectedInTerritory &&
                                  selectedLoaCodes?.length &&
                                  !isDisabled) // no loa is selected, don't select the license
                          }
                          isCheckboxNeeded={!isReview} // In Review Mode
                          disableCheckBox={
                            isTerritoriesTab ||
                            !!isSelectedInTerritory || // if territories disable
                            !!isDisabled
                          }
                          tooltipText={isDisabled}
                          text={`${d.licenseClass} (${d.lcCode})`}
                          stateCode={stateCode}
                          LoaDetails={
                            isTerritoriesTab || !isReview
                              ? d.loaData
                              : d.loaData.filter((d) =>
                                  selectedLoaCodes.includes(d.loaCode)
                                )
                          }
                          handleCheckboxChange={(lc, loaName, loa, isChecked) =>
                            handleCheckboxChange(
                              lc,
                              loaName,
                              loa,
                              isChecked,
                              stateName,
                              d.licenseClass,
                              d.loaData
                            )
                          }
                          selectedIds={
                            isTerritoriesTab || isSelectedInTerritory
                              ? isDisabled
                                ? []
                                : isTerritoriesTab
                                  ? d.loaData?.map((d) => d.loaCode) || [] // in territory view, select all loas
                                  : d.loaData
                                      ?.filter((d) =>
                                        selectedLoaCodes?.includes(d?.loaCode)
                                      )
                                      ?.map((d: any) => d?.loaCode) || [] // in states view, select all loas which are in the territory
                              : selectedLoaCodes
                          }
                          activeLicense={activeLicensepresent}
                        />
                      );
                    })}
                  </Spin>
                </div>
              </div>
            </div>
          )}
        </Card>
      </>
    );
  }
);

export default ProducerAssignments;
