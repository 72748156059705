import { ProfileLabelProps } from '../../types/common/profile.type';
import { twMerge } from 'tailwind-merge';

const ProfileLabel: React.FC<ProfileLabelProps> = ({ className, label }) => {
  return (
    <div
      className={twMerge(
        'w-[78px] h-[78px] grid place-content-center bg-[#B5E6FF] text-[#0074B0] font-poppins font-medium leading-[44.57px] text-[33.43px] rounded-[278.57px]',
        className
      )}
    >
      {label?.firstName?.[0] || ''}
      {label?.lastName?.[0] || ''}
    </div>
  );
};

export default ProfileLabel;
