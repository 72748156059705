import { Button, Typography, notification } from 'antd';

import { OnboardingLayout } from '../../../components/layout/onboardingLayout';
import { SignupService } from '../../../services/signup.service';
import drawIcon from '../../../assets/icons/draw.svg';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../authProvider';

function OnboardedMobile() {
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();

  const showError = (
    message: string,
    description: string,
    isWarning: boolean = false
  ) => {
    api[isWarning ? 'warning' : 'error']({
      message: message,
      description: description,
    });
  };

  const showSuccess = (message: string) => {
    api['success']({
      message: message,
    });
  };

  const sendOnboardedMailMobile = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (token) {
        const responseMessage =
          await SignupService.sendOnboardedMailMobile(token);
        showSuccess(responseMessage);
      }
    } catch (error) {
      showError('Error', 'Error while sending email');
    }
  };

  return (
    <div>
      {contextHolder}
      <OnboardingLayout flowType="Onboarding">
        <div className="responsive-container items-center ">
          <div className="form-header pt-10 flex flex-col gap-[22px]">
            <img src={drawIcon} alt="Draw" />
            <div>
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                Thanks for getting started!
              </Typography.Paragraph>
              <Typography.Paragraph style={{ fontWeight: 600 }}>
                InsureTrek is desktop only for now.{' '}
              </Typography.Paragraph>
              <Typography.Paragraph>
                Click the below button to email yourself a link so you can get
                started next time you’re on a bigger screen.
              </Typography.Paragraph>
            </div>
            <div>
              <Button
                style={{
                  padding: '0 24px',
                  height: '36px',
                  borderRadius: '8px',
                  backgroundColor: '#00A6FB',
                  color: '#fff',
                }}
                onClick={sendOnboardedMailMobile}
              >
                Send Email
              </Button>
            </div>
          </div>
        </div>
      </OnboardingLayout>
    </div>
  );
}

export default observer(OnboardedMobile);
