import { Button, Typography } from 'antd';

import { AdminInvitesService } from '../../../services/adminInvites.service';
import AdminSignUpLayout from '../../../components/signUp/layouts/signUpLayout';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import { InviteResponseEnum } from '../../../enums/invite-response.enum';
import Loading from '../../../components/loading';
import { RouteConstants } from '../../../constants/routes.constants';
import { SignUpSteps } from '../../../enums/signupPage.enum';
import sideScreen from '../../../assets/images/home-screen-wallpaper.png';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';

function AdminInvite() {
  const { getAccessTokenSilently } = useAuth();
  const { refreshToken } = useAuth();
  const navigate = useNavigate();

  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  const toggleLoading = (AcceptOrReject: InviteResponseEnum, flag: boolean) => {
    if (AcceptOrReject === InviteResponseEnum.ACCEPTED) {
      setIsAcceptLoading(flag);
    } else {
      setIsRejectLoading(flag);
    }
  };

  const handleInvite = async (AcceptOrReject: InviteResponseEnum) => {
    try {
      toggleLoading(AcceptOrReject, true);
      const accessToken = await getAccessTokenSilently();
      const resp = await DownlineInviteService.updateInviteStatus(
        signupStore.adminInviteDetails?.inviteToken || '',
        accessToken,
        AcceptOrReject === InviteResponseEnum.ACCEPTED ? true : false
      );
      if (resp) {
        signupStore.setInviteType(null);
        signupStore.setIsInvited(false);
        if (AcceptOrReject === InviteResponseEnum.REJECTED) {
          await refreshToken(true);
          navigate(RouteConstants.onboarding.path);
        } else {
          signupStore.setIsAdminAuthorized(true);
          await refreshToken(true);
          navigate(RouteConstants.allAgents.path);
        }
      }
    } catch (error) {
      console.error(error);
      signupStore.setStep(SignUpSteps.ADMIN_PROFILE_1);
    } finally {
      toggleLoading(AcceptOrReject, false);
    }
  };

  return (
    <AdminSignUpLayout>
      <div className="admin-invite-screen">
        <div style={{ width: '45%' }}>
          <hr
            style={{
              backgroundColor: '#DFF4F5',
              width: '100%',
              textAlign: 'left',
              marginLeft: '0px',
              marginTop: '35%',
              border: '0.01px solid #DFF4F5',
            }}
          />

          <Typography.Paragraph
            style={{
              fontSize: '32px',
              fontWeight: 500,
              marginTop: '5%',
            }}
          >
            You have been invited by
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              fontSize: '32px',
              fontWeight: 500,
              marginTop: '-5%',
            }}
          >
            <span style={{ color: 'var(--primary-color)', fontWeight: 600 }}>
              {signupStore.adminInviteDetails?.agencyName}
            </span>{' '}
            as an admin.
          </Typography.Paragraph>

          <div>
            <Button
              id="accept-invite-button"
              className="button secondary-button"
              size="large"
              value={InviteResponseEnum.ACCEPTED}
              style={{
                marginTop: '30px',
                width: '100%',
              }}
              onClick={() => handleInvite(InviteResponseEnum.ACCEPTED)}
              loading={isAcceptLoading}
              disabled={isRejectLoading}
            >
              Accept Invite
            </Button>

            <Button
              id="reject-invite-button"
              size="large"
              style={{
                marginTop: '20px',
                width: '100%',
                color: 'var(--secondary-color)',
                background: '#fff',
                borderColor: 'var(--border-color)',
              }}
              value={InviteResponseEnum.REJECTED}
              onClick={() => handleInvite(InviteResponseEnum.REJECTED)}
              loading={isRejectLoading}
              disabled={isAcceptLoading}
            >
              Reject Invite
            </Button>
          </div>
        </div>
        <div style={{ width: '45%' }}>
          <img alt="screen" style={{ width: '100%' }} src={sideScreen} />
        </div>
      </div>
    </AdminSignUpLayout>
  );
}

export default AdminInvite;
