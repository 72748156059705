import {
  Collapse,
  Radio,
  Skeleton,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { groupBy, isEmpty } from 'lodash';

import { Appointments } from './agentSpecificRTS';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { TagStyle } from '../../agency/agencyRts/agencyRts';
import { getLcDetailsForLoaAndState } from '../../../utils/getLcDetailsForLoa&State.utils';
import { getStateNameFromCode } from '../../../utils/common.utils';

const { Panel } = Collapse;

interface RTSCollapseCardProps {
  appointments: Appointments[];
  accordianLoader: boolean;
  hideBioInfo: boolean;
}

const RTSCollapseCard: React.FC<RTSCollapseCardProps> = ({
  appointments,
  accordianLoader,
  hideBioInfo,
}) => {
  const [isStateMode, setIsStateMode] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState<string[]>([]);

  const filteredAppointMents = appointments.filter(
    (appointment) =>
      (appointment?.appointmentLicenseConfiguration?.loaCode !== '' ||
        appointment?.appointmentLicenseConfiguration?.licenseConfiguration
          ?.loaCode !== '') &&
      appointment.status !== 'Terminated'
  );

  const groupedAppointments = isStateMode
    ? groupBy(filteredAppointMents, 'stateCode')
    : groupBy(filteredAppointMents, 'companyName');

  const groupedAppointments2Level: any = {};
  Object.keys(groupedAppointments).map((key) => {
    const level1Grouping = isStateMode
      ? groupBy(groupedAppointments[key], 'companyName')
      : groupBy(groupedAppointments[key], 'stateCode');

    Object.keys(level1Grouping).forEach((level1Key: any) => {
      if (level1Key) {
        level1Grouping[level1Key].forEach((value: any) => {
          const isSupported =
            value?.appointmentLicenseConfiguration?.isSupported;
          const loaCode = isSupported
            ? value?.appointmentLicenseConfiguration?.licenseConfiguration
                ?.loaCode
            : value?.appointmentLicenseConfiguration?.loaCode;
          const lcDetails = getLcDetailsForLoaAndState(
            loaCode,
            value?.stateCode
          );
          value.lcCode = lcDetails?.lcCode;
          value.licenseClass = lcDetails?.licenseClass;
        });
        level1Grouping[level1Key] = groupBy(
          level1Grouping[level1Key],
          'licenseClass'
        ) as any;
      }
    });
    groupedAppointments2Level[key] = level1Grouping;
  });

  const columns: TableColumnsType<Appointments> = [
    {
      dataIndex: 'loa',
      title: 'LOA',
      key: 'loa',
      width: '25%',
      render: (text, record) => {
        const isSupported =
          record?.appointmentLicenseConfiguration?.isSupported;

        const loaCode = isSupported
          ? record?.appointmentLicenseConfiguration?.licenseConfiguration
              ?.loaCode
          : record?.appointmentLicenseConfiguration?.loaCode;
        const loa = isSupported
          ? record?.appointmentLicenseConfiguration?.licenseConfiguration?.loa
          : record?.appointmentLicenseConfiguration?.loa;
        const lcDetails = getLcDetailsForLoaAndState(
          loaCode || '',
          record?.stateCode
        );
        if (!loaCode && !loa) {
          return <span className="pl-24">-</span>;
        }
        return lcDetails?.licenseClass ? (
          <Tooltip title="Active LOA">
            <Tag color="var(--tag-success-color)" style={TagStyle}>
              <span style={{ color: 'var(--black-color)' }}>
                {loa} ({loaCode})
              </span>
            </Tag>
          </Tooltip>
        ) : (
          <Tooltip title="LC missing">
            <Tag color="var(--tag-failure-color)" style={TagStyle}>
              <span style={{ color: 'var(--black-color)' }}>
                {loa} ({loaCode})
              </span>
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'statusReasonDate',
      title: 'Status Date',
      width: '15%',
      key: 'statusReasonDate',
      render: (text, record) => {
        if (!text) return <span className="pl-24">-</span>;
        const dateObject = new Date(text);
        return dateObject.toLocaleDateString('en-US');
      },
    },
    {
      dataIndex: 'appointmentRenewalDate',
      title: 'Appointment Renewal Date',
      width: '15%',
      key: 'appointRenewalDate',
      render: (text, record) => {
        if (!text || record.status === 'Terminated')
          return <span className="pl-24">-</span>;
        const dateObject = new Date(text);
        return dateObject.toLocaleDateString('en-US');
      },
    },
  ];

  return accordianLoader ? (
    <Skeleton />
  ) : !isEmpty(groupedAppointments2Level) ? (
    <div
      style={{
        height: `calc(100vh - ${hideBioInfo ? '295px' : '577px'})`,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          width: '100%',
          marginBottom: '10px',
        }}
      >
        {isEmpty(groupedAppointments) ? null : (
          <Radio.Group
            disabled={accordianLoader}
            defaultValue="state"
            buttonStyle="solid"
            onChange={(data) => {
              setIsStateMode(data.target.value === 'state');
              setExpandedPanels([]);
            }}
          >
            <Radio.Button value="state">State</Radio.Button>
            <Radio.Button value="carriers">Carriers</Radio.Button>
          </Radio.Group>
        )}
      </div>
      <Collapse
        activeKey={expandedPanels}
        onChange={(key) => setExpandedPanels([...key])}
      >
        {Object.keys(groupedAppointments2Level)
          .sort()
          .map((key, index) => {
            return (
              <Panel
                header={getStateNameFromCode(key)}
                key={key}
                className="table-layout-v2"
              >
                <Collapse>
                  {Object.keys(groupedAppointments2Level[key]).map(
                    (groupedKey: string, idx) => (
                      <Panel
                        header={getStateNameFromCode(groupedKey)}
                        key={`panel-${idx}`}
                      >
                        {Object.keys(
                          groupedAppointments2Level[key][groupedKey]
                        ).map((lcName: any, idxx) => {
                          const dataSource =
                            groupedAppointments2Level?.[key]?.[groupedKey]?.[
                              lcName
                            ];
                          return lcName ? (
                            <Collapse accordion>
                              <Panel
                                header={lcName}
                                key={`panel-${idxx}-${groupedKey}`}
                              >
                                <Table
                                  columns={columns}
                                  dataSource={
                                    Array.isArray(dataSource) ? dataSource : []
                                  }
                                  pagination={false}
                                />
                              </Panel>
                            </Collapse>
                          ) : (
                            <Table
                              columns={columns}
                              dataSource={
                                Array.isArray(dataSource) ? dataSource : []
                              }
                              pagination={false}
                            />
                          );
                        })}
                      </Panel>
                    )
                  )}
                </Collapse>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
      }}
    >
      <EmptyState
        image="empty data"
        heading="No appointments for this producer "
        content=""
      />
    </div>
  );
};

export default RTSCollapseCard;
