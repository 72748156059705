import {
  ACTION_REQUIRED,
  NiprActionIcon,
  getNiprActionStatus,
} from '../../../pages/agency/licenses/applications/pending-producer.utils';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Drawer, Row, Skeleton, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import ApplicationTypeCell from '../../../components/applications/applicationTypeCell';
import { ClickableLinkText } from '../../../components/clickableLink';
import CopySmall from '../../../components/common/icons/CopySmall';
import FeesCell from '../../../components/applications/feesCell';
import { StateConstants } from '../../../constants/state.constants';
import { formatDateWithTime } from '../../date.utils';
import { getNameString } from '../../name.utils';
import { getNiprResponseFromLicenseForModal } from '../../nipr/license.utils';
import { getTimeDifference } from '../../date/date-difference';
import { groupBy } from 'lodash';

interface PendingApplicationModalProps {
  selectedLicenseDetails: any;
  isLoading: boolean;
  setSelectedLicenseDetails: React.Dispatch<React.SetStateAction<any>>;
  updateActionRequiredFlag: (commentCode: number, isRead: boolean) => void;
  isUpdating: number[];
}

const PendingApplicationModal: React.FC<PendingApplicationModalProps> = ({
  selectedLicenseDetails,
  setSelectedLicenseDetails,
  updateActionRequiredFlag,
  isLoading,
  isUpdating,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [niprResponses, setNiprResponses] = useState<string[]>([]);

  useEffect(() => {
    if (selectedLicenseDetails) {
      const niprResponse = getNiprResponseFromLicenseForModal(
        selectedLicenseDetails
      );
      if (
        niprResponse.errors?.length === 0 ||
        selectedLicenseDetails?.gatewayTransaction?.submissionSuccess
      ) {
        setNiprResponses(niprResponse.messages);
      } else {
        setNiprResponses([]);
      }
      const niprMessage =
        selectedLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
          ?.license;
      const niprErrorResponse = Array.isArray(niprMessage?.response)
        ? niprMessage?.response?.flatMap((d: any) => d.messages)
        : [];
      const intResponse = niprMessage?.intResponse?.messages || [];
      setErrors(
        [...niprErrorResponse, ...intResponse]?.filter(
          (d) => d.actionRequired === ACTION_REQUIRED
        )
      );
    }
  }, [selectedLicenseDetails]);

  const transactionId =
    selectedLicenseDetails?.gatewayTransaction?.niprGatewaySuccessResponse
      ?.niprTrans;

  const stateCode =
    (selectedLicenseDetails?.license as any)?.[0]?.stateCode ||
    selectedLicenseDetails?.license?.stateCode;

  const isDuplicateLicenseNumber =
    (selectedLicenseDetails?.license as any)?.[0]?.isDuplicateLicenseNumber ||
    selectedLicenseDetails?.license?.isDuplicateLicenseNumber;

  const licenseNumber = isDuplicateLicenseNumber
    ? null
    : (selectedLicenseDetails?.license as any)?.[0]?.licenseNumber ||
      selectedLicenseDetails?.license?.licenseNumber;

  return (
    <Drawer
      destroyOnClose
      open={!!selectedLicenseDetails || isLoading}
      title={null}
      onClose={() => setSelectedLicenseDetails(null)}
      width={1066}
      headerStyle={{ display: 'none' }}
      placement="right"
      rootClassName="top-0-drawer"
      bodyStyle={{
        borderRadius: '16px',
        overflow: 'hidden',
      }}
      style={{
        top: 0,
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        boxShadow: 'none',
      }}
    >
      {isLoading ? (
        <div className="mt-4 px-3">
          <Skeleton active />
        </div>
      ) : (
        <div className="font-figtree">
          <div className="flex justify-between">
            <div>
              {errors?.length ? (
                <>
                  {' '}
                  <div className="text-[#222222] text-sm leading-[16.8px]  font-semibold">
                    Follow-Ups{' '}
                    <NiprActionIcon
                      niprAction={getNiprActionStatus(selectedLicenseDetails)}
                    />
                  </div>
                  <div className=" text-xs my-2 w-[70%] leading-[14.4px] text-[#848484]">
                    Please note that clicking &quot;Mark as done&quot; will not
                    complete the license application warnings. You still need to
                    manually follow the instructions below and mark it as done
                    for your own tracking purposes.
                  </div>
                </>
              ) : null}
            </div>
            <div
              className="cursor-pointer w-7 h-7 rounded-md bg-[#F0F0F0] ml-3 grid place-content-center"
              onClick={() => setSelectedLicenseDetails(null)}
            >
              <CloseOutlined />
            </div>
          </div>
          {!errors?.length ? null : (
            <div className="flex flex-col py-2 gap-2 overflow-y-scroll max-h-[260px]">
              {errors?.map((d: any, index: number) => {
                const isLoading = isUpdating.includes(d?.commentCode);
                return (
                  <div
                    key={`${d?.commentCode}-${index}`}
                    className="flex justify-between gap-3 w-full border-[#2286FF] border text-secondary-color bg-[#EDF9FF] rounded-md py-3 px-3"
                  >
                    <div className="w-[70%] font-figtree font-semibold text-xs leading-5 ">
                      {ClickableLinkText(d?.comments)}
                      {!d?.updatedBy ? null : (
                        <div className="mt-2">
                          <span className="text-xs leading-[14.4px] text-[#848484]">
                            Last Updated by
                          </span>{' '}
                          <span className=" text-xs text-[#21B4FF] leading-[14.4px] font-semibold">
                            {getNameString(d?.updatedBy)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="gap-3">
                      <div
                        onClick={() =>
                          updateActionRequiredFlag(
                            d?.commentCode,
                            !d?.markedAsDone
                          )
                        }
                        className={`${!d?.markedAsDone ? 'bg-secondary-color text-white' : 'border border-[#001F45]'} px-3 py-2 flex items-center gap-1 min-w-[120px] rounded-md  ${isLoading ? 'opacity-70' : 'opacity-100 cursor-pointer'}`}
                      >
                        {isLoading ? (
                          <Spin size="small" />
                        ) : (
                          <CheckCircleOutlined />
                        )}{' '}
                        {d?.markedAsDone ? 'Mark as not Done' : 'Mark as Done'}
                      </div>
                      {/* <div
                      onClick={async () => {
                        if (!links?.length) return;

                        links.forEach((link: any, index) => {
                          setTimeout(() => {
                            window.open(link, `${index}`);
                          }, index * 500); // Introduces a 200ms delay for each subsequent link
                        });
                      }}
                      className={`text-[#006EF5] flex items-center gap-1 font-figtree text-xs font-medium underline leading-[14.4px] ${!links?.length ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    >
                      <LinkOutline /> Open All links
                    </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!niprResponses?.length ? null : (
            <div>
              <div className="leading-7 text-sm font-semibold text-dark-blue">
                NIPR Message
              </div>
              <div>
                {niprResponses?.map((d, index) => {
                  return (
                    <div
                      key={index}
                      className="font-figtree text-dark-blue text-sm leading-5"
                    >
                      {ClickableLinkText(d)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-3 mt-2">
            <div>
              <div className="leading-7 text-sm font-semibold text-dark-blue">
                License Details
              </div>
              <div className="bg-[#D3E7FF80] py-3 px-2 flex gap-4 items-center rounded-md">
                <div className="w-[78px] h-[78px] bg-[#A7CEFF] text-[#00479D] leading-[44.57px] text-[33.43px] font-medium font-poppins rounded-full grid place-content-center">
                  {stateCode}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="font-medium leading-6 text-lg text-black-blue">
                    {StateConstants[stateCode]}
                  </div>
                  {!licenseNumber ? null : (
                    <div className="text-xs leading-5 flex gap-1 items-center text-[#1D2C50]">
                      <span className="mr-1">License Number: </span>
                      {licenseNumber}
                      <CopySmall
                        className="cursor-pointer"
                        onClick={() =>
                          navigator.clipboard.writeText(licenseNumber)
                        }
                      />
                    </div>
                  )}
                  <div className="text-xs leading-5 flex gap-1 items-center text-[#1D2C50]">
                    <span className="mr-1">Transaction ID: </span>{' '}
                    {transactionId || '-'}
                    {transactionId ? (
                      <CopySmall
                        className="cursor-pointer"
                        onClick={() =>
                          navigator.clipboard.writeText(transactionId!)
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="leading-7 text-sm font-semibold text-dark-blue">
                Other Information
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 pt-2 pb-4 border-b  gap-y-4 justify-between">
                {!selectedLicenseDetails?.producer ? null : (
                  <>
                    {' '}
                    <div className="flex gap-6 items-center">
                      <span className="inline-block min-w-[100px] text-secondary-color text-xs leading-5">
                        Producer Name
                      </span>{' '}
                      <span className="inline-block text-[#0074B0] text-xs leading-5">
                        {getNameString(
                          (selectedLicenseDetails as any)?.producer?.[0]?.name
                        )}
                      </span>
                    </div>
                    <div className="flex gap-6 items-center">
                      <span className="inline-block  min-w-[100px] text-secondary-color text-xs leading-5">
                        Renewal Type
                      </span>{' '}
                      <span className="inline-block text-secondary-color text-xs leading-5">
                        {(selectedLicenseDetails as any)?.producer?.[0]
                          ?.paymentConfigs?.isAutoRenewalActive
                          ? 'Auto Apply'
                          : 'Manual Apply'}
                      </span>
                    </div>
                  </>
                )}
                <div className="flex items-center gap-6">
                  <span className="inline-block  min-w-[100px] text-secondary-color text-xs leading-5">
                    Application Status
                  </span>{' '}
                  <span className="inline-block border border-[#F8D79A] rounded-[26px] px-2 bg-[#FCEFCF] text-secondary-color text-xs leading-5">
                    Pending
                  </span>
                </div>

                <div className="flex gap-6 items-center">
                  <span className="inline-block  min-w-[100px] text-secondary-color text-xs leading-5">
                    Fee
                  </span>{' '}
                  <FeesCell fees={selectedLicenseDetails} />
                </div>

                <div className="flex items-center gap-6">
                  <span className="inline-block  min-w-[100px] text-secondary-color text-xs leading-5">
                    Type
                  </span>{' '}
                  <ApplicationTypeCell
                    applicationType={selectedLicenseDetails?.applicationType}
                  />
                </div>

                <div className="flex gap-6 items-center">
                  <span className="inline-block  min-w-[100px] text-secondary-color text-xs leading-5">
                    Applied on
                  </span>{' '}
                  <Row align="middle" style={{ textAlign: 'center' }}>
                    <Typography.Paragraph
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        marginBottom: 0,
                      }}
                    >
                      {selectedLicenseDetails?.applyDate ? (
                        <Tooltip
                          title={formatDateWithTime(
                            selectedLicenseDetails.applyDate
                          )}
                        >
                          {getTimeDifference(
                            selectedLicenseDetails.applyDate.toString()
                          )}
                        </Tooltip>
                      ) : (
                        '-'
                      )}
                    </Typography.Paragraph>
                  </Row>
                </div>
              </div>
            </div>
            <div>
              <div className="leading-7 text-sm mb-2 font-semibold text-dark-blue">
                License Class & LOA’s
              </div>
              <div className="flex flex-col gap-3">
                {Object.values(
                  groupBy(
                    selectedLicenseDetails?.licenseConfigurations,
                    'licenseClassCode'
                  )
                )?.map((d: any[]) => {
                  return (
                    <div key={d?.[0]?.licenseClassCode}>
                      <div className="text-sm text-black-blue mb-1">
                        {d?.[0]?.licenseClass} ({d?.[0]?.licenseClassCode})
                      </div>
                      <div className="flex gap-3">
                        {d?.map((d) => {
                          return (
                            <div
                              key={d?.loa}
                              className="border py-1 px-2 rounded-[4px] border-[#BFBFBF] text-black-blue text-xs"
                            >
                              {d?.loa} ({d?.loaCode})
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default PendingApplicationModal;
