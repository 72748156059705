import { ProcessingFlags } from '../../types/response/data/agents/agentDocument.type';

const getAssignmentStatusTooltip = (
  processingFlags: ProcessingFlags
): string | null => {
  let tooltipTitle;

  if (
    processingFlags?.isLicenseProcessing &&
    processingFlags?.isAssignmentProcessing
  ) {
    tooltipTitle = 'License(s) and Assignment(s) are currently being processed';
  } else if (processingFlags?.isLicenseProcessing) {
    tooltipTitle = 'License(s) currently being processed';
  } else if (processingFlags?.isAssignmentProcessing) {
    tooltipTitle = 'Assignment(s) currently being processed';
  } else {
    tooltipTitle = null;
  }

  return tooltipTitle;
};

export { getAssignmentStatusTooltip };
