import { Button, Input, Modal } from 'antd';

import React from 'react';
import { adminStore } from '../../../../stores/admin.store';
import { listBuilderTitleStyle } from './listBuilderUtils';
import moment from 'moment';
import { observer } from 'mobx-react-lite';

type isLoading = {
  orgView: boolean;
  listView: boolean;
  isSaving: boolean;
  tableLoader: boolean;
};

type searchString = {
  producers: string;
  list: string;
};

export type savedNameType = {
  updatedAt: string;
  agencyName: string;
} | null;

interface SaveConfirmationModalProps {
  onSaveListCancel: () => void;
  saveListModal: boolean;
  onSaveList: () => void;
  isFetching: isLoading;
  isUpdate: boolean;
  searchString: searchString;
  setSearchString: React.Dispatch<React.SetStateAction<searchString>>;
  savedName: savedNameType;
}

const SaveConfirmationModal: React.FC<SaveConfirmationModalProps> = ({
  saveListModal,
  onSaveListCancel,
  onSaveList,
  isFetching,
  isUpdate,
  searchString,
  setSearchString,
  savedName,
}) => {
  return (
    <Modal
      title="Save List"
      closable={!isFetching.isSaving}
      maskClosable={false}
      centered
      onCancel={() => {
        onSaveListCancel();
      }}
      width={400}
      open={saveListModal}
      footer={null}
    >
      <div className="flex flex-col w-full gap-4 my-6 items-center">
        <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
          {' '}
          <div
            className="figtree w-40 font-12"
            style={{
              ...listBuilderTitleStyle,
              color: '#001F45',
            }}
          >
            List Name:
          </div>{' '}
          <Input
            placeholder="Enter the List name"
            style={{ height: 24, fontSize: 12 }}
            value={searchString.list}
            onChange={(e) =>
              setSearchString((prev) => ({
                ...prev,
                list: e.target.value,
              }))
            }
          />
        </div>
        <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
          {' '}
          <div
            className="figtree w-40 font-12"
            style={{
              ...listBuilderTitleStyle,
              color: '#001F45',
            }}
          >
            Created by:
          </div>{' '}
          <Input
            style={{ height: 24, fontSize: 12 }}
            value={savedName?.agencyName || adminStore.agency?.name || ''}
            disabled={true}
          />
        </div>
        <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
          {' '}
          <div
            className="figtree w-40 font-12"
            style={{
              ...listBuilderTitleStyle,
              color: '#001F45',
            }}
          >
            Last Updated on:
          </div>{' '}
          <Input
            style={{ height: 24, fontSize: 12 }}
            value={moment(savedName?.updatedAt || new Date()).format(
              'MM/DD/YYYY'
            )}
            disabled={true}
          />
        </div>
      </div>
      <div className="flex justify-end gap-4 items-center">
        {' '}
        <div
          style={{
            fontSize: 12,
            lineHeight: '14.4px',
            fontWeight: 500,
            cursor: isFetching?.isSaving ? 'default' : 'pointer',
          }}
          className={'figtree'}
          onClick={() => {
            onSaveListCancel();
          }}
        >
          Cancel
        </div>
        <Button
          className="figtree save-list-button"
          style={{
            background: 'var(--Secondary-Secondary, #001F45)',
            color: '#ffffff',
            fontSize: 12,
            lineHeight: '14.4px',
            fontWeight: 500,
            height: 24,
            opacity: !searchString.list ? 0.7 : 1,
          }}
          disabled={isFetching?.isSaving || !searchString.list}
          onClick={() => {
            onSaveList();
          }}
          loading={isFetching?.isSaving}
          size="small"
        >
          Save {isUpdate ? 'Changes' : 'List'}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(SaveConfirmationModal);
