import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import { ModalContainer } from './delete-agency.style';
import { TextDetail } from '../../application-status/applicationstatus.style';
import { adminStore } from '../../../../stores/admin.store';
import { useAuth } from '../../../../auth/authProvider';

interface ChangePasswordProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  agencyName: string;
}

const DeleteAgencyModal: React.FC<ChangePasswordProps> = ({
  visible,
  setVisible,
  agencyName,
}) => {
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [agencyId, setAgencyId] = useState(adminStore.agency?.id);

  useEffect(() => {
    if (adminStore.agency) setAgencyId(adminStore.agency.id);
  }, [adminStore.agency]);

  const onFinish = async (values: any) => {
    try {
      if (bearerToken) {
        const response: any = await AgencyService.deleteAgency(bearerToken);

        if (response.status === 'SUCCESS') {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  type FieldType = {
    loginPassword?: string;
  };
  return (
    <Modal
      width={'500px'}
      maskClosable={false}
      open={visible}
      onCancel={() => {
        setVisible(false); // Call the setVisible function to close the modal
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer>
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'16px'}>
          Delete Agency
        </TextDetail>
        <div style={{ width: '410px' }}>
          <TextDetail fontSize={'16px'}>
            {otpSent
              ? 'Verify OTP sent to r****ler@domain.com '
              : 'A verification OTP will be sent to master admins email before deleting this agency'}
          </TextDetail>
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, width: '600px' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {!otpSent && (
            <Form.Item<FieldType>
              label="Login Password"
              name="currentPassword"
              rules={[
                { required: true, message: 'Please input Login Password!' },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {otpSent && (
            <Form.Item<FieldType>
              label="Enter OTP"
              name="otp"
              rules={[{ required: true, message: 'Please input OTP!' }]}
            >
              <Input />
            </Form.Item>
          )}

          {!otpSent ? (
            <Form.Item>
              <Button
                style={{ width: '100%', marginBottom: '13px', height: '40px' }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={() => {
                  setOtpSent(!otpSent);
                }}
              >
                Send Verification OTP
              </Button>
              <Button
                style={{ width: '100%', height: '40px' }}
                htmlType="reset"
              >
                Cancel
              </Button>
            </Form.Item>
          ) : (
            <Form.Item>
              <div>
                <Button
                  style={{
                    width: '100%',
                    marginBottom: '13px',
                    height: '40px',
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Delete {agencyName}
                </Button>
                <Button
                  style={{ width: '100%', height: '40px' }}
                  onClick={() => {
                    setOtpSent(!otpSent);
                    setVisible(false);
                  }}
                  htmlType="reset"
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          )}
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteAgencyModal;
