import { Button, Card, Input, List, Row, Select, Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import ActivityCard from '../../../../components/agentOverview/activityCard';
import { ActivityTimelineFilters } from '../../../../types/request/agents/activityTimelineFilters.type';
import { AgentActivityTimelineEvent } from '../../../../types/response/data/agents/agentActivityTimelineEvent.type';
import { AgentService } from '../../../../services/agent.service';
import { AppEventSubTypes } from '../../../../enums/appEventSubType.enum';
import { IdConstants } from '../../../../constants/id.constants';
import { ResponseStatus } from '../../../../enums/responseStatus.enum';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { useAuth } from '../../../../auth/authProvider';
import { useParams } from 'react-router';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';

const { Search } = Input;
const Option = Select.Option;

function AgentActivity() {
  const { agentId } = useParams();
  const [agentIdValue, setAgentIdValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [agentActivity, setAgentActivity] = useState<
    AgentActivityTimelineEvent[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');

  const { getAccessTokenSilently } = useAuth();
  const [filters, setFilters] = useState<ActivityTimelineFilters>({
    appEventSubType: [],
  });

  useEffect(() => {
    if (agentId) {
      setAgentIdValue(agentId);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (agentIdValue) {
      fetchTimeline();
    }
  }, [agentIdValue, filters]);

  useEffect(() => {
    setSearchText(queryText || '');
    if (queryText !== prevSearchText && prevSearchText !== '') {
      fetchTimeline(queryText || '');
      setPrevSearchText(queryText || '');
    }
  }, [queryText]);

  const fetchTimeline = async (searchTextProp?: string) => {
    setIsLoading(true);
    const bearerToken = await getAccessTokenSilently();
    const resp = await AgentService.getAgentActivityTimeline(
      agentIdValue,
      pageSize,
      1,
      bearerToken,
      searchTextProp !== undefined ? searchTextProp : searchText,
      filters
    ).catch((e) => {
      console.error(e);
    });
    if (resp && resp.status === ResponseStatus.SUCCESS) {
      setAgentActivity(resp.data);
      setTotalCount(resp.totalCount);
    }
    if (agentIdValue !== appStateInfoStore.currentAgentId) {
      appStateInfoStore.getAgentDetail(agentIdValue, bearerToken);
    }
    setIsLoading(false);
  };

  const onLoadMore = async () => {
    setIsLoadingMore(true);
    const bearerToken = await getAccessTokenSilently();
    const resp = await AgentService.getAgentActivityTimeline(
      agentIdValue,
      pageSize,
      pageNumber + 1,
      bearerToken,
      searchText,
      filters
    ).catch((e) => {
      console.error(e);
    });
    if (resp && resp.status === ResponseStatus.SUCCESS) {
      setAgentActivity((prev) => [...prev, ...resp.data]);
      setTotalCount(resp.totalCount);
    }
    setPageNumber((prev) => prev + 1);
    setIsLoadingMore(false);
  };
  const loadMore = (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      {totalCount > agentActivity.length ? (
        <Button loading={isLoadingMore} onClick={onLoadMore}>
          Load more
        </Button>
      ) : agentActivity.length === 0 ? (
        <span style={{ color: 'var(--light-grey1)' }}>
          No events to be loaded
        </span>
      ) : (
        <span style={{ color: 'var(--light-grey1)' }}>All events loaded</span>
      )}
    </div>
  );

  const handleFilterSelection = async (value: any) => {
    setFilters((prevFilters: ActivityTimelineFilters) => {
      if (value.includes(AppEventSubTypes.StateGroupAdded)) {
        return {
          ...prevFilters,
          appEventSubType: [...value, AppEventSubTypes.StateGroupDuplicated],
        };
      } else {
        return {
          ...prevFilters,
          appEventSubType: value,
        };
      }
    });
    setPageNumber(1);
  };

  const optionDetails = [
    {
      label: 'Onboarding',
      key: '1',
      value: 'onboarded',
    },
    {
      label: 'Filled Employment History',
      key: '2',
      value: 'filled_employment_history',
    },
    {
      label: 'Updated Employment History',
      key: '3',
      value: 'updated_employment_history',
    },
    {
      label: 'Filled Background Question',
      key: '4',
      value: 'filled_bg_questions',
    },
    {
      label: 'Updated Background Question',
      key: '5',
      value: 'updated_bg_questions',
    },
    {
      label: 'State Assignment Added',
      key: '6',
      value: 'state_added',
    },
    {
      label: 'State Assignment Updated',
      key: '7',
      value: 'state_assignment_updated',
    },
    {
      label: 'State Assignment Removed',
      key: '8',
      value: 'state_removed',
    },
    {
      label: 'Territory Added',
      key: '9',
      value: 'state_group_added',
    },
    {
      label: 'Territory Updated',
      key: '10',
      value: 'state_group_updated',
    },
    {
      label: 'Territory Removed',
      key: '11',
      value: 'state_group_removed',
    },
    {
      label: 'License Application',
      key: '12',
      value: 'create_gateway_transaction',
    },
    {
      label: 'License Expired',
      key: '13',
      value: 'license_expired',
    },
    {
      label: 'Watchlisted',
      key: '12',
      value: 'watchlisted',
    },
    {
      label: 'Offboarding',
      key: '13',
      value: 'offboarded',
    },
  ];

  const options = optionDetails.map((itr) => {
    return <Option key={itr.value}>{itr.label}</Option>;
  });

  return (
    <Card
      style={{
        width: '100%',
        flexGrow: '1',
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      <Row>
        <div
          style={{
            position: 'sticky',
            top: '0',
            zIndex: '1',
            backgroundColor: '#FFFFFF',
            paddingBottom: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <Search
            size="middle"
            id={IdConstants.SEARCH_INPUT + '-activity-timeline'}
            placeholder="Search by State Names, Territories, LCs "
            style={{
              borderRadius: 1,
              color: '#92A69E',
              borderColor: 'var(--border-color)',
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onSearch={() => {
              if (searchText !== prevSearchText) {
                fetchTimeline();
                setQueryText(searchText);
                setPrevSearchText(searchText);
              }
            }}
            onPressEnter={() => {
              if (searchText !== prevSearchText) {
                fetchTimeline();
                setQueryText(searchText);
                setPrevSearchText(searchText);
              }
            }}
            disabled={isLoading || isLoadingMore}
          /> */}
        </div>
        <Select
          mode="multiple"
          size={'middle'}
          placeholder="Select filters"
          onChange={handleFilterSelection}
          style={{ width: '100%' }}
          disabled={isLoading || isLoadingMore}
        >
          {options}
        </Select>
      </Row>
      <div style={{ paddingTop: '10px', position: 'relative' }}>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <List
            className="activity-timeline-list"
            loading={isLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={agentActivity}
            renderItem={(item) => (
              <div style={{ marginBottom: '10px' }}>
                {' '}
                <ActivityCard activity={item} />
              </div>
            )}
          />
        )}
      </div>
    </Card>
  );
}

export default AgentActivity;
