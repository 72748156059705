import {
  DetailsReviewProps,
  ReviewSectionProps,
  StateReviewProps,
  dropDownTYpe,
} from './agencyLicensingInterfaces.type';

import { ApplicantType } from '../../enums/applicantType.enum';
import LicensedProducer from './licensedProducer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { Tooltip } from 'antd';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getDisplayFees } from '../../utils/common.utils';
import { getNameString } from '../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { states } from '../../constants/state.constants';

export const getAssignedDrlp = (data: any) => {
  const name = getNameString(data?.name);
  return name
    ? `${name} ${
        data?.drlpFlags?.isOnboarded || data?.isOnboarded
          ? ''
          : '(Not Onboarded)'
      }`
    : `${data?.npn}  (Not Onboarded)`;
};

const StateReview: React.FC<StateReviewProps> = ({ stateName }) => {
  return (
    <div
      style={{
        minWidth: '300px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <p
        style={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '20px',
          marginRight: 24,
          color: '#07212D',
          maxWidth: '100%',
        }}
      >
        {stateName}
      </p>
    </div>
  );
};

const DetailsReview: React.FC<DetailsReviewProps> = observer(
  ({ details, stateCode, dropdownOptions }) => {
    const licenseConfig = appStateInfoStore.getLicenseConfigs();
    const licenseConfigs = JSON.parse(JSON.stringify(licenseConfig));

    const getDetails = (detail: any) => {
      const stateDetails = licenseConfigs?.filter(
        (data: any) => data.stateCode === stateCode
      );

      const licenseDetails = stateDetails?.filter(
        (data: any) => data.licenseClassCode === detail?.licenseClass
      );

      const loaDetails = stateDetails?.filter((data: any) =>
        detail?.loa?.includes(data.id)
      );

      const agencyHomeState = agencyProfileStore?.agencyDetails?.homeState;
      const loaCount = loaDetails?.length || 0;

      const fees =
        appStateInfoStore.calculateFeesForAgencyLicense(
          stateCode,
          loaCount,
          agencyHomeState,
          ApplicantType.FIRM
        ) || 0;

      return { licenseDetails, loaDetails, fees };
    };

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        {details?.map((detail, index) => {
          const { licenseDetails, loaDetails, fees } = getDetails(detail);
          const selectedDrlps = dropdownOptions?.[stateCode]?.[
            detail?.licenseClass
          ]?.filter((data: any) => data.selected);
          return (
            <div
              key={index}
              style={{
                width: '100%',
                borderRadius: '6px',
                backgroundColor: '#F1F8FE',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 500,
                  fontSize: '16px',
                  padding: '16px 16px 0',
                  lineHeight: '20px',
                  color: '#222222',
                }}
              >
                DRLP
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '0 16px 16px',
                  gap: 16,
                  flexWrap: 'wrap',
                }}
              >
                {selectedDrlps?.length === 0 ? (
                  <div
                    style={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#222222',
                      margin: 0,
                      paddingTop: 16,
                    }}
                  >
                    {' '}
                    No DRLP Assigned
                  </div>
                ) : null}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>
                  {selectedDrlps?.map((data: any, index: number) => (
                    <LicensedProducer
                      text={getAssignedDrlp(data)}
                      notClosable={true}
                      onClose={() => {}}
                      key={index}
                    />
                  ))}
                </div>
              </div>
              <div
                style={{
                  border: '1px',
                  width: '100%',
                  borderBottom: '1px solid #e0e0e0',
                }}
              />
              <div
                className="override-checkbox-bg"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  padding: '16px',
                  gap: 14,
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#222222',
                  }}
                >
                  {licenseDetails?.[0]?.licenseClass}
                </div>
                <div
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#00A6FB',
                    marginLeft: '12px',
                    display: 'flex',
                    gap: 12,
                  }}
                >
                  {fees?.fees ? (
                    <div>
                      ( Estimated fees:{' '}
                      <span className="font-medium">
                        ${getDisplayFees(fees?.fees)}
                      </span>{' '}
                      )
                    </div>
                  ) : null}
                  {fees?.isRetaliatory ? (
                    <Tooltip
                      title={
                        <div>
                          We are currently building fee calculations for
                          retaliatory states. For more information, please refer{' '}
                          <a target="__blank" href={fees?.retaliatoryLink}>
                            {fees?.retaliatoryLink}
                          </a>
                        </div>
                      }
                      style={{ marginLeft: 2 }}
                    >
                      <QuestionCircleOutlined
                        style={{
                          cursor: 'pointer',
                          color: '#97AABF',
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  padding: '16px',
                }}
              >
                {loaDetails?.map((data: any) => {
                  return (
                    <div
                      key={data?.loa}
                      className="override-checkbox-bg"
                      style={{
                        height: '24px',
                        borderRadius: '4px',
                        border: '1px solid #E6E6E6',
                        padding: '8px 12px',
                        gap: '12px',
                        backgroundColor: '#FFFFFF',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#222222',
                          margin: 0,
                        }}
                      >
                        {data.loa}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

const SingleStateReview: React.FC<{ state: State; dropdownOptions: any }> = ({
  state,
  dropdownOptions,
}) => {
  const { name, details } = state;
  const stateCode = states?.filter((s) => s.name === name)?.[0]?.code;

  return (
    <div
      style={{
        width: '100%',
        border: '1px solid #DFEDF5',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        gap: '22px',
        boxSizing: 'border-box',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StateReview stateName={name} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '320px',
          borderLeft: '1px solid #DFEDF5',
          height: '100%',
        }}
      />
      <DetailsReview
        dropdownOptions={dropdownOptions}
        details={details}
        stateCode={stateCode}
      />
    </div>
  );
};

interface State {
  name: string;
  details: {
    licenseClass: string;
    loa: any;
  }[];
}

const ReviewSection: React.FC<ReviewSectionProps> = ({
  states,
  dropdownOptions,
}) => {
  return (
    <div
      style={{
        width: '100%',
        // height: '200px',
        borderRadius: '6px',
        border: '1px solid #DFEDF5',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        margin: '10px auto',
      }}
    >
      {states?.map((state, index) => (
        <SingleStateReview
          key={index}
          state={state}
          dropdownOptions={dropdownOptions}
        />
      ))}
    </div>
  );
};

interface ReviewSectionLicenseClassAndLoaProps {
  dropdownOptions: dropDownTYpe;
  agencyStateAssignment: {
    [stateName: string]: {
      stateCode: string;
      filteredAgencyLicenseClassConfigs: {
        licenseClassCode: string;
        licenseConfigIds: any;
      }[];
    };
  };
}
const ReviewSectionLicenseClassAndLoa: React.FC<
  ReviewSectionLicenseClassAndLoaProps
> = ({ agencyStateAssignment, dropdownOptions }) => {
  const states: State[] = Object.entries(agencyStateAssignment)?.map(
    ([stateName, { filteredAgencyLicenseClassConfigs }]) => {
      const details = filteredAgencyLicenseClassConfigs?.map(
        ({ licenseClassCode, licenseConfigIds }) => ({
          licenseClass: licenseClassCode,
          loa: licenseConfigIds,
        })
      );

      return { name: stateName, details };
    }
  );

  return <ReviewSection states={states} dropdownOptions={dropdownOptions} />;
};

export default ReviewSectionLicenseClassAndLoa;
