import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { findIndex, groupBy, sortBy, toUpper } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import JSZip from 'jszip';
import { MasterDataService } from '../../../services/masterData.service';
import { PDFViewer } from '../../../components/pdfViewer/pdfViewer';
import { RefObject } from '../../agentSidePortal/agentInformation/backgroundQuestions/individualBackgroundQuestion';
import TextWithIcon from '../../../components/common/textWithIcon/textWithIcon';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getNameString } from '../../../utils/name.utils';
import moment from 'moment';
import saveAs from 'file-saver';
import { useAuth } from '../../../auth/authProvider';
import { useParams } from 'react-router';

export interface RefObject_ {
  validateAndGetFilledResponse: () => any;
}
type Question = {
  description: string;
  questionKey: string;
  answer: string;
  supportingDocuments: any[];
  hasAttachment: boolean;
  answerType: string;
  isDependent: boolean;
  trigger: any;
  visible: boolean;
};

const SupportDocuments = () => {
  const { agentId } = useParams();
  const { bearerToken } = useAuth();
  const [isQuestionsLoading, setQuestionsLoading] = useState<boolean>(true);
  const [backgroundQuestions, setBackgroundQuestions] = useState<Question[]>(
    []
  );
  const [backgroundAnswers, setBackgroundAnswers] = useState<any>();
  const questionsRef = useRef<(RefObject | null)[]>([]);
  questionsRef.current.fill(null, 0, backgroundQuestions.length - 1);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewFileType, setPreviewFileType] = useState('');
  const [previewDocName, setPreviewDocName] = useState('');

  const handlePreview = (url: any, fileType: any, previewDocName: any) => {
    setPreviewUrl(url);
    setPreviewDocName(previewDocName);
    setPreviewFileType(fileType);
    setPreviewVisible(true);
  };

  const getAgentDetails = async () => {
    const response: any = await AgentService.getAgentDetails(
      agentId || '',
      bearerToken || ''
    ).catch((error) => {
      console.error('Error:: ', error.message);
    });
    if (response.data) {
      appStateInfoStore.setCurrentAgentId(response.data.id);
      appStateInfoStore.setHeader(getNameString(response.data.name));
    }
  };

  useEffect(() => {
    if (!appStateInfoStore.header && agentId && bearerToken) {
      getAgentDetails();
    }
  }, [agentId]);

  const handlePreviewCancel = () => {
    setPreviewVisible(false);
  };

  const getDocumentViewer = (fileType: any, url: any) => {
    switch (fileType) {
      case 'pdf':
        return <PDFViewer pdfUrl={url} />;

      case 'image':
        return (
          <img
            alt="Preview"
            src={url}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        );

      case 'doc':
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <DocViewer
              documents={[
                {
                  uri: url,
                  fileType: 'doc',
                },
              ]}
              style={{ width: '100%', height: 800 }}
              pluginRenderers={DocViewerRenderers}
            />
          </div>
        );

      case 'docx':
        return (
          <DocViewer
            documents={[
              {
                uri: url,
                fileType: 'docx',
              },
            ]}
            style={{ width: '100%', height: 800 }}
            pluginRenderers={DocViewerRenderers}
          />
        );

      case 'jpe':
        return (
          <img
            alt="JPE Preview"
            src={url}
            style={{ width: '100%', height: 800 }}
          />
        );

      case 'jpg':
        return (
          <img
            alt="JPG Preview"
            src={url}
            style={{ width: '100%', height: 800 }}
          />
        );

      case 'jpeg':
        return (
          <img
            alt="JPEG Preview"
            src={url}
            style={{ width: '100%', height: 800 }}
          />
        );

      case 'gif':
        return (
          <img
            alt="GIF Preview"
            src={url}
            style={{ width: '100%', height: 800 }}
          />
        );
      default:
        return <p>This file type is not supported for preview.</p>;
    }
  };

  const columns = [
    {
      title: 'Question Description',
      dataIndex: 'questionText',
      key: 'questionText',
      render: (record: any) => (
        <Typography.Paragraph>
          <div
            dangerouslySetInnerHTML={{ __html: record }}
            style={{ textAlign: 'justify' }}
          />
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Supporting Documents',
      dataIndex: 'supportingDocuments',
      key: 'supportingDocuments',
      width: '50%',
      align: 'center' as const,
      render: (supportingDocuments: any) => (
        <Table
          dataSource={supportingDocuments}
          pagination={false}
          columns={[
            {
              title: 'Document Name',
              dataIndex: 'name',
              key: 'name',
              render: (text: any) => <div>{text}</div>,
            },
            {
              title: 'Uploaded Date',
              dataIndex: 'uploadedAt',
              key: 'uploadedAt',
              render: (text: any) => <div>{moment(text).format('lll')}</div>,
            },
            {
              title: '',
              dataIndex: 'url',
              key: 'documents',
              align: 'center',
              render: (text: any, record: any) => (
                <Button
                  onClick={() => {
                    handlePreview(
                      text,
                      record.name.split('.').pop(),
                      record.name
                    );
                  }}
                  icon={<EyeOutlined />}
                >
                  Preview
                </Button>
              ),
            },
          ]}
        />
      ),
    },
  ];

  const getAllBackgroundQuestions = async () => {
    try {
      if (bearerToken) {
        setQuestionsLoading(true);
        const response: any =
          await MasterDataService.getBackgroundQuestions(bearerToken);
        const sortedQuestions = sortBy(response, 'displayOrder');
        sortedQuestions.forEach((q) => {
          if (q.isDependent) q.visible = false;
          else q.visible = true;
        });

        setBackgroundQuestions(sortedQuestions);
        const backgroundAnswers: any =
          await AgencyService.getOnboardedAgentBackgroundQuestionsForAgency(
            bearerToken,
            agentId
          );

        const groupedAnswers = groupBy(backgroundAnswers.data, 'questionKey');

        setBackgroundAnswers(groupedAnswers);
        setQuestionsLoading(false);
      }
    } catch (error) {
      console.error('Error in getting background questions', error);
      setQuestionsLoading(false);
    }
  };

  useEffect(() => {
    getAllBackgroundQuestions();
  }, []);

  const filteredAnswers: { [key: string]: any[] } = {};
  for (const [questionKey, answers] of Object.entries(
    backgroundAnswers ?? {}
  )) {
    if (
      Array.isArray(answers) &&
      answers.length > 0 &&
      answers[0]?.supportingDocuments
    ) {
      filteredAnswers[questionKey] = answers;
    } else {
      console.warn(
        `Answers for questionKey ${questionKey} are missing or don't have supporting documents.`
      );
    }
  }

  const filteredQuestionsAndAnswers = Object.entries(filteredAnswers)
    .map(([questionKey, answers]) => {
      return {
        ...(backgroundQuestions.find(
          (question) => question.questionKey === questionKey
        ) || {}),
        supportingDocuments: answers[0]?.supportingDocuments,
      };
    })
    .filter((question) => question.supportingDocuments.length !== 0);

  const handleDownloadAll = () => {
    const zip = new JSZip();

    Object.values(backgroundAnswers).forEach((answers: any) => {
      answers.forEach((answer: any) => {
        const supportingDocuments = answer?.supportingDocuments || [];
        supportingDocuments.forEach((doc: any) => {
          if (doc && doc.url) {
            const filename = doc.name;
            const fileContent = fetch(doc.url).then((response) =>
              response.blob()
            );
            zip.file(filename, fileContent);
          }
        });
      });
    });

    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      saveAs(
        content,
        `Support Documents - ${appStateInfoStore.getHeader()}.zip`
      );
    });
  };
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  return (
    <Card
      style={{
        width: '100%',
        cursor: isQuestionsLoading ? 'not-allowed' : '',
        position: 'relative',
      }}
    >
      <div className="flex justify-between items-center">
        <div>
          <TextWithIcon
            text="View and download all the support documents of this producer"
            icon={<ExclamationCircleOutlined />}
            iconColor="var(--primary-color)"
          />
        </div>
        <div>
          <Button
            size="middle"
            onClick={handleDownloadAll}
            disabled={
              isQuestionsLoading ||
              filteredQuestionsAndAnswers.length === 0 ||
              subscriptionStatusInactive
            }
            icon={<FileZipOutlined />}
          >
            Download All Documents
          </Button>
        </div>
      </div>
      {isQuestionsLoading ? (
        <div className="spin-style w-full pt-5 flex items-center justify-center">
          <Skeleton active />
        </div>
      ) : (
        <div className="pt-6">
          <Table
            dataSource={filteredQuestionsAndAnswers}
            columns={columns}
            rowKey="questionKey"
            pagination={false}
            scroll={{ y: 'calc(100vh - 250px)' }}
          />
        </div>
      )}
      <Modal
        open={previewVisible}
        onCancel={handlePreviewCancel}
        footer={null}
        width="80%"
        destroyOnClose
      >
        <Typography.Paragraph
          style={{
            color: 'var(--secondary-color)',
            fontSize: '24px',
            fontWeight: 500,
          }}
        >
          Document Preview
        </Typography.Paragraph>
        <Row>
          <Col span={10}>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '16px' }}
            >
              Document Name
            </Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '16px' }}
            >
              Document Type
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Typography.Paragraph style={{ fontSize: '16px' }}>
              {previewDocName}
            </Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph style={{ fontSize: '16px' }}>
              {toUpper(previewFileType)}
            </Typography.Paragraph>
          </Col>
        </Row>
        {getDocumentViewer(previewFileType, previewUrl)}
      </Modal>
    </Card>
  );
};

export default SupportDocuments;
