/* eslint-disable no-async-promise-executor */
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';

export class StripeService {
  static readonly host = UrlConstants.backend;
  static readonly modulePath = '/stripe';

  static async getBillingPortalURL(token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${this.modulePath}/billing-dashboard`);
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'StripeService :: getBillingPortalURL :: Error :: ',
          error
        );
        reject(error?.response?.data?.error);
      }
    });
  }

  static async getBillingProducerPortalURL(token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${this.modulePath}/producer/billing-dashboard`);
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'StripeService :: getBillingProducerPortalURL :: Error :: ',
          error
        );
        reject(error?.response?.data?.error);
      }
    });
  }
}
