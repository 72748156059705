import {
  AgencyStateAssignment,
  DRLP_OPTIONS_CTA,
  LicenseSelectionProps,
} from './agencyLicensingInterfaces.type';
import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { State, states } from '../../constants/state.constants';
import axios, { CancelTokenSource } from 'axios';

import { ApplicantType } from '../../enums/applicantType.enum';
import CurrentState from './currentState';
import { LicensesService } from '../../services/licenses.service';
import SelectedState from './selectedState';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { cancelPreviousRequest } from '../../utils/api.utils';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../auth/authProvider';

const LicenseSelection: React.FC<LicenseSelectionProps> = ({
  selectedStates,
  setAgencyStateAssignment,
  prefilledLoaIds,
  dropdownOptions,
  setDropdownOptions,
  sourceDropdowns,
  selectedIds,
  setSelectedIds,
  onAddNewProducer,
}) => {
  const { bearerToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [currentState, setCurrentState] = useState<State>({
    name: '',
    code: '',
  });
  const onStateSelect = (state: { name: string; code: string }) => {
    setCurrentState(state);
  };

  const [searchQuery, setSearchQuery] = useState<string>('');

  // Filtered states based on search query
  const tempFilteredStates = states?.filter(
    (state) =>
      state.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      selectedStates.some((selectedState) => selectedState === state.name)
  );
  const filteredStates = [...tempFilteredStates].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const onAgencyStateAssignment = (
    currentStateAssignment: AgencyStateAssignment
  ) => {
    setAgencyStateAssignment((prevState) => ({
      ...prevState,
      [currentState.name]: currentStateAssignment,
    }));
  };

  const getDrlpState = async () => {
    if (!bearerToken || !currentState.code) return;
    try {
      setLoading(true);
      if (cancelToken) {
        cancelPreviousRequest(cancelToken);
      }
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const drlpDropdowns = await LicensesService.getDrlpListState(
        bearerToken,
        currentState.code,
        cancelTokenSource
      );
      const licenseConfig = appStateInfoStore.getLicenseConfigs();
      const licenseConfigs = JSON.parse(JSON.stringify(licenseConfig));

      const supportedLicenseConfigs = licenseConfigs?.filter(
        (config: { isSupported: boolean; applicantType: string }) =>
          config?.isSupported === true &&
          (config?.applicantType === ApplicantType.BOTH ||
            config?.applicantType === ApplicantType.FIRM)
      );

      const currentStateLicenseConfigs = supportedLicenseConfigs?.filter(
        (config: { stateCode: string }) =>
          config?.stateCode === currentState.code
      );

      const currentStateLicenseClassName: string[] = Array.from(
        new Set(
          currentStateLicenseConfigs?.map(
            (config: any) => config?.licenseClassCode
          )
        )
      );

      const options: any = {};
      currentStateLicenseClassName?.forEach((d, index) => {
        const alreadyInAgencyDrlp =
          dropdownOptions?.[currentState.code]?.[Number(d)] || [];

        const uniqueDrlps = drlpDropdowns?.filter(
          (datas: any) =>
            !alreadyInAgencyDrlp?.length ||
            alreadyInAgencyDrlp?.every(
              (data: any) =>
                data.id !== datas.id && data.agentId !== datas.agentId
            )
        );

        let source: any[] = [];

        if (!dropdownOptions?.[currentState.code]) {
          const drlps: any[] = [];
          currentStateLicenseConfigs?.forEach((d: any) => {
            if (sourceDropdowns?.[currentState?.code]?.[d?.id]) {
              drlps.push(
                ...(sourceDropdowns?.[currentState.code]?.[d?.id] || [])
              );
            }
          });

          source = drlps || [];
        }

        options[d] = [
          ...(source || []),
          ...(alreadyInAgencyDrlp || []),
          ...(uniqueDrlps || []),
        ];
      });

      setDropdownOptions((prev: any) => ({
        ...prev,
        [currentState.code]: options,
      }));
      setLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoading(false);
      }
      console.error(error);
    }
  };

  const onUpdateDropDown = (options: any, index: string, type?: string) => {
    setDropdownOptions((prev: any) => ({
      ...prev,
      [currentState.code]: {
        ...prev[currentState.code],
        [index]:
          type === DRLP_OPTIONS_CTA.ADD
            ? [...(prev[currentState.code]?.[index] || []), options]
            : (prev[currentState.code]?.[index] || [])?.map((data: any) => {
                if (data.id === options.id) {
                  return {
                    ...(type === DRLP_OPTIONS_CTA.MODIFY
                      ? { ...data, ...options }
                      : { ...options, ...data }),
                    selected: type === DRLP_OPTIONS_CTA.DELETE ? false : true,
                  };
                }
                return data;
              }),
      },
    }));
  };

  useEffect(() => {
    if (selectedStates.length && !currentState.name) {
      const sortedSelectedStates = [...selectedStates].sort((a, b) =>
        a.localeCompare(b)
      );

      const selectedState = states?.filter(
        (data) => data.name === sortedSelectedStates?.[0]
      )?.[0];
      // todo
      onStateSelect(selectedState);
    }
  }, [selectedStates]);

  useEffect(() => {
    getDrlpState();
  }, [currentState.code]);

  return (
    <Card
      style={{
        width: '100%',
        // height: '680px',
        borderRadius: '6px',
        border: '1px solid #E6E6E6',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        gap: '22px',
        boxSizing: 'border-box',
        // overflowY: 'auto',
      }}
      bodyStyle={{ display: 'flex', height: '680px', padding: '0' }}
    >
      <Row gutter={[20, 0]} style={{ flex: 1 }}>
        <Col style={{ display: 'flex' }}>
          <SelectedState
            currentState={currentState.name}
            filteredStates={filteredStates}
            onStateSelect={onStateSelect}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Col>
        <Col style={{ display: 'flex', flex: 1 }}>
          <CurrentState
            currentState={currentState}
            onAgencyStateAssignment={onAgencyStateAssignment}
            prefilledLoaIds={prefilledLoaIds}
            drlpOptions={dropdownOptions?.[currentState.code] || {}}
            loading={loading}
            onUpdateDropDown={onUpdateDropDown}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            onStateSelect={onStateSelect}
            sortedStates={filteredStates}
            onAddNewProducer={onAddNewProducer}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default observer(LicenseSelection);
