import Loading from '../components/loading';
import { RoleType } from '../enums/roles.enum';
import { withRoles } from '../auth/useRoles';

function HomeRedirect() {
  return <Loading logo={true} />;
}

export default withRoles(HomeRedirect, [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
