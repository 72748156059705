import { PROCESSING_FEE_PERCENTAGE } from '../../utils/nipr/calculate-nipr-fee.utils';
import { Tooltip } from 'antd';
import { costFormatter } from '../../utils/input.utils';

function FeesCell({ fees }: { fees: any }) {
  const stateFee =
    fees?.gatewayTransaction?.niprGatewaySuccessResponse?.stateFee || 0;
  const transFee =
    fees?.gatewayTransaction?.niprGatewaySuccessResponse?.transFee || 0;
  const platFormFee = Number(
    costFormatter((stateFee + transFee) * PROCESSING_FEE_PERCENTAGE)
  );

  const feeValues = [
    { label: 'State Fee:', value: stateFee },
    { label: 'Transaction Fee:', value: transFee },
    { label: 'Processing Fee:', value: platFormFee },
  ];
  return (
    <Tooltip
      title={
        <ul className="flex flex-col gap-2 font-figtree">
          {feeValues?.map((d) => {
            return (
              <li key={d.label}>
                {' '}
                <span className="font-medium">{d.label}</span>{' '}
                <span className="font-semibold text-base text-white">
                  ${d.value}
                </span>
              </li>
            );
          })}
        </ul>
      }
    >
      <span className="text-[#084F2C] bg-[#98F5C880] border-[1.1px] border-[#0F9D58] font-figtree font-semibold leading-[14.4px] text-xs rounded-sm py-[2px] px-2 inline">
        $ {(stateFee + transFee + platFormFee)?.toFixed(2)}
      </span>
    </Tooltip>
  );
}

export default FeesCell;
