import { BulkOnboardingStage } from '../../../enums/bulkOnboardingStage.enum';
import { InfoCircleOutlined } from '@ant-design/icons';
import rightArrow from '../../../assets/icons/arrow-right-s-line.svg';

interface WarningBannerProps {
  canOnboard: number;
  cannotOnboard: number;
  handleContinueOnboarding: () => void;
  onBoardErrorCSV: any;
  onboardingStage?: string;
}

const PreviewBanner = (props: WarningBannerProps) => {
  interface ReplacementMap {
    [key: string]: string;
  }

  const getCSV = (): void => {
    const filteredData = props.onBoardErrorCSV.filter(
      (row: any) => row?.errorCondition
    );
    const onboardError = filteredData?.[0];
    const header = Object.keys(onboardError || {});
    let csvHeader = header.join(',') + '\n';

    const replacements: ReplacementMap = {
      name: 'lastName',
      npn: 'NPN',
      ssn: 'SSN',
      email: 'Email',
      errorCondition: 'Error Condition',
    };

    const csvRows = filteredData.map((obj: any) => {
      const formattedName = obj?.name || obj?.lastName;

      const rowData = { ...obj, name: formattedName };
      return header
        .map((key) => (key === 'name' ? rowData.name : (obj as any)[key]))
        .join(',');
    });

    const pattern = new RegExp(Object.keys(replacements).join('|'), 'g');
    csvHeader = csvHeader.replace(pattern, (match) => replacements[match]);
    const csv = csvHeader + csvRows.join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'producer-error.csv';

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div
      style={{
        padding: '9px 16px',
        border:
          props.canOnboard === 0 ? '1px solid #EB3131' : '1px solid #31EB91',
        backgroundColor: props.canOnboard === 0 ? '#FACBCD' : '#CBFAE3',
        borderRadius: 4,
        marginTop: '22px',
      }}
    >
      <p className="font-figtree font-semibold text-sm mb-[6px] text-[#001F45]">
        <InfoCircleOutlined
          style={{
            height: '17.143px',
            width: '17.143px',
            marginRight: 10,
            color: props.canOnboard === 0 ? '#9D0F0F' : '#0F9D58',
          }}
        />
        {props.onboardingStage === BulkOnboardingStage.COMPLETED
          ? 'Onboarding Completed'
          : props.canOnboard === 0
            ? 'You are unable to onboard any producers'
            : 'You can still onboard successful producers'}
      </p>

      <p className="font-figtree font-normal text-xs text-[#001F45] mb-[8px] ml-[27px]">
        {props.canOnboard === props.canOnboard + props.cannotOnboard
          ? `All ${props.canOnboard} producers could be onboarded successfully`
          : props.cannotOnboard === props.canOnboard + props.cannotOnboard
            ? `Unfortunately, none of the ${props.cannotOnboard} producers could be onboarded. Please review the errors and try again.`
            : `Out of ${props.canOnboard + props.cannotOnboard} producers,
          ${props.canOnboard} ${props.canOnboard === 1 ? 'has' : 'have'} succeeded while ${props.cannotOnboard} ${props.cannotOnboard === 1 ? 'has' : 'have'} not.` +
              (props.onboardingStage !== BulkOnboardingStage.COMPLETED
                ? ` You can choose to onboard the successful producers now and bring the unsuccessful one(s) onboard later.`
                : '')}
      </p>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        {props.canOnboard !== 0 &&
          props.onboardingStage !== BulkOnboardingStage.COMPLETED && (
            <p
              style={{
                color: '#0B703F',
                marginLeft: 27,
                display: 'flex',
                flexDirection: 'row',
              }}
              onClick={(e) => {
                if (props.onboardingStage === BulkOnboardingStage.COMPLETED) {
                  e.stopPropagation();
                } else props.handleContinueOnboarding();
              }}
            >
              <span
                style={{
                  cursor:
                    props.onboardingStage === BulkOnboardingStage.COMPLETED
                      ? 'not-allowed'
                      : 'pointer',
                }}
                className="flex cursor-pointer underline font-semibold text-sm font-figtree"
              >
                Continue Onboarding
                <img src={rightArrow} alt="logo" className="ml-1" />
              </span>
            </p>
          )}
        {props.cannotOnboard !== 0 && (
          <p
            className="font-figtree font-normal text-sm underline cursor-pointer"
            style={{
              color: '#EB3131',
              marginLeft:
                props.canOnboard !== 0 &&
                props.onboardingStage !== BulkOnboardingStage.COMPLETED
                  ? 0
                  : '27px',
            }}
            onClick={() => getCSV()}
          >
            Download Error Report
          </p>
        )}
      </div>
    </div>
  );
};

export default PreviewBanner;
