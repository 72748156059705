import { Col, Collapse, CollapseProps, Input, Skeleton } from 'antd';
import DownlineHierarchy, {
  AgencyCard,
  OrganizationTab,
} from './downlineHiearchy';
import React, { useState } from 'react';

import { Name } from '../../../../types/data/name.type';
import { RoleType } from '../../../../enums/roles.enum';
import { adminStore } from '../../../../stores/admin.store';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import arrowLeftDoubleLine from '../../../../assets/icons/arrow-left-double-line.svg';
import { getNameString } from '../../../../utils/name.utils';
import { observer } from 'mobx-react';
import { withRoles } from '../../../../auth/useRoles';

export type FilterCondition = 'equals' | 'contains' | 'startsWith' | 'endsWith';

interface OrganizationViewProps {
  downlineAgency: any;
  isFetching: boolean;
  directProducers: { producerName: Name; id?: string; npn: string }[];
  selectedProducerLevel: string;
  setSelectedProducerLevel: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationView: React.FC<OrganizationViewProps> = ({
  downlineAgency,
  isFetching,
  directProducers,
  selectedProducerLevel,
  setSelectedProducerLevel,
}) => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const [searchString, setSearchString] = useState('');

  // const getDownlineHiearchy = () => {
  //   return downlineAgency.filter((d: any) =>
  //     d?.downlineAgencyDetails?.name
  //       ?.toLowerCase()
  //       ?.trim()
  //       ?.includes(searchString?.trim()?.toLowerCase())
  //   );
  // };

  const totalCounts = downlineAgency?.reduce(
    (acc: any, val: any) => {
      let agencyCount = val?.downlineAgencyDetails ? 1 : 0;
      let downlineProducersCount = 0;
      let directProducersCount = 0;
      const uplineAgencyIdSet = new Set();

      directProducersCount +=
        val?.downlineAgencyDetails?.directProducerCount || 0;

      if (val?.downlineAgentDetails) {
        downlineProducersCount += 1;
      }

      val?.downlineHierarchy?.forEach((d: any) => {
        if (d?.downlineAgencyHierarchyDetails) {
          agencyCount += 1;
        }

        if (d?.downlineAgentHierarchyDetails) {
          downlineProducersCount += 1;
        }

        if (d?.uplineAgencyId && !uplineAgencyIdSet.has(d?.uplineAgencyId)) {
          uplineAgencyIdSet.add(d?.uplineAgencyId);
          directProducersCount +=
            d?.downlineAgencyHierarchyDetails?.directProducerCount || 0;
        }
      });

      return {
        totalDownlines: acc.totalDownlines + agencyCount,
        totalProducers:
          acc.totalProducers + downlineProducersCount + directProducersCount,
        directProducers: directProducers?.length,
      };
    },
    {
      totalDownlines: 0,
      totalProducers: directProducers?.length,
      directProducers: directProducers?.length,
    }
  );

  const getDownlines = (
    downlineHierarchy: any,
    currentLevel: number = 0,
    agencyId: string
  ) => {
    const agencyDetails = downlineHierarchy?.filter(
      (d: any) =>
        Boolean(d?.downlineAgencyHierarchyDetails) &&
        d.level === currentLevel &&
        d.uplineAgencyId === agencyId
    );
    const producers = downlineHierarchy?.filter(
      (d: any) =>
        Boolean(d?.downlineAgentHierarchyDetails) &&
        d.level === currentLevel + 1
    );
    const downlineProducers = producers?.map((d: any) => ({
      name: getNameString(d?.downlineAgentHierarchyDetails?.name),
      npn: d?.downlineAgentHierarchyDetails?.npn,
      level: currentLevel,
    }));

    if (!agencyDetails?.length && !downlineProducers?.length) return null;

    return (
      <>
        {agencyDetails?.map((d: any, index: number) => {
          const allDownlines = downlineHierarchy?.filter(
            (da: any) =>
              Boolean(da?.downlineAgencyHierarchyDetails) &&
              da.level > currentLevel
          );
          const downlines = allDownlines?.some(
            (da: any) =>
              da?.uplineAgencyId === d?.downlineAgencyHierarchyDetails?._id
          )
            ? allDownlines
            : [];

          const downlineDirectProducers = downlines?.reduce(
            (acc: any, val: any) =>
              acc + val?.downlineAgencyHierarchyDetails?.directProducerCount,
            0
          );
          const producers = downlineHierarchy?.filter(
            (data: any) =>
              Boolean(data?.downlineAgentHierarchyDetails) &&
              data.level > currentLevel
          );

          const downlineProducers = producers
            ?.filter(
              (da: any) =>
                da?.uplineAgencyId === d?.downlineAgencyHierarchyDetails?._id
            )
            ?.map((d: any) => ({
              name: getNameString(d?.downlineAgentHierarchyDetails?.name),
              npn: d?.downlineAgentHierarchyDetails?.npn,
              level: currentLevel + 3,
            }));

          return (
            <DownlineHierarchy
              key={d?.downlineAgencyHierarchyDetails?.name || index}
              agencyName={d?.downlineAgencyHierarchyDetails?.name}
              totalDownlines={downlines?.length}
              totalProducers={
                producers?.length +
                downlineDirectProducers +
                d?.downlineAgencyHierarchyDetails?.directProducerCount
              }
              downlineProducers={downlineProducers}
              directProducers={
                d?.downlineAgencyHierarchyDetails?.directProducerCount
              }
              children={() =>
                getDownlines(
                  downlineHierarchy,
                  currentLevel + 1,
                  d?.downlineAgencyHierarchyDetails?._id
                )
              }
              onClick={() => {
                setSelectedProducerLevel(
                  d?.downlineAgencyHierarchyDetails?._id
                );
              }}
              isProducerSelected={
                selectedProducerLevel === d?.downlineAgencyHierarchyDetails?._id
              }
              level={currentLevel + 3}
              isLast={index + 1 === agencyDetails?.length}
              searchString={searchString}
            />
          );
        })}
        {agencyDetails?.length === 0 && downlineProducers?.length ? (
          <>
            <DownlineHierarchy
              agencyName={undefined}
              totalDownlines={0}
              hideDownline={true}
              totalProducers={1}
              directProducers={0}
              children={() => null}
              downlineProducers={downlineProducers}
              onClick={() => {}}
              isProducerSelected={false}
              level={2}
              searchString={searchString}
              isLast={true}
            />
          </>
        ) : null}
      </>
    );
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <AgencyCard
          agencyName={adminStore?.agency?.name || ''}
          totalDownlines={totalCounts?.totalDownlines}
          totalProducers={totalCounts?.totalProducers}
          directProducers={totalCounts?.directProducers}
          level={1}
          searchString={searchString}
        />
      ),
      children: (
        <OrganizationTab
          onClick={() => {
            if (!adminStore?.agency?.id) return;
            setSelectedProducerLevel(adminStore?.agency?.id);
          }}
          isProducerSelected={selectedProducerLevel === adminStore?.agency?.id}
          agencyName={adminStore?.agency?.name}
          producerCount={totalCounts?.directProducers}
          isLast={true}
          children={() => {
            const agencyDetails = downlineAgency?.filter(
              (d: any) => d.downlineAgencyDetails
            );
            const directDownlineProducerDetails = downlineAgency
              ?.filter((d: any) => d.downlineAgentDetails)
              ?.map((d: any) => ({
                name: getNameString(d?.downlineAgentDetails?.name),
                npn: d?.downlineAgentDetails?.npn,
                level: 2,
              }));
            return (
              <>
                {agencyDetails?.map((d: any, index: number) => {
                  const agencyCount = d?.downlineHierarchy?.filter(
                    (d: any) =>
                      Boolean(d?.downlineAgencyHierarchyDetails) &&
                      d.level === 0
                  );
                  const downlineProducerDetails = d?.downlineHierarchy
                    ?.filter((d: any) =>
                      Boolean(d?.downlineAgentHierarchyDetails)
                    )
                    ?.map((d: any) => ({
                      name: getNameString(
                        d?.downlineAgentHierarchyDetails?.name
                      ),
                      npn: d?.downlineAgentHierarchyDetails?.npn,
                      level: (d?.downlineAgentHierarchyDetails?.level || 0) + 3,
                    }));

                  const downlineDirectProducers = d.downlineHierarchy
                    ?.filter((d: any) =>
                      Boolean(d?.downlineAgencyHierarchyDetails)
                    )
                    ?.reduce(
                      (acc: any, val: any) =>
                        acc +
                        (val?.downlineAgencyHierarchyDetails
                          ?.directProducerCount || 0),
                      0
                    );

                  const directProducerCount =
                    d?.downlineAgencyDetails?.directProducerCount || 0;

                  return (
                    <DownlineHierarchy
                      key={d?.downlineAgencyDetails?.name || index}
                      agencyName={d?.downlineAgencyDetails?.name}
                      totalDownlines={agencyCount?.length}
                      totalProducers={
                        (downlineProducerDetails?.length || 0) +
                        downlineDirectProducers +
                        directProducerCount
                      }
                      downlineProducers={downlineProducerDetails}
                      directProducers={directProducerCount}
                      children={() =>
                        getDownlines(
                          d?.downlineHierarchy,
                          0,
                          d?.downlineAgencyDetails?._id
                        )
                      }
                      onClick={() => {
                        setSelectedProducerLevel(d?.downlineAgencyDetails?._id);
                      }}
                      isProducerSelected={
                        selectedProducerLevel === d?.downlineAgencyDetails?._id
                      }
                      level={2}
                      searchString={searchString}
                      isLast={
                        index + 1 === agencyDetails?.length &&
                        !directDownlineProducerDetails?.length
                      }
                    />
                  );
                })}
                {directDownlineProducerDetails?.length ? (
                  <>
                    <DownlineHierarchy
                      agencyName={undefined}
                      totalDownlines={0}
                      hideDownline={true}
                      totalProducers={1}
                      directProducers={0}
                      children={() => null}
                      downlineProducers={directDownlineProducerDetails}
                      onClick={() => {}}
                      isProducerSelected={false}
                      level={2}
                      searchString={searchString}
                      isLast={true}
                    />
                  </>
                ) : null}
              </>
            );
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ display: 'block', width: '100%' }}>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <div className="custom-collapse">
          {' '}
          <Input
            placeholder="Search"
            style={{ width: 441, height: 28 }}
            onChange={(e) => setSearchString(e.target.value)}
            disabled={subscriptionStatusInactive}
          />{' '}
          <Col
            span={24}
            style={{
              display: 'flex',
              margin: '24px 0',
            }}
          >
            <div
              style={{
                overflow: 'auto',
                height: 'calc(100vh - 200px)',
              }}
              className="width-100 figtree var(--color-primary) font-14 line-height-20"
            >
              {' '}
              <Collapse
                defaultActiveKey={['1']}
                onChange={() => {}}
                expandIcon={(props) => (
                  <img
                    src={arrowLeftDoubleLine}
                    style={{ rotate: !props?.isActive ? '90deg' : '' }}
                    alt="arrow-left-double-line"
                  />
                )}
                items={items}
                ghost={true}
              />
            </div>
            <div></div>
          </Col>
        </div>
      )}
    </div>
  );
};

export default withRoles(observer(OrganizationView), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
