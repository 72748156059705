import { ActiveDotProps } from '../types/data/common.type';
import { twMerge } from 'tailwind-merge';

const ActiveDot: React.FC<ActiveDotProps> = ({ className }) => {
  return (
    <div
      className={twMerge(
        'w-[10px] inline-block h-[10px] rounded-full mr-1',
        className
      )}
    ></div>
  );
};

export default ActiveDot;
