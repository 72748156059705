import { useEffect, useState } from 'react';

const useWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    // Add event listener when the component mounts
    window?.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

export default useWidth;
