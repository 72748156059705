import {
  DetailsCard,
  DisplayRow,
  EmpDetailsCard,
  TextDetail,
} from './displayDetails.style';
import {
  EmployeeHistory,
  agentSideStore,
} from '../../../../stores/agentPortalStore';

import { Button } from 'antd';
import { EmploymentType } from './getDetails';
import React from 'react';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getStateNameFromCode } from '../../../../utils/common.utils';

type displayProp = {
  emp: EmployeeHistory;
};

export default function DisplayDetails({ emp }: displayProp) {
  const handleEdit = () => {
    emp.isEditing = true;
    agentSideStore.setIsUpdating(true);
  };
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  return (
    <EmpDetailsCard style={{ width: '100%', padding: 0 }}>
      <DisplayRow>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            Job Title
          </TextDetail>
          <TextDetail fontSize={'14px'}>{emp.title}</TextDetail>
        </DetailsCard>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            Job Description
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {emp.description ? emp.description : '-'}
          </TextDetail>
        </DetailsCard>

        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            Company Name
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {emp.companyName ? emp.companyName : '-'}
          </TextDetail>
        </DetailsCard>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            Duration
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {emp.startDate?.format('MM/YYYY')} to{' '}
            {!emp.endDate ? 'Present' : emp?.endDate?.format('MM/YYYY')}
          </TextDetail>
        </DetailsCard>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            Employment Type
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {' '}
            {emp.employmentType
              ? emp.employmentType === EmploymentType.FULL_TIME
                ? 'Full Time'
                : emp.employmentType === EmploymentType.PART_TIME
                  ? 'Part Time'
                  : emp.employmentType
              : '-'}
          </TextDetail>
        </DetailsCard>
      </DisplayRow>
      <DisplayRow>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            City
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {' '}
            {emp.city ? emp.city : '-'}
          </TextDetail>
        </DetailsCard>
        <DetailsCard>
          <TextDetail fontSize={'14px'} textColor={'#076166'}>
            State
          </TextDetail>
          <TextDetail fontSize={'14px'}>
            {' '}
            {emp.stateCode
              ? getStateNameFromCode(emp.stateCode) + ' (' + emp.stateCode + ')'
              : '-'}
          </TextDetail>
        </DetailsCard>

        <DetailsCard></DetailsCard>
        <DetailsCard></DetailsCard>

        <DetailsCard>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexGrow: '1',
              alignItems: 'center',
            }}
          >
            <Button
              type="default"
              style={{
                fontWeight: 400,
                display: 'inline-block',
                alignSelf: 'flex-end',
                width: '100px',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={handleEdit}
              block
              disabled={subscriptionStatusInactive}
            >
              Edit
            </Button>
          </div>
        </DetailsCard>
      </DisplayRow>
    </EmpDetailsCard>
  );
}
