import { BulkOnboardingStage } from '../../../../enums/bulkOnboardingStage.enum';
import { Tag } from 'antd';

interface StateCellProps {
  onboardingStage?: string;
}

const stageLabels: Record<string, string> = {
  [BulkOnboardingStage.RETRIEVE_SCB]: 'NIPR Status Pending - Retrieving Report',
  [BulkOnboardingStage.CONTINUE_ONBOARDING]: 'NIPR Status - Success',
  [BulkOnboardingStage.ONBOARD_PROCESSING]: 'Onboarding in Progress',
  [BulkOnboardingStage.COMPLETED]: 'Onboarding Status - Success',
};

function OnboardingStatusCell({ onboardingStage }: StateCellProps) {
  const label = stageLabels[onboardingStage ?? ''] || 'Unknown Status';

  return (
    <Tag
      color={'green'}
      style={{
        borderRadius: '16px',
        padding: '1px 6px',
        fontSize: '10px',
        fontWeight: 400,
        marginRight: 0,
        backgroundColor:
          onboardingStage === BulkOnboardingStage.COMPLETED ||
          onboardingStage === BulkOnboardingStage.CONTINUE_ONBOARDING
            ? '#98F5C880'
            : '#FCEFCF',
        border:
          onboardingStage === BulkOnboardingStage.COMPLETED ||
          onboardingStage === BulkOnboardingStage.CONTINUE_ONBOARDING
            ? '1px solid #31EB91'
            : '1px solid #FFE4A0',
      }}
    >
      <span style={{ color: '#222222', fontFamily: 'Figtree !important' }}>
        {label}
      </span>
    </Tag>
  );
}

export default OnboardingStatusCell;
