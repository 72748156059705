import { Dropdown, Menu, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import AgencyLogo from '../agencyLogo/agencyLogo';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { UserType } from '../../../enums/userType.enum';
import { adminStore } from '../../../stores/admin.store';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { auth0Store } from '../../../stores/auth0Store';
import { capitalize } from 'lodash';
import dropdownIcon from '../../../assets/icons/expand-up-down-line.svg';
import { getInitials } from '../../../utils/name.utils';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { withRoles } from '../../../auth/useRoles';

const AGENCY_SEPARATOR = '____AGENCY___NAME__';

function SelectProfile() {
  const { loadUser, getAccessTokenSilently } = useAuth();
  const navigate = useNavigate();

  const [selectedProfile, setSelectedProfile] = useState(() => {
    const initialProfile =
      auth0Store.getCurrentProfile() || auth0Store.getProfiles()[0];
    return {
      value: initialProfile?.id + AGENCY_SEPARATOR + initialProfile?.agencyId,
      label: initialProfile?.agency.name,
      role: initialProfile?.role,
    };
  });
  const [isSingleAgencyProfile, setIsSingleAgencyProfile] =
    useState<boolean>(false);
  const isIndividualProducer =
    signupStore?.getUserType() === UserType.INDIVIDUAL_PRODUCER;
  const hideToggleOption = isSingleAgencyProfile;

  const handleSelectProfile = async (value: string) => {
    const token = await getAccessTokenSilently();
    const regex = new RegExp(`(.*)${AGENCY_SEPARATOR}(.*)`);
    const matches = value.match(regex);

    const profileId = matches?.[1];
    const agencyId = matches?.[2];
    const profile = auth0Store.findProfileById(profileId!, agencyId!);
    if (profile) {
      signupStore.setIsAppLoading(true);
      auth0Store.setCurrentProfile(profile);
      if (profile.agencyId) {
        hierarchyViewStore.setCurrentAgencyId(profile.agencyId);
        await agencyProfileStore.loadAgencyDetails(token);
      }
      loadUser().then(() => {
        if (profile.role === RoleType.ADMIN) {
          navigate(RouteConstants.allAgents.path);
        } else if (
          [RoleType.AGENT, RoleType.INDIVIDUAL_PRODUCER].includes(profile.role)
        ) {
          navigate(RouteConstants.agentDashBoard.path);
        }
      });
    }
  };

  useEffect(() => {
    if (auth0Store.getProfiles()?.length === 1) setIsSingleAgencyProfile(true);
  }, []);

  const content = (
    <span
      className={`font-figtree max-w-[170px] text-sm  overflow-x-hidden overflow-y-visible text-ellipsis whitespace-nowrap font-medium ${hideToggleOption ? '' : ' mt-[-4px]'}`}
    >
      {selectedProfile.label}
    </span>
  );

  const menu = (
    <Menu
      onClick={({ key }) => {
        const selected = auth0Store
          .getProfiles()
          .find(
            (profile) =>
              profile.id + AGENCY_SEPARATOR + profile.agencyId === key
          );
        if (selected) {
          setSelectedProfile({
            value: key,
            label: selected.agency.name,
            role: selected.role,
          });
          handleSelectProfile(key);
        }
      }}
      className="flex flex-col gap-2"
      items={auth0Store.getProfiles().map((itr) => {
        const isSelected =
          selectedProfile.value === itr.id + AGENCY_SEPARATOR + itr.agencyId;

        const isCurrentProfile =
          itr.agency.name === auth0Store.getCurrentProfile()?.agency.name &&
          itr.role === auth0Store.getCurrentProfile()?.role;

        const label = (
          <div className="font-figtree">
            <div className="flex gap-2 items-center">
              <AgencyLogo
                agencyNameInitial={getInitials(itr.agency.name)}
                style={{
                  height: '25px',
                  width: '25px',
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',
                  fontSize: '14px',
                }}
                imageUrl={itr.agency.logo}
              />
              <div>
                <span className="block font-medium text-sm h-5">
                  {itr.agency.name}
                </span>

                <span className="text-[10px] h-3 font-normal text-[#848484]">
                  {itr.role === RoleType.INDIVIDUAL_PRODUCER
                    ? capitalize(RoleType.AGENT)
                    : capitalize(itr.role)}
                </span>
              </div>
            </div>
          </div>
        );

        return {
          key: itr.id + AGENCY_SEPARATOR + itr.agencyId,
          label,
          className: isSelected
            ? 'menu-item-agency-selected'
            : 'menu-item-agency',
          disabled: isCurrentProfile,
          style: {
            height: '44px',
            padding: '6px 8px',
            fontFamily: 'Figtree',
            fontWeight: 400,
            fontSize: '14px',
            backgroundColor: isSelected ? '#eeeeee' : '#ffffff',
            color: 'rgba(0, 0, 0, 0.88)',
          },
        };
      })}
    />
  );

  return auth0Store?.getProfiles()?.length > 0 ? (
    <div className="px-4 pt-4 pb-8 flex items-center bg-[#fafafa]">
      <AgencyLogo
        key={adminStore.agency?.logo}
        imageUrl={adminStore.agency?.logo}
        style={{
          height: '24px',
          width: '24px',
          border: '1px solid #EBEBEB',
          borderRadius: '4px',
          marginRight: '8px',
          fontSize: '14px',
        }}
        agencyNameInitial={getInitials(adminStore.agency?.name || '')}
      />

      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={hideToggleOption}
        destroyPopupOnHide
        className={`${hideToggleOption ? 'hide-toggle' : ''}`}
      >
        <button
          className={`h-6 cursor-pointer gap-1 flex border-none bg-transparent ${isIndividualProducer ? 'items-center' : ''}`}
        >
          <div className="flex flex-col">
            {isSingleAgencyProfile ? (
              <Tooltip title={selectedProfile.label} placement="rightTop">
                {content}
              </Tooltip>
            ) : (
              content
            )}

            {isIndividualProducer ? null : (
              <span className="text-[10px] mt-[-3px] font-figtree self-start text-[#848484]">
                {capitalize(auth0Store.getCurrentProfile()?.role || '')}
              </span>
            )}
          </div>
          {!isSingleAgencyProfile && (
            <img src={dropdownIcon} width={12} height={12} />
          )}
        </button>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
}

SelectProfile.displayName = 'SelectProfile';

export default withRoles(observer(SelectProfile), [
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
  RoleType.ADMIN,
  RoleType.INDIVIDUAL_PRODUCER,
]);
