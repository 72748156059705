import { Button, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import AdminSignUpLayout from '../../../components/signUp/layouts/signUpLayout';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import { ErrorMessageConstants } from '../../../constants/error-message.constants';
import { InviteResponseEnum } from '../../../enums/invite-response.enum';
import { NiprTransactionType } from '../../../enums/niprTransactionType';
import { RouteConstants } from '../../../constants/routes.constants';
import { SignUpSteps } from '../../../enums/signupPage.enum';
import { UserType } from '../../../enums/userType.enum';
import { adminStore } from '../../../stores/admin.store';
import { isAboveTabletWidth } from '../../../utils/screen.utils';
import { observer } from 'mobx-react-lite';
import sideScreen from '../../../assets/images/home-screen-wallpaper.png';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';

function DownlineInvite() {
  const { isOnboardingEnabled, refreshToken, isAuthenticated, isOnboarded } =
    useAuth();
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [loader, setLoader] = useState(false);
  const [acceptIsLoading, setAcceptIsLoading] = useState(false);
  const navigate = useNavigate();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  useEffect(() => {
    if (isAuthenticated())
      if (isOnboarded && !isAboveTabletWidth())
        navigate(RouteConstants.onboardedMobile.path);
    if (isOnboarded || !signupStore.isInvited) navigate('/redirect');
  }, [signupStore.isInvited]);

  // agency accept flow
  const handleAccept = async () => {
    setAcceptIsLoading(true);
    signupStore.setDownlineInviteResponse(InviteResponseEnum.ACCEPTED);
    signupStore.step = SignUpSteps.ADMIN_PROFILE_1;
    await refreshToken(true);

    navigate(RouteConstants.onboarding.path);
    setAcceptIsLoading(false);
  };

  // this flow only exists for agency as of now
  const handleReject = async () => {
    setLoader(true);
    if (signupStore.downlineInviteDetails?.inviteToken) {
      signupStore.setInviteType(null);
      signupStore.setIsInvited(false);
      signupStore.setDownlineInviteResponse(InviteResponseEnum.REJECTED);

      const accessToken = await getAccessTokenSilently();
      const resp = await DownlineInviteService.rejectDownlineInviteOnSignup(
        signupStore.downlineInviteDetails?.inviteToken,
        accessToken
      ).catch((error: any) => {
        showError(ErrorMessageConstants.REJECT_INVITE_ERROR);
        console.error(error);
        setLoader(false);
      });
      if (resp) {
        signupStore.setStep(SignUpSteps.ADMIN_PROFILE_1);
        await refreshToken(true);

        navigate(RouteConstants.onboarding.path);
      }
    }
    setLoader(false);
  };

  const handleSubmitGeneralAgent = async () => {
    setLoader(true);
    const token = await getAccessTokenSilently();
    const response =
      await DownlineInviteService.updateResponseForAgentDownlineInviteOnSignup(
        signupStore.downlineInviteDetails?.inviteToken || '',
        InviteResponseEnum.ACCEPTED,
        token
      ).catch((err) => {
        console.error('err', err);
        showError(ErrorMessageConstants.ACCEPT_INVITE_ERROR);
        setLoader(false);
      });

    if (response) {
      signupStore.setUserType(UserType.AGENT);
      await refreshToken(true);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (adminStore.account?.id) {
      signupStore.setStep(
        isOnboardingEnabled
          ? SignUpSteps.SETUP_AGENCY
          : SignUpSteps.WELCOME_SCREEN
      );
    }
  }, [adminStore.account]);

  return (
    <AdminSignUpLayout>
      {contextHolder}
      <div className="admin-invite-screen">
        <div
          style={{
            marginLeft: '15%',
            width: '40%',
            marginRight: '20%',
            marginTop: '-5%',
          }}
        >
          <Typography.Paragraph
            style={{
              fontSize: '24px',
              fontWeight: 500,
              marginTop: '20%',
            }}
          >
            You have been invited by
            <br />
            <span style={{ color: 'var(--primary-color)', fontWeight: 600 }}>
              {signupStore.getDownlineInviteDetails()?.inviterName}
            </span>{' '}
            as a downline.
          </Typography.Paragraph>

          {/* {typeof signupStore.getDownlineInviteDetails()?.creditAmount ===
            'number' && (
            // signupStore.getDownlineInviteDetails()?.creditAmount > -1 &&
            <div style={{ display: 'flex', marginTop: '10%' }}>
              <Typography.Paragraph
                style={{
                  fontSize: '24px',
                  fontWeight: 400,
                  marginRight: '30%',
                }}
              >
                Amount Allocated
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  fontSize: '24px',
                  fontWeight: 600,
                  color: 'var(--primary-color)',
                }}
              >
                $
                {costFormatter(
                  signupStore.getDownlineInviteDetails()?.creditAmount
                )}
              </Typography.Paragraph>
            </div>
          )} */}
          {signupStore.getDownlineInviteDetails()?.inviteeEntityType ===
            NiprTransactionType.FIRM && (
            <div style={{ marginTop: '7%' }}>
              <Typography.Paragraph
                style={{
                  fontSize: '32px',
                  fontWeight: 500,
                }}
              >
                Join as an agency
              </Typography.Paragraph>
              <div>
                <Button
                  id="accept-and-create-agency-button"
                  className="button secondary-button"
                  size="large"
                  style={{
                    marginTop: '-30px',
                    width: '100%',
                  }}
                  onClick={handleAccept}
                  loading={acceptIsLoading}
                  disabled={loader}
                >
                  Accept and Create Agency
                </Button>

                <Button
                  id="reject-and-create-agency-button"
                  loading={loader}
                  disabled={acceptIsLoading}
                  size="large"
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    color: 'var(--secondary-color)',
                    background: '#fff',
                    borderColor: 'var(--border-color)',
                  }}
                  onClick={handleReject}
                >
                  Reject and Create Agency
                </Button>
              </div>
            </div>
          )}
          {signupStore.getDownlineInviteDetails()?.inviteeEntityType ===
            NiprTransactionType.INDIVIDUAL && (
            <div style={{ marginTop: '7%' }}>
              <Typography.Paragraph
                style={{
                  fontSize: '32px',
                  fontWeight: 500,
                }}
              >
                Join as a general Agent
              </Typography.Paragraph>
              <Button
                id="downline-invite-join-insuretrek-button"
                loading={loader}
                className="button"
                size="large"
                style={{
                  marginTop: '-30px',
                  width: '100%',
                }}
                onClick={handleSubmitGeneralAgent}
              >
                Join InsureTrek
              </Button>
            </div>
          )}
        </div>

        <div style={{ width: '45%' }}>
          <img
            alt="screen"
            style={{
              width: '175%',
              height: '175%',
              marginLeft: '-45%',
              marginTop: '15%',
            }}
            src={sideScreen}
          />
        </div>
      </div>
    </AdminSignUpLayout>
  );
}

export default observer(DownlineInvite);
