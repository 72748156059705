import { CloseOutlined } from '@ant-design/icons';
import { LicensedProducerProps } from './agencyLicensingInterfaces.type';
import React from 'react';
import { Tag } from 'antd';

const LicensedProducer: React.FC<LicensedProducerProps> = ({
  text,
  notClosable,
  onClose,
  noMargin,
}) => {
  const handleClose = () => {
    onClose(text);
  };

  return (
    <Tag
      style={{
        borderRadius: '26px',
        border: '1px solid #437EE1',
        padding: '8px 16px',
        backgroundColor: '#C7E3FA',
        marginTop: notClosable && !noMargin ? '20px' : 0,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#000000',
        }}
      >
        {text}
      </span>
      {!notClosable ? (
        <CloseOutlined
          style={{
            color: '#000000',
            marginLeft: '8px',
            cursor: notClosable && !noMargin ? 'default' : 'pointer',
          }}
          onClick={handleClose}
        />
      ) : null}
    </Tag>
  );
};

export default LicensedProducer;
