export enum daysToAutoRenewOptions {
  FIFTEEN_DAYS = 15,
  THIRTY_DAYS = 30,
  FOURTY_FIVE_DAYS = 45,
}

export type daysToAutoRenewType = daysToAutoRenewOptions;

export interface preferenceIsUpdatingType {
  isOpen: boolean;
  loading: boolean;
  daysToAutoRenew: daysToAutoRenewType;
}

export interface Preferences {
  isProducerAlertEnabled?: boolean;
  daysToAutoRenew?: daysToAutoRenewType;
}

export interface preferenceType {
  isLoading: boolean;
  preferences?: Preferences;
}

export interface GeneralPropsType {
  setPreferences: React.Dispatch<React.SetStateAction<preferenceType>>;
}
