import { Avatar, Tooltip } from 'antd';
import { applySort, getStateNameFromCode } from '../../../utils/common.utils';

import type { ColumnsType } from 'antd/es/table';
import { IdConstants } from '../../../constants/id.constants';
import Table from 'antd/es/table';
import { UpdatedStateGroup } from '../../../types/data/stateGroup.types';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import moment from 'moment';

interface StateGroupTableProps {
  loader: boolean;
  stateGroups: UpdatedStateGroup[];
  refresh: () => void;
  setSortValue?: React.Dispatch<React.SetStateAction<any>>;
  rowSelection: any;
  selectedTerritory: any;
  setSelectedTerritory: React.Dispatch<React.SetStateAction<any>>;
}

function StateGroupTable({
  loader,
  stateGroups,
  refresh,
  setSortValue,
  rowSelection,
  selectedTerritory,
  setSelectedTerritory,
}: StateGroupTableProps) {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const Columns: ColumnsType<UpdatedStateGroup> = [
    {
      title: 'Territories',
      dataIndex: 'name',
      key: 'name',
      sorter: subscriptionStatusInactive ? false : true,
      render: (name) => {
        return (
          <span
            style={{
              borderRadius: 26,
              lineHeight: '20px',
              padding: '0 12px',
              display: 'inline-block',
            }}
            className="text-base-style border border-[#F8D79A] bg-[#FCEFCF]"
          >
            {name}
          </span>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">{text ? text : '-'}</div>
        );
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">{text ? text : '-'}</div>
        );
      },
    },
    {
      title: 'Last Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string) => {
        return (
          <div className="sub-heading-small figtree">
            {text ? moment(text).format('MM/DD/YYYY hh:mm A') : '-'}
          </div>
        );
      },
    },
    {
      title: 'Assigned States',
      dataIndex: 'states',
      key: 'states',
      width: 400,
      render: (_, { stateLicenseConfigs }) => {
        if (!stateLicenseConfigs || !stateLicenseConfigs?.length)
          return (
            <div className="figtree no-territories">No States Assigned</div>
          );
        return (
          <div
            className="custom-avatar-group flex my-2 flex-wrap gap-y-2 mr-2"
            style={{ paddingLeft: 5 }}
          >
            {stateLicenseConfigs.map((stateLicenseConfig) => {
              return (
                <Tooltip
                  key={stateLicenseConfig.stateCode}
                  title={`${getStateNameFromCode(
                    stateLicenseConfig.stateCode
                  )}`}
                >
                  <Avatar
                    size={'small'}
                    className="ant-custom-avatar ml-[-6px]"
                  >
                    {stateLicenseConfig.stateCode}
                  </Avatar>
                </Tooltip>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      rowClassName={(record) =>
        record?.id === selectedTerritory?.id ? 'selected-producer-row' : ''
      }
      rowKey={(record) => record.id}
      pagination={false}
      columns={Columns}
      loading={loader}
      rowSelection={subscriptionStatusInactive ? undefined : rowSelection}
      dataSource={stateGroups}
      onChange={(pagination, filters, sorter) => {
        const sort = applySort(sorter, refresh);
        if (setSortValue) setSortValue(sort);
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            if (!record.id || selectedTerritory?.id === record.id) return;
            setSelectedTerritory(record);
          },
        };
      }}
      style={{ overflowY: 'auto', cursor: 'pointer' }}
      scroll={{
        y: 'calc(100vh - 220px)',
      }}
      className={IdConstants.STATE_GROUPS.STATE_GROUPS_TABLE_CLASS_NAME}
    />
  );
}
export default StateGroupTable;
