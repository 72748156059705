import {
  ActiveLicense,
  AssignmentOptions,
  ProducerDetails,
  StateLicenses,
  updateProducerAssignmetPayload,
} from '../../agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { Button, Layout, Spin, message } from 'antd';
import ProducerStatDetails, {
  statDetails,
} from '../../agents/manageAssignments/ProducerStatDetails';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../utils/common.utils';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import { LeftOutlined } from '@ant-design/icons';
import ProducerAssignments from '../../agents/manageAssignments/producerAssignments';
import { RoleType } from '../../../enums/roles.enum';
import { SIDER_WIDTH_MANAGE_ASSIGNMENTS } from '../../../constants/style.constants';
import { STATE_CONSTANTS_COUNT } from '../../../constants/state.constants';
import { StateDetails } from '../../../types/data/masterData';
import { UserType } from '../../../enums/userType.enum';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { agentSideStore } from '../../../stores/agentPortalStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { auth0Store } from '../../../stores/auth0Store';
import { groupBy } from 'lodash';
import { individualOnboardStore } from '../../../stores/individualOnboardStore';
import insureTrekLogo from '../../../assets/images/insureTrekLogo.png';
import { observer } from 'mobx-react-lite';
import siderImage from '../../../assets/images/agencyLicensingSidebar.png';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { validateAssignmentStep } from '../../agents/onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { withRoles } from '../../../auth/useRoles';

const { Content, Sider, Footer } = Layout;

// individual producer portal
// manage assignments for individual producers
// territory selection not needed as of now
const ManageAssignmentsByProducer = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState(false);
  const [statloading, setStatLoading] = useState(true);
  const [producerDetails, setProducerDetails] = useState<ProducerDetails>();

  const [selectedItems, setSelectedItems] = useState<StateLicenses[]>([]); // selected states in assignments
  const [selectedState, setSelectedState] = useState('Alabama');
  const [selectedTab, setSelectedTab] = useState(AssignmentOptions.STATE);
  const [masterData, setMasterData] = useState<StateDetails[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [statDetails, setStatDetails] = useState<statDetails>({
    totalStates: STATE_CONSTANTS_COUNT,
    activeStates: 0,
    statesSelected: 0,
    territoriesSelected: 0,
    totalTerriotories: 0,
    totalEstimatedCost: 0,
  });

  const [disableSave, setDisableSave] = useState(false);

  const fetchProducerDetails = async () => {
    try {
      const bearerToken = await getAccessTokenSilently();
      setStatLoading(true);
      const producerDetails = await AgentService.getCurrentAgent(bearerToken);
      setProducerDetails(
        (producerDetails as { data: ProducerDetails })?.data as ProducerDetails
      );
    } catch (err) {
      console.error(err);
    } finally {
      setStatLoading(false);
    }
  };

  // fee calculation for selected licenses
  useEffect(() => {
    let fees = 0;
    const stateCodes: string[] = [];

    // state fee
    selectedItems?.forEach((d) => {
      const stateCode = getStateCodeFromName(d?.name);
      Object.entries(d)?.forEach(([k, v]) => {
        if (k === 'name' || typeof v === 'string') return;
        const activeDetails =
          individualOnboardStore.producerActiveLicenses?.filter(
            (d1) => d1.stateCode === stateCode
          )?.[0];
        if (
          (stateCode && stateCodes.includes(`${stateCode}${d.lcCode}`)) ||
          (stateCode && activeDetails?.licenseClassCode === k)
        )
          return;
        if (stateCode) stateCodes.push(`${d.stateCode}${k}`);

        fees +=
          appStateInfoStore.calculateFeesForProducerLicenses(
            stateCode!,
            activeDetails?.licenseClassCode === k
          ) || 0;
      });
    });

    setStatDetails((prev) => ({ ...prev, totalEstimatedCost: fees }));
  }, [selectedItems, individualOnboardStore.producerActiveLicenses]);

  const handleIndividualAssignStates = async (
    activeLicenses: ActiveLicense[],
    _?: boolean // isBulk not needed
  ) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.getMyLicenseConfiguration(token);

      const selectedStates =
        response?.data
          ?.map((d: any) => {
            return !d?.territoryId && d;
          })
          ?.filter(Boolean) || [];

      const selectedItems: StateLicenses[] = [];
      selectedStates.forEach((d: any) => {
        const stateDetails: any = {};
        const stateName = getStateNameFromCode(d?.stateCode || '');
        if (stateName) {
          stateDetails['name'] = stateName;
          const loas = groupBy(d?.licenseConfigurations, 'licenseClassCode');
          Object.entries(loas).forEach(([k, v]) => {
            stateDetails[k] = {
              name: v?.[0]?.licenseClass,
              loas:
                v?.map((d) => ({
                  id: d?.id,
                  loaCode: d?.loaCode,
                  name: d?.loa,
                })) || [],
            };
          });
          selectedItems.push(stateDetails);
        }
      });
      setSelectedItems(selectedItems as StateLicenses[]);

      const masterData = [...appStateInfoStore.getLicenseConfigsMasterData()];

      masterData.sort((a, b) => {
        const nameA = getStateNameFromCode(a.stateCode);
        const nameB = getStateNameFromCode(b.stateCode);
        return nameA.localeCompare(nameB);
      });

      const updatedMasterData = masterData.map((d) => {
        const licenses = d?.licenseClasses?.map((lc) => {
          const activeLicense = activeLicenses.find(
            (al) =>
              al.stateCode === d.stateCode && lc.lcCode === al?.licenseClassCode
          );
          const updatedConfigs: string[] = [];
          const activeLoas: string[] = [];
          lc.loaData?.forEach((d) => {
            const activeLoa = activeLicense?.loaDetails?.find(
              (loa) => loa.loaCode === d?.loaCode
            );
            if (activeLoa) {
              activeLoas.push(activeLoa.loaCode);
            }
            if (activeLoa && d?.referenceLicenseConfig) {
              updatedConfigs.push(d?.referenceLicenseConfig);
            }
          });
          const loas = lc.loaData?.filter((d) => {
            if (
              d?.referenceLicenseConfig &&
              updatedConfigs.includes(d?.id || '')
            )
              return false;
            return activeLoas.includes(d.loaCode) || !d.isRetired;
          });
          return {
            ...lc,
            loaData: loas,
          };
        });
        return {
          ...d,
          licenseClasses: licenses,
        };
      });

      setMasterData(updatedMasterData);
    } catch (error) {
      console.error(error);
      message.error('Failed to get producer assignments.Please Try Again');
    } finally {
      setLoading(false);
    }
  };

  const updateAssignmentDetails = async () => {
    const isError = validateAssignmentStep(selectedItems, []);
    if (isError) return;
    try {
      setIsSaving(true);
      const bearerToken = await getAccessTokenSilently();
      const payload: updateProducerAssignmetPayload[] = [];

      selectedItems.forEach((data) => {
        const licenseConfigurations: string[] = [];
        Object.entries(data).forEach(([k, v]) => {
          if (typeof v === 'string') return;
          v?.loas?.forEach((element) => {
            licenseConfigurations.push(element?.id || '');
          });
        });
        payload.push({
          stateCode: getStateCodeFromName(data.name) || '',
          licenseConfigurations,
        });
      });

      const response = await AgencyService.updateBulkAssignmentsForProducer(
        [agentSideStore.agentId!],
        payload,
        bearerToken
      );
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
      navigate(-1);
      setIsSaving(true);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const initializeStores = async () => {
      const token = await getAccessTokenSilently();

      await appStateInfoStore.initializeStore(token);

      if (
        signupStore.userType === UserType.ADMIN &&
        !agencyProfileStore.agencyDetails?.homeState
      )
        await agencyProfileStore.loadAgencyDetails(token);
    };

    if (auth0Store.getProfilesLoaded()) {
      initializeStores();
      fetchProducerDetails();
    }
  }, [auth0Store.getProfilesLoaded()]);

  const isAssignmentAssigned = () => {
    return selectedItems?.some((data) => data.name);
  };

  return (
    <Layout className="min-h-[100vh]">
      {/* Sider Component */}
      <Sider
        style={{
          backgroundImage: `url(${siderImage})`,
          position: 'sticky',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className="h-[100vh] sticky top-0 bg-cover bg-no-repeat"
        width={SIDER_WIDTH_MANAGE_ASSIGNMENTS}
        theme="light"
      >
        {/* Content inside Sider */}
        <div className="h-full flex flex-col items-center justify-start pt-[30px]">
          <div className="flex items-center justify-center mb-10">
            <img
              alt="logo"
              className="w-[164px] h-[42.8px]"
              src={insureTrekLogo}
            />
          </div>
          <div className="w-[198px] h-[27px] text-center font-poppins font-medium leading-[27px] text-white mb-3">
            Manage States
          </div>
          <div className="flex justify-center w-full mt-3">
            <Button
              className=" w-[201px] h-9 rounded-md px-6 py-3 bg-[#e8ebee] border-0 gap-[10px] flex items-center justify-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              <LeftOutlined style={{ fontSize: '4px' }} />
              Go Back
            </Button>
          </div>
          <div className="w-[201px] h-[1px] border border-[#374F6d] mt-6" />
          <div className="flex items-center flex-col mt-2">
            <p className="figtree w-[198px] h-7 text-xs leading-[14.4px] text-[#00a6fb] text-left">
              Edit Assigned states and Assigned Territories to your Producer
            </p>
          </div>
        </div>
      </Sider>
      {/* Main Content Layout */}
      <Layout>
        {statloading ? (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              minHeight: 'calc(100vh - 64px)',
            }}
            className="grid place-content-center min-h-[calc(100vh-64px)]"
          >
            {' '}
            <Spin />
          </div>
        ) : (
          <Content className="flex-1 p-5 overflow-auto">
            <ProducerStatDetails
              isIndividualProducer={true}
              producerDetails={producerDetails!}
              producerStatDetails={statDetails}
              isBulk={false}
              setProducerIds={() => []}
            />
            <ProducerAssignments
              selectedItems={selectedItems}
              selectedState={selectedState}
              selectedTab={selectedTab}
              setSelectedItems={setSelectedItems}
              setSelectedState={setSelectedState}
              setSelectedTab={setSelectedTab}
              setStatDetails={setStatDetails}
              handleIndividualAssignStates={handleIndividualAssignStates}
              isLoading={loading}
              masterData={masterData}
              statDetails={statDetails}
              setDisableSave={setDisableSave}
              producerDetails={producerDetails!}
              producerId={agentSideStore.agentId!}
              isBulk={false}
              isIndividualProducer={true} // individual-producer assignments
              // territory not needed as of now
              selectedTerritory={''}
              setSelectedTerritory={() => []}
              stateGroups={[]}
              setStateGroups={() => []}
            />
          </Content>
        )}
        <Footer className="w-full flex items-center justify-end bottom-0 sticky gap-1 p-5 bg-white border border-[#e6e6e6] h-16 box-border">
          <Button
            type="primary"
            onClick={updateAssignmentDetails}
            className="h-[46px] w-[277px]"
            disabled={
              disableSave || loading || statloading || !isAssignmentAssigned()
            }
            loading={isSaving}
          >
            Save Changes
          </Button>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default withRoles(observer(ManageAssignmentsByProducer), [
  RoleType.INDIVIDUAL_PRODUCER,
]);
