import StripeOnboardingButton from './stripeOnboardingButton';

function StripeOnboardingRequiredToast() {
  return (
    <div>
      <span>
        It seems there&apos;s something pending with your Stripe onboarding.
      </span>
      <StripeOnboardingButton
        collapsed={false}
        text="Complete stripe onboarding"
        style={{
          marginTop: '10px',
        }}
        showTooltip={true}
      />
    </div>
  );
}

export default StripeOnboardingRequiredToast;
