import {
  MultipleOnboardLookup,
  MultipleOnboardSteps,
} from '../enums/onboardSteps.enum';

import FileParser from '../utils/file-parser/fileParser';
import { LicenseApplicationResponse } from '../types/response/license-application.type';
import { PaymentTypeEnum } from '../enums/paymentType.enum';
import { getNameString } from '../utils/name.utils';
import { makeAutoObservable } from 'mobx';

interface Address {
  dateUpdated: string;
  addressTypeCode: string;
  addressType: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface ContactInfo {
  businessPhone: string;
  businessPhoneUpdateDate: string;
  businessEmail: string;
  businessEmailUpdateDate: string;
  fax: string;
  faxUpdateDate: string;
}

interface LicenseDetail {
  loaName: string;
  loaCode: string | null;
  loaIssueDate: string;
  status: string;
  statusReason: string;
  statusReasonDate: string;
  ceCompliance: string;
  ceRenewalDate: string;
  ceCreditsNeeded: string;
}

interface License {
  licenseNumber: string;
  licenseIssueDate: string;
  licenseExpireDate: string;
  licenseClass: string;
  licenseClassCode: string;
  residentIndicator: string;
  active: string;
  details: LicenseDetail[];
  stateCode: string;
}

interface Producer {
  npn: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  addresses: Address[];
  contactInfos: ContactInfo[];
  licenses: License[];
}
export interface CsvJson {
  name: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  state?: string;
  npn?: string;
  prefferredEmail?: string;
  gender: string;
  phone: string;
  errorCondition: string;
}

export interface ScbReportBody {
  scbProducers: Producer[];
  scbPartialFindList: string[];
  csvJson: CsvJson[];
}

export type AgentUpdateDto = {
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  agencyId: string;
  homeState: string;
  ssn: string;
  lastName?: string;
  phone: string;
  fax: string;
  email: string;
  gender: string;
};

export type updateAgentDto = {
  agentId: string;
  npn?: string;
  updateAgentDto: AgentUpdateDto;
};

export type createAgentDto = {
  agentId: string;
  npn?: string;
  createAgentDto: AgentUpdateDto;
};

export type OnboardErrorDto = {
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  npn?: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  state?: string;
  email: string;
  errorCondition: string;
};

export type OnboardResultDto = {
  bulkUpdateAgentsDto: updateAgentDto[];
  bulkCreateAgentsDto: createAgentDto[];
  onboardError: OnboardErrorDto[];
  totalOnboardSuccessCount: number;
  totalOnboardErrorCount: number;
};

export type producerViewTableContent = {
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  npn?: string;
  ssn?: string;
  lastName?: string;
  email: string;
  gender: string;
  phone: string;
};

class MultipleOnboardStore {
  currentStep: MultipleOnboardSteps = MultipleOnboardSteps.IMPORT_AGENTS;
  minStep: MultipleOnboardSteps = MultipleOnboardSteps.IMPORT_AGENTS;
  maxStep: MultipleOnboardSteps = MultipleOnboardSteps.IMPORT_AGENTS;
  isModalVisible: boolean = false;
  assignmentSkipped: boolean = false;
  startedOnboard: boolean = true;
  lookupMethod: string = MultipleOnboardLookup.SSN;
  scbReportBody: ScbReportBody | null = null;
  onboardResult: OnboardResultDto | null = null;
  bulkAgentsIds: string[] = []; //'65c1fbb4b55dba15c5b4bd30'
  producerViewTableContent: producerViewTableContent[] = [];
  reportId: string = '';
  canBeOnboardCount: number = 0;
  cannotBeOnboardedCount: number = 0;
  onBoardErrorCSV: CsvJson[] = [];
  bulkOnboardId: string = '';

  getCanBeOnboardCount(): number {
    return this.canBeOnboardCount;
  }

  setCanBeOnboardCount(canBeOnboardCount: number): void {
    this.canBeOnboardCount = canBeOnboardCount;
  }

  getCannotBeOnboardedCount(): number {
    return this.cannotBeOnboardedCount;
  }

  setCannotBeOnboardedCount(cannotBeOnboardedCount: number): void {
    this.cannotBeOnboardedCount = cannotBeOnboardedCount;
  }

  setReportId = (reportId: string) => {
    this.reportId = reportId;
  };

  setProducerViewTableContent = () => {
    const combineData: producerViewTableContent[] = [];
    if (this.onboardResult) {
      this.onboardResult.bulkCreateAgentsDto.forEach((agent) => {
        combineData.push({
          name: agent.createAgentDto.name,
          npn: agent?.npn,
          email: agent.createAgentDto.email,
          gender: agent.createAgentDto.gender,
          phone: agent.createAgentDto.phone,
          ssn: agent?.createAgentDto?.ssn,
          lastName: agent?.createAgentDto?.lastName,
        });
      });
      this.onboardResult.bulkUpdateAgentsDto.forEach((agent) => {
        combineData.push({
          name: agent.updateAgentDto.name,
          npn: agent?.npn,
          email: agent.updateAgentDto.email,
          gender: agent.updateAgentDto.gender,
          phone: agent.updateAgentDto.phone,
          ssn: agent?.updateAgentDto?.ssn,
          lastName: agent?.updateAgentDto?.lastName,
        });
      });
    }
    this.producerViewTableContent = combineData;
  };

  setBulkAgentsIds = (ids: string[]) => {
    this.bulkAgentsIds = ids;
  };

  setOnboardResult = (result: OnboardResultDto) => {
    result.onboardError.sort((a, b) => {
      const nameA = getNameString(a.name).trim();
      const nameB = getNameString(b.name).trim();
      return nameA.localeCompare(nameB);
    });
    this.onboardResult = result;
  };

  uploadedFileName: string = '';
  isFileUploaded: boolean = false;
  uploadErrorMessage: any = '';
  uploadStatus: string = '';
  uploadProgress: number = 0;

  uploadedFileContent: any = null;
  uploadedFile: object | null = null;
  fileContent = localStorage.getItem('fileContent') || '';
  multipleOnboardJsonData: any = null;
  fileParser: FileParser;
  licenses: LicenseApplicationResponse[] = [];
  onboardingType: string[] = [];

  //Agent View
  missingEmail: CsvJson[] = [];
  onBoardModalVisibility: boolean = false;

  //valid csv data
  agentWithValidNiprResponse: CsvJson[] = [];

  //paymentConfig
  paymentConfig: {
    initialPaymentPayee: string;
    renewalPaymentPayee: string;
    paymentType: any;
    amountCap: number | null;
  } = {
    initialPaymentPayee: '',
    renewalPaymentPayee: '',
    paymentType: null,
    amountCap: null,
  };

  autoRenewEnabled: boolean = false;
  paymentsConfigured: boolean = false;

  setAgentWithValidNiprResponse = (value: CsvJson[]) =>
    (this.agentWithValidNiprResponse = value);
  getAgentWithValidNiprResponse = (): CsvJson[] =>
    this.agentWithValidNiprResponse;

  constructor() {
    makeAutoObservable(this);
    this.fileParser = new FileParser();
  }

  //SETTERS
  setAssignmentSkipped = (value: boolean) => {
    this.assignmentSkipped = value;
  };
  setCurrentStep = (value: MultipleOnboardSteps) => {
    this.currentStep = value;
  };
  setBulkOnboardId = (value: string) => {
    this.bulkOnboardId = value;
  };
  setStartedOnboarded = (value: boolean) => {
    this.startedOnboard = value;
  };
  setMaxStep = (value: MultipleOnboardSteps) => {
    this.maxStep = value;
  };

  setMinStep = (value: MultipleOnboardSteps) => {
    this.minStep = value;
  };

  setScbReportBody = (value: ScbReportBody) => {
    this.scbReportBody = value;
  };

  setLicenses(value: LicenseApplicationResponse[]) {
    this.licenses = value;
  }

  updateInitialPaymentByAgentId = (
    agents: string[],
    initialPaymentAccount: any
  ) => {
    this.paymentConfig.initialPaymentPayee = initialPaymentAccount;
  };
  updateRenewalPaymentByAgentId = (
    agents: string[],
    renewalPaymentAccount: any
  ) => {
    this.paymentConfig.renewalPaymentPayee = renewalPaymentAccount;
  };
  updatePaymentTypeByAgentId = (
    agents: string[],
    paymentType: string,
    paymentCap: number
  ) => {
    this.paymentConfig.paymentType = paymentType;
    this.paymentConfig.amountCap =
      paymentType === PaymentTypeEnum.CAP ? paymentCap : -1;
  };

  updateAutoRenewalByAgentId = (agents: string[], autoRenewal: any) => {
    this.autoRenewEnabled = autoRenewal;
  };

  setLookupMethod = (value: MultipleOnboardLookup) => {
    this.lookupMethod = value;
    if (value === MultipleOnboardLookup.SSN) {
      this.setOnboardingType(['ssn', 'lastName']);
    } else if (value === MultipleOnboardLookup.LICENSE) {
      this.setOnboardingType(['licenseNumber', 'state']);
    } else if (value === MultipleOnboardLookup.NPN) {
      this.setOnboardingType(['npn']);
    }
  };

  setErrorMessage = (value: any) => {
    this.uploadErrorMessage = value;
  };
  setIsFileUploaded = (value: boolean) => {
    this.isFileUploaded = value;
  };
  setUploadProgress = (value: any) => {
    this.uploadProgress = value;
  };
  setUploadFileName = (value: any) => {
    this.uploadedFileName = value;
  };
  setUploadStatus = (value: any) => {
    this.uploadStatus = value;
  };
  setUploadedFile = (value: any) => {
    this.uploadedFile = value;
  };

  setFileContent(fileContent: string) {
    this.fileContent = fileContent;
    localStorage.setItem('fileContent', fileContent);
    try {
      const multipleOnboardJsonData =
        this.fileParser.parseCsvToJson(fileContent);
      const multipleOnboardJsonDataWithIds = multipleOnboardJsonData.map(
        (row: any, index: number) => ({
          id: index,
          ...row,
        })
      );
      this.multipleOnboardJsonData = multipleOnboardJsonDataWithIds;
      const multipleOnboardJsonDataString = JSON.stringify(
        multipleOnboardJsonDataWithIds
      );
      localStorage.setItem(
        'multipleOnboardJsonData',
        multipleOnboardJsonDataString
      );
    } catch (error) {
      console.error('Error parsing file:', error);
    }
  }

  setOnboardingType = (value: string[]) => (this.onboardingType = value);
  getOnboardingType = (): string[] => this.onboardingType;

  setMissingEmail = (data: CsvJson[]) => {
    this.missingEmail = data;
  };

  setOnboardModalVisibility = (bool: boolean) => {
    this.onBoardModalVisibility = bool;
  };

  getMissingEmail = () => {
    return this.missingEmail;
  };

  setModalVisibility = (value: boolean) => (this.isModalVisible = value);

  getScbReportBody = (): ScbReportBody | null => this.scbReportBody;

  loadUploadedContent() {
    const storedFileContent = localStorage.getItem('multipleOnboardJsonData');
    if (storedFileContent) {
      try {
        const multipleOnboardJsonData = JSON.parse(storedFileContent);
        if (Array.isArray(multipleOnboardJsonData)) {
          this.uploadedFileContent = multipleOnboardJsonData;
          return multipleOnboardJsonData;
        } else {
          console.error('Invalid JSON data. Expected an array.');
        }
      } catch (error) {
        console.error(
          'Error parsing uploadedContent file from localStorage:',
          error
        );
      }
    }
    return null;
  }

  loadUploadedFile() {
    const storedFile = localStorage.getItem('uploadedFile');
    if (storedFile) {
      try {
        this.uploadedFile = JSON.parse(storedFile);
      } catch (error) {
        this.uploadedFile = null;
        localStorage.removeItem('uploadedFile');
      }
    }
  }
  resetMultipleOnboard = () => {
    this.paymentConfig = {
      initialPaymentPayee: '',
      renewalPaymentPayee: '',
      paymentType: null,
      amountCap: null,
    };
    this.reportId = '';
    this.bulkAgentsIds = [];
    this.producerViewTableContent = [];
    this.onboardResult = null;
    this.fileContent = '';
    this.uploadedFile = null;
    this.uploadedFileName = '';
    this.uploadedFileName = '';
    this.isFileUploaded = false;
    this.uploadErrorMessage = '';
    this.uploadStatus = '';
    this.uploadProgress = 0;
    this.bulkOnboardId = '';
    this.multipleOnboardJsonData = null;
    this.currentStep = MultipleOnboardSteps.IMPORT_AGENTS;
    this.lookupMethod = MultipleOnboardLookup.SSN;
  };
}

export const multipleOnboardStore = new MultipleOnboardStore();
