import { Button, Layout, Modal } from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import {
  RouteConstants,
  agencyOrganizationSubRoutes,
  hideSideBarRoutes,
} from '../constants/routes.constants';
import { useEffect, useState } from 'react';

import AgencyAdmin from '../pages/agency/settings/agencyAdmins';
import AgencyAgentOverview from '../pages/agents/agentsOverview/agencyAgentOverview';
import AgencyBlackList from '../pages/agency/blacklist/blacklist';
import AgencyLicensingLayout from './agencyLicensing/agencyLicensingLayout';
import AgencyRts from '../pages/agency/agencyRts/agencyRts';
import AgentActivity from '../pages/agents/agentsOverview/agentOverview/activity';
import AgentPortal from '../pages/agentSidePortal/agentPortal';
import AgentPortalLicenses from '../pages/agentSidePortal/agentLicenses';
import AgentSettings from '../pages/agentSidePortal/agentSettings';
import AgentSupportDocuments from '../pages/agentSidePortal/agentInformation/supportingDocuments/agent-support-documents';
import AllLicenses from '../pages/agentSidePortal/licenses/all-licenses';
import AllLicensesAgencyTab from '../pages/agency/licenses/allLicenses/agency/allLicensesAgencyTab';
import AllLicensesAgentsTab from '../pages/agency/licenses/allLicenses/all-licenses-agents-tab';
import BackgroundQuestionsScreen from '../pages/agentSidePortal/agentInformation/backgroundQuestions/backgroundQuestions';
import BulkAddressUpdate from '../pages/agency/bulkAddress';
import DetailsAgency from '../pages/agency/details/details';
import Downlines from '../pages/agency/downlines/downlines';
import EditStateGroup from '../pages/stateGroups/editStateGroup/editStateGroup';
import EmploymentHistory from '../pages/agentSidePortal/agentInformation/backgroundQuestions/employmentHistory';
import ErrorModal from './common/errorModal/errorModal';
import Header from './headers/header';
import { Helmet } from 'react-helmet';
import HomeRedirect from '../pages/homeRedirect';
import IndividualOnboardV2 from '../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2';
import InviteDownline from '../pages/agents/inviteDownline';
import Invites from '../pages/invites';
import LinkOutline from './common/icons/LinkOutline';
import ManageAssignments from '../pages/agents/manageAssignments/manageAssignment';
import ManageAssignmentsByProducer from '../pages/agents/manageAssignments/manageAssignmentByProducer';
import ManageAssignmentsProducerPortal from '../pages/agentSidePortal/manageAssignments/ManageAssignmentsProducerPortal';
import Menu from './menu';
import { MenuType } from '../enums/menuType.enum';
import { NODE_ENV } from '../enums/nodeEnv.enum';
import NiprCosts from '../pages/ledger/niprCosts';
import OnboardNewUserTour from './common/Tour/onboardNewUserTour';
import OnboardedMobile from '../auth/onboarding/signup/onboardedMobile';
import Organization from '../pages/agents/agentsOverview/organization';
import PageNotFound from '../pages/404';
import PendingAgencyApplications from '../pages/agency/licenses/applications/PendingAgencyApplications';
import PendingProducerApplications from '../pages/agency/licenses/applications/PendingProducerApplications';
import ProducerDashboard from '../pages/agentSidePortal/dashboard/dashboard';
import RedirectPage from '../pages/redirect';
import Rts from '../pages/agents/RTS/rts';
import RtsViewAgents from '../pages/agents/RTS/agentSpecificRTS';
import SavedList from '../pages/agents/agentsOverview/listView/savedList';
import StateGroups from '../pages/stateGroups/stateGroups';
import StripeCustomerPortal from '../pages/ledger/stripeCustomerPortal';
import StripePendingInvoice from './common/icons/stripePendingIcon';
import { StripeService } from '../services/stripe.service';
import SupportDocuments from '../pages/agents/agentsOverview/support-documents';
import { TAB_SCREEN } from '../utils/screen.utils';
import { TourConstants } from '../constants/tour.constant';
import UpLines from '../pages/agency/uplines/uplines';
import UserProfile from '../pages/agency/settings/userProfile';
import { UserType } from '../enums/userType.enum';
import ViewDownlineInvitations from '../pages/agency/downlines/viewInvitation';
import ViewInvitations from '../pages/agency/uplines/viewInvitation';
import ViewUserInvitations from '../pages/agency/settings/agencyAdminsInvite';
import { agencyProfileStore } from '../stores/agencyProfileStore';
import { appStateInfoStore } from '../stores/appStateInfo.store';
import { findRouteByPath } from '../utils/route.utils';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../stores/signupStore';
import { tourStore } from '../stores/tour.store';
import { trackCustomEvent } from '../utils/trackingUtils';
import { useAuth } from '../auth/authProvider';
import useWidth from '../hooks/useWidth';

const { Content } = Layout;

const EditStateGroupWrapper = () => {
  const { id } = useParams();
  return <EditStateGroup key={id} />;
};

function MyLayout() {
  const windowWith = useWidth();
  const { getAccessTokenSilently } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [helmetText, setHelmetText] = useState<string>('InsureTrek');
  const location = useLocation();
  const [newMenu, setNewMenu] = useState(MenuType.MainMenu);
  const { roles } = useAuth();
  const [stripePageLoader, setStripeLoader] = useState(false);

  useEffect(() => {
    const initializeStores = async () => {
      const token = await getAccessTokenSilently();

      if (signupStore.userType === UserType.ADMIN)
        await agencyProfileStore.loadAgencyDetails(token);
      //TODO Onboarding new stripe flow has to enable this
      // await stripeStore.init(token);

      await appStateInfoStore.initializeStore(token);
    };
    initializeStores();

    return () => sessionStorage.removeItem(TourConstants.AGENCY_ONBOARD_TOUR);
  }, []);

  useEffect(() => {
    const path = location.pathname;

    const matchedRoute = findRouteByPath(path);
    if (matchedRoute) {
      if (
        // admin portal agenty sider menu route keys
        [
          'admin-portal_agent-activity',
          'admin-portal_agent-overview',
          'admin-portal_agent-rts',
          'admin-portal_agent-support-documents',
          'agency-background-questions',
          'agency-employment-history',
          'admin-portal_agent-license',
          'admin-portal_agent-overview-all-license',
          'admin-portal_agent-overview-pending-applications',
        ].includes(matchedRoute.route.key)
      ) {
        setNewMenu(MenuType.SubMenu);
      } else {
        setNewMenu(MenuType.MainMenu);
        if (matchedRoute.route.headerText)
          appStateInfoStore.setHeader(matchedRoute.route.headerText);
      }
      appStateInfoStore.setIcon(matchedRoute.route.icon);
      appStateInfoStore.setSelectedMenuKey(
        matchedRoute.route.key === RouteConstants.rtsViewAgentsFromTable.key
          ? RouteConstants.agentRTS.key
          : agencyOrganizationSubRoutes.includes(matchedRoute.route.key)
            ? RouteConstants.agencyHierarchy.key
            : matchedRoute.route.key || ''
      );
      appStateInfoStore.setTooltip(matchedRoute.route.tooltip || '');
      setHelmetText(
        matchedRoute.route.helmetText
          ? matchedRoute.route.helmetText + ' | InsureTrek'
          : 'InsureTrek'
      );
    }
  }, [location.pathname, appStateInfoStore.header]);

  useEffect(() => {
    const path = location.pathname;

    const matchedRoute = findRouteByPath(path);
    if (matchedRoute) {
      const path = location.pathname;
      const currentRole = roles[0];

      trackCustomEvent(
        (matchedRoute.route.helmetText
          ? matchedRoute.route.helmetText
          : 'InsureTrek'
        ).trimEnd(),
        path,
        currentRole
      );
    }
  }, [location.pathname]);

  // need different padding on these below pages
  const isSiderPages =
    location.pathname === RouteConstants.manageAssignments.path ||
    location.pathname === RouteConstants.stateGroups.path ||
    location.pathname === RouteConstants.agencyHierarchy.path ||
    location.pathname === RouteConstants.agencyHierarchySavedLists.path;

  const stripeNavigation = async () => {
    try {
      setStripeLoader(true);
      const token = await getAccessTokenSilently();
      const response: any = await StripeService.getBillingPortalURL(token);
      window.open(
        response?.data?.billingDashboardUrl,
        '_blank',
        'rel=noopener noreferrer'
      );
    } catch (error) {
      console.error(error);
    } finally {
      setStripeLoader(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{helmetText}</title>
        <meta
          name="robots"
          content={
            process.env.REACT_APP_NODE_ENV !== NODE_ENV.PRODUCTION
              ? 'noindex'
              : undefined
          }
          data-react-helmet="true"
        />
      </Helmet>
      {/* mobile screen - insuretrek is not available on mobiles and tabs for now */}
      {windowWith < TAB_SCREEN ? (
        <Routes>
          <Route path={'*'} element={<OnboardedMobile />} />
        </Routes>
      ) : //  routes with custom sider
      hideSideBarRoutes?.some(
          (d) =>
            location.pathname === d ||
            // below two are dynamic routes
            location.pathname.includes(
              RouteConstants.manageAssignmentsByProducers.keyPath
            ) ||
            location.pathname.includes(RouteConstants.editStateGroup.keyPath)
        ) ? (
        <Routes>
          <Route
            path={RouteConstants.myAgencyManageAssignments.path}
            element={<AgencyLicensingLayout />}
          />
          <Route
            path={RouteConstants.manageAssignmentsByProducers.path}
            element={<ManageAssignmentsByProducer />}
          />
          <Route
            path={RouteConstants.manageBulkAssignmentsByProducers.path}
            element={<ManageAssignmentsByProducer isBulk={true} />}
          />
          <Route
            path={RouteConstants.onboardProducer.path}
            element={<IndividualOnboardV2 />}
          />
          <Route
            path={RouteConstants.createStateGroup.path}
            element={<EditStateGroup />}
          />
          <Route
            path={RouteConstants.editStateGroup.path}
            element={<EditStateGroupWrapper />}
          />
          {/* producer portal manage assignments */}
          <Route
            path={RouteConstants.manageAssignmentProducerPortal.path}
            element={<ManageAssignmentsProducerPortal />}
          />

          <Route
            path="*"
            element={
              <div className="grid place-content-center min-h-[100vh]">
                <PageNotFound showNavigateButton={true} />
              </div>
            }
          />
        </Routes>
      ) : (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
          <Menu menuType={newMenu} />
          <Layout>
            <Header headerText={appStateInfoStore.header} />

            <Content
              id="ant-layout-container"
              style={{
                padding: isSiderPages ? '0 0 0 15px' : '22px 20px',
                display: 'flex',
                flexGrow: '1',
                overflowY: 'auto',
              }}
            >
              <Routes>
                <Route
                  path={RouteConstants.agencyBackgroundQuestions.path}
                  element={
                    <div style={{ width: '100%' }}>
                      <BackgroundQuestionsScreen
                        isAgency={true}
                        isLoading={false}
                        editMode={true}
                        isCollapsible
                      />
                    </div>
                  }
                />
                <Route
                  path={RouteConstants.agencyEmploymentHistory.path}
                  element={
                    <EmploymentHistory isAgency={true} isOnboarded={true} />
                  }
                />
                <Route
                  path={RouteConstants.agentOverviewAllLicenses.path}
                  element={
                    <AllLicensesAgentsTab key={'producer-overview-in-admin'} />
                  }
                />
                <Route
                  path={RouteConstants.rootRedirect.path}
                  element={<HomeRedirect />}
                />
                <Route
                  path={RouteConstants.allAgents.path}
                  element={<AgencyAgentOverview />}
                />
                <Route
                  path={RouteConstants.licensesAgency.path}
                  element={<AllLicensesAgencyTab />}
                />
                <Route
                  path={RouteConstants.agentOverview.path}
                  element={<ProducerDashboard isAdmin={true} />} // todo
                />
                <Route
                  path={RouteConstants.inviteDownline.path}
                  element={
                    <InviteDownline
                      onError={(error) => {
                        console.error('setErrorMessage :: ', error);
                        setErrorMessage(error);
                      }}
                    />
                  }
                />
                <Route path={RouteConstants.agentRTS.path} element={<Rts />} />
                <Route
                  path={RouteConstants.rtsViewAgentsFromTable.path}
                  element={<RtsViewAgents />}
                />
                <Route
                  path={RouteConstants.agentPortalRTS.path}
                  element={
                    <RtsViewAgents isAgentPortal={true} hideBioInfo={true} />
                  }
                />
                <Route
                  path={RouteConstants.rtsViewAgentsFromOverView.path}
                  element={<RtsViewAgents hideBioInfo={true} />}
                />
                <Route
                  path={RouteConstants.agencyRTS.path}
                  element={<AgencyRts />}
                />
                <Route
                  path={RouteConstants.agentActivity.path}
                  element={<AgentActivity />}
                />
                <Route
                  path={RouteConstants.agencySupportDocuments.path}
                  element={<SupportDocuments />}
                />
                <Route
                  path={RouteConstants.agentSupportDocuments.path}
                  element={<AgentSupportDocuments />}
                />

                <Route
                  path={RouteConstants.agentAllLicenses.path}
                  element={<AllLicenses />}
                />

                <Route
                  path={RouteConstants.agencyLicenses.path}
                  element={
                    <AllLicensesAgentsTab key={'producer-all-licenses'} />
                  }
                />

                <Route
                  path={RouteConstants.agencyHierarchy.path}
                  element={<Organization />}
                />
                <Route
                  path={RouteConstants.agencyHierarchySavedLists.path}
                  element={<SavedList />}
                />
                <Route
                  path={RouteConstants.downlines.path}
                  element={<Downlines />}
                />
                <Route
                  path={RouteConstants.uplines.path}
                  element={<UpLines />}
                />
                <Route
                  path={RouteConstants.invite_uplines.path}
                  element={<ViewInvitations />}
                />
                <Route
                  path={RouteConstants.invite_downlines.path}
                  element={<ViewDownlineInvitations />}
                />
                <Route
                  path={RouteConstants.invite_admins.path}
                  element={<ViewUserInvitations />}
                />
                <Route
                  path={RouteConstants.stateGroups.path}
                  element={<StateGroups />}
                />
                <Route
                  path={RouteConstants.agentDashBoard.path}
                  element={<ProducerDashboard />}
                />
                <Route
                  path={RouteConstants.agencyGeneralProfile.path}
                  element={<DetailsAgency />}
                />
                <Route
                  path={RouteConstants.agencyAdminProfile.path}
                  element={<UserProfile />}
                />

                <Route
                  path={RouteConstants.agentProfile.path}
                  element={<UserProfile hideDetails={true} />}
                />

                <Route
                  path={RouteConstants.agentPortal.path}
                  element={
                    <div>
                      <AgentPortal />
                    </div>
                  }
                />
                <Route
                  path={RouteConstants.agentPortalLicenses.path}
                  element={
                    <div>
                      <AgentPortalLicenses />
                    </div>
                  }
                />
                <Route
                  path={RouteConstants.agentSettings.path}
                  element={
                    <div>
                      <AgentSettings />
                    </div>
                  }
                />
                <Route
                  path={RouteConstants.backgroundQuestions.path}
                  element={
                    <div style={{ width: '100%' }}>
                      <BackgroundQuestionsScreen
                        isLoading={false}
                        editMode={true}
                        isCollapsible
                      />
                    </div>
                  }
                />

                <Route
                  path={RouteConstants.employmentHistory.path}
                  element={<EmploymentHistory isOnboarded={true} />}
                />
                <Route
                  path={RouteConstants.manageAssignments.path}
                  element={<ManageAssignments />}
                />
                <Route
                  path={RouteConstants.admins.path}
                  element={<AgencyAdmin />}
                />
                <Route
                  path={RouteConstants.blacklist.path}
                  element={<AgencyBlackList />}
                />
                <Route
                  path={RouteConstants.agencyApplications.path}
                  element={<PendingAgencyApplications />}
                />
                <Route
                  path={RouteConstants.agencyAgentApplications.path}
                  element={
                    <PendingProducerApplications key="agency-pending-applications" />
                  }
                />
                <Route
                  path={RouteConstants.agentOverviewPendingApplications.path}
                  element={
                    <PendingProducerApplications
                      isAgentOverview={true}
                      key="agency-agent-overview-pending-applications"
                    />
                  }
                />
                <Route
                  path={RouteConstants.agentApplications.path}
                  element={
                    <PendingProducerApplications
                      isAgentPortal={true}
                      key="producer-portal-pending-applications"
                    />
                  }
                />
                <Route
                  path={RouteConstants.bulkAddressUpdate.path}
                  element={<BulkAddressUpdate />}
                />
                <Route
                  path={RouteConstants.niprTransaction.path}
                  element={<NiprCosts />}
                />

                <Route
                  path={RouteConstants.invites.path}
                  element={<Invites />}
                />
                <Route
                  path={RouteConstants.billing.path}
                  element={<StripeCustomerPortal />}
                />
                {/* producer portal billing */}
                <Route
                  path={RouteConstants.produerPortalBilling.path}
                  element={<StripeCustomerPortal />}
                />
                <Route path="redirect" element={<RedirectPage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Content>
            {errorMessage && (
              <ErrorModal
                errorMessage={errorMessage}
                onClose={() => setErrorMessage(null)}
              />
            )}

            {tourStore.signupDemoTour && <OnboardNewUserTour />}
          </Layout>
        </Layout>
      )}
      <Modal
        open={appStateInfoStore.getShowPendingStripeInvoiceModal()}
        centered
        closable={false}
        className="zero-padding"
        footer={null}
        width={'574px'}
      >
        <div>
          <div className="bg-[#FFC942] h-10 mb-7 flex items-center rounded-t-lg">
            <div className="flex justify-between px-3 py-2 w-full">
              <div className="flex gap-3">
                <InfoCircleOutlined
                  className="text-[#222222]"
                  style={{ color: '#222222' }}
                />
                <div className="font-figtree">Payment Due</div>
              </div>
              <CloseOutlined
                className="text-[100px]"
                onClick={() => {
                  appStateInfoStore.setShowPendingStripeInvoiceModal(false);
                }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center w-full">
            <StripePendingInvoice className="h-[135px] w-[209px]" />
          </div>
          <div className="py-2">
            <div className="flex justify-center items-center font-normal text-[14px] font-figtree">
              We were unable to process your payment
            </div>
            <div className="flex justify-center items-center font-normal text-[14px] font-figtree">
              Please update your card details
            </div>
          </div>
          <div className="flex justify-center items-center gap-5 py-5">
            <Button
              className="flex justify-center items-center bg-[#E8EBEE] h-9 w-24 rounded-lg"
              onClick={() => {
                appStateInfoStore.setShowPendingStripeInvoiceModal(false);
              }}
            >
              Close
            </Button>
            <Button
              className="inline-flex justify-center items-center bg-[#001F45] text-white h-9 w-32 rounded-lg"
              onClick={() => {
                stripeNavigation();
              }}
              loading={stripePageLoader}
            >
              <LinkOutline className="mr-2" fill="#FFFFFF" />
              Visit Billing
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default observer(MyLayout);
