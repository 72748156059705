import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';

import closeIcon from '../assets/icons/close-line.svg';
import externalLinkIcon from '../assets/icons/external-link-line.svg';
import infoIcon from '../assets/icons/information-line.svg';
import missingCardIcon from '../assets/icons/missing-card-details.svg';

interface CardDetailsModalProps {
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleBilling: () => void;
  loading: boolean;
}

const CardDetailsModal: React.FC<CardDetailsModalProps> = ({
  modalVisibility,
  setModalVisibility,
  handleBilling,
  loading,
}) => {
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      modalBodyRef.current &&
      !modalBodyRef.current.contains(event.target as Node)
    ) {
      setModalVisibility(false);
    }
  };

  useEffect(() => {
    if (modalVisibility) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisibility]);

  return (
    <Modal
      open={modalVisibility}
      centered
      closable={false}
      footer={null}
      bodyStyle={{
        overflow: 'auto',
        padding: 0,
      }}
      style={{
        height: '218px',
        width: '464px',
        padding: '0 !important',
      }}
      className="card-details-modal"
    >
      <div ref={modalBodyRef} className="rounded-[8px] p-0">
        <div className="flex flex-col gap-[22px]">
          <div
            className="h-[40px] bg-[#9D0F0F] flex flex-row"
            style={{
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              padding: '0 10px 0 22px',
              gap: '8px',
            }}
          >
            <img src={infoIcon} alt="inf0" />
            <span className="text-white text-sm font-figtree font-medium content-center">
              Card Details Missing
            </span>
            <img
              src={closeIcon}
              alt="close"
              className={`absolute right-[10px] pt-[8px] ${
                loading ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => !loading && setModalVisibility(false)}
            />
          </div>
          <img
            className="self-center max-w-[210px] max-h-[135px]"
            src={missingCardIcon}
            alt="card"
          />
          <span className="max-w-[348px] font-figtree font-normal text-sm self-center text-center">
            We were unable to apply for license Please update your card details
            to proceed with
          </span>
          <div className="flex flex-row justify-center gap-[16px] mb-[22px]">
            <Button
              style={{
                color: '#001F45',
                lineHeight: '1.5',
              }}
              className="bg-[#E8EBEE] w-[102px] rounded-[8px] h-[36px] px-6 text-sm font-normal border-none shadow-none flex items-center justify-center font-figtree !important"
              onClick={() => setModalVisibility(false)}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              style={{
                color: '#fff',
                lineHeight: '1.5',
                opacity: loading ? 0.6 : 1,
                cursor: loading ? 'not-allowed' : 'pointer',
                backgroundColor: '#001F45',
              }}
              className={`w-[153px] h-[36px] rounded-[8px] gap-[10px] px-6 text-base font-normal border-none shadow-none flex items-center justify-center font-figtree ${
                loading ? 'pointer-events-none' : ''
              }`}
              onClick={handleBilling}
              disabled={loading}
            >
              <img src={externalLinkIcon} alt="link" />
              Visit Billing
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CardDetailsModal;
