export enum AgencyLicenseType {
  ACTIVE = 'active',
  NEEDS_ATTENTION = 'needsAttention',
  RENEWALS = 'renewal',
  ALL = 'all',
}

export enum ProducerLicenseType {
  ACTIVE = 'active',
  NEEDS_ATTENTION = 'needsAttention',
  RENEWALS = 'renewal',
  ALL = 'all',
}
