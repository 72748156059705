import { Select, Spin, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { PayeeEnum } from '../../../enums/payee.enum';
import { PaymentProps } from '../../../types/request/agents/dashboard.type';
import { agentSideStore } from '../../../stores/agentPortalStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getPayee } from '../../../utils/producer/producerPaymentConfig.utils';
import { twMerge } from 'tailwind-merge';

/**
 * Agent Payment Configuration Component
 *
 * This component manages the payment settings for an agent or individual producer.
 * It provides options for configuring the initial payment and renewal payment settings.
 *
 * @typedef {Object} PaymentProps - Props for the AgentPaymentConfig component.
 * @property {ProducerDetails} agent - The agent or producer details, including payment configuration.
 * @property {(checked: boolean) => void} onChange - Callback to handle changes in the auto-renewal switch.
 * @property {boolean} isIndividualProducer - Indicates if the user is an individual producer.
 * @property {boolean} isAdmin - Indicates if the user has admin privileges.
 * @property {boolean} loading - Determines if the loading spinner should be displayed.
 *
 * @param {PaymentProps} props - The props for the component.
 *
 * @returns {JSX.Element} The rendered Agent Payment Configuration Component.
 */
const AgentPaymentConfig = ({
  agent,
  onChange,
  isIndividualProducer,
  loading,
  isAdmin,
}: PaymentProps) => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const [autoRenew, setAutoRenew] = useState<boolean>(
    agent?.paymentConfig?.isAutoRenewalActive
  );

  useEffect(() => {
    setAutoRenew(agent?.paymentConfig?.isAutoRenewalActive);
  }, [agent?.paymentConfig?.isAutoRenewalActive]);

  const licenseInitialPayee = getPayee(
    agent?.paymentConfig?.licenseInitialPayee as PayeeEnum,
    isAdmin,
    agentSideStore.agencyName
  );
  const licenseRenewalPayee = getPayee(
    agent?.paymentConfig?.licenseRenewalPayee as PayeeEnum,
    isAdmin,
    agentSideStore.agencyName
  );

  const isDisabled =
    agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENCY &&
    !isIndividualProducer &&
    !isAdmin;

  const tooltip = isAdmin
    ? null
    : agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT ||
        isIndividualProducer
      ? autoRenew
        ? 'Your assigned licenses will be applied automatically and the required amount will be deducted'
        : 'Your assigned licenses will be not be applied automatically and you will have to apply them manually'
      : 'Agency will have to configure their auto apply settings';

  return (
    <Spin spinning={loading}>
      <div className="bg-white z-10  px-5 py-3 my-2 md:my-0 flex flex-col md:flex-row gap-3 rounded-lg">
        <div className="flex flex-col gap-2">
          <div className="leading-[14.63px] min-h-4 text-[12.19px] text-[#989898]">
            Initial Payment
          </div>
          <Select
            className="h-8 font-figtree min-w-[142px]"
            disabled={isDisabled || subscriptionStatusInactive || !isAdmin}
            value={licenseInitialPayee || undefined}
            onChange={(value) => {
              onChange(autoRenew, { licenseInitialPayee: value });
            }}
            placeholder="Select Initial Payee"
          >
            <Select.Option
              value={
                isIndividualProducer ? licenseInitialPayee : PayeeEnum.AGENCY
              }
            >
              <span className="capitalize">
                {isIndividualProducer ? licenseInitialPayee : PayeeEnum.AGENCY}
              </span>
            </Select.Option>
            {!isIndividualProducer && (
              <Select.Option value={PayeeEnum.AGENT}>
                <span className="capitalize">
                  {isAdmin ? PayeeEnum.AGENT : 'Self'}
                </span>
              </Select.Option>
            )}
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <div className="leading-[14.63px] text-[12.19px] flex gap-4 justify-between text-[#989898]">
            Renewal Payment{' '}
            <Tooltip title={tooltip}>
              <Switch
                onChange={(e) => onChange(e)}
                size="small"
                className={twMerge(
                  agent?.paymentConfig?.licenseRenewalPayee ===
                    PayeeEnum.AGENT ||
                    isIndividualProducer ||
                    isAdmin
                    ? 'opacity-100'
                    : 'opacity-50'
                )}
                disabled={
                  isDisabled ||
                  subscriptionStatusInactive ||
                  (agent?.paymentConfig?.licenseRenewalPayee ===
                    PayeeEnum.AGENT &&
                    isAdmin &&
                    !isIndividualProducer)
                }
                checked={!!autoRenew}
              />
            </Tooltip>
          </div>
          <Select
            className="h-8 font-figtree "
            disabled={isDisabled || subscriptionStatusInactive || !isAdmin}
            value={licenseRenewalPayee || undefined}
            onChange={(value) => {
              onChange(value === 'producer' ? false : autoRenew, {
                licenseRenewalPayee: value,
              });
              if (value === 'producer') setAutoRenew(false);
            }}
            placeholder="Select Renewal Payee"
          >
            <Select.Option
              value={
                isIndividualProducer ? licenseRenewalPayee : PayeeEnum.AGENCY
              }
            >
              <span className="capitalize">
                {isIndividualProducer ? licenseRenewalPayee : PayeeEnum.AGENCY}
              </span>
            </Select.Option>
            {!isIndividualProducer && (
              <Select.Option value={PayeeEnum.AGENT}>
                <span className="capitalize">
                  {isAdmin ? PayeeEnum.AGENT : 'Self'}
                </span>
              </Select.Option>
            )}
          </Select>
        </div>
      </div>
    </Spin>
  );
};

export default AgentPaymentConfig;
