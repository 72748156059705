import CustomButton from '../components/common/button/CustomButton';
import { RouteConstants } from '../constants/routes.constants';
import { TextDetail } from '../utils/modal/application-status/applicationstatus.style';
import { appStateInfoStore } from '../stores/appStateInfo.store';
import pageNotFoundSvg from '../assets/images/pageNotFound.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const PageNotFound: React.FC<{ showNavigateButton?: boolean }> = ({
  showNavigateButton,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    appStateInfoStore.setHeader(RouteConstants.pageNotFound.headerText);
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        justifyItems: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'white',
        width: '100%',
        justifyContent: 'space-evenly',
      }}
    >
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px',
          width: '100%',
          marginTop: 20,
        }}
      >
        <TextDetail color="#023047" fontSize="32px" fontWeight={500}>
          Oops! It seems like you&apos;ve ventured into uncharted territory.
        </TextDetail>
        <TextDetail color="#222" fontSize="18px" fontWeight={400}>
          The page you&apos;re looking for may have taken a detour.
        </TextDetail>
      </div>

      <img src={pageNotFoundSvg} width="270px" height="270px" alt="" />
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          width: '100%',
        }}
      >
        <TextDetail color="#023047" fontSize="32px" fontWeight={500}>
          Error 404: Page Not Found
        </TextDetail>
        <TextDetail color="#222" fontSize="18px" fontWeight={400}>
          In the meantime, you can navigate back to the homepage or contact our
          <a href="#"> support team</a> for assistance.
        </TextDetail>
        <TextDetail color="#222" fontSize="18px" fontWeight={400}>
          We apologize for the inconvenience. Happy navigating!
        </TextDetail>

        {showNavigateButton ? (
          <CustomButton
            type="internalNavigation"
            buttonName="Return to HomePage"
            buttonStyle="primary"
            onClick={() => navigate('/')}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PageNotFound;
