import { OnboardingLayout } from '../../components/layout/onboardingLayout';
import { RouteConstants } from '../../constants/routes.constants';
import SignUpAccountCreation from './signup/signUpAccountCreation';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useAuth } from '../authProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    fbq?: (...args: any[]) => void;
  }
}

const SignUpScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isOnboarded, onboardingStage } = useAuth();

  useEffect(() => {
    if (isAuthenticated()) {
      if (isOnboarded) navigate('/redirect');
      else if (!isEmpty(onboardingStage)) {
        navigate(RouteConstants.onboarding.path);
      }
    }
  }, [onboardingStage, isOnboarded, isAuthenticated]);

  const onSubmitSuccess = (response: any) => {
    // IF adminInvite - True --> Redirect to overview
    // IF agent attribute is present --> Agent Sign up (Work Experience Page)
    // IF agency attribute is not empty --> Take to basic info page and disable inputs
    // If agency attribute is empty --> Then usual flow
    if (response.inviteType === 'user') {
      navigate(RouteConstants.authUserInvite.path);
    } else if (response.agent) {
      // Navigate user to agent onboarding
      navigate('/agent-onboarding');
    }

    if (process.env.REACT_APP_NODE_ENV === 'production') {
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: process.env.REACT_APP_SIGNUP_CONVERSION_EVENT,
        });
      }

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');

        window.fbq('trackCustom', 'InsureTrek Sign Up');
      }
    }
    // else if (response.userType === 'admin' && !isEmpty(response.agency)) {
    //   navigate('/onboarding');
    //   Take to next step but disable inputs
    // } else if (response.userType === 'admin' && isEmpty(response.agency)) {
    //   Take to next step
    //   navigate('/onboarding');
    // }
    else if (!response.inviteType || response.inviteType === 'downline') {
      navigate(RouteConstants.onboarding.path);
    }
  };

  return (
    <OnboardingLayout step={1} flowType="SignUp">
      <SignUpAccountCreation onSubmitSuccess={onSubmitSuccess} />
    </OnboardingLayout>
  );
};

export default observer(SignUpScreen);
