import { apiStore } from '../stores/api.store';
import axios from 'axios';
import { cancelPreviousRequest } from '../utils/api.utils';

export interface Suggestion {
  street_line: string;
  secondary?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  entries?: number;
}

export class SmartyAddress {
  private static readonly API_KEY =
    process.env.REACT_APP_PUBLIC_SMARTY_EMBEDDED_KEY;
  private static readonly BASE_URL =
    process.env.REACT_APP_PUBLIC_SMARTY_BASEURL;

  public static async getSuggestions(
    search: string,
    selected?: string
  ): Promise<Suggestion[]> {
    if (!this.API_KEY || !this.BASE_URL) {
      console.error('API key or Base URL not set in environment variables');
      return [];
    }

    try {
      cancelPreviousRequest(apiStore.getTokenForGetUnconfiguredPaymentAgents());
      const cancelTokenSource = axios.CancelToken.source();
      const response = await apiStore.getApiClientForSmartyAddress(
        cancelTokenSource
      )<{ suggestions: Suggestion[] }>(this.BASE_URL, {
        params: {
          key: this.API_KEY,
          search,
          license: 'us-autocomplete-pro-cloud',
          ...(selected ? { selected } : {}),
        },
      });
      return response.data.suggestions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
