import {
  AutoComplete,
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AgentsDownlineInvitation } from '../../../types/data/agencyDownlineInvitation';
import { DownlineInviteService } from '../../../services/downline-invite.service';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import InviteDownlineTour from '../../../components/common/Tour/inviteDownlineTour';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import RevokeInviteModal from '../../../utils/modal/downlines/revoke-invite/revoke.invite';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { TableType } from '../../../enums/tableType.enum';
import { adminStore } from '../../../stores/admin.store';
import { applySort } from '../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function ViewInvitations() {
  const tableType = TableType.AGENCY_DOWNLINE_INVITATION;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [activeData, setActiveData] = useState<any>('');
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [agencyId, setAgencyId] = useState(adminStore.agency?.id);
  const { bearerToken } = useAuth();
  const { getAccessTokenSilently } = useAuth();
  const [revokeInviteModal, setRevokeInviteModal] = useState(false);
  const [InviteDetails, setInviteDetails] =
    useState<AgentsDownlineInvitation>();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [controlledFilters, setControlledFilters] = useState([]);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchDownlineInvitation(queryText || '', newPageNumber, newPageSize);
  };
  const [sortValue, setSortValue] = useState({});

  useEffect(() => {
    if (adminStore.agency?.id) {
      setAgencyId(adminStore.agency.id);
    }
  }, [adminStore.account]);

  const fetchDownlineInvitation = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        if (
          tourStore.inviteDownlineDemoTour &&
          tourStore.inviteDownlineDemoTourPhase === 3
        ) {
          tourStore.setInviteDownlineDemoTourVisible(false);
        }
        if (cancelToken) {
          cancelPreviousRequest(cancelToken);
        }
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: any = await DownlineInviteService.getDownlineInvitation(
          size || pageSize,
          page || pageNumber,
          bearerToken,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : '',
          { ...requestBody, ...(sort || sortValue || {}) },
          cancelTokenSource
        );
        if (response) {
          setCount(response.count);
          setActiveData(response.downlines);
          setIsLoading(false);
          setCancelToken(undefined);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
    } finally {
      if (
        tourStore.inviteDownlineDemoTour &&
        tourStore.inviteDownlineDemoTourPhase === 3
      ) {
        tourStore.setInviteDownlineDemoTourVisible(true);
      }
    }
  };

  const columns: ColumnsType<AgentsDownlineInvitation> = [
    {
      title: 'Associated Email',
      dataIndex: 'email',
      key: 'inviteeDetails.email',
      sorter: true,
      width: 200,
      render: (text: string, record: AgentsDownlineInvitation) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
                marginLeft: 12,
              }}
            >
              {text}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'Invited Using ',
      dataIndex: 'invitedUsing',
      key: 'invitedUsing',
      sorter: true,
      width: 200,
      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },

    {
      title: 'Invited By',
      dataIndex: 'invitedby',
      key: 'inviterDetails.account.name',
      sorter: true,
      width: 200,
      render: (text: string) => {
        return (
          <Row align="middle">
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {text}
            </Typography.Paragraph>
          </Row>
        );
      },
    },
    {
      title: (
        <span>
          Invited On{' '}
          <Tooltip title="The invite expires in 30 days from start date!">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: 'invitedOn',
      key: 'createdAt',
      sorter: true,
      width: 200,
      render: (text: string, record: AgentsDownlineInvitation) => {
        const inviteDate = new Date(text).getTime();
        const currentDate = new Date().getTime();
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const daysDifference = Math.floor(
          (currentDate - inviteDate) / millisecondsInDay
        );

        const expiryDays = 30;
        const color = record.isExpired ? '#ff4d4f' : '#faad14';
        let content = '';
        if (record.isExpired) {
          content = 'Invite Expired';
        } else if (daysDifference > 0 && daysDifference < expiryDays) {
          content = `Invite expires in ${expiryDays - daysDifference} ${
            daysDifference === 1 ? 'day' : 'days'
          }`;
        }

        return (
          <Row align="middle" style={{ textAlign: 'center' }}>
            <Tooltip title={content}>
              <Typography.Paragraph
                style={{
                  color: color,
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {text}
              </Typography.Paragraph>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      render: (record: AgentsDownlineInvitation) => {
        return (
          <Row
            align="middle"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              cursor: record.isExpired ? 'not-allowed' : 'pointer',
              color: record.isExpired ? '#999' : '#000',
            }}
          >
            <Typography.Paragraph
              onClick={() => {
                if (!record.isExpired) {
                  setInviteDetails(record);
                  setRevokeInviteModal(true);
                }
              }}
              style={{ color: record.isExpired ? '#999' : '#00a6fb' }}
            >
              <Tooltip title="Revoke Invite">
                <Button
                  disabled={record.isExpired}
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    opacity: record.isExpired ? '0.5' : '1',
                    background: 'var(--important-color)',
                    color: 'white',
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </Typography.Paragraph>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (bearerToken && agencyId) {
      fetchDownlineInvitation();
    }
  }, [bearerToken, tableType, agencyId, queryText, searchType]);

  const fetchFiltersInfoForFindAll = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await DownlineInviteService.getDownlineInvitationFilters(token);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.DOWNLINE_INVITATIONS
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchDownlineInvitation('', 1);
    setPageNumber(1);
  }, [requestBody]);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  useEffect(() => {
    fetchFiltersInfoForFindAll();
  }, []);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_VIEW_INVITATION;

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <div
      style={{ height: 'fit-content', width: '100%' }}
      className="table-layout-v2 relative"
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          controlledFilters={controlledFilters}
          fetchFilters={() => {
            return new Promise((resolve) =>
              resolve([] as FilterFieldsMetaData[])
            );
          }}
          clearFilters={() => {
            clearFilters();
          }}
          pageName={pageName}
          filterPage={PageConstants.DOWNLINE_INVITATIONS}
        />

        <div style={{ display: 'flex', columnGap: '12px', margin: '10px 0px' }}>
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            disabled={isLoading}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={
                IdConstants.SEARCH_INPUT + '-agency-dowlines-view-invitations'
              }
              size="middle"
              placeholder="Search Invites by Email or Invited By"
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>

          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>

        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters, pageName }}
        />

        <Row className="table">
          <Table
            columns={columns}
            dataSource={activeData}
            rowSelection={undefined}
            pagination={false}
            className={IdConstants.AGENTS.INVITE_DOWNLINE.VIEW_INVITATIONS}
            loading={isLoading}
            style={{ width: '100%' }}
            scroll={{
              y: 'calc(100vh - 280px)',
            }}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchDownlineInvitation);
              setSortValue(sort);
            }}
          />
        </Row>
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              current={pageNumber}
              total={count}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
        <RevokeInviteModal
          visible={revokeInviteModal}
          setVisible={setRevokeInviteModal}
          invitationDetails={InviteDetails}
          refresh={() => {
            setPageNumber(1);
            setRevokeInviteModal(false);
            fetchDownlineInvitation(undefined, 1);
          }}
        />
      </ConfigProvider>
      {tourStore.inviteDownlineDemoTour &&
        tourStore.inviteDownlineDemoTourVisible && <InviteDownlineTour />}
    </div>
  );
}

export default withRoles(observer(ViewInvitations), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
