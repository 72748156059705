import {
  Button,
  Card,
  Collapse,
  FloatButton,
  Modal,
  Row,
  Skeleton,
  Spin,
  Tooltip,
  message,
  notification,
} from 'antd';
import IndividualBackgroundQuestion, {
  RefObject,
} from './individualBackgroundQuestion';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { findIndex, groupBy, isEmpty, sortBy } from 'lodash';
import { unstable_useBlocker, useParams } from 'react-router';

import { AGENCY_BACKGROUND_QUESTIONS_TYPE } from '../../../agency/backgroundQuestions/myAgencyBackgroundQuestions';
import AgencyAssignedIcon from '../../../../components/common/icons/AgencyAssignedIcon';
import { AgentService } from '../../../../services/agent.service';
import { ApplicantType } from '../../../../enums/applicantType.enum';
import { DownOutlined } from '@ant-design/icons';
import EmptyState from '../../../../components/common/emptyState/emptyState';
import { LicensesService } from '../../../../services/licenses.service';
import { MasterDataService } from '../../../../services/masterData.service';
import { RcFile } from 'antd/es/upload';
import { RoleType } from '../../../../enums/roles.enum';
import { StateConstants } from '../../../../constants/state.constants';
import { TotalSizeInfo } from '../../../../components/totalSizeInfo';
// import { TotalSizeInfo } from '../../../../components/totalSizeInfo';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getNameString } from '../../../../utils/name.utils';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { observer } from 'mobx-react';
import { useAuth } from '../../../../auth/authProvider';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../../auth/useRoles';

export interface RefObject_ {
  validateAndGetFilledResponse: () => any;
  getAnswerDetails: () => any;
}

interface collapseProps {
  children: React.ReactNode;
  itemKey: string;
}

type GroupedBackgroundQuestions =
  | {
      [key in keyof typeof StateConstants]: Question[];
    }
  | { uniform: Question[] };

type Question = {
  description: string;
  questionId: string;
  answer: string;
  supportingDocuments: any[];
  hasAttachment: boolean;
  answerType: string;
  isDependent: boolean;
  stateCode: string;
  trigger: any;
  visible: boolean;
  isAgentSignup: boolean;
};

let uniqueIndexCounter = 0;

const AnswerOptions = () => [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

const Options = () => [
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
];
const BackgroundQuestionsScreen = React.forwardRef<
  RefObject_,
  {
    isLoading: boolean;
    editMode: boolean;
    isAgency?: boolean;
    isAgentSignup?: boolean;
    onBoardedAgentId?: string;
    isCollapsible?: boolean;
    isMyAgency?: boolean;
    selectedType?: string;
    searchValue?: string;
  }
>(
  (
    {
      isLoading = false,
      editMode = false,
      isAgency = false,
      onBoardedAgentId,
      isAgentSignup = false,
      isCollapsible = false,
      isMyAgency = false,
      selectedType = '',
      searchValue = '',
    },
    ref
  ) => {
    const subscriptionStatusInactive =
      appStateInfoStore.isInternalSubscriptionStatusInactive();
    const { bearerToken } = useAuth();
    const updatedAnswer = useRef<any>();
    const [isBlocking, setIsBlocking] = useState(false);
    const [searchParams] = useSearchParams();
    const blocker = onBoardedAgentId
      ? null
      : unstable_useBlocker(isBlocking && !isAgentSignup);
    const { getAccessTokenSilently } = useAuth();
    const { agentId } = useParams();
    const [isBgQnsLoading, setIsBgQnsLoading] = useState(false);
    const [isQuestionsLoading, setQuestionsLoading] = useState<boolean>(true);
    const allSaving = appStateInfoStore.backgroundQuestionsLoader;
    const [backgroundQuestions, setBackgroundQuestions] = useState<Question[]>(
      []
    );
    const [stateAssigned, setIsStateAssigned] = useState<string[]>([]);
    const [activeKeys, setActiveKeys] = useState<string | string[]>([]);
    const [allActiveKeys, setAllActiveKeys] = useState<string | string[]>([]);
    const [groupedBackgroundQuestions, setGroupedBackgroundQuestions] =
      useState<GroupedBackgroundQuestions>();
    const stateCode = searchParams.get('stateCode');

    const [prevBackgroundQuestions, setPrevBackgroundQuestions] = useState<
      Question[]
    >([]);
    const [savingQnType, setSavingQnType] = useState('');

    const [noSearchValue, setSearchState] = useState(false);
    const [formData] = useState(new FormData());
    const [prevAnswers, setPrevAnswers] = useState<any>();
    const [bgAnswers, setBackgroundAnswers] = useState<any>([]);
    const questionsRef = useRef<(RefObject | null)[]>([]);
    useImperativeHandle(ref, () => ({
      validateAndGetFilledResponse,
      getAnswerDetails,
    }));
    const backgroundAnswers = updatedAnswer.current || bgAnswers;

    const updateBackgroundAnswers = (questionId: any, fileList: any[]) => {
      setBackgroundAnswers((prevAnswers: any) => {
        const updatedAnswers = { ...(updatedAnswer.current || prevAnswers) };
        if (
          Object.prototype.hasOwnProperty.call(
            updatedAnswers?.[questionId]?.[0],
            'questionId'
          )
        ) {
          updatedAnswers[questionId][0].supportingDocuments = [...fileList];
        }
        return updatedAnswers;
      });
    };

    useEffect(() => {
      if (questionsRef?.current?.length !== backgroundQuestions?.length)
        questionsRef?.current?.fill(null, 0, backgroundQuestions.length - 1);
      setGroupedBackgroundQuestions(getGroupedQuestions(backgroundQuestions));
    }, [backgroundQuestions]);

    const getAnswerDetails = () => {
      return { prevAnswers, backgroundAnswers };
    };

    const updateSubAnswer = (
      questionId: string,
      answer: string | null,
      isParent = true
    ): any => {
      const subQuestions = backgroundQuestions.filter(
        (data) => data.questionId === questionId
      )?.[0];
      const prevAnswers = backgroundAnswers[subQuestions?.questionId]?.[0];
      if (!subQuestions?.visible && isParent) return [];
      if (subQuestions?.trigger?.length) {
        return [
          {
            ...subQuestions,
            answer: answer,
            supportingDocuments: !answer
              ? []
              : prevAnswers?.supportingDocuments || [],
          },

          ...(subQuestions.trigger
            ?.map((question: any) => {
              if (question.option.toString() !== answer) {
                const returnedAnswer = updateSubAnswer(
                  question?.questionId,
                  null,
                  false
                );
                return returnedAnswer?.flat(returnedAnswer?.length || 1);
              }
              return null;
            })
            .filter(Boolean) || []),
        ];
      } else {
        return [
          {
            ...subQuestions,
            answer: answer,
            supportingDocuments: !answer
              ? []
              : prevAnswers?.supportingDocuments || [],
          },
        ];
      }
    };

    const validateAndGetFilledResponse = (qnType?: string) => {
      // Later the assigned_states would be fetched from the Agents Document for each agent
      // Based on this the agent would answer questions
      const assigned_states = Object.keys(StateConstants);
      let answers: any[] = [];
      questionsRef.current.forEach((ref) => {
        if (ref) {
          const answer = ref.getFilledResponse();
          if (answer && (!qnType || qnType === answer?.stateCode)) {
            const subAnswers = updateSubAnswer(
              answer?.questionId,
              answer?.answer
            ).flat();
            answers = [...answers, ...subAnswers];
          }
        }
      });
      const visibleUniformBackgroundQuestions = backgroundQuestions.filter(
        (obj: Question) => obj.visible === true
      );

      const uniformQuestions = visibleUniformBackgroundQuestions.filter(
        (question) => !question.stateCode || question.stateCode === 'ALL'
      );

      const nonUniformQuestions = visibleUniformBackgroundQuestions.filter(
        (question) =>
          question.stateCode &&
          question.stateCode !== 'ALL' &&
          (!qnType || qnType === question.stateCode)
      );

      // Separate answers into uniform and non-uniform based on the presence of state
      const uniformAnswers = answers.filter(
        (answer) =>
          (!answer.stateCode || answer.stateCode === 'ALL') &&
          answer?.answer !== null &&
          answer?.answer !== ''
      );
      const nonUniformAnswers = answers.filter(
        (answer) =>
          (!qnType || answer.stateCode === qnType) &&
          answer.stateCode &&
          answer.stateCode !== 'ALL' &&
          answer?.answer !== null &&
          assigned_states?.includes(answer.stateCode) &&
          answer?.answer !== ''
      );

      // Compare lengths to determine if all questions have valid answers
      const isUniformAnswersValid =
        qnType && qnType !== 'ALL'
          ? true
          : uniformQuestions.length === uniformAnswers.length;
      const isNonUniformAnswersValid =
        qnType === 'ALL'
          ? true
          : nonUniformQuestions.length === nonUniformAnswers.length;

      return isUniformAnswersValid && isNonUniformAnswersValid ? answers : null;
    };

    const getGroupedQuestions = (bgQn: any[], mutateOriginal = false) => {
      const questions = mutateOriginal ? bgQn : bgQn.map((q) => ({ ...q }));

      questions.forEach((q) => {
        if (mutateOriginal) {
          q.visible = !q.isDependent;
        } else {
          q = { ...q, visible: !q.isDependent };
        }
      });

      const groupedQuestions = questions.reduce((acc, question) => {
        const state =
          question.stateCode === 'ALL' ? 'uniform' : question.stateCode;

        // If the state doesn't exist in the accumulator, initialize it as an empty array
        if (!acc[state]) {
          acc[state] = [];
        }

        // Push the current question into the appropriate state array
        acc[state].push(question);

        return acc;
      }, {});

      return groupedQuestions;
    };

    const getAllBackgroundQuestions = async (isUpdating?: boolean) => {
      try {
        if (bearerToken) {
          if (!isUpdating) setQuestionsLoading(true);
          const response: any = await MasterDataService.getBackgroundQuestions(
            bearerToken,
            isMyAgency ? ApplicantType.FIRM : ApplicantType.INDIVIDUAL
          );
          const sortedQuestions = sortBy(response, 'displayOrder');
          const groupedQuestions = getGroupedQuestions(sortedQuestions, true);
          setGroupedBackgroundQuestions(groupedQuestions);
          const keys = Object.entries(groupedQuestions || {}).map(
            ([k, v]) => k
          );
          setAllActiveKeys([...keys]);
          if (stateCode) {
            setActiveKeys([stateCode]);
            if (Object.keys(StateConstants).includes(stateCode)) {
              setTimeout(() => {
                const elem = document.getElementById(stateCode);
                elem?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }, 1500);
            }
          }
          setBackgroundQuestions(sortedQuestions);
          const backgroundAnswers: any =
            await AgentService.getOnboardedAgentBackgroundQuestions(
              bearerToken,
              isAgency ? onBoardedAgentId || agentId || '' : undefined,
              isMyAgency
            );
          if (isMyAgency) {
            const assignedStates =
              await LicensesService.getStateListFromAssignment(bearerToken);
            setIsStateAssigned(assignedStates?.map((d: any) => d.stateCode));
          }
          const groupedAnswers = groupBy(backgroundAnswers, 'questionId');

          sortedQuestions?.forEach((bgQn) => {
            const triggersToActivate: any[] = bgQn.trigger.filter(
              (t: { questionId: string; option: string }) => {
                return (
                  t.option.toString() ===
                  groupedAnswers?.[bgQn.questionKey]?.[0]?.answer
                );
              }
            );

            if (triggersToActivate.length > 0) {
              sortedQuestions.forEach((bgQn) => {
                if (
                  findIndex(triggersToActivate, {
                    questionKey: bgQn.questionKey,
                  }) > -1
                ) {
                  bgQn.visible = true;
                }
              });
            }
          });
          setBackgroundQuestions([...sortedQuestions]);
          setPrevBackgroundQuestions(
            structuredClone(sortedQuestions) // this line is to avoid shallow copy
          );
          updatedAnswer.current = null;
          setBackgroundAnswers({ ...groupedAnswers });
          setPrevAnswers(structuredClone(groupedAnswers));
          setQuestionsLoading(false);
        }
      } catch (error) {
        console.error('Error in getting background questions', error);
        setQuestionsLoading(false);
      }
    };

    useEffect(() => {
      setIsBgQnsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
      const handleBeforeUnload = (event: Event) => {
        if (!isBlocking || isAgentSignup || onBoardedAgentId) return;
        event.preventDefault();
        return 'block';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () =>
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isBlocking]);

    const loadAgent = async () => {
      if (agentId && bearerToken) {
        const response: any = await AgentService.getAgentDetails(
          agentId,
          bearerToken
        ).catch((error: any) => {
          console.error('Error:: ', error.message);
        });
        if (response.data) {
          appStateInfoStore.setCurrentAgentId(response.data.id);
          appStateInfoStore.setHeader(getNameString(response.data.name));
        }
      }
    };

    const getAnswers = (answers: any) => {
      const answer = Object.values(
        answers as {
          [key: string]: {
            answer: string;
            supportingDocuments: { uid: string }[];
          }[];
        }
      )
        ?.map((data) =>
          !data?.[0]?.answer
            ? ''
            : (data?.[0]?.answer as string) +
              (supportingDocumentUrl(data?.[0]?.supportingDocuments) ||
                'not-found')
        )
        .join('');

      return answer;
    };

    const supportingDocumentUrl = (supportingDocuments: { uid: string }[]) => {
      if (supportingDocuments?.length === 0) return '';
      return supportingDocuments
        ?.map((supportingDocument) => supportingDocument?.uid)
        ?.join('');
    };

    const updateBlocking = (prevAnswers: any, updatedAnswer: any) => {
      const prev = getAnswers(prevAnswers);
      const current = getAnswers(updatedAnswer);
      setIsBlocking(prev !== current);
    };

    useEffect(() => {
      if (onBoardedAgentId) return;
      if (prevAnswers && backgroundAnswers) {
        updateBlocking(prevAnswers, backgroundAnswers);
      }
    }, [prevAnswers, backgroundAnswers]);

    // const isSaveDisabledBySection = (section: string) => {
    //   const currentSectionQnIds: string[] = [];
    //   backgroundQuestions?.forEach((d) => {
    //     if (d.stateCode === section) {
    //       currentSectionQnIds.push(d.questionId);
    //     }
    //   });
    //   const updateBgAnswers = Object.entries(
    //     updatedAnswer.current || backgroundAnswers
    //   )?.reduce((acc: any, [k, v]) => {
    //     if (currentSectionQnIds?.includes(k)) {
    //       acc[k] = v;
    //     }
    //     return acc;
    //   }, {});
    //   const oldprevAnswers = Object.entries(prevAnswers)?.reduce(
    //     (acc: any, [k, v]) => {
    //       if (currentSectionQnIds?.includes(k)) {
    //         acc[k] = v;
    //       }
    //       return acc;
    //     },
    //     {}
    //   );
    //   const prev = getAnswers(oldprevAnswers);
    //   const current = getAnswers(updateBgAnswers);
    //   return prev === current;
    // };

    const onCancel = (cancelBySection?: string) => {
      // cancel the current changes by section -> cancelBySection
      const prevAnswersQns: string[] = [];
      const prevQns = cancelBySection
        ? backgroundQuestions?.map((d) => {
            if (d.stateCode !== cancelBySection) return d;
            prevAnswersQns.push(d.questionId);
            const findPrevQns = prevBackgroundQuestions.find(
              (da) => da.questionId === d.questionId
            ) as Question;
            return findPrevQns;
          }) || []
        : prevBackgroundQuestions;
      const prevAns = cancelBySection
        ? Object.entries(updatedAnswer.current || backgroundAnswers)?.reduce(
            (acc: any, [k, v]) => {
              if (prevAnswersQns?.includes(k)) {
                acc[k] = prevAnswers?.[k];
              } else {
                acc[k] = v;
              }
              return acc;
            },
            {}
          )
        : prevAnswers;

      const sortedQuestions = structuredClone(prevQns);
      setBackgroundAnswers(structuredClone(prevAns));
      updatedAnswer.current = prevAns;
      setBackgroundQuestions(sortedQuestions);
      const groupedQuestions = getGroupedQuestions(sortedQuestions);
      setGroupedBackgroundQuestions(groupedQuestions);
    };

    useEffect(() => {
      if (isAgency) {
        loadAgent();
      }
    }, []);

    const updateBackGroundQuestionResponse = async (
      response: any,
      questionId: string
    ) => {
      const questionToBeUpdated: any = backgroundQuestions.filter(
        (obj: Question) => obj.questionId === questionId
      );

      backgroundAnswers[questionToBeUpdated[0].questionId][0].answer =
        response.answer;
      backgroundAnswers[
        questionToBeUpdated[0].questionId
      ][0].supportingDocuments = response.supportingDocuments;

      if (
        questionToBeUpdated[0].trigger.length > 0 &&
        !isEmpty(response.childQuestionsResponse)
      ) {
        const groupedChildResponse = groupBy(
          response.childQuestionsResponse,
          'questionKey'
        );

        questionToBeUpdated[0].trigger.forEach(
          (t: { questionId: string; option: string }) => {
            backgroundAnswers[t.questionId][0].answer =
              groupedChildResponse[t.questionId][0]?.answer;
            backgroundAnswers[t.questionId][0].supportingDocuments =
              groupedChildResponse[t.questionId][0].supportingDocuments;
          }
        );
      }

      setBackgroundAnswers({ ...backgroundAnswers });
      updatedAnswer.current = null;
      setBackgroundQuestions([...backgroundQuestions]);
    };

    useEffect(() => {
      getAllBackgroundQuestions();
    }, []);

    const deleteChildAnswerOnCancel = (question: Question) => {
      if (question.trigger.length > 0) {
        question.trigger.forEach(
          (t: { questionId: string; option: string }) => {
            for (const [key] of formData.entries()) {
              if (key.startsWith(t.questionId)) {
                formData.delete(key);
              }
            }
          }
        );
      }
    };

    const updateAnswer = (
      question: Question,
      answer: any,
      filledAnswer?: any
    ) => {
      // Check if the question has any triggers
      let triggered = false;
      if (question.trigger.length > 0) {
        triggered = true;
        // Filter the triggers that match the given answer
        const triggersToActivate: any[] = question.trigger.filter(
          (t: { questionKey: string; option: string }) =>
            t.option.toString() === answer?.answer
        );

        // If there are any matching triggers, make the corresponding background questions visible
        if (triggersToActivate.length > 0) {
          backgroundQuestions.forEach((bgQn) => {
            if (
              findIndex(triggersToActivate, {
                questionKey: bgQn.questionId,
              }) > -1
            ) {
              bgQn.visible = true;
            }
          });
        } else {
          // If no matching triggers, hide the corresponding background questions and reset their answers
          backgroundQuestions.forEach((bgQn) => {
            const index = findIndex(question.trigger, {
              questionKey: bgQn.questionId,
            });
            if (index > -1) {
              bgQn.visible = false;
              updateAnswer(bgQn, null); // Recursively update the answer to null
            }
          });
        }

        // Update the state of background questions
        setBackgroundQuestions([...backgroundQuestions]);
      }

      // Check if there is already an answer for the given question
      // backgroundAnswers?.[question.questionId]?.[0] -> now by default answers api returns as []
      updatedAnswer.current = {
        ...(updatedAnswer.current || backgroundAnswers),
        [question.questionId]: [
          {
            ...((updatedAnswer.current || backgroundAnswers)?.[
              question.questionId
            ]?.[0] || {}),
            ...(isCollapsible ? filledAnswer : {}),
            ...(answer || {}), // Update with the new answer
            ...(answer?.answer === 'false'
              ? { supportingDocuments: [] } // Clear supporting documents if the answer is 'false'
              : {}),
          },
        ],
        ...updateSubAnswerWhenChanged(question.questionId, answer?.answer), // Update sub-answers if changed
      };
      if (triggered) {
        setBackgroundAnswers(updatedAnswer.current);
        updatedAnswer.current = null;
      } else {
        updateBlocking(prevAnswers, updatedAnswer.current);
      }
    };

    const updateChildAnswer = (
      questionId: string,
      ChildQuestionAnswers: FormData
    ) => {
      for (const [key] of formData.entries()) {
        if (key.startsWith(questionId)) {
          formData.delete(key);
        }
      }
      for (const [key, value] of ChildQuestionAnswers.entries()) {
        formData.append(key, value);
      }
    };

    const updateSubAnswerWhenChanged = (
      questionId: string,
      answer: string
    ): any => {
      if (answer === 'false') {
        const subQuestions = backgroundQuestions.filter(
          (data) => data.questionId === questionId
        )?.[0];
        if (subQuestions?.trigger?.length) {
          return {
            [subQuestions?.trigger?.[0]?.questionKey]: [
              {
                ...subQuestions,
                answer: null,
                supportingDocuments: [],
              },
            ],
            ...updateSubAnswer(subQuestions.trigger?.[0]?.questionKey, 'false'),
          };
        }
        return {};
      }
      return {};
    };

    const resetPrevQuestionsOnSave = (answers: any) => {
      const groupedAnswers = groupBy(answers, 'questionKey');
      setPrevAnswers(groupedAnswers);

      // need to revamp
      prevBackgroundQuestions?.forEach((bgQn) => {
        const triggersToActivate: any[] = bgQn.trigger.filter(
          (t: { questionId: string; option: string }) => {
            return (
              t.option.toString() ===
              groupedAnswers?.[bgQn.questionId]?.[0]?.answer
            );
          }
        );

        if (triggersToActivate.length > 0) {
          prevBackgroundQuestions.forEach((bgQn) => {
            if (
              findIndex(triggersToActivate, {
                questionKey: bgQn.questionId,
              }) > -1
            ) {
              bgQn.visible = true;
            }
          });
        } else {
          const triggersToNotActivate: any[] = bgQn.trigger.filter(
            (t: { questionId: string; option: string }) => {
              return (
                t.option.toString() !==
                groupedAnswers?.[bgQn.questionId]?.[0]?.answer
              );
            }
          );
          prevBackgroundQuestions.forEach((bgQn) => {
            if (
              findIndex(triggersToNotActivate || [], {
                questionKey: bgQn.questionId,
              }) > -1
            ) {
              bgQn.visible = false;
            }
          });
        }
      });
      setPrevBackgroundQuestions(structuredClone(prevBackgroundQuestions));
    };

    const onAllQuestionsSave = async (qnType?: string) => {
      const answers = validateAndGetFilledResponse(qnType);

      if (answers !== null && Array.isArray(answers)) {
        setIsBgQnsLoading(true);
        setSavingQnType(qnType || '');
        const formData = new FormData();
        for (const answer of answers) {
          if (!answer.questionId) continue;
          if (answer.hasAttachment && answer.answer === 'true') {
            // let alreadyUploaded = false;
            const allFilesInAws = answer.supportingDocuments.every(
              (data: any) => data.url || data.s3Key
            );
            for (const file of answer.supportingDocuments) {
              if (file.url || file.s3Key) {
                if (
                  allFilesInAws &&
                  prevAnswers?.[answer.questionKey]?.[0]?.supportingDocuments
                    ?.length ===
                    backgroundAnswers?.[answer.questionKey]?.[0]
                      ?.supportingDocuments?.length
                ) {
                  // alreadyUploaded = true;
                } else {
                  const response = await fetch(file.url);
                  const blob = await response.blob();
                  const filename = file.name;
                  const uploadedFile = new File([blob], filename, {
                    type: blob.type,
                  });
                  formData.append(
                    answer.questionId + '_file',
                    uploadedFile as RcFile
                  );
                }
              } else {
                formData.append(
                  answer.questionId + '_file',
                  file.originFileObj as RcFile
                );
              }
            }
            formData.append(answer.questionId, answer.answer);
          } else {
            formData.append(answer.questionId, answer.answer);
          }
        }
        appStateInfoStore.setBackgroundQuestionsLoader(true);
        const bearerToken = await getAccessTokenSilently();
        AgentService.postBackGroundQuestions(
          bearerToken,
          formData,
          agentId || onBoardedAgentId || '',
          isMyAgency
        )
          .then((data: any) => {
            resetPrevQuestionsOnSave(data?.data);
            message.success('Successfully Updated..!');
          })
          .catch((error: any) => {
            console.error('Error:', error);
            message.error('Failed to submit the answers. Please try again.');
          })
          .finally(() => {
            appStateInfoStore.setBackgroundQuestionsLoader(false);
            setIsBgQnsLoading(false);
            setSavingQnType('');
          });
      } else {
        notification.error({
          message: 'Error',
          description: !qnType
            ? 'Fill All The Questions'
            : `Fill All The ${
                qnType === 'ALL' ? 'Generic' : StateConstants[qnType]
              } Questions`,
        });
      }
    };

    const CollapseContainer: React.FC<collapseProps> = ({
      children,
      itemKey,
    }) => {
      if (!isCollapsible) return <>{children}</>;

      const isStateAssigned = stateAssigned?.includes(itemKey);
      const collapseTitle = StateConstants[itemKey] || 'Generic questions';

      if (
        (selectedType === AGENCY_BACKGROUND_QUESTIONS_TYPE.ASSINGED_STATES &&
          !isStateAssigned) ||
        !collapseTitle?.toLowerCase()?.includes(searchValue?.toLowerCase())
      ) {
        return <div style={{ display: 'none' }}>{children}</div>;
      }

      const isNotOpen = !activeKeys?.includes(itemKey);
      const itemType = itemKey === 'uniform' ? 'ALL' : itemKey;
      const isQuestionSaving = savingQnType === itemType;

      return (
        <React.Fragment key={itemKey}>
          <Collapse
            key={itemKey}
            defaultActiveKey={allActiveKeys}
            expandIcon={() => <DownOutlined rotate={isNotOpen ? 270 : 0} />}
            onChange={() => {
              setActiveKeys((prev: string | string[]) => {
                if (Array.isArray(prev)) {
                  if (prev?.includes(itemKey))
                    return prev.filter((d) => d !== itemKey);
                  return [...prev, itemKey];
                }
                return [...prev, itemKey];
              });
            }}
            items={[
              {
                key: itemKey,
                id: itemKey,
                label: (
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                      color: '#001F45',
                      fontWeight: 500,
                    }}
                  >
                    <>{collapseTitle}</>
                    {isStateAssigned ? (
                      <Tooltip title="Assigned to agency">
                        <AgencyAssignedIcon />
                      </Tooltip>
                    ) : null}
                  </div>
                ),
                extra: isNotOpen ? null : (
                  <div
                    className="flex"
                    style={{ gap: 16, alignItems: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {' '}
                    <Button
                      className="figtree save-list-button"
                      style={{
                        background: '#FCFAFF',
                        color: '#001F45',
                        fontSize: 12,
                        lineHeight: '14.4px',
                        fontWeight: 500,
                        height: 24,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCancel(itemType);
                      }}
                      disabled={
                        savingQnType === itemType || subscriptionStatusInactive
                      }
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="figtree save-list-button"
                      style={{
                        background: 'var(--Secondary-Secondary, #001F45)',
                        color: '#FCFAFF',
                        fontSize: 12,
                        lineHeight: '14.4px',
                        fontWeight: 500,
                        height: 24,
                        opacity: isQuestionSaving ? 0.7 : 1,
                      }}
                      disabled={isQuestionSaving || subscriptionStatusInactive}
                      onClick={(e) => {
                        e.stopPropagation();
                        // onSaveList();
                        onAllQuestionsSave(itemType);
                      }}
                      loading={isQuestionSaving}
                      size="small"
                    >
                      Save changes
                    </Button>
                  </div>
                ),
                children: (
                  <div
                    {...(isNotOpen
                      ? { style: { width: 0, height: 0, overflow: 'hidden' } }
                      : {})}
                  >
                    {children}
                  </div>
                ),
              },
            ]}
            className="my-5"
            rootClassName={
              isNotOpen
                ? 'bg-collapsible-container-hide'
                : 'bg-collapsible-container'
            }
          ></Collapse>
        </React.Fragment>
      );
    };

    const renderQuestions = (groupKey: string, groupValue: any) => {
      return (
        <div key={groupKey}>
          {!isCollapsible ? <h3>{StateConstants[groupKey]}</h3> : null}

          <table style={{ width: '100%' }}>
            <tbody>
              {groupValue.map((question: Question) => {
                if (question?.visible) {
                  const uniqueIndex = uniqueIndexCounter++;
                  return (
                    <IndividualBackgroundQuestion
                      onBoardedAgentId={onBoardedAgentId}
                      question={question}
                      pageLoader={isBgQnsLoading}
                      numberOfSupportingDocuments={
                        backgroundAnswers
                          ? backgroundAnswers?.[question?.questionId]?.[0]
                              ?.supportingDocuments
                          : 0
                      }
                      answerUpdate={
                        backgroundAnswers
                          ? backgroundAnswers?.[question?.questionId]?.[0]
                              ?.answer
                          : ''
                      }
                      answer={
                        backgroundAnswers?.[question?.questionId]
                          ? backgroundAnswers?.[question?.questionId]
                          : null
                      }
                      answerOptions={
                        question.answerType === 'options'
                          ? Options()
                          : AnswerOptions()
                      }
                      key={question?.questionId}
                      editMode={false}
                      ref={(ref: any) => {
                        questionsRef.current[uniqueIndex] = ref;
                      }}
                      onAnswerChange={(a, b) =>
                        updateAnswer(
                          a,
                          b,
                          questionsRef.current[uniqueIndex]?.getFilledResponse()
                        )
                      }
                      onAnswerUpdate={updateBackGroundQuestionResponse}
                      onChildSave={updateChildAnswer}
                      childFormData={formData}
                      childAnswers={backgroundAnswers ? backgroundAnswers : ''}
                      deleteChildAnswerOnCancel={deleteChildAnswerOnCancel}
                      updateBackgroundAnswers={updateBackgroundAnswers}
                    />
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      );
    };

    const getGroupedBgQuestions = () =>
      groupedBackgroundQuestions &&
      Object.entries(groupedBackgroundQuestions).sort(([keyA], [keyB]) => {
        if (keyA === 'uniform') return -1;
        if (keyB === 'uniform') return 1;
        return getStateNameFromCode(keyA).localeCompare(
          getStateNameFromCode(keyB)
        );
      });

    useEffect(() => {
      const isNoData =
        searchValue &&
        !getGroupedBgQuestions()?.filter(([groupKey]) => {
          const collapseTitle = StateConstants[groupKey] || 'Generic questions';
          return collapseTitle
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()?.trim());
        })?.length;
      const stateAssignedState =
        selectedType === AGENCY_BACKGROUND_QUESTIONS_TYPE.ASSINGED_STATES &&
        !getGroupedBgQuestions()?.some(([groupKey]) => {
          return stateAssigned?.includes(groupKey);
        });
      setSearchState(!!isNoData || stateAssignedState);
    }, [searchValue, groupedBackgroundQuestions, selectedType]);

    return (
      <Spin
        spinning={allSaving}
        tip={'Saving...'}
        className="spin-tip"
        size="large"
      >
        {!noSearchValue ? (
          <Card
            style={{
              width: '100%',
              cursor: isBgQnsLoading ? 'not-allowed' : '',
            }}
          >
            {!isAgentSignup && !isCollapsible && (
              <TotalSizeInfo
                onSave={onAllQuestionsSave}
                isSaveMode={!onBoardedAgentId}
                isSaveDisabled={isQuestionsLoading || !isBlocking}
                isSaveLoading={isLoading || isBgQnsLoading}
                onCancel={onCancel}
              />
            )}
            {isCollapsible ? (
              <Row justify={'end'} style={{ gap: 16 }}>
                <Button
                  id="filters-button"
                  disabled={isLoading || isBgQnsLoading}
                  onClick={() => {
                    setActiveKeys([...allActiveKeys]);
                  }}
                >
                  Expand All
                </Button>
                <Button
                  id="filters-button"
                  disabled={isLoading || isBgQnsLoading}
                  onClick={() => {
                    setActiveKeys([]);
                  }}
                >
                  Collapse All
                </Button>
              </Row>
            ) : null}
            {isQuestionsLoading ? (
              <Skeleton active />
            ) : (
              <div style={{ paddingTop: '10px' }}>
                {getGroupedBgQuestions()?.map(([groupKey, groupValue]) => {
                  if (isCollapsible) {
                    return (
                      <CollapseContainer itemKey={groupKey} key={groupKey}>
                        {renderQuestions(groupKey, groupValue)}
                      </CollapseContainer>
                    );
                  }
                  return renderQuestions(groupKey, groupValue);
                })}
              </div>
            )}
          </Card>
        ) : null}
        <Modal
          title={isBgQnsLoading ? null : 'Are you sure you want to exit?'}
          open={blocker?.state === 'blocked'}
          maskClosable={false}
          closable={false}
          width={600}
          onOk={() => {
            if (isBgQnsLoading) {
              blocker?.reset?.();
            } else {
              blocker?.proceed?.();
            }
          }}
          onCancel={() => {
            blocker?.reset?.();
          }}
          cancelButtonProps={{
            style: { ...(isBgQnsLoading ? { display: 'none' } : {}) },
          }}
        >
          {isBgQnsLoading ? (
            <p>
              Changes to the responses are pending. Please wait until the
              updates are completed before navigating away from the page.
            </p>
          ) : (
            <p>
              Changes have been made to some responses. If you leave this
              section now, your edits will be discarded. Do you want to continue
              without saving?
            </p>
          )}
        </Modal>
        <FloatButton.BackTop
          target={() =>
            document.getElementById('ant-layout-container') || window
          }
          tooltip="Scroll to top"
        />
        {noSearchValue && (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              minHeight: '400px',
            }}
          >
            <EmptyState
              image="empty data"
              heading=""
              width="100%"
              content="No Records Found"
            />
          </div>
        )}
      </Spin>
    );
  }
);

BackgroundQuestionsScreen.displayName = 'BackgroundQuestionsScreen';

export default withRoles(observer(BackgroundQuestionsScreen), [
  RoleType.AGENT,
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
  RoleType.INDIVIDUAL_PRODUCER,
]);
