import { Button, Typography, notification } from 'antd';
import { TAB_SCREEN, isAboveTabletWidth } from '../../../utils/screen.utils';
import { isEmpty, isNull } from 'lodash';
import { useEffect, useState } from 'react';

import Loading from '../../../components/loading';
import { OnboardingLayout } from '../../../components/layout/onboardingLayout';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SignupService } from '../../../services/signup.service';
import { UserType } from '../../../enums/userType.enum';
import { adminStore } from '../../../stores/admin.store';
import { getQueryParams } from '../../../utils/queryParams.utils';
import logo from '../../../assets/icons/insuretrek-logo.svg';
import { observer } from 'mobx-react-lite';
import paymentProcessingIcon from '../../../assets/icons/payment-processing.svg';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../authProvider';
import { useNavigate } from 'react-router';
import useWidth from '../../../hooks/useWidth';

const StripeRedirection = () => {
  const windowWidth = useWidth();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(true);
  const [isPaymentVerified, setPaymentVerified] = useState(false);
  const [isVerifyStarted, setIsVerifyStarted] = useState(false);
  const [noQueryParam, setNoQueryParam] = useState<boolean>(false);
  const [stripeURL, setStripURL] = useState<string>('');
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isOnboarded,
    onboardingStage,
    bearerToken,
    refreshToken,
  } = useAuth();
  const queryParams = getQueryParams();
  const getSubscriptionItemQuantity = (): number => {
    if (
      adminStore.agency?.agencySignupInfoDto.agencyNpn &&
      adminStore.agency?.agencySignupInfoDto.producerNpn
    ) {
      return 2;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const handleVerify = async () => {
      if (!isOnboarded && !isVerifyStarted) {
        if (onboardingStage !== 'stripe-customer' && !isEmpty(queryParams)) {
          navigate(RouteConstants.onboarding.path);
        } else {
          setIsVerifyStarted(true);
          if (queryParams['session_id'] || queryParams['customer-id'])
            await refreshToken(true);
          setIsVerifyStarted(false);
        }
      }
    };
    if (isAuthenticated()) {
      if (!isNull(isOnboarded) && !isNull(onboardingStage)) {
        handleVerify();
      }
    } else {
      navigate({
        pathname: RouteConstants.rootRedirect.path,
        search:
          '?redirectTo=' +
          encodeURIComponent(
            window.location.pathname +
              window.location.hash +
              window.location.search
          ),
      });
    }
  }, [isOnboarded, onboardingStage]);

  useEffect(() => {
    if (!queryParams['session_id'] && !queryParams['customer-id']) {
      setNoQueryParam(true);
      getStripeURL();
    }
  }, [bearerToken]);

  useEffect(() => {
    if (isOnboarded) {
      api['success']({
        message: "Looks like you're all set",
        description: 'Welcome to InsureTrek',
      });
      if (!isAboveTabletWidth()) navigate(RouteConstants.onboardedMobile.path);
      else {
        if (localStorage.getItem('x-role') === RoleType.AGENT) {
          navigate(RouteConstants.agentAllLicenses.path);
        } else {
          navigate(RouteConstants.allAgents.path);
        }
      }
    } else if (onboardingStage === 'stripe-customer') {
      setIsVerifyStarted(true);
      processStripePaymentVerification();
    }
  }, [isOnboarded, onboardingStage]);

  const processStripePaymentVerification = () => {
    setLoading(true);
    if (
      bearerToken &&
      (queryParams['session_id'] || queryParams['customer-id'])
    ) {
      SignupService.validateStripePayment(bearerToken, {
        checkoutSessionId: queryParams['session_id'],
        customerId: queryParams['customer-id'],
        subscriptionItemQuantity: getSubscriptionItemQuantity(),
        b: queryParams['b'] === 'true',
      })
        .then(() => {
          api['success']({
            message: 'Payment Successful',
            description: 'Welcome to Insuretrek !',
          });
          setPaymentVerified(true);
          refreshToken(true);
        })
        .then(() => {
          return;
        })
        .catch((err: any) => {
          api['error']({
            message: 'Payment Processing Failed',
            description: 'Please try requesting another link',
          });
          setPaymentVerified(false);
        });
    }
    setLoading(false);
  };

  const getStripeURL = async () => {
    setLoading(true);
    try {
      if (bearerToken) {
        const response: any =
          await SignupService.getStripeValidURL(bearerToken);
        setStripURL(response.data.checkoutUrl);
      }
    } catch (error) {
      console.error('Error :: getStripeURL ::', error);
      api['error']({
        message: 'Unable to proceed',
        description: 'Please contact support',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    if (stripeURL) {
      window.location.href = stripeURL;
    }
  };

  return (
    <OnboardingLayout flowType="MagicLink">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}
      {windowWidth < TAB_SCREEN ? null : (
        <img alt="logo" src={logo} style={{ width: '200px' }} />
      )}{' '}
      <div className="responsive-container">
        <img alt="email-verification" src={paymentProcessingIcon} />
        {isLoading ? (
          <Loading fullHeight={false} />
        ) : (
          <div style={{ textAlign: 'center' }}>
            {!noQueryParam ? (
              <div>
                <Typography.Title level={2}>
                  {isPaymentVerified
                    ? "You're all set to experience InsureTrek! "
                    : 'Apologies. We are unable to capture your payment'}
                </Typography.Title>
                <Typography.Paragraph type="secondary">
                  {isPaymentVerified
                    ? 'We have initiated your subscription for Insuretrek.'
                    : 'Unable to process payment. Please reach out to support if the problem persists'}
                </Typography.Paragraph>{' '}
              </div>
            ) : (
              <div>
                <Typography.Title level={5} style={{ marginBottom: '10px' }}>
                  Payment information not added. Please click on the below
                  button to add
                </Typography.Title>
                <Button key="submit" type="primary" onClick={handleContinue}>
                  Continue to Subscription
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </OnboardingLayout>
  );
};

export default observer(StripeRedirection);
