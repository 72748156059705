import { Button, Space, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { MagicCodeVerificationModal } from './emailVerificationStep/magicCodeVerificationModal';
import { SUPPORT_MAIL } from '../../../constants/mail.constants';
import { SignupService } from '../../../services/signup.service';
import { isEmpty } from 'lodash';
import { useAuth } from '../../authProvider';

export type MagicLinkVerificationStage = 'magic-link' | 'no-magic-link';

type Props = {
  variant: MagicLinkVerificationStage;
  maskedEmail?: string;
};

export const SignUpEmailVerification = ({ variant }: Props) => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [businessEmail, setBusinessEmail] = useState<string>('');

  const [showCodeVerificationModal, setShowCodeVerificationModal] =
    useState(false);

  const [api, contextHolder] = notification.useNotification();
  const { getAccessTokenSilently, refreshToken } = useAuth();

  useEffect(() => {
    if (variant === 'magic-link') getMagicLinkStatus(false);
  }, []);

  const showError = (
    message: string,
    description: string,
    isWarning: boolean = false
  ) => {
    api[isWarning ? 'warning' : 'error']({
      message: message,
      description: description,
    });
  };

  const showSuccess = (type: string, message: string) => {
    api['success']({
      message:
        type === 'resend' ? 'Resend Successful' : 'Verification Successful',
      description: message,
    });
  };

  const getMagicLinkStatus = async (displayError = true) => {
    const token = await getAccessTokenSilently();
    if (token) {
      setIsRefreshLoading(true);
      const response: any = await SignupService.getMagicLinkStatus(token).catch(
        (err) => {
          console.error(err);
          showError('Error', err);
        }
      );
      if (response) {
        if (!isEmpty(response?.data?.businessEmail))
          setBusinessEmail(response?.data?.businessEmail);
        if (response.data.status === 'verified') {
          refreshToken(true)
            .then((res) => {
              showSuccess(
                'refresh',
                'Your agency has been successfully verified'
              );
            })
            .catch((err) => showError('Error', err?.message))
            .finally(() => {
              setIsRefreshLoading(false);
            });
        } else {
          if (displayError) {
            showError('Error', 'Email has not been verified yet', true);
          }
          setIsRefreshLoading(false);
        }
      } else {
        setIsRefreshLoading(false);
      }
    }
  };

  const onResendMagicLinkVerification = async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      setIsResendLoading(true);
      const response = await SignupService.resendMagicLink(token).catch((err) =>
        showError('Error', err)
      );
      if (response) showSuccess('resend', 'Magic Link has been resent');

      setIsResendLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-6 justify-start w-full">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}

      {variant === 'magic-link' && (
        <div className="flex flex-col gap-y-6 justify-start w-full">
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
            Verify your Business email
          </Typography.Title>
          <Typography.Paragraph className="no-margin-bottom">
            In order to pull your information, we have sent an email to the
            email address you have on file with NIPR. Check your inbox to verify
            that you have access to that account.
          </Typography.Paragraph>
          <Typography.Paragraph
            className="no-margin-bottom"
            style={{ color: '#A1A1A2', fontWeight: 600 }}
          >
            We have sent you verification link to {businessEmail}
          </Typography.Paragraph>
          <div>
            <Typography.Paragraph className="no-margin-bottom">
              Don&apos;t have access to that inbox, or need help finding it?
            </Typography.Paragraph>
            <Typography.Paragraph className="no-margin-bottom">
              Click&nbsp;
              <Link to={`mailto:${SUPPORT_MAIL}`} target="_blank">
                here
              </Link>
              &nbsp;to reach out to the team
            </Typography.Paragraph>
          </div>
          <div className="flex flex-col mobile:flex-row items-center mobile:space-between gap-3">
            <div>
              <Space>
                <Button
                  style={{ height: 36 }}
                  type="primary"
                  loading={isRefreshLoading}
                  onClick={() => getMagicLinkStatus()}
                  disabled={isResendLoading}
                >
                  Refresh
                </Button>
                <Button
                  style={{ height: 36 }}
                  type="primary"
                  loading={isResendLoading}
                  onClick={onResendMagicLinkVerification}
                  disabled={isRefreshLoading}
                >
                  Resend
                </Button>
              </Space>
            </div>
            <div>
              <Button
                style={{ height: 36 }}
                onClick={() => {
                  setShowCodeVerificationModal(true);
                }}
                disabled={isRefreshLoading || isResendLoading}
              >
                Verify with Code
              </Button>
            </div>
          </div>
          {/* Code Verification Modal */}
          <MagicCodeVerificationModal
            visible={showCodeVerificationModal}
            onCancel={() => {
              setShowCodeVerificationModal(false);
            }}
            onVerificationComplete={() => {
              setIsRefreshLoading(true);
              setShowCodeVerificationModal(false);
              refreshToken(true).then(() => {
                getMagicLinkStatus();
              });
            }}
            showError={showError}
          />
        </div>
      )}
      {variant === 'no-magic-link' && (
        <div className="flex flex-col gap-y-6 justify-start w-full">
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
            Unfortunately we were not able to verify you at this moment
          </Typography.Title>
          <Typography.Paragraph className="no-margin-bottom">
            Looks like your NIPR account doesn&apos;t have an associated
            business email.
          </Typography.Paragraph>
          <Typography.Paragraph className="no-margin-bottom">
            Click&nbsp;
            <Typography.Link target="_blank" href={`mailto:${SUPPORT_MAIL}`}>
              here&nbsp;
            </Typography.Link>
            to reach out to the team for help
          </Typography.Paragraph>

          <Link to={`mailto:${SUPPORT_MAIL}`} target="_blank">
            <Button style={{ height: 36, width: '40%' }} type="primary">
              Contact Support
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
