// src/components/PlausibleProvider.tsx

import React, { createContext, useContext } from 'react';

import Plausible from 'plausible-tracker';

interface PlausibleProviderProps {
  domain: string;
  children: React.ReactNode;
}

const plausibleInstance = Plausible();

const PlausibleContext = createContext(plausibleInstance);

export const usePlausible = () => {
  const context = useContext(PlausibleContext);
  if (!context) {
    throw new Error('usePlausible must be used within a PlausibleProvider');
  }
  return context;
};

const PlausibleProvider: React.FC<PlausibleProviderProps> = ({
  domain,
  children,
}) => {
  plausibleInstance.enableAutoPageviews();

  // Override trackPageview to include query parameters
  plausibleInstance.trackPageview({
    url: window.location.href,
    trackLocalhost: true,
  });

  // Manually track the first page load if needed
  plausibleInstance.trackPageview();

  return (
    <PlausibleContext.Provider value={plausibleInstance}>
      {children}
    </PlausibleContext.Provider>
  );
};

export default PlausibleProvider;
