import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Skeleton,
  Switch,
  Typography,
  notification,
} from 'antd';
import {
  CsvJson,
  multipleOnboardStore,
  producerViewTableContent,
} from '../../../stores/multipleOnboardStore';
import {
  MultipleOnboardLookup,
  MultipleOnboardSteps,
} from '../../../enums/onboardSteps.enum';
import OnboardDraft, { Report } from './draft/draft';
import Table, { ColumnsType } from 'antd/es/table';
import {
  licenseStateColumns,
  nameNpnColumns,
  ssnColumns,
} from '../../../utils/table-manager/lookupTemplate.table-manager';
import {
  licenseStateData,
  nameNpnData,
  ssnData,
} from '../../../data/mockData/onboardLookupTemplate.mockData';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AccountWithProducerDocument } from '../../../types/response/data/agents/agentDocument.type';
import AgencyLogo from '../../../components/common/agencyLogo/agencyLogo';
import { AgentService } from '../../../services/agent.service';
import { BulkOnboardingStage } from '../../../enums/bulkOnboardingStage.enum';
import CircularProgress from '@mui/joy/CircularProgress';
import { ErrorMessageConstants } from '../../../constants/error-message.constants';
import LookupTemplatePopup from '../../../components/common/lookupTemplatePopup/lookupTemplatePopup';
import Lottie from 'lottie-react';
import { NotificationType } from '../../../types/common/notification.type';
import { PayeeEnum } from '../../../enums/payee.enum';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import PreviewCSVModal from '../../../utils/onboard-agents/multipleOnboard/preview-csv';
import React from 'react';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { ScbReportService } from '../../../services/scb-report.service';
import { SelectOutlined } from '@ant-design/icons';
import arrowDown from '../../../assets/icons/bx-chevron-down.svg.svg';
import buildingIcon from '../../../assets/icons/buildingIcon.svg';
import { capitalize } from 'lodash';
import { getNameString } from '../../../utils/name.utils';
import googleSheetsLogo from '../../../assets/icons/googleSheetsLogo.svg';
import { observer } from 'mobx-react-lite';
import pendingFile from '../../../assets/icons/pending-file.json';
import producerIcon from '../../../assets/icons/producer-icon.svg';
import refreshReport from '../../../assets/icons/refresh-report.svg';
import successTick from '../../../assets/icons/success-tick (1).json';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

interface MultipleOnboardProps {
  isBlocking: boolean;
  openWarningModal: boolean;
  changeTab: (state: boolean) => void;
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
  refreshKey?: number;
  requestBody?: CsvJson[];
  setSelectedRecordId?: (record: string) => void;
  selectedRecordId?: string;
  setOnboardingStage?: any;
  onboardingStage?: string;
  data?: [];
  setData?: any;
  isRetrievingPreviewData?: boolean;
}

function MultipleOnboard({
  isBlocking,
  setIsBlocking,
  openWarningModal,
  changeTab,
  refreshKey,
  requestBody,
  selectedRecordId,
  setSelectedRecordId,
  setOnboardingStage,
  onboardingStage,
  data,
  setData,
  isRetrievingPreviewData,
}: MultipleOnboardProps) {
  const [fileName, setFileName] = useState<string>('Onboard_SSN_NPN.xlsx');
  const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>();
  const { getAccessTokenSilently } = useAuth();
  const [fileLink, setFileLink] = useState<string>(
    `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Onboard_SSN_NPN.xlsx`
  );
  const [loader, setLoading] = useState<boolean>(false);
  const blocker = unstable_useBlocker(isBlocking);
  const [leaving, setLeaving] = useState(false);
  const [warningModalVisibility, setWarningModalVisibility] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState<
    AccountWithProducerDocument[]
  >([]);

  const navigate = useNavigate();
  const downloadLink = 'exampleSheet.csv';
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['info']({
      message: 'Onboarding is in progress',
      description: message,
    });
  };
  const [csvData, setCsvData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Agency');

  const [paymentConfig, setPaymentConfig] = useState<{
    [key: string]: {
      licenseInitialPayee: string;
      licenseRenewalPayee: string;
      isAutoRenewalActive: boolean;
    };
  }>({});

  const showAlert = (message: string, type: NotificationType) => {
    api[type]({
      message: 'Notification',
      description: message,
    });
  };

  // const infoOptions = [
  //   {
  //     key: 'ssn',
  //     title: 'Individual SSN and Last Name',
  //     desc: 'These above columns should be in your file',
  //     value: MultipleOnboardLookup.SSN,
  //     fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/SSN_LastName.csv`,
  //   },
  //   // {
  //   //   key: 'npn',
  //   //   title: 'Name and NPN Number',
  //   //   desc: 'These above columns should be in your file',
  //   //   value: MultipleOnboardLookup.NPN,
  //   //   fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Name_NPN.csv`,
  //   // },

  //   // {
  //   //   key: 'state',
  //   //   title: 'Name, License Number and State',
  //   //   desc: 'These above columns should be in your file',
  //   //   value: MultipleOnboardLookup.LICENSE,
  //   //   fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/License_State.csv`,
  //   // },
  // ];

  const stepInfo = [
    {
      steps: 'CSV Upload',
      heading: '',
      content: '',
      key: 0,
    },
    {
      steps: 'Producers View',
      heading: 'Producers View',
      content:
        'All producers in the CSV File are mapped with our system fields and verified are shown here',
      key: 1,
    },
    {
      steps: 'Assignment',
      heading: 'Assign State or Territories',
      content:
        'Assign States or Territories to this producer" to "Assign States or Territories to this batch of prodcuers',
      key: 2,
    },
    {
      steps: 'Configure Payments',
      heading: 'Manage Payment Configs',
      content:
        'Configure who will pay for new licenses and renewals for all the producers',
      key: 3,
    },
    // {
    //   steps: 'Needs Attention',
    //   heading: 'Needs Attention',
    //   content: 'Take action on inactive licenses or missing LOAs',
    //   key: 4,
    // },
  ];

  const steps = stepInfo.map((info) => info.steps);

  const handleSearch = (event: any) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === '') {
      setFilteredData(csvData);
    } else {
      const filtered = csvData.filter((record: any) => {
        const name = record.name || {};
        const { firstName = '', lastName = '' } = name;
        const fullName = `${firstName} ${lastName}`.toLowerCase();

        return (
          fullName.includes(query) ||
          record.email?.toLowerCase().includes(query) ||
          record.ssn?.includes(query) ||
          record.npn?.includes(query)
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    if (
      multipleOnboardStore.currentStep === MultipleOnboardSteps.AGENT_VIEW &&
      leaving
    )
      setWarningModalVisibility(true);
    if (!isBlocking && leaving) {
      navigate(`${RouteConstants.allAgents.path}`);
    }
  }, [leaving, isBlocking]);

  useEffect(() => {
    return () => {
      multipleOnboardStore.resetMultipleOnboard();
    };
  }, []);

  useEffect(() => {
    if (multipleOnboardStore.currentStep === MultipleOnboardSteps.AGENT_VIEW)
      fetchBulkOnboardProducerMinimalDetails();
  }, [multipleOnboardStore.currentStep]);

  const [dropdownValues, setDropdownValues] = useState({});

  const handleDropdownChange = (recordKey: any, field: any, value: any) => {
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [recordKey]: {
        ...(prevValues as any)[recordKey],
        [field]: value,
      },
    }));
  };

  const fetchBulkOnboardProducerMinimalDetails = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getBulkProducerMinimal(
        token,
        multipleOnboardStore.bulkOnboardId
      );
      setCsvData(response.data);
      setFilteredData(response.data);
      const config = response.data.reduce((acc: any, record: any) => {
        if (record.npn) {
          acc[record.npn] = {
            licenseInitialPayee: 'agency',
            licenseRenewalPayee: 'agency',
            isAutoRenewalActive: true,
          };
        }
        return acc;
      }, {});
      setPaymentConfig(config);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleMenuClick = (e: any, npn: string, variation: string) => {
    const updatedConfig = { ...paymentConfig };
    const updatePayload = {
      ...(variation === 'newLicenses' && { licenseInitialPayee: e.key }),
      ...(variation === 'renewalLicenses' && {
        licenseRenewalPayee: e.key,
        ...(e.key === 'producer' && { isAutoRenewalActive: false }),
      }),
      ...(variation === 'autoRenew' && { isAutoRenewalActive: e }),
    };

    if (selectedRows.length === 0) {
      updatedConfig[npn] = {
        ...updatedConfig[npn],
        ...updatePayload,
      };
      setPaymentConfig(updatedConfig);
      return;
    }

    if (selectedRows.some((row: any) => row.npn === npn)) {
      selectedRows.forEach((row: any) => {
        const selectedNpn = row.npn;
        if (
          variation === 'autoRenew' &&
          e === true &&
          updatedConfig[selectedNpn]?.licenseRenewalPayee === 'producer'
        ) {
          return;
        }
        updatedConfig[selectedNpn] = {
          ...updatedConfig[selectedNpn],
          ...updatePayload,
        };
      });
    } else {
      updatedConfig[npn] = {
        ...updatedConfig[npn],
        ...updatePayload,
      };
    }

    setPaymentConfig(updatedConfig);
  };

  const menu = (npn: string, variation: string) => (
    <Menu
      onClick={(e) => handleMenuClick(e, npn, variation)}
      style={{
        width: '120px',
      }}
    >
      <Menu.Item key="agency">Agency</Menu.Item>
      {/* <Menu.Item key="producer">Producer</Menu.Item> */}
    </Menu>
  );

  const producerViewTableColumns: ColumnsType<producerViewTableContent> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'firstName',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'SSN',
      dataIndex: 'ssn',
      key: 'ssn',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'New Licenses',
      dataIndex: 'newLicenses',
      key: 'newLicenses',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div
            style={{
              height: '22px',
              width: '82px',
              borderRadius: '26px',
              border: '1px solid #4F9EFF',
              backgroundColor: '#D3E7FF',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              padding: '1px 6px',
            }}
          >
            <img
              src={
                record.npn &&
                paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                  ?.licenseInitialPayee === 'agency'
                  ? buildingIcon
                  : producerIcon
              }
              alt="icon"
              width="16px"
              height="16px"
            />
            <span
              style={{
                color: '#001F45',
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Figtree',
              }}
            >
              {record.npn &&
              paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                ?.licenseInitialPayee
                ? capitalize(
                    paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                      .licenseInitialPayee
                  )
                : 'Agency'}
            </span>
          </div>
          <Dropdown
            overlay={menu(record.npn || '', 'newLicenses')}
            trigger={['click']}
            placement="bottomCenter"
          >
            <img
              src={arrowDown}
              alt="arrow"
              width="10px"
              height="10px"
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
        </div>
      ),
    },

    {
      title: 'Renewal Licenses',
      dataIndex: 'renewalLicenses',
      key: 'renewalLicenses',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div
            style={{
              height: '22px',
              width: '82px',
              borderRadius: '26px',
              border: '1px solid #4F9EFF',
              backgroundColor: '#D3E7FF',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              padding: '1px 6px',
            }}
          >
            <img
              src={
                record.npn &&
                paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                  ?.licenseRenewalPayee === 'agency'
                  ? buildingIcon
                  : producerIcon
              }
              alt="icon"
              width="16px"
              height="16px"
            />
            <span
              style={{
                color: '#001F45',
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Figtree',
              }}
            >
              {record.npn &&
              paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                ?.licenseRenewalPayee
                ? capitalize(
                    paymentConfig[`${record.npn}` as keyof typeof paymentConfig]
                      .licenseRenewalPayee
                  )
                : 'Agency'}
            </span>
          </div>
          <Dropdown
            overlay={menu(record.npn || '', 'renewalLicenses')}
            trigger={['click']}
            placement="bottomCenter"
          >
            <img
              src={arrowDown}
              alt="arrow"
              width="10px"
              height="10px"
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
        </div>
      ),
    },

    {
      title: 'Auto Renew',
      dataIndex: 'autoRenew',
      key: 'autoRenew',
      render: (text, record) => (
        <Switch
          onChange={(e) => handleMenuClick(e, record.npn || '', 'autoRenew')}
          checked={
            paymentConfig[record.npn as any]?.isAutoRenewalActive || false
          }
          disabled={
            paymentConfig[record.npn as any]?.licenseRenewalPayee === 'producer'
          }
        />
      ),
    },
  ];

  const handleOnboard = async () => {
    setLoading(true);
    setIsBlocking(true);
    try {
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.multipleOnboard(
        token,
        multipleOnboardStore.bulkOnboardId,
        paymentConfig
      );
      multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.ONBOARDING);
      showError('Please try refreshing again in a while');
      multipleOnboardStore.setBulkAgentsIds(response?.producerIds as string[]);
    } catch (error) {
      setIsBlocking(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const handleNext = () => {
    multipleOnboardStore.setCurrentStep(multipleOnboardStore.currentStep + 1);
    multipleOnboardStore.setMaxStep(multipleOnboardStore.maxStep + 1);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleContinueOnboarding = (recordId: string) => {
    if (setSelectedRecordId) {
      setSelectedRecordId(recordId);
    }
    if (onboardingStage === BulkOnboardingStage.RETRIEVE_SCB)
      multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.RETRIEVE_REPORT);
    else if (onboardingStage === BulkOnboardingStage.CONTINUE_ONBOARDING)
      if (
        multipleOnboardStore.currentStep ===
        MultipleOnboardSteps.RETRIEVE_REPORT
      )
        multipleOnboardStore.setCurrentStep(
          MultipleOnboardSteps.RETRIEVED_REPORT
        );
      else multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.AGENT_VIEW);
    else if (onboardingStage === BulkOnboardingStage.ONBOARD_PROCESSING)
      multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.ONBOARDING);
    else if (onboardingStage === BulkOnboardingStage.COMPLETED)
      multipleOnboardStore.setCurrentStep(MultipleOnboardSteps.ONBOARDED);
  };

  const handlePrev = () => {
    if (multipleOnboardStore.currentStep === MultipleOnboardSteps.AGENT_VIEW) {
      setWarningModalVisibility(true);
    } else {
      if (
        multipleOnboardStore.currentStep === MultipleOnboardSteps.IMPORT_AGENTS
      ) {
        multipleOnboardStore.setStartedOnboarded(false);
      } else {
        multipleOnboardStore.setCurrentStep(
          multipleOnboardStore.currentStep - 1
        );
      }
    }
  };

  const handleStepClick = (step: number) => {
    if (multipleOnboardStore.currentStep <= multipleOnboardStore.maxStep) {
      multipleOnboardStore.setCurrentStep(step);
    }
  };

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (`${process.env.REACT_APP_CLOUD_FRONT_URL}`)
      if (
        multipleOnboardStore.lookupMethod === MultipleOnboardLookup.SSN &&
        process.env.REACT_APP_SSN_TEMPLATE_PATH
      ) {
        setColumns(ssnColumns);
        setData(ssnData);
        setFileName('SSN-LastName.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_SSN_TEMPLATE_PATH
        );
      } else if (
        multipleOnboardStore.lookupMethod === MultipleOnboardLookup.NPN &&
        process.env.REACT_APP_NPN_TEMPLATE_PATH
      ) {
        setColumns(nameNpnColumns);
        setData(nameNpnData);
        setFileName('Name-NPN.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_NPN_TEMPLATE_PATH
        );
      } else {
        setColumns(licenseStateColumns);
        setData(licenseStateData);
        setFileName('License-State.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_LICENSE_TEMPLATE_PATH
        );
      }
  }, [multipleOnboardStore.lookupMethod]);

  const validateSubmit = () => {
    const { initialPaymentPayee, renewalPaymentPayee, paymentType, amountCap } =
      multipleOnboardStore.paymentConfig;
    if (!initialPaymentPayee || !renewalPaymentPayee) {
      return false;
    }
    if (paymentType === PaymentTypeEnum.CAP && (!amountCap || amountCap <= 0)) {
      return false;
    }
    if (
      (initialPaymentPayee === PayeeEnum.AGENCY ||
        renewalPaymentPayee === PayeeEnum.AGENCY) &&
      (!paymentType || paymentType === PaymentTypeEnum.NA)
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      {contextHolder}
      <div>
        {!multipleOnboardStore.startedOnboard && (
          <div>
            <Card>
              <Typography.Paragraph
                style={{
                  fontSize: '20px',
                  color: 'var(--primary-color)',
                  fontWeight: 500,
                  marginTop: '10px',
                }}
              >
                Basic Requirements
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  fontSize: '24px',
                  fontWeight: 500,
                  marginTop: '-10px',
                }}
              >
                What information do you have about agents?
                <span style={{ color: '#F00' }}>*</span>
              </Typography.Paragraph>

              <Row justify="space-between">
                {/* <RadioGroup
                options={infoOptions}
                selectedCard={multipleOnboardStore.lookupMethod}
                onOptionChange={(cardValue: MultipleOnboardLookup) => {
                  multipleOnboardStore.setLookupMethod(cardValue);
                }}
              /> */}
              </Row>
              <Typography.Paragraph
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginTop: '10px',
                  color: '#525252',
                }}
              >
                Keep all your agent data to be onboarded ready in a CSV File
              </Typography.Paragraph>
              <Card style={{ backgroundColor: '#F5F5F7', marginTop: '16px' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Row align="middle">
                      <img src={googleSheetsLogo} alt="logo" />
                      <Typography.Paragraph
                        style={{
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '15px',
                        }}
                      >
                        {fileName} + 1
                      </Typography.Paragraph>
                    </Row>
                    <Typography.Paragraph
                      style={{ fontSize: '12px', color: '#ABABAB' }}
                    >
                      Download this attached example file and use it as a
                      reference <br /> to avoid error while matching column
                      attributes
                    </Typography.Paragraph>
                  </Col>
                  <Col>
                    <Row justify="end">
                      <Button
                        size="large"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          setShowTemplatePopup(true);
                        }}
                      >
                        <SelectOutlined /> Show Template
                      </Button>
                      <Button href={fileLink} size="large">
                        Download
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Card>
            <Button
              size="large"
              style={{ width: '100%', marginTop: '10px' }}
              className="button"
              onClick={() => {
                multipleOnboardStore.setStartedOnboarded(true);
              }}
            >
              Start Onboarding
            </Button>
          </div>
        )}
        {multipleOnboardStore.startedOnboard && (
          <div>
            {/* <Card
              style={{
                padding: '0',
                borderRadius: '6px 6px 0px 0px',
              }}
            >
              <CustomSteps
                steps={steps}
                current={multipleOnboardStore.currentStep}
                onClickStep={handleStepClick}
                maxStep={multipleOnboardStore.maxStep}
                minStep={multipleOnboardStore.minStep}
                disabled={
                  multipleOnboardStore.currentStep ===
                    MultipleOnboardSteps.AGENT_VIEW && loader
                }
              />
            </Card> */}
            <Card
              style={{
                backgroundColor: '#FCFCFC',
                width: '100%',
                padding: '0',
                borderRadius: '0 0 8px 8px',
              }}
              className="bulk-onboarding-layout"
            >
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {multipleOnboardStore.currentStep !==
                  MultipleOnboardSteps.IMPORT_AGENTS &&
                  multipleOnboardStore.currentStep !==
                    MultipleOnboardSteps.ASSIGNMENT && (
                    <div>
                      <Typography.Paragraph
                        style={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'var(--secondary-color)',
                        }}
                      >
                        {stepInfo[multipleOnboardStore.currentStep].heading}
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        style={{
                          marginTop: '-15px',
                        }}
                        className="reset-icon-size"
                      >
                        <TextWithIcon
                          iconColor="#97BFBF"
                          textStyle={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#97BFBF',
                          }}
                          text={
                            stepInfo[multipleOnboardStore.currentStep].content
                          }
                          iconAlignment="left"
                          icon={<InfoCircleOutlined />}
                        />
                      </Typography.Paragraph>
                      {multipleOnboardStore.currentStep ===
                      MultipleOnboardSteps.ACTIONABLE_LICENSES && (
                      <Typography.Paragraph
                        style={{
                          marginTop: '-15px',
                        }}
                      >
                        <TextWithIcon
                          iconColor="#97BFBF"
                          textStyle={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#97BFBF',
                          }}
                          text="Creating licenses for bulk-assigned producers may take time. Please wait to reflect ongoing changes"
                          iconAlignment="left"
                          icon={<InfoCircleOutlined />}
                        />
                      </Typography.Paragraph>
                    )}
                    </div>
                  )}
              </div> */}
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.IMPORT_AGENTS &&
                fileLink && (
                  <OnboardDraft
                    handleNext={handleNext}
                    handleContinueOnboarding={(recordId: string) => {
                      handleContinueOnboarding(recordId);
                    }}
                    setOnboardingStage={setOnboardingStage}
                    onboardingStage={onboardingStage}
                    refreshKey={refreshKey}
                  />
                )}
              {
                <PreviewCSVModal
                  csvJson={requestBody || []}
                  onboardingStage={BulkOnboardingStage.RETRIEVE_SCB}
                  modalVisibility={modalVisibility}
                  handleContinueOnboarding={() =>
                    handleContinueOnboarding(selectedRecordId || '')
                  }
                  setModalVisibility={setModalVisibility}
                />
              }
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.RETRIEVE_REPORT && (
                <div
                  style={{
                    height: '780px',
                    alignItems: 'center',
                    width: '477px',
                    justifySelf: 'center',
                    textAlign: 'start',
                  }}
                  className="flex flex-col justify-center gap-3"
                >
                  <Lottie
                    animationData={pendingFile}
                    style={{ height: '120px', width: '120px' }}
                    loop={true}
                  />
                  <span className="font-figtree text-sm text-[#001F45] font-semibold">
                    NIPR Status Pending
                  </span>
                  <span className="font-figtree text-sm text-[#001F45] font-normal">
                    Retrieving Report...
                  </span>
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '28px',
                      width: '139px',
                      backgroundColor: '#E6E6E6',
                      color: '#001F45',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    disabled={isRetrievingPreviewData}
                    onClick={() => setModalVisibility(true)}
                  >
                    Preview Data
                  </Button>
                  <span className="text-[#848484] text-center font-normal text-sm">
                    The process of retrieving the report will take approximately
                    10 minutes. Feel free to return after that time to check the
                    status.
                  </span>
                </div>
              )}
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.RETRIEVED_REPORT && (
                <div
                  style={{
                    height: '780px',
                    alignItems: 'center',
                  }}
                  className="flex flex-col justify-center gap-3"
                >
                  <Lottie
                    animationData={successTick}
                    style={{
                      height: '140px',
                      width: '140px',
                      marginBottom: '-20px',
                    }}
                    loop={false}
                  />
                  <span className="font-figtree text-sm text-[#001F45] font-normal">
                    NIPR Verification Success
                  </span>
                  <Button
                    style={{
                      height: '28px',
                      width: '240px',
                      backgroundColor: '#001F45',
                      color: '#FCFAFF',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    onClick={() => {
                      multipleOnboardStore.setCurrentStep(
                        MultipleOnboardSteps.AGENT_VIEW
                      );
                    }}
                  >
                    <span className="font-medium text-sm text-center">
                      Continue Onboarding
                    </span>
                  </Button>
                  <Button
                    style={{
                      height: '28px',
                      width: '240px',
                      backgroundColor: '#E6E6E6',
                      color: '#001F45',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    onClick={() => setModalVisibility(true)}
                  >
                    <span className="font-medium text-sm text-center">
                      Preview Data
                    </span>
                  </Button>
                  <span className="text-[#848484] font-normal text-sm text-center w-[295px]">
                    You can always continue onboarding in the “View Onboarding
                    History” section Later.
                  </span>
                </div>
              )}
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.AGENT_VIEW && (
                <div style={{ position: 'relative', minHeight: '100vh' }}>
                  <div
                    style={{
                      padding: '16px 20px',
                      paddingBottom: '62px',
                    }}
                    className="flex flex-col gap-4 bg-[#f5f5f5]"
                  >
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col gap-2">
                        <span className="font-figtree font-semibold text-lg">
                          List of Producer’s to be onboarded
                        </span>
                        <span className="pb-3 font-figtree text-xs font-normal text-[#848484]">
                          These are the information of your producers in a CSV
                          File format.
                        </span>

                        <Input
                          id="search-input-bulk-onboarding"
                          placeholder="Search"
                          style={{ width: '100%', marginBottom: '20px' }}
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="flex flex-row gap-[14.7px]">
                        <div
                          style={{ border: '0.919px solid #e6e6e6' }}
                          className="flex flex-row w-[205px] h-[100px] bg-[#fff] rounded-[7.35px]"
                        >
                          <div
                            style={{ width: '100%' }}
                            className="flex flex-row gap-[10px] py-[14.7px] pl-[10.11px]"
                          >
                            <AgencyLogo
                              style={{ height: '71.67px', width: '71.67px' }}
                              agencyNameInitial={(
                                multipleOnboardStore.getCanBeOnboardCount() +
                                multipleOnboardStore.getCannotBeOnboardedCount()
                              ).toString()}
                            />
                            <div className="flex flex-col">
                              <span
                                style={{ fontSize: '12.864px' }}
                                className="text-[#001F45] font-medium font-figtree"
                              >
                                Total Number of
                              </span>
                              <span
                                style={{ fontSize: '16.54px' }}
                                className="text-[#001F45] font-medium font-figtree"
                              >
                                Producers
                              </span>
                              <span
                                style={{ fontSize: '11.026px' }}
                                className="text-[#989898] font-normal font-figtree"
                              >
                                in your CSV File
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ border: '0.919px solid #e6e6e6' }}
                          className="flex flex-row w-[205px] h-[100px] bg-[#fff] rounded-[7.35px]"
                        >
                          <div
                            style={{ width: '100%' }}
                            className="flex flex-row gap-[20.22px] py-[14.7px] pl-[10.11px]"
                          >
                            <CircularProgress
                              size="lg"
                              determinate
                              value={
                                (multipleOnboardStore.canBeOnboardCount /
                                  (multipleOnboardStore.canBeOnboardCount +
                                    multipleOnboardStore.cannotBeOnboardedCount)) *
                                100
                              }
                            >
                              {`${multipleOnboardStore.canBeOnboardCount}/${
                                multipleOnboardStore.canBeOnboardCount +
                                multipleOnboardStore.cannotBeOnboardedCount
                              }`}
                            </CircularProgress>
                            <div className="flex flex-col">
                              <span
                                style={{ fontSize: '12.864px' }}
                                className="text-[#001F45] font-medium font-figtree"
                              >
                                Number of
                              </span>
                              <span
                                style={{ fontSize: '16.54px' }}
                                className="text-[#001F45] font-medium font-figtree"
                              >
                                Producers
                              </span>
                              <span
                                style={{ fontSize: '11.026px' }}
                                className="text-[#989898] font-normal font-figtree"
                              >
                                Onboarded
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loader ? (
                      <Skeleton active />
                    ) : (
                      <Table
                        dataSource={filteredData}
                        columns={producerViewTableColumns}
                        rowSelection={rowSelection}
                        pagination={{
                          defaultPageSize: 100,
                          showSizeChanger: false,
                        }}
                        rowKey={(record: any) => record.npn}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      padding: '16px 20px',
                      position: 'absolute',
                      bottom: 0,
                      height: '62px',
                      width: '100%',
                      backgroundColor: '#fff',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '16px',
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      onClick={() =>
                        multipleOnboardStore.setCurrentStep(
                          MultipleOnboardSteps.IMPORT_AGENTS
                        )
                      }
                      className="w-[92px] h-[36px] justify-center pl-6 pr-6 bg-[#E8EBEE] font-figtree font-normal text-sm rounded-md border-none"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleOnboard}
                      className="w-[162px] h-[36px] bg-[#00A6FB] justify-center pl-6 pr-4 font-figtree font-normal text-sm rounded-md border-none text-[#fff]"
                    >
                      Onboard Producers
                    </Button>
                  </div>
                </div>
              )}
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.ONBOARDING && (
                <div
                  style={{
                    height: '780px',
                    alignItems: 'center',
                  }}
                  className="flex flex-col justify-center gap-3"
                >
                  <Lottie
                    animationData={pendingFile}
                    style={{ height: '120px', width: '120px' }}
                    loop={true}
                  />
                  <span className="font-figtree text-sm text-[#001F45] font-semibold">
                    Producer Onboard is in Progress...
                  </span>
                  <Button
                    style={{
                      height: '28px',
                      backgroundColor: '#E6E6E6',
                      color: '#001F45',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    onClick={() =>
                      navigate(RouteConstants.manageAssignments.path)
                    }
                  >
                    <span className="font-medium text-sm text-center">
                      Go to Manage Producers
                    </span>
                  </Button>
                  <span className="text-[#848484] font-normal text-sm text-center w-[492px]">
                    Onboarding your producers may take a little time, so feel
                    free to sit back and relax while we take care of it. Or, if
                    you&apos;d like, you can go back to your work and check the
                    status anytime!
                  </span>
                </div>
              )}
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.ONBOARDED && (
                <div
                  style={{
                    height: '780px',
                    alignItems: 'center',
                  }}
                  className="flex flex-col justify-center gap-3"
                >
                  <Lottie
                    animationData={successTick}
                    style={{
                      height: '140px',
                      width: '140px',
                      marginBottom: '-20px',
                    }}
                    loop={false}
                  />
                  <span className="font-figtree text-sm text-[#001F45] font-normal">
                    Producer onboarding Success
                  </span>
                  <Button
                    style={{
                      height: '28px',
                      width: '240px',
                      backgroundColor: '#001F45',
                      color: '#FCFAFF',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    onClick={() => {
                      multipleOnboardStore.setCurrentStep(
                        MultipleOnboardSteps.IMPORT_AGENTS
                      );
                    }}
                  >
                    <span className="font-medium text-sm text-center">
                      Add more Producers
                    </span>
                  </Button>
                  <Button
                    style={{
                      height: '28px',
                      width: '240px',
                      backgroundColor: '#E6E6E6',
                      color: '#001F45',
                      padding: '0 12px',
                      fontFamily: 'Figtree !important',
                      borderRadius: '6px',
                      border: 'none',
                    }}
                    onClick={() => setModalVisibility(true)}
                  >
                    <span className="font-medium text-sm text-center">
                      Preview Data
                    </span>
                  </Button>
                </div>
              )}

              {/* {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.AGENT_VIEW && (
                <ProducerView setIsBlocking={setIsBlocking} />
              )} */}

              {/* {multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.ACTIONABLE_LICENSES && <ActionableLicense />} */}
            </Card>
          </div>
        )}

        <Modal
          title={'Are you sure you want to exit the onboarding process?'}
          open={
            blocker.state === 'blocked' ||
            openWarningModal ||
            warningModalVisibility
          }
          maskClosable={false}
          closable={false}
          width={600}
          onOk={() => {
            if (
              multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.AGENT_VIEW
            )
              setWarningModalVisibility(false);
            if (openWarningModal) changeTab(false);
            else blocker.reset?.();
          }}
          onCancel={() => {
            setIsBlocking(false);
            if (openWarningModal) changeTab(true);
            else blocker.proceed?.();
            if (
              multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.AGENT_VIEW
            ) {
              setWarningModalVisibility(false);
              multipleOnboardStore.setCurrentStep(
                multipleOnboardStore.currentStep - 1
              );
            }
          }}
          okText={
            multipleOnboardStore.currentStep === 1
              ? 'Continue Onboarding'
              : 'Complete onboarding'
          }
          cancelButtonProps={{
            className: 'leave-onboard important-action-button',
            disabled:
              multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.AGENT_VIEW && loader,
          }}
          cancelText={
            multipleOnboardStore.currentStep === 1
              ? 'Leave'
              : 'Exit and Complete Later'
          }
        >
          {multipleOnboardStore.currentStep === 1 ? (
            <p>
              Leaving the onboarding process at this stage will discard all the
              information and your onboarding progress will not be saved. Are
              you sure you want to exit?
            </p>
          ) : (
            <p>
              The producers have been onboarded. However, the onboarding process
              involves additional steps that should be completed in their
              respective sections on the platform if you choose to exit. Are you
              sure?
            </p>
          )}
        </Modal>
        <LookupTemplatePopup
          visible={showTemplatePopup}
          setVisible={(visible: boolean) => {
            if (!visible) {
              setShowTemplatePopup(false);
            }
            return showTemplatePopup;
          }}
          lookupTemplateDetails={{
            columns: columns,
            selectedCard: multipleOnboardStore.lookupMethod,
            data: data,
          }}
        />
      </div>
    </>
  );
}

export default withRoles(observer(MultipleOnboard), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
