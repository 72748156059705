import {
  Button,
  Card,
  Col,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
} from 'antd';
import CloneTerritory, {
  cloneState,
} from './editStateGroup/saveAsNewStateGroup/cloneTerritory';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../services/agency.service';
import CopyIconCustom from '../../components/common/icons/CopyIconCustom';
import DeleteConfirmationModal from './editStateGroup/modals/deleteStateGroup';
import EmptyState from '../../components/common/emptyState/emptyState';
import { IdConstants } from '../../constants/id.constants';
import ManageTerritoryTour from '../../components/common/Tour/manageTerritoryTour';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import StateGroupTable from '../../components/common/tables/stateGroupTable';
import { UpdatedStateGroup } from '../../types/data/stateGroup.types';
import { adminStore } from '../../stores/admin.store';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { buttonStyle } from '../agents/agentsOverview/organizationView/organizationUtils';
import { getStateNameFromCode } from '../../utils/common.utils';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../stores/tour.store';
import { useAuth } from '../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../auth/useRoles';

const { Search } = Input;

function StateGroups() {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(100);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [stateGroups, setStateGroups] = useState<UpdatedStateGroup[]>([]);
  const [selectedRows, setSelectedRows] = useState<UpdatedStateGroup[]>([]);
  const [stateGroupsTotalCount, setStateGroupsTotalCount] = useState<number>(0);
  const { getAccessTokenSilently } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [sortValue, setSortValue] = useState({});
  const [selectedTerritory, setSelectedTerritory] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [stateGroupId, setStateGroupId] = useState<string[]>([]);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [isViewAgents, setIsViewAgents] = useState(false);
  const [cloneTerritory, setCloneTerritory] = useState<cloneState>({
    loading: false,
    isOpen: false,
    title: '',
    isManage: false,
  });

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchStateGroups(queryText || '', newPageNumber, newPageSize);
  };

  async function fetchStateGroups(
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) {
    if (adminStore.agency?.id) {
      setIsLoading(true);
      if (tourStore.manageTerritoryCurrentStep === 5) {
        tourStore.setManageTerritoryTour(false);
      }
      const token = await getAccessTokenSilently();
      await AgencyService.getStateGroupsForAgency(
        token,
        page || pageNumber,
        size || pageSize,
        searchTextProp ? searchTextProp : queryText || '',
        { ...(sort || sortValue || {}) }
      )
        .then((resp) => {
          if (resp && resp.data) {
            const data = resp.data;
            data.forEach((item) => {
              item.stateLicenseConfigs.sort((a, b) => {
                const stateNameA = getStateNameFromCode(a.stateCode);
                const stateNameB = getStateNameFromCode(b.stateCode);
                return stateNameA.localeCompare(stateNameB);
              });
            });
            setStateGroups(resp.data);
            if ('totalCount' in resp) {
              setStateGroupsTotalCount(resp.totalCount);
            }
          }
        })
        .catch((error) => {
          console.error('StateGroups :: fetchStateGroups :: error :: ', error);
        })
        .finally(() => {
          if (tourStore.manageTerritoryCurrentStep === 5) {
            tourStore.setManageTerritoryTour(true);
          }
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    fetchStateGroups();
  }, [adminStore.account, queryText]);

  useEffect(() => {
    setSearchString(queryText || '');
  }, [queryText]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(
        selectedRows.map((d) => ({
          ...d,
          producer: { npn: d?.npn, id: d?.id },
        }))
      );
    },
    selectedRowKeys: selectedRows?.map((d) => d.id),
  };

  const getStates = () => {
    return selectedTerritory?.stateLicenseConfigs
      ?.map((d: { stateCode: string; stateName: string }) => ({
        stateCode: d?.stateCode,
        stateName: getStateNameFromCode(d?.stateCode),
      }))
      ?.filter((state: { stateCode: string; stateName: string }) =>
        state?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
  };

  useEffect(() => {
    setSearchQuery('');
  }, [selectedTerritory?.id]);

  const loadingDuplicateTerritory =
    cloneTerritory.loading && !cloneTerritory.isManage;

  return (
    <div>
      <Col span={24} className="flex">
        <div
          style={{
            marginRight: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '10px',
              paddingTop: 22,
            }}
            className="relative table-layout-v2 figtree v2-search"
          >
            <div
              style={{
                display: 'flex',
                gap: 12,
                justifyContent: 'space-between',
              }}
            >
              <Search
                id={IdConstants.SEARCH_INPUT + '-state-group'}
                placeholder="Search Territory by Name"
                size="middle"
                style={{
                  width: 372,
                }}
                value={searchString}
                // allowClear
                onChange={(e) => {
                  if (e.target.value === '') {
                    setPageNumber(1);
                    setQueryText('');
                  }
                  setSearchString(e.target.value);
                }}
                onSearch={(value) => {
                  if (queryText !== value) {
                    setPageNumber(1);
                    setQueryText(value);
                  }
                }}
                onPressEnter={(e) => {
                  if (queryText !== (e.target as HTMLInputElement).value) {
                    setQueryText((e.target as HTMLInputElement).value);
                    setPageNumber(1);
                  }
                }}
                disabled={isLoading || subscriptionStatusInactive}
              />
              <div
                className="flex reset-icon-size font-14"
                style={{ gap: 12, alignItems: 'center' }}
              >
                <Tooltip
                  title={
                    selectedRows.length > 0 || subscriptionStatusInactive
                      ? null
                      : 'Select Territories to Remove'
                  }
                >
                  <Button
                    id={'remove-territories'}
                    className={`button figtree ${
                      selectedRows.length > 0 && !subscriptionStatusInactive
                        ? 'important-action-button'
                        : 'disabled-action-button'
                    }`}
                    disabled={
                      subscriptionStatusInactive
                        ? true
                        : selectedRows.length > 0 && !isLoading
                          ? false
                          : true
                    }
                    onClick={() => {
                      setDeleteModalVisibility(true);
                      setStateGroupId(selectedRows?.map((d) => d.id));
                    }}
                    style={{
                      opacity:
                        selectedRows.length > 0 && !isLoading ? '100%' : '50%',
                      height: 28,
                      padding: '2px 16px',
                    }}
                  >
                    <DeleteOutlined /> Remove
                  </Button>
                </Tooltip>
                {selectedRows?.length ? (
                  <Tooltip
                    title={
                      selectedRows.length > 0
                        ? null
                        : 'Select Territories to Duplicate'
                    }
                  >
                    <Button
                      id={'duplicate-territories'}
                      style={{
                        ...buttonStyle,
                        margin: 0,
                        opacity: subscriptionStatusInactive
                          ? '50%'
                          : !loadingDuplicateTerritory
                            ? '100%'
                            : '50%',
                        height: 28,
                        padding: '2px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 6,
                      }}
                      disabled={
                        !subscriptionStatusInactive
                          ? true
                          : !cloneTerritory.loading
                            ? false
                            : true
                      }
                      loading={loadingDuplicateTerritory}
                      onClick={() => {
                        setCloneTerritory((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                    >
                      <CopyIconCustom /> Duplicate Territories
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Create Territory">
                    <Button
                      id={
                        IdConstants.STATE_GROUPS.CREATE_STATE_GROUPS_BUTTON +
                        '-state-groups'
                      }
                      className="figtree"
                      style={{
                        ...buttonStyle,
                        margin: 0,
                        height: 28,
                        padding: '2px 16px',
                      }}
                      onClick={() =>
                        !subscriptionStatusInactive &&
                        navigate(`${RouteConstants.createStateGroup.path}`)
                      }
                      size="middle"
                      disabled={subscriptionStatusInactive}
                    >
                      <PlusOutlined /> Create
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div>
              <Row
                className="table table-layout-v2"
                style={{ marginTop: '10px', rowGap: '10px' }}
              >
                <Col
                  span={24}
                  style={{
                    width: `calc(100vw - ${
                      adminStore.isCollapsed
                        ? selectedTerritory
                          ? '430px'
                          : '130px'
                        : selectedTerritory
                          ? '600px'
                          : '300px'
                    })`,
                  }}
                >
                  <StateGroupTable
                    loader={isLoading}
                    stateGroups={stateGroups}
                    refresh={fetchStateGroups}
                    setSortValue={setSortValue}
                    rowSelection={rowSelection}
                    selectedTerritory={selectedTerritory}
                    setSelectedTerritory={setSelectedTerritory}
                  />
                </Col>
              </Row>
            </div>
            {!isEmpty(stateGroups) && (
              <Row
                className="pagination"
                style={{
                  marginBottom: '10px',
                }}
                justify="end"
              >
                <Pagination
                  current={pageNumber}
                  defaultCurrent={pageNumber}
                  total={stateGroupsTotalCount}
                  pageSize={pageSize}
                  onChange={onPaginationChange}
                  showSizeChanger={false}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  disabled={isLoading}
                />
              </Row>
            )}
          </div>
        </div>
        {selectedTerritory ? (
          <div
            style={{
              minWidth: 300,
              padding: '12px',
              background: '#ffffff',
              overflow: 'auto',
              height: 'calc(100vh - 72px)',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 0 12px',
                cursor: 'pointer',
              }}
              className="ant-modal-close-selected-producer"
            >
              <CloseOutlined
                onClick={() => {
                  setSearchQuery('');
                  setSelectedTerritory(null);
                }}
                style={{ padding: 5, borderRadius: 6 }}
              />
            </div>
            <Card className="remove-padding">
              <div className="figtree">
                <div
                  style={{
                    borderRadius: '8px 0 0 8px',
                  }}
                  className="flex flex-col items-center w-full gap-3 py-4 px-5 justify-center bg-[#EDF9FF] border border-[#EDF9FF]"
                >
                  <div
                    style={{
                      fontWeight: 400,
                      lineHeight: '20px',
                      fontSize: 12,
                      color: '#989898',
                    }}
                    className="figtree"
                  >
                    This territory has{' '}
                    {selectedTerritory?.stateLicenseConfigs?.length}
                    {selectedTerritory?.stateLicenseConfigs?.length
                      ? ' states'
                      : ' state'}{' '}
                    in total
                  </div>
                  <div
                    className="figtree"
                    style={{
                      fontWeight: 400,
                      lineHeight: '30px',
                      fontSize: 18,
                      color: '#001F45',
                      background: '#DAF2FF',
                      padding: '2px 18px',
                      borderRadius: 39,
                    }}
                  >
                    {selectedTerritory?.name}
                  </div>
                </div>

                <div
                  className="flex-center"
                  style={{
                    borderBottom: '1px solid #F5F6F7',
                    gap: 12,
                    padding: '8px 0',
                  }}
                >
                  <Tooltip title="Manage Territory">
                    <Button
                      id={
                        IdConstants.STATE_GROUPS.CREATE_STATE_GROUPS_BUTTON +
                        'manage-territory'
                      }
                      className="figtree reset-icon-size font-14"
                      style={{
                        ...buttonStyle,
                        margin: 0,
                        height: 28,
                        padding: '2px 16px',
                      }}
                      onClick={() =>
                        navigate(
                          `${RouteConstants.editStateGroup.path.replace(
                            ':id',
                            selectedTerritory?.id
                          )}`
                        )
                      }
                      size="middle"
                      disabled={subscriptionStatusInactive}
                    >
                      <EditOutlined /> Manage
                    </Button>
                  </Tooltip>
                  <Button
                    id={'duplicate-territory'}
                    style={{
                      ...buttonStyle,
                      margin: 0,
                      opacity: !(
                        cloneTerritory.loading && cloneTerritory.isManage
                      )
                        ? '100%'
                        : '50%',
                      height: 28,
                      padding: '2px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 6,
                    }}
                    loading={cloneTerritory.loading && cloneTerritory.isManage}
                    disabled={
                      subscriptionStatusInactive
                        ? true
                        : !cloneTerritory.loading
                          ? false
                          : true
                    }
                    onClick={() => {
                      setCloneTerritory((prev) => ({
                        ...prev,
                        isOpen: true,
                        isManage: true,
                      }));
                    }}
                  >
                    <CopyIconCustom /> Duplicate
                  </Button>
                </div>
              </div>
            </Card>
            <div>
              <Input
                placeholder="Search states in this territory"
                prefix={<SearchOutlined />}
                allowClear
                value={searchQuery}
                style={{ margin: '16px 0' }}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                maxHeight: 'calc(100vh - 410px)',
                overflow: 'auto',
              }}
            >
              {getStates()?.length === 0 ? (
                <div
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    height: '475px',
                  }}
                >
                  <EmptyState
                    image="empty data"
                    heading=""
                    width="100%"
                    content="No States Found"
                  />
                </div>
              ) : null}
              {getStates()?.map((state: any) => {
                return (
                  <div
                    key={state.stateCode}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '0 8px',
                        borderRadius: '6px',
                      }}
                    >
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '26px',
                          border: '1px solid #437EE1',
                          padding: '2px 6px',
                          background: '#F1F8FE',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: '10px',
                            lineHeight: '20px',
                          }}
                        >
                          {state.stateCode}
                        </span>
                      </div>
                      <div>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '22px',
                            color: '#001F45',
                          }}
                        >
                          {state.stateName}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {tourStore.manageTerritoryTour && <ManageTerritoryTour />}
        <CloneTerritory
          cloneTerritory={cloneTerritory}
          setCloneTerritory={setCloneTerritory}
          handleSuccess={() => {
            fetchStateGroups();
            setSelectedRows([]);
          }}
          stateGroups={stateGroups}
          selectedRows={selectedRows}
          selectedTerritory={selectedTerritory}
        />

        {deleteModalVisibility ? (
          <DeleteConfirmationModal
            stateGroupId={stateGroupId}
            visible={deleteModalVisibility}
            handleVisibility={(success?: boolean) => {
              setDeleteModalVisibility(false);
              if (success) {
                if (
                  selectedRows.map((d) => d.id).includes(selectedTerritory?.id)
                ) {
                  setSelectedTerritory(null);
                }
                setSelectedRows([]);
                fetchStateGroups();
              }
            }}
          />
        ) : null}
        {/* {isViewAgents && (
          <AgentsByStateGroupModal
            stateGroupId={stateGroupId}
            isViewAgents={isViewAgents}
            close={() => setIsViewAgents(false)}
          />
        )} */}
      </Col>
    </div>
  );
}

export default withRoles(observer(StateGroups), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
