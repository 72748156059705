/* eslint-disable no-async-promise-executor */
import { ApplicantType } from '../enums/applicantType.enum';
import { ArraySuccessResponse } from '../types/response/arraySuccessResponse.type';
import { DrlpConfig } from '../types/data/drlpConfig.type';
import { LicenseClassDto } from '../types/data/licenseClass.type';
import { LicenseConfigurationDto } from '../types/data/licenseConfiguration.type';
import { LoaDto } from '../types/data/loa.type';
import { StateRenewalConfig } from '../types/data/licenseFeesConfig.type';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';

export class MasterDataService {
  static host = UrlConstants.backend;
  static modulePath = '/master-data/';

  static async getStates(token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const agent = await apiStore.getApiClient(token).get(`${this.host}`, {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        });

        resolve(agent);
      } catch (error: any) {
        console.error(
          'masterDataService :: getStates :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Retrieves background questions
   * /master-data/background-questions?size=50&page=1
   * @param bearerToken
   * @returns
   */
  static async getBackgroundQuestions(
    bearerToken: string,
    applicantType: ApplicantType = ApplicantType.INDIVIDUAL
  ) {
    const path =
      applicantType === ApplicantType.INDIVIDUAL
        ? '/bg-question/producer/all'
        : '/bg-question/agency/all';
    return new Promise(async (resolve, reject) => {
      try {
        const backgroundQuestions = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(
          backgroundQuestions.data?.data?.map((d: any) => ({
            ...d,
            questionId: d.questionKey,
            _id: d.id,
          })) || []
        );
      } catch (error: any) {
        console.error(
          'masterDataService :: getBackgroundQuestions :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getLicenseConfigs(
    bearerToken: string
  ): Promise<ArraySuccessResponse<LicenseConfigurationDto>> {
    const path = '/license-configuration';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as ArraySuccessResponse<LicenseConfigurationDto>);
      } catch (error: any) {
        console.error(
          'masterDataService :: getLicenseConfigs :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getLoas(
    bearerToken: string
  ): Promise<ArraySuccessResponse<LoaDto>> {
    const path = '/master-data/loas';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as ArraySuccessResponse<LoaDto>);
      } catch (error: any) {
        console.error(
          'masterDataService :: getLoas :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getLicenseClasses(
    bearerToken: string
  ): Promise<ArraySuccessResponse<LicenseClassDto>> {
    const path = '/master-data/lcs';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as ArraySuccessResponse<LicenseClassDto>);
      } catch (error: any) {
        console.error(
          'masterDataService :: getLicenseClasses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getDrlpConfigs(
    bearerToken: string
  ): Promise<ArraySuccessResponse<DrlpConfig>> {
    const path = '/drlp-configurations';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as ArraySuccessResponse<DrlpConfig>);
      } catch (error: any) {
        console.error(
          'masterDataService :: getLicenseClasses :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getStateRenewalConfigs(
    bearerToken: string
  ): Promise<ArraySuccessResponse<StateRenewalConfig>> {
    const path = '/license/firm-renewal-configs';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response.data as ArraySuccessResponse<StateRenewalConfig>);
      } catch (error: any) {
        console.error(
          'masterDataService :: getStateRenewalConfig :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async getStateIndividualRenewalConfigs(
    bearerToken: string
  ): Promise<StateRenewalConfig[]> {
    const path = '/license/individual-renewal-configs';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(response?.data?.data as StateRenewalConfig[]);
      } catch (error: any) {
        console.error(
          'masterDataService :: getStateIndividualRenewalConfigs :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }
}
