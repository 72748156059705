export enum siderMenuKey {
  // admin portal
  ADMIN_PORTAL_PRODUCERS = 'admin-portal-producers',
  ADMIN_PORTAL_AGENCY = 'admin-portal-agency',
  ADMIN_PORTAL_SETUP = 'admin-portal-setup',
  ADMIN_PORTAL_MY_ACCOUNT = 'admin-portal-my-account',
  ADMIN_PORTAL_PRODUCERS_OVERVIEW_MY_PRODUCER = 'admin-producer-overview-my-producer',
  ADMIN_PORTAL_PRODUCERS_OVERVIEW_ONBOARDING = 'admin-producer-overview-onboarding',
  // producer portal
  PRODUCER_PORTAL_MY_PRODUCER = 'producer-portal-my-producer',
  PRODUCER_PORTAL_ONBOARDING = 'producer-portal-onboarding',
  PRODUCER_PORTAL_MY_ACCOUNT = 'producer-portal-my-account',
}
