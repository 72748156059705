export enum ApplicationStatusEnum {
  Success = 'successful',
  Applied = 'applied',
  Declined = 'declined',
  Not_Applied = 'not_applied',
  PENDING = 'pending',
  MISSING_LOA = 'missing_loa',
}

export enum ApplicationStatusDisplayEnum {
  APPLIED = 'Applied',
  DECLINED = 'Declined',
  RENEWAL_PENDING = 'Renewal Pending',
  RENEWAL_DECLINED = 'Renewal Declined',
  MISSING_LOA = 'Missing LOA',
  DECLINED_LOA = 'LOA Declined',
}
